import { isClient } from "@/utils";
import initializeSuperTokens from "./superTokens";
import initializeTelemetry from "@/open-telemetry";
import { SHOULD_TRACE_EVENTS } from "@/constants";

const initialize = () => {
    initializeSuperTokens();

    if (isClient() && SHOULD_TRACE_EVENTS) {
        initializeTelemetry();
    }
};

export default initialize;
