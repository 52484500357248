import ROUTES from "@/constants/routes";

const replaceHistoryToRouteToHomeOnBackButton = () => {
    const currentState = window.history.state;

    const newUrl = ROUTES.home();

    window.history.replaceState(
        { ...currentState, as: newUrl, url: newUrl },
        "",
        ROUTES.home()
    );
};

export default replaceHistoryToRouteToHomeOnBackButton;
