type GetIsCurrentUserTurnProps = {
    isCurrentUserFirstMove: boolean;
    totalNumberOfMoves: number;
};

const getIsCurrentUserTurn = ({
    isCurrentUserFirstMove,
    totalNumberOfMoves,
}: GetIsCurrentUserTurnProps) => {
    let isCurrentUserTurn = isCurrentUserFirstMove;

    // loop through each move and check if it is current user's turn
    for (let i = 0; i < totalNumberOfMoves; i++) {
        isCurrentUserTurn = isCurrentUserTurn ? false : true;
    }

    return isCurrentUserTurn;
};

export default getIsCurrentUserTurn;
