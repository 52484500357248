import { Wallet_Transaction_Type_Enum } from "@/types/generated-types";

const getTransactionTitleFromType=(type: string) => {
    switch (type) {
        case Wallet_Transaction_Type_Enum.Deposit: {
            return "Deposit";
        }
        case Wallet_Transaction_Type_Enum.Withdrawal: {
            return "Withdrawal";
        }
        case Wallet_Transaction_Type_Enum.Bonus: {
            return "Bonus";
        }
        case Wallet_Transaction_Type_Enum.EntryFees: {
            return "Entry Fees";
        }
        case Wallet_Transaction_Type_Enum.Gst: {
            return "GST";
        }
        case Wallet_Transaction_Type_Enum.Tds: {
            return "TDS";
        }
        case Wallet_Transaction_Type_Enum.TournamentWinnings:
        case Wallet_Transaction_Type_Enum.Winning: {
            return "Winnings";
        }
        case Wallet_Transaction_Type_Enum.Refund: {
            return "Refund";
        }
    }
};

export default getTransactionTitleFromType;