import LocalStorageService from "./localStorageService";

class AnonymousUser {
    isCreated = false;
    localStorageAnonymousJwtKey = "anonymousJwt";

    constructor() {
        this.refreshIsCreated();
    }

    setAnonymousJwt(jwt: string) {
        if (!jwt) return;
        LocalStorageService.set(this.localStorageAnonymousJwtKey, jwt);
        this.refreshIsCreated();
    }

    refreshIsCreated() {
        LocalStorageService.get(this.localStorageAnonymousJwtKey)
            ? (this.isCreated = true)
            : (this.isCreated = false);
    }
}

export default new AnonymousUser();
