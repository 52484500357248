import { createContext } from "react";


type CurrentUser = {
    id: string;
    userID: string;
    walletBalance: number;
    userName: string;
    isUserActivated: boolean;
    isTournamentEnabled: boolean;
    isAdmin: boolean;
    anonymousUserInfo: {
        userType?: string,
        anonymousUserID?: string,
        currentOnboardGame?: string,
    };
};

const CurrentUserContext = createContext<{
    id: string | null;
    userID: string | null;
    userName: string | null;
    walletBalance: number | null;
    isUserActivated: boolean | null;
    isTournamentEnabled: boolean;
    isAdmin: boolean | null;
    anonymousUserInfo: {
        userType?: string | null;
        anonymousUserID?: string | null;
        currentOnboardGame?: string | null;
    } | null;
}>({} as CurrentUser);

export default CurrentUserContext;