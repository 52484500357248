import { toast as ReactToastify } from "react-toastify";

type ToastProps =
    | {
          type: "success" | "info" | "error" | "warning" | string;
          message: string;
          duration?: number;
      }
    | {
          type: "dismiss";
          message?: string;
          duration?: number;
      };

const toast = ({ type, message, duration = 2000 }: ToastProps) => {
    if (type === "dismiss") {
        ReactToastify.dismiss();

        return;
    }

    // @ts-ignore
    const reactNativeWebView = window.ReactNativeWebView;

    if (reactNativeWebView === undefined) {
        ReactToastify.dismiss();

        switch (type) {
            case "success": {
                ReactToastify.success(message);
                break;
            }
            case "error": {
                ReactToastify.error(message);
                break;
            }
            case "info": {
                ReactToastify.info(message);
                break;
            }
            case "warning": {
                ReactToastify.warning(message);
                break;
            }
            default: {
                ReactToastify(message);
                break;
            }
        }
    } else {
        reactNativeWebView.postMessage(
            JSON.stringify({
                event: "toast",
                payload: {
                    type,
                    message,
                    duration,
                },
            })
        );
    }
};

export default toast;
