import isClient from "./isClient";

class LocalStorageService {
    public static get(key: string) {
        if (!isClient()) {
            return null;
        }

        try {
            const serializedValue = window.localStorage.getItem(key);

            return serializedValue ? JSON.parse(serializedValue || "{}") : null;
        } catch (error) {
            console.error(`Error getting value from localStorage: ${error}`);

            return JSON.parse("{}");
        }
    }

    public static set(key: string, value: unknown): void {
        if (!isClient()) {
            return;
        }

        try {
            const serializedValue = JSON.stringify(value);

            localStorage.setItem(key, serializedValue);
        } catch (error) {
            console.error(`Error setting value in localStorage: ${error}`);
        }
    }

    public static update(
        key: string,
        updater: (prevValue: unknown) => unknown
    ): void {
        if (!isClient()) {
            return;
        }

        try {
            const serializedPrevValue = localStorage.getItem(key);

            const prevValue = JSON.parse(serializedPrevValue || "{}");

            const updatedValue = updater(prevValue);

            const serializedUpdatedValue = JSON.stringify(updatedValue);

            localStorage.setItem(key, serializedUpdatedValue);
        } catch (error) {
            console.error(`Error updating value in localStorage: ${error}`);
        }
    }

    public static remove(key: string): void {
        if (!isClient()) {
            return;
        }

        try {
            localStorage.removeItem(key);
        } catch (error) {
            console.error(`Error removing value from localStorage: ${error}`);
        }
    }
}

export default LocalStorageService;
