// eslint-disable-next-line unicorn/filename-case
import { Chess } from "chess.js";
import switchNextMoveSideInFEN from "../switchNextMoveSideInFEN";

type GetCurrentFENProps = {
    moves: {
        sourceSquare?: string | null;
        targetSquare?: string | null;
        isTimerExpired?: boolean;
        promotion?: string | null;
    }[];
    initialFEN: string;
};

const getCurrentFEN = ({ moves, initialFEN }: GetCurrentFENProps) => {
    let game = new Chess(initialFEN);

    try {
        for (const { sourceSquare, targetSquare, isTimerExpired, promotion } of moves) {
            if (isTimerExpired) {
                // since timer expired switch the sides
                game = new Chess(switchNextMoveSideInFEN(game.fen()));
                continue;
            }

            if (!sourceSquare || !targetSquare) {
                throw new Error(
                    "getCurrentFEN: sourceSquare or targetSquare is not defined"
                );
            }

            game.move({
                from: sourceSquare,
                to: targetSquare,
                promotion: promotion || "q",
            });
        }

        return game.fen();
    } catch (error) {
        console.log(
            JSON.stringify({
                error,
                moves,
                initialFEN,
            })
        );

        throw error;
    }
};

export default getCurrentFEN;
