/* eslint-disable max-lines */

/* eslint-disable @typescript-eslint/no-explicit-any */

/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Attributes } from "@opentelemetry/api";
import { getEnv, baggageUtils } from "@opentelemetry/core";
import * as core from "@opentelemetry/core";
import { hrTimeToNanoseconds } from "@opentelemetry/core";
import {
    OTLPExporterBrowserBase,
    OTLPExporterConfigBase,
    appendResourcePathToUrl,
    appendRootPathToUrlIfNeeded,
} from "@opentelemetry/otlp-exporter-base";
import { IExportTraceServiceRequest } from "@opentelemetry/otlp-transformer";
import { ReadableSpan, SpanExporter } from "@opentelemetry/sdk-trace-base";
import getUpdatedReadableSpans from "./getUpdatedReadableSpans";
import getUpdatedSpanName from "./getUpdatedSpanName";

export function toAnyValue(value: unknown): any {
    const t = typeof value;

    if (t === "string") return { stringValue: value as string };
    if (t === "number") {
        if (!Number.isInteger(value)) return { doubleValue: value as number };

        return { intValue: value as number };
    }
    if (t === "boolean") return { boolValue: value as boolean };
    if (value instanceof Uint8Array) return { bytesValue: value };
    if (Array.isArray(value))
        return {
            arrayValue: { values: value.map((element) => toAnyValue(element)) },
        };
    if (t === "object" && value != null)
        return {
            kvlistValue: {
                values: Object.entries(value as object).map(([k, v]) =>
                    toKeyValue(k, v)
                ),
            },
        };

    return {};
}

export function toKeyValue(key: string, value: unknown): any {
    return {
        key: key,
        value: toAnyValue(value),
    };
}

export function toAttributes(attributes: Attributes): any[] {
    return Object.keys(attributes).map((key) =>
        toKeyValue(key, attributes[key])
    );
}

function createResourceMap(readableSpans: ReadableSpan[]) {
    const resourceMap: Map<any, Map<string, ReadableSpan[]>> = new Map();

    for (const record of readableSpans) {
        let ilmMap = resourceMap.get(record.resource);

        if (!ilmMap) {
            ilmMap = new Map();
            resourceMap.set(record.resource, ilmMap);
        }

        // TODO this is duplicated in basic tracer. Consolidate on a common helper in core
        const instrumentationLibraryKey = `${
            record.instrumentationLibrary.name
        }@${record.instrumentationLibrary.version || ""}:${
            record.instrumentationLibrary.schemaUrl || ""
        }`;

        let records = ilmMap.get(instrumentationLibraryKey);

        if (!records) {
            records = [];
            ilmMap.set(instrumentationLibraryKey, records);
        }

        records.push(record);
    }

    return resourceMap;
}

export function toOtlpSpanEvent(timedEvent: any): any {
    return {
        attributes: timedEvent.attributes
            ? toAttributes(timedEvent.attributes)
            : [],
        name: timedEvent.name,
        timeUnixNano: hrTimeToNanoseconds(timedEvent.time),
        droppedAttributesCount: timedEvent.droppedAttributesCount || 0,
    };
}

export function toOtlpLink(link: any, useHex?: boolean): any {
    return {
        attributes: link.attributes ? toAttributes(link.attributes) : [],
        spanId: useHex
            ? link.context.spanId
            : core.hexToBase64(link.context.spanId),
        traceId: useHex
            ? link.context.traceId
            : core.hexToBase64(link.context.traceId),
        traceState: link.context.traceState?.serialize(),
        droppedAttributesCount: link.droppedAttributesCount || 0,
    };
}

export function sdkSpanToOtlpSpan(span: any, useHex?: boolean): any {
    const ctx = span.spanContext();

    const status = span.status;

    const userID = sessionStorage.getItem("userID") || "";

    const parentSpanId = useHex
        ? span.parentSpanId
        : span.parentSpanId == null
        ? undefined
        : core.hexToBase64(span.parentSpanId);

    const updatedSpanName = getUpdatedSpanName(span);

    return {
        traceId: useHex ? ctx.traceId : core.hexToBase64(ctx.traceId),
        spanId: useHex ? ctx.spanId : core.hexToBase64(ctx.spanId),
        parentSpanId: parentSpanId,
        traceState: ctx.traceState?.serialize(),
        name: updatedSpanName,
        // Span kind is offset by 1 because the API does not define a value for unset
        kind: span.kind == null ? 0 : span.kind + 1,
        startTimeUnixNano: hrTimeToNanoseconds(span.startTime),
        endTimeUnixNano: hrTimeToNanoseconds(span.endTime),
        attributes: toAttributes({
            ...span.attributes,
            "user.id": userID,
        }),
        droppedAttributesCount: span.droppedAttributesCount,
        events: span.events.map((element: any) => toOtlpSpanEvent(element)),
        droppedEventsCount: span.droppedEventsCount,
        status: {
            // API and proto enums share the same values
            code: status.code as unknown as any,
            message: status.message,
        },
        links: span.links.map((link: any) => toOtlpLink(link, useHex)),
        droppedLinksCount: span.droppedLinksCount,
    };
}

function spanRecordsToResourceSpans(
    readableSpans: ReadableSpan[],
    useHex?: boolean
) {
    const resourceMap = createResourceMap(readableSpans);

    const out: any[] = [];

    const entryIterator = resourceMap.entries();

    let entry = entryIterator.next();

    while (!entry.done) {
        const [resource, ilmMap] = entry.value;

        const scopeResourceSpans: any[] = [];

        const ilmIterator = ilmMap.values();

        let ilmEntry = ilmIterator.next();

        while (!ilmEntry.done) {
            const scopeSpans = ilmEntry.value;

            if (scopeSpans.length > 0) {
                const { name, version, schemaUrl } =
                    scopeSpans[0].instrumentationLibrary;

                const spans = scopeSpans.map((readableSpan) =>
                    sdkSpanToOtlpSpan(readableSpan, useHex)
                );

                scopeResourceSpans.push({
                    scope: { name, version },
                    spans: spans,
                    schemaUrl: schemaUrl,
                });
            }
            ilmEntry = ilmIterator.next();
        }

        // TODO SDK types don't provide resource schema URL at this time
        const transformedSpans: any = {
            resource: {
                attributes: toAttributes({
                    ...resource.attributes,
                }),
                droppedAttributesCount: 0,
            },
            scopeSpans: scopeResourceSpans,
            schemaUrl: undefined,
        };

        out.push(transformedSpans);
        entry = entryIterator.next();
    }

    return out;
}

export function createExportTraceServiceRequest(
    spans: ReadableSpan[],
    useHex?: boolean
) {
    return {
        resourceSpans: spanRecordsToResourceSpans(spans, useHex),
    };
}

const DEFAULT_COLLECTOR_RESOURCE_PATH = "v1/traces";

const DEFAULT_COLLECTOR_URL = `http://localhost:4318/${DEFAULT_COLLECTOR_RESOURCE_PATH}`;

/**
 * Collector Trace Exporter for Node
 */
/**
 * Collector Trace Exporter for Web
 */
export class OTLPTraceExporter
    extends OTLPExporterBrowserBase<ReadableSpan, IExportTraceServiceRequest>
    implements SpanExporter
{
    constructor(config: OTLPExporterConfigBase = {}) {
        super(config);
        this._headers = Object.assign(
            this._headers,
            baggageUtils.parseKeyPairsIntoRecord(
                getEnv().OTEL_EXPORTER_OTLP_TRACES_HEADERS
            )
        );
    }
    convert(spans: ReadableSpan[]): IExportTraceServiceRequest {
        const updatedReadableSpans = getUpdatedReadableSpans(spans);

        return createExportTraceServiceRequest(updatedReadableSpans, true);
    }

    getDefaultUrl(config: OTLPExporterConfigBase): string {
        return typeof config.url === "string"
            ? config.url
            : getEnv().OTEL_EXPORTER_OTLP_TRACES_ENDPOINT.length > 0
            ? appendRootPathToUrlIfNeeded(
                  getEnv().OTEL_EXPORTER_OTLP_TRACES_ENDPOINT
              )
            : getEnv().OTEL_EXPORTER_OTLP_ENDPOINT.length > 0
            ? appendResourcePathToUrl(
                  getEnv().OTEL_EXPORTER_OTLP_ENDPOINT,
                  DEFAULT_COLLECTOR_RESOURCE_PATH
              )
            : DEFAULT_COLLECTOR_URL;
    }
}
