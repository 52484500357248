import React from "react";


type Props = {
    className?: string;
};

const Loader = ({ className }: Props) => {
    return (
        <div className="flex h-full w-full flex-grow items-center justify-center">
            <div
                className={`my-10 h-6 w-6 animate-spin rounded-full border-t-2 border-dark-blue text-white ${
                    className ?? ""
                }`}
            />
        </div>
    );
};

export default Loader;