const ROUTES = {
    room: ({ roomID }: { roomID: string }) => `/room/${roomID}`,
    singlePlayer: ({ roomID }: { roomID: string }) =>
        `/room/single-player/${roomID}`,

    home: () => "/",
    login: () => `/auth/login`,
    wallet: () => `/wallet`,
    transactionHistory: () => `/transaction-history`,
    termsAndConditions: () => `/terms-and-conditions`,
    404: () => `/404`,
    rules: () => `/rules`,
    battleMode: () => `/battle-mode`,
    tournamentHome: () => `/tournament`,
    tournament: ({ tournamentID }: { tournamentID: string }) =>
        `/tournament/${tournamentID}`,
    tournamentLeaderboard: ({ tournamentID }: { tournamentID: string }) =>
        `/tournament/${tournamentID}/leaderboard/`,
    tournamentProgress: ({ tournamentID }: { tournamentID: string }) =>
        `/tournament/${tournamentID}/progress/`,
    withdraw: () => `/withdraw`,
    onBoard: () => `/onboarding`,
};

export default ROUTES;
