import { PIECE_SCORES } from "@/constants";
import { Moves } from "@/types";
import { Chess, Move, PieceSymbol } from "chess.js";
import getCurrentFEN from "../getCurrentFEN";

type CalculateScoreProps = {
    moves: Moves;
    initialFEN: string;
    side: "white" | "black";
};

const getScore = ({ initialFEN, moves, side }: CalculateScoreProps) => {
    if (moves.length === 0) return { score: 0 };

    let game = new Chess(initialFEN);

    const firstMoveTurn = game.turn();
    
    const isFirstMoveSide =
    side ===
    (firstMoveTurn === "b"
        ? "black"
        : firstMoveTurn === "w"
        ? "white"
            : "");
    
    let histories: (
        | Move
        | {
              isTimerExpired: true;
          }
    )[] = [];

    for (const [
        i,
        { sourceSquare, targetSquare, isTimerExpired, promotion },
    ] of moves.entries()) {
        if (isTimerExpired) {
            histories = [
                ...histories,
                ...game.history({ verbose: true }),
                {
                    isTimerExpired: true,
                },
            ];

            game = new Chess(
                getCurrentFEN({
                    moves: moves.slice(0, i + 1),
                    initialFEN,
                })
            );
            continue;
        }

        if (!sourceSquare || !targetSquare)
            throw new Error(
                "getCurrentFEN: sourceSquare or targetSquare is not defined"
            );

        game.move({
            from: sourceSquare,
            to: targetSquare,
            promotion: promotion || "q",
        });
    }

    histories.push(...game.history({ verbose: true }));

    let score = 0;

    const capturesList = [];

    for (const [index, move] of histories.entries()) {
        if ("isTimerExpired" in move) {
            if ((index % 2 === 0 && isFirstMoveSide) || (index % 2 !== 0 && !isFirstMoveSide))
                score--;
            continue;
        }

        const isCheck = move.san.endsWith("+");

        const nextMove = histories.length > index + 1 && histories[index + 1];

        if (isCheck && nextMove && "isTimerExpired" in nextMove) {
            return {
                score, captures: capturesList,
                isBlackKingCaptured: move.color === "w",
                isWhiteKingCaptured: move.color === "b",
            };
        }

        const piece = move.captured?.toLowerCase() as PieceSymbol;

        const isCheckMate = move.san.endsWith("#");

        const flag = move.flags;

        if (
            move?.captured &&
            ((side === "black" && move.color === "b") ||
                (side === "white" && move.color === "w"))
        ) {
            capturesList.push(piece);
        }

        if (isCheckMate) {
            if (
                (side === "black" && move.color === "b") ||
                (side === "white" && move.color === "w")
            ) {
                if (flag.includes("p") && move.promotion) {
                    score += (piece ? PIECE_SCORES[piece] : 0) + (move.promotion ? PIECE_SCORES[move.promotion] : 0) - PIECE_SCORES[move.piece];
                }
                else if (piece) {
                    score += PIECE_SCORES[piece];
                }
            }

            return {
                score: score, captures: capturesList,
                isBlackKingCaptured: move.color === "w",
                isWhiteKingCaptured: move.color === "b",
            };
        }        
            
        if (!piece && (!move.promotion || !flag.includes("p"))) continue;

        if (
            (side === "black" && move.color === "b") ||
            (side === "white" && move.color === "w")
        ) {            
            if (flag.includes("p") && move.promotion) {
                score += (piece ? PIECE_SCORES[piece] : 0) + (move.promotion ? PIECE_SCORES[move.promotion] : 0) - PIECE_SCORES[move.piece];
            }
            else {
                score += PIECE_SCORES[piece];
            }
        }
    }

    return { score, captures: capturesList };
};

export default getScore;

