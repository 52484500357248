/* eslint-disable @typescript-eslint/no-non-null-assertion */
import SuperTokens from "supertokens-auth-react";
import Passwordless, {
    createCode,
    consumeCode,
} from "supertokens-auth-react/recipe/passwordless";
import Session from "supertokens-auth-react/recipe/session";

const isClient = () => typeof window !== "undefined";

type InitializeSuperTokensProps =
    | {
          apiDomain?: string;
          websiteDomain?: string;
      }
    | undefined;

const initializeSuperTokens = ({
    apiDomain = typeof process === "undefined"
        ? ""
        : process.env.NEXT_PUBLIC_API_DOMAIN!,
    websiteDomain = typeof process === "undefined"
        ? ""
        : process.env.NEXT_PUBLIC_WEBSITE_DOMAIN!,
}: InitializeSuperTokensProps = {}) => {
    if (isClient()) {
        // @ts-ignore
        window.createCode = createCode;

        // @ts-ignore
        window.consumeCode = consumeCode;

        SuperTokens.init({
            appInfo: {
                // learn more about this on https://supertokens.com/docs/emailpassword/appinfo
                appName: "Zoop Chess",
                apiDomain,
                websiteDomain,
                apiBasePath: "/auth",
                websiteBasePath: (process.env.PUBLIC_URL ?? "") + "/auth",
            },
            recipeList: [
                Session.init(),
                Passwordless.init({
                    contactMethod: "PHONE",
                }),
            ],
        });
    }
};

export default initializeSuperTokens;
