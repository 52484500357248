import { useCallback } from "react";
import { useRouter } from "next/router";

const useGoBack = () => {
    const { push, back } = useRouter();

    const goBack = useCallback(() => {
        if (window.history && window.history.length > 2) {
            back();

            return;
        }

        push("/");
    }, [push, back]);

    return goBack;
};

export default useGoBack;
