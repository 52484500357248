//@ts-nocheck
/* @ts-nocheck eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  bpchar: any;
  float8: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type ChooseRandomSide__GenericResponse = {
  __typename?: 'ChooseRandomSide__GenericResponse';
  is_first_move: Scalars['Boolean'];
  message: Scalars['String'];
  side?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type GenericResponse = {
  __typename?: 'GenericResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** mutation root */
export type Mutation = {
  __typename?: 'Mutation';
  addUpiDetails: GenericResponse;
  checkPanValidation: GenericResponse;
  chooseRandomSide: Rooms;
  createAnonymousJWT: Scalars['String'];
  createBattleModeRoom: Rooms;
  createNewRoom: Rooms;
  createOnBoardingGame2Room: Rooms;
  createReplayRoom: Rooms;
  /** delete data from the table: "pan_card_details" */
  delete_pan_card_details?: Maybe<Pan_Card_Details_Mutation_Response>;
  /** delete single row from the table: "pan_card_details" */
  delete_pan_card_details_by_pk?: Maybe<Pan_Card_Details>;
  /** delete data from the table: "potential_games" */
  delete_potential_games?: Maybe<Potential_Games_Mutation_Response>;
  /** delete single row from the table: "potential_games" */
  delete_potential_games_by_pk?: Maybe<Potential_Games>;
  /** delete data from the table: "room_moves" */
  delete_room_moves?: Maybe<Room_Moves_Mutation_Response>;
  /** delete single row from the table: "room_moves" */
  delete_room_moves_by_pk?: Maybe<Room_Moves>;
  /** delete data from the table: "room_participants" */
  delete_room_participants?: Maybe<Room_Participants_Mutation_Response>;
  /** delete single row from the table: "room_participants" */
  delete_room_participants_by_pk?: Maybe<Room_Participants>;
  /** delete data from the table: "room_sides" */
  delete_room_sides?: Maybe<Room_Sides_Mutation_Response>;
  /** delete single row from the table: "room_sides" */
  delete_room_sides_by_pk?: Maybe<Room_Sides>;
  /** delete data from the table: "room_wallet_transactions" */
  delete_room_wallet_transactions?: Maybe<Room_Wallet_Transactions_Mutation_Response>;
  /** delete single row from the table: "room_wallet_transactions" */
  delete_room_wallet_transactions_by_pk?: Maybe<Room_Wallet_Transactions>;
  /** delete data from the table: "rooms" */
  delete_rooms?: Maybe<Rooms_Mutation_Response>;
  /** delete single row from the table: "rooms" */
  delete_rooms_by_pk?: Maybe<Rooms>;
  /** delete data from the table: "supertokens.all_auth_recipe_users" */
  delete_supertokens_all_auth_recipe_users?: Maybe<Supertokens_All_Auth_Recipe_Users_Mutation_Response>;
  /** delete single row from the table: "supertokens.all_auth_recipe_users" */
  delete_supertokens_all_auth_recipe_users_by_pk?: Maybe<Supertokens_All_Auth_Recipe_Users>;
  /** delete data from the table: "supertokens.jwt_signing_keys" */
  delete_supertokens_jwt_signing_keys?: Maybe<Supertokens_Jwt_Signing_Keys_Mutation_Response>;
  /** delete single row from the table: "supertokens.jwt_signing_keys" */
  delete_supertokens_jwt_signing_keys_by_pk?: Maybe<Supertokens_Jwt_Signing_Keys>;
  /** delete data from the table: "supertokens.key_value" */
  delete_supertokens_key_value?: Maybe<Supertokens_Key_Value_Mutation_Response>;
  /** delete single row from the table: "supertokens.key_value" */
  delete_supertokens_key_value_by_pk?: Maybe<Supertokens_Key_Value>;
  /** delete data from the table: "supertokens.passwordless_codes" */
  delete_supertokens_passwordless_codes?: Maybe<Supertokens_Passwordless_Codes_Mutation_Response>;
  /** delete single row from the table: "supertokens.passwordless_codes" */
  delete_supertokens_passwordless_codes_by_pk?: Maybe<Supertokens_Passwordless_Codes>;
  /** delete data from the table: "supertokens.passwordless_users" */
  delete_supertokens_passwordless_users?: Maybe<Supertokens_Passwordless_Users_Mutation_Response>;
  /** delete single row from the table: "supertokens.passwordless_users" */
  delete_supertokens_passwordless_users_by_pk?: Maybe<Supertokens_Passwordless_Users>;
  /** delete data from the table: "supertokens.session_access_token_signing_keys" */
  delete_supertokens_session_access_token_signing_keys?: Maybe<Supertokens_Session_Access_Token_Signing_Keys_Mutation_Response>;
  /** delete single row from the table: "supertokens.session_access_token_signing_keys" */
  delete_supertokens_session_access_token_signing_keys_by_pk?: Maybe<Supertokens_Session_Access_Token_Signing_Keys>;
  /** delete data from the table: "supertokens.session_info" */
  delete_supertokens_session_info?: Maybe<Supertokens_Session_Info_Mutation_Response>;
  /** delete single row from the table: "supertokens.session_info" */
  delete_supertokens_session_info_by_pk?: Maybe<Supertokens_Session_Info>;
  /** delete data from the table: "supertokens.user_additional_fields" */
  delete_supertokens_user_additional_fields?: Maybe<Supertokens_User_Additional_Fields_Mutation_Response>;
  /** delete single row from the table: "supertokens.user_additional_fields" */
  delete_supertokens_user_additional_fields_by_pk?: Maybe<Supertokens_User_Additional_Fields>;
  /** delete data from the table: "supertokens.user_public_fields" */
  delete_supertokens_user_public_fields?: Maybe<Supertokens_User_Public_Fields_Mutation_Response>;
  /** delete data from the table: "tournament" */
  delete_tournament?: Maybe<Tournament_Mutation_Response>;
  /** delete single row from the table: "tournament" */
  delete_tournament_by_pk?: Maybe<Tournament>;
  /** delete data from the table: "tournament_leaderboard" */
  delete_tournament_leaderboard?: Maybe<Tournament_Leaderboard_Mutation_Response>;
  /** delete single row from the table: "tournament_leaderboard" */
  delete_tournament_leaderboard_by_pk?: Maybe<Tournament_Leaderboard>;
  /** delete data from the table: "tournament_levels_metadata" */
  delete_tournament_levels_metadata?: Maybe<Tournament_Levels_Metadata_Mutation_Response>;
  /** delete single row from the table: "tournament_levels_metadata" */
  delete_tournament_levels_metadata_by_pk?: Maybe<Tournament_Levels_Metadata>;
  /** delete data from the table: "tournament_participants" */
  delete_tournament_participants?: Maybe<Tournament_Participants_Mutation_Response>;
  /** delete single row from the table: "tournament_participants" */
  delete_tournament_participants_by_pk?: Maybe<Tournament_Participants>;
  /** delete data from the table: "tournament_wallet_transactions" */
  delete_tournament_wallet_transactions?: Maybe<Tournament_Wallet_Transactions_Mutation_Response>;
  /** delete single row from the table: "tournament_wallet_transactions" */
  delete_tournament_wallet_transactions_by_pk?: Maybe<Tournament_Wallet_Transactions>;
  /** delete data from the table: "upi_details" */
  delete_upi_details?: Maybe<Upi_Details_Mutation_Response>;
  /** delete single row from the table: "upi_details" */
  delete_upi_details_by_pk?: Maybe<Upi_Details>;
  /** delete data from the table: "user_room_pings" */
  delete_user_room_pings?: Maybe<User_Room_Pings_Mutation_Response>;
  /** delete single row from the table: "user_room_pings" */
  delete_user_room_pings_by_pk?: Maybe<User_Room_Pings>;
  /** delete data from the table: "wallet_transaction_statuses" */
  delete_wallet_transaction_statuses?: Maybe<Wallet_Transaction_Statuses_Mutation_Response>;
  /** delete single row from the table: "wallet_transaction_statuses" */
  delete_wallet_transaction_statuses_by_pk?: Maybe<Wallet_Transaction_Statuses>;
  /** delete data from the table: "wallet_transaction_type" */
  delete_wallet_transaction_type?: Maybe<Wallet_Transaction_Type_Mutation_Response>;
  /** delete single row from the table: "wallet_transaction_type" */
  delete_wallet_transaction_type_by_pk?: Maybe<Wallet_Transaction_Type>;
  /** delete data from the table: "wallet_transactions" */
  delete_wallet_transactions?: Maybe<Wallet_Transactions_Mutation_Response>;
  /** delete single row from the table: "wallet_transactions" */
  delete_wallet_transactions_by_pk?: Maybe<Wallet_Transactions>;
  /** delete data from the table: "withdrawal_requests" */
  delete_withdrawal_requests?: Maybe<Withdrawal_Requests_Mutation_Response>;
  /** delete single row from the table: "withdrawal_requests" */
  delete_withdrawal_requests_by_pk?: Maybe<Withdrawal_Requests>;
  endGameRoom: GenericResponse;
  endSinglePlayerGameRoomWithWinner: GenericResponse;
  /** insert data into the table: "pan_card_details" */
  insert_pan_card_details?: Maybe<Pan_Card_Details_Mutation_Response>;
  /** insert a single row into the table: "pan_card_details" */
  insert_pan_card_details_one?: Maybe<Pan_Card_Details>;
  /** insert data into the table: "potential_games" */
  insert_potential_games?: Maybe<Potential_Games_Mutation_Response>;
  /** insert a single row into the table: "potential_games" */
  insert_potential_games_one?: Maybe<Potential_Games>;
  /** insert data into the table: "room_moves" */
  insert_room_moves?: Maybe<Room_Moves_Mutation_Response>;
  /** insert a single row into the table: "room_moves" */
  insert_room_moves_one?: Maybe<Room_Moves>;
  /** insert data into the table: "room_participants" */
  insert_room_participants?: Maybe<Room_Participants_Mutation_Response>;
  /** insert a single row into the table: "room_participants" */
  insert_room_participants_one?: Maybe<Room_Participants>;
  /** insert data into the table: "room_sides" */
  insert_room_sides?: Maybe<Room_Sides_Mutation_Response>;
  /** insert a single row into the table: "room_sides" */
  insert_room_sides_one?: Maybe<Room_Sides>;
  /** insert data into the table: "room_wallet_transactions" */
  insert_room_wallet_transactions?: Maybe<Room_Wallet_Transactions_Mutation_Response>;
  /** insert a single row into the table: "room_wallet_transactions" */
  insert_room_wallet_transactions_one?: Maybe<Room_Wallet_Transactions>;
  /** insert data into the table: "rooms" */
  insert_rooms?: Maybe<Rooms_Mutation_Response>;
  /** insert a single row into the table: "rooms" */
  insert_rooms_one?: Maybe<Rooms>;
  /** insert data into the table: "supertokens.all_auth_recipe_users" */
  insert_supertokens_all_auth_recipe_users?: Maybe<Supertokens_All_Auth_Recipe_Users_Mutation_Response>;
  /** insert a single row into the table: "supertokens.all_auth_recipe_users" */
  insert_supertokens_all_auth_recipe_users_one?: Maybe<Supertokens_All_Auth_Recipe_Users>;
  /** insert data into the table: "supertokens.jwt_signing_keys" */
  insert_supertokens_jwt_signing_keys?: Maybe<Supertokens_Jwt_Signing_Keys_Mutation_Response>;
  /** insert a single row into the table: "supertokens.jwt_signing_keys" */
  insert_supertokens_jwt_signing_keys_one?: Maybe<Supertokens_Jwt_Signing_Keys>;
  /** insert data into the table: "supertokens.key_value" */
  insert_supertokens_key_value?: Maybe<Supertokens_Key_Value_Mutation_Response>;
  /** insert a single row into the table: "supertokens.key_value" */
  insert_supertokens_key_value_one?: Maybe<Supertokens_Key_Value>;
  /** insert data into the table: "supertokens.passwordless_codes" */
  insert_supertokens_passwordless_codes?: Maybe<Supertokens_Passwordless_Codes_Mutation_Response>;
  /** insert a single row into the table: "supertokens.passwordless_codes" */
  insert_supertokens_passwordless_codes_one?: Maybe<Supertokens_Passwordless_Codes>;
  /** insert data into the table: "supertokens.passwordless_users" */
  insert_supertokens_passwordless_users?: Maybe<Supertokens_Passwordless_Users_Mutation_Response>;
  /** insert a single row into the table: "supertokens.passwordless_users" */
  insert_supertokens_passwordless_users_one?: Maybe<Supertokens_Passwordless_Users>;
  /** insert data into the table: "supertokens.session_access_token_signing_keys" */
  insert_supertokens_session_access_token_signing_keys?: Maybe<Supertokens_Session_Access_Token_Signing_Keys_Mutation_Response>;
  /** insert a single row into the table: "supertokens.session_access_token_signing_keys" */
  insert_supertokens_session_access_token_signing_keys_one?: Maybe<Supertokens_Session_Access_Token_Signing_Keys>;
  /** insert data into the table: "supertokens.session_info" */
  insert_supertokens_session_info?: Maybe<Supertokens_Session_Info_Mutation_Response>;
  /** insert a single row into the table: "supertokens.session_info" */
  insert_supertokens_session_info_one?: Maybe<Supertokens_Session_Info>;
  /** insert data into the table: "supertokens.user_additional_fields" */
  insert_supertokens_user_additional_fields?: Maybe<Supertokens_User_Additional_Fields_Mutation_Response>;
  /** insert a single row into the table: "supertokens.user_additional_fields" */
  insert_supertokens_user_additional_fields_one?: Maybe<Supertokens_User_Additional_Fields>;
  /** insert data into the table: "supertokens.user_public_fields" */
  insert_supertokens_user_public_fields?: Maybe<Supertokens_User_Public_Fields_Mutation_Response>;
  /** insert a single row into the table: "supertokens.user_public_fields" */
  insert_supertokens_user_public_fields_one?: Maybe<Supertokens_User_Public_Fields>;
  /** insert data into the table: "tournament" */
  insert_tournament?: Maybe<Tournament_Mutation_Response>;
  /** insert data into the table: "tournament_leaderboard" */
  insert_tournament_leaderboard?: Maybe<Tournament_Leaderboard_Mutation_Response>;
  /** insert a single row into the table: "tournament_leaderboard" */
  insert_tournament_leaderboard_one?: Maybe<Tournament_Leaderboard>;
  /** insert data into the table: "tournament_levels_metadata" */
  insert_tournament_levels_metadata?: Maybe<Tournament_Levels_Metadata_Mutation_Response>;
  /** insert a single row into the table: "tournament_levels_metadata" */
  insert_tournament_levels_metadata_one?: Maybe<Tournament_Levels_Metadata>;
  /** insert a single row into the table: "tournament" */
  insert_tournament_one?: Maybe<Tournament>;
  /** insert data into the table: "tournament_participants" */
  insert_tournament_participants?: Maybe<Tournament_Participants_Mutation_Response>;
  /** insert a single row into the table: "tournament_participants" */
  insert_tournament_participants_one?: Maybe<Tournament_Participants>;
  /** insert data into the table: "tournament_wallet_transactions" */
  insert_tournament_wallet_transactions?: Maybe<Tournament_Wallet_Transactions_Mutation_Response>;
  /** insert a single row into the table: "tournament_wallet_transactions" */
  insert_tournament_wallet_transactions_one?: Maybe<Tournament_Wallet_Transactions>;
  /** insert data into the table: "upi_details" */
  insert_upi_details?: Maybe<Upi_Details_Mutation_Response>;
  /** insert a single row into the table: "upi_details" */
  insert_upi_details_one?: Maybe<Upi_Details>;
  /** insert data into the table: "user_room_pings" */
  insert_user_room_pings?: Maybe<User_Room_Pings_Mutation_Response>;
  /** insert a single row into the table: "user_room_pings" */
  insert_user_room_pings_one?: Maybe<User_Room_Pings>;
  /** insert data into the table: "wallet_transaction_statuses" */
  insert_wallet_transaction_statuses?: Maybe<Wallet_Transaction_Statuses_Mutation_Response>;
  /** insert a single row into the table: "wallet_transaction_statuses" */
  insert_wallet_transaction_statuses_one?: Maybe<Wallet_Transaction_Statuses>;
  /** insert data into the table: "wallet_transaction_type" */
  insert_wallet_transaction_type?: Maybe<Wallet_Transaction_Type_Mutation_Response>;
  /** insert a single row into the table: "wallet_transaction_type" */
  insert_wallet_transaction_type_one?: Maybe<Wallet_Transaction_Type>;
  /** insert data into the table: "wallet_transactions" */
  insert_wallet_transactions?: Maybe<Wallet_Transactions_Mutation_Response>;
  /** insert a single row into the table: "wallet_transactions" */
  insert_wallet_transactions_one?: Maybe<Wallet_Transactions>;
  /** insert data into the table: "withdrawal_requests" */
  insert_withdrawal_requests?: Maybe<Withdrawal_Requests_Mutation_Response>;
  /** insert a single row into the table: "withdrawal_requests" */
  insert_withdrawal_requests_one?: Maybe<Withdrawal_Requests>;
  makeCashWithdrawRequest: GenericResponse;
  makeMove: GenericResponse;
  makeMoveWithTimerExpired: GenericResponse;
  ping: Scalars['String'];
  startOnboardingGame: GenericResponse;
  startSinglePlayerGame: Rooms;
  /** update data of the table: "pan_card_details" */
  update_pan_card_details?: Maybe<Pan_Card_Details_Mutation_Response>;
  /** update single row of the table: "pan_card_details" */
  update_pan_card_details_by_pk?: Maybe<Pan_Card_Details>;
  /** update multiples rows of table: "pan_card_details" */
  update_pan_card_details_many?: Maybe<Array<Maybe<Pan_Card_Details_Mutation_Response>>>;
  /** update data of the table: "potential_games" */
  update_potential_games?: Maybe<Potential_Games_Mutation_Response>;
  /** update single row of the table: "potential_games" */
  update_potential_games_by_pk?: Maybe<Potential_Games>;
  /** update multiples rows of table: "potential_games" */
  update_potential_games_many?: Maybe<Array<Maybe<Potential_Games_Mutation_Response>>>;
  /** update data of the table: "room_moves" */
  update_room_moves?: Maybe<Room_Moves_Mutation_Response>;
  /** update single row of the table: "room_moves" */
  update_room_moves_by_pk?: Maybe<Room_Moves>;
  /** update multiples rows of table: "room_moves" */
  update_room_moves_many?: Maybe<Array<Maybe<Room_Moves_Mutation_Response>>>;
  /** update data of the table: "room_participants" */
  update_room_participants?: Maybe<Room_Participants_Mutation_Response>;
  /** update single row of the table: "room_participants" */
  update_room_participants_by_pk?: Maybe<Room_Participants>;
  /** update multiples rows of table: "room_participants" */
  update_room_participants_many?: Maybe<Array<Maybe<Room_Participants_Mutation_Response>>>;
  /** update data of the table: "room_sides" */
  update_room_sides?: Maybe<Room_Sides_Mutation_Response>;
  /** update single row of the table: "room_sides" */
  update_room_sides_by_pk?: Maybe<Room_Sides>;
  /** update multiples rows of table: "room_sides" */
  update_room_sides_many?: Maybe<Array<Maybe<Room_Sides_Mutation_Response>>>;
  /** update data of the table: "room_wallet_transactions" */
  update_room_wallet_transactions?: Maybe<Room_Wallet_Transactions_Mutation_Response>;
  /** update single row of the table: "room_wallet_transactions" */
  update_room_wallet_transactions_by_pk?: Maybe<Room_Wallet_Transactions>;
  /** update multiples rows of table: "room_wallet_transactions" */
  update_room_wallet_transactions_many?: Maybe<Array<Maybe<Room_Wallet_Transactions_Mutation_Response>>>;
  /** update data of the table: "rooms" */
  update_rooms?: Maybe<Rooms_Mutation_Response>;
  /** update single row of the table: "rooms" */
  update_rooms_by_pk?: Maybe<Rooms>;
  /** update multiples rows of table: "rooms" */
  update_rooms_many?: Maybe<Array<Maybe<Rooms_Mutation_Response>>>;
  /** update data of the table: "supertokens.all_auth_recipe_users" */
  update_supertokens_all_auth_recipe_users?: Maybe<Supertokens_All_Auth_Recipe_Users_Mutation_Response>;
  /** update single row of the table: "supertokens.all_auth_recipe_users" */
  update_supertokens_all_auth_recipe_users_by_pk?: Maybe<Supertokens_All_Auth_Recipe_Users>;
  /** update multiples rows of table: "supertokens.all_auth_recipe_users" */
  update_supertokens_all_auth_recipe_users_many?: Maybe<Array<Maybe<Supertokens_All_Auth_Recipe_Users_Mutation_Response>>>;
  /** update data of the table: "supertokens.jwt_signing_keys" */
  update_supertokens_jwt_signing_keys?: Maybe<Supertokens_Jwt_Signing_Keys_Mutation_Response>;
  /** update single row of the table: "supertokens.jwt_signing_keys" */
  update_supertokens_jwt_signing_keys_by_pk?: Maybe<Supertokens_Jwt_Signing_Keys>;
  /** update multiples rows of table: "supertokens.jwt_signing_keys" */
  update_supertokens_jwt_signing_keys_many?: Maybe<Array<Maybe<Supertokens_Jwt_Signing_Keys_Mutation_Response>>>;
  /** update data of the table: "supertokens.key_value" */
  update_supertokens_key_value?: Maybe<Supertokens_Key_Value_Mutation_Response>;
  /** update single row of the table: "supertokens.key_value" */
  update_supertokens_key_value_by_pk?: Maybe<Supertokens_Key_Value>;
  /** update multiples rows of table: "supertokens.key_value" */
  update_supertokens_key_value_many?: Maybe<Array<Maybe<Supertokens_Key_Value_Mutation_Response>>>;
  /** update data of the table: "supertokens.passwordless_codes" */
  update_supertokens_passwordless_codes?: Maybe<Supertokens_Passwordless_Codes_Mutation_Response>;
  /** update single row of the table: "supertokens.passwordless_codes" */
  update_supertokens_passwordless_codes_by_pk?: Maybe<Supertokens_Passwordless_Codes>;
  /** update multiples rows of table: "supertokens.passwordless_codes" */
  update_supertokens_passwordless_codes_many?: Maybe<Array<Maybe<Supertokens_Passwordless_Codes_Mutation_Response>>>;
  /** update data of the table: "supertokens.passwordless_users" */
  update_supertokens_passwordless_users?: Maybe<Supertokens_Passwordless_Users_Mutation_Response>;
  /** update single row of the table: "supertokens.passwordless_users" */
  update_supertokens_passwordless_users_by_pk?: Maybe<Supertokens_Passwordless_Users>;
  /** update multiples rows of table: "supertokens.passwordless_users" */
  update_supertokens_passwordless_users_many?: Maybe<Array<Maybe<Supertokens_Passwordless_Users_Mutation_Response>>>;
  /** update data of the table: "supertokens.session_access_token_signing_keys" */
  update_supertokens_session_access_token_signing_keys?: Maybe<Supertokens_Session_Access_Token_Signing_Keys_Mutation_Response>;
  /** update single row of the table: "supertokens.session_access_token_signing_keys" */
  update_supertokens_session_access_token_signing_keys_by_pk?: Maybe<Supertokens_Session_Access_Token_Signing_Keys>;
  /** update multiples rows of table: "supertokens.session_access_token_signing_keys" */
  update_supertokens_session_access_token_signing_keys_many?: Maybe<Array<Maybe<Supertokens_Session_Access_Token_Signing_Keys_Mutation_Response>>>;
  /** update data of the table: "supertokens.session_info" */
  update_supertokens_session_info?: Maybe<Supertokens_Session_Info_Mutation_Response>;
  /** update single row of the table: "supertokens.session_info" */
  update_supertokens_session_info_by_pk?: Maybe<Supertokens_Session_Info>;
  /** update multiples rows of table: "supertokens.session_info" */
  update_supertokens_session_info_many?: Maybe<Array<Maybe<Supertokens_Session_Info_Mutation_Response>>>;
  /** update data of the table: "supertokens.user_additional_fields" */
  update_supertokens_user_additional_fields?: Maybe<Supertokens_User_Additional_Fields_Mutation_Response>;
  /** update single row of the table: "supertokens.user_additional_fields" */
  update_supertokens_user_additional_fields_by_pk?: Maybe<Supertokens_User_Additional_Fields>;
  /** update multiples rows of table: "supertokens.user_additional_fields" */
  update_supertokens_user_additional_fields_many?: Maybe<Array<Maybe<Supertokens_User_Additional_Fields_Mutation_Response>>>;
  /** update data of the table: "supertokens.user_public_fields" */
  update_supertokens_user_public_fields?: Maybe<Supertokens_User_Public_Fields_Mutation_Response>;
  /** update multiples rows of table: "supertokens.user_public_fields" */
  update_supertokens_user_public_fields_many?: Maybe<Array<Maybe<Supertokens_User_Public_Fields_Mutation_Response>>>;
  /** update data of the table: "tournament" */
  update_tournament?: Maybe<Tournament_Mutation_Response>;
  /** update single row of the table: "tournament" */
  update_tournament_by_pk?: Maybe<Tournament>;
  /** update data of the table: "tournament_leaderboard" */
  update_tournament_leaderboard?: Maybe<Tournament_Leaderboard_Mutation_Response>;
  /** update single row of the table: "tournament_leaderboard" */
  update_tournament_leaderboard_by_pk?: Maybe<Tournament_Leaderboard>;
  /** update multiples rows of table: "tournament_leaderboard" */
  update_tournament_leaderboard_many?: Maybe<Array<Maybe<Tournament_Leaderboard_Mutation_Response>>>;
  /** update data of the table: "tournament_levels_metadata" */
  update_tournament_levels_metadata?: Maybe<Tournament_Levels_Metadata_Mutation_Response>;
  /** update single row of the table: "tournament_levels_metadata" */
  update_tournament_levels_metadata_by_pk?: Maybe<Tournament_Levels_Metadata>;
  /** update multiples rows of table: "tournament_levels_metadata" */
  update_tournament_levels_metadata_many?: Maybe<Array<Maybe<Tournament_Levels_Metadata_Mutation_Response>>>;
  /** update multiples rows of table: "tournament" */
  update_tournament_many?: Maybe<Array<Maybe<Tournament_Mutation_Response>>>;
  /** update data of the table: "tournament_participants" */
  update_tournament_participants?: Maybe<Tournament_Participants_Mutation_Response>;
  /** update single row of the table: "tournament_participants" */
  update_tournament_participants_by_pk?: Maybe<Tournament_Participants>;
  /** update multiples rows of table: "tournament_participants" */
  update_tournament_participants_many?: Maybe<Array<Maybe<Tournament_Participants_Mutation_Response>>>;
  /** update data of the table: "tournament_wallet_transactions" */
  update_tournament_wallet_transactions?: Maybe<Tournament_Wallet_Transactions_Mutation_Response>;
  /** update single row of the table: "tournament_wallet_transactions" */
  update_tournament_wallet_transactions_by_pk?: Maybe<Tournament_Wallet_Transactions>;
  /** update multiples rows of table: "tournament_wallet_transactions" */
  update_tournament_wallet_transactions_many?: Maybe<Array<Maybe<Tournament_Wallet_Transactions_Mutation_Response>>>;
  /** update data of the table: "upi_details" */
  update_upi_details?: Maybe<Upi_Details_Mutation_Response>;
  /** update single row of the table: "upi_details" */
  update_upi_details_by_pk?: Maybe<Upi_Details>;
  /** update multiples rows of table: "upi_details" */
  update_upi_details_many?: Maybe<Array<Maybe<Upi_Details_Mutation_Response>>>;
  /** update data of the table: "user_room_pings" */
  update_user_room_pings?: Maybe<User_Room_Pings_Mutation_Response>;
  /** update single row of the table: "user_room_pings" */
  update_user_room_pings_by_pk?: Maybe<User_Room_Pings>;
  /** update multiples rows of table: "user_room_pings" */
  update_user_room_pings_many?: Maybe<Array<Maybe<User_Room_Pings_Mutation_Response>>>;
  /** update data of the table: "wallet_transaction_statuses" */
  update_wallet_transaction_statuses?: Maybe<Wallet_Transaction_Statuses_Mutation_Response>;
  /** update single row of the table: "wallet_transaction_statuses" */
  update_wallet_transaction_statuses_by_pk?: Maybe<Wallet_Transaction_Statuses>;
  /** update multiples rows of table: "wallet_transaction_statuses" */
  update_wallet_transaction_statuses_many?: Maybe<Array<Maybe<Wallet_Transaction_Statuses_Mutation_Response>>>;
  /** update data of the table: "wallet_transaction_type" */
  update_wallet_transaction_type?: Maybe<Wallet_Transaction_Type_Mutation_Response>;
  /** update single row of the table: "wallet_transaction_type" */
  update_wallet_transaction_type_by_pk?: Maybe<Wallet_Transaction_Type>;
  /** update multiples rows of table: "wallet_transaction_type" */
  update_wallet_transaction_type_many?: Maybe<Array<Maybe<Wallet_Transaction_Type_Mutation_Response>>>;
  /** update data of the table: "wallet_transactions" */
  update_wallet_transactions?: Maybe<Wallet_Transactions_Mutation_Response>;
  /** update single row of the table: "wallet_transactions" */
  update_wallet_transactions_by_pk?: Maybe<Wallet_Transactions>;
  /** update multiples rows of table: "wallet_transactions" */
  update_wallet_transactions_many?: Maybe<Array<Maybe<Wallet_Transactions_Mutation_Response>>>;
  /** update data of the table: "withdrawal_requests" */
  update_withdrawal_requests?: Maybe<Withdrawal_Requests_Mutation_Response>;
  /** update single row of the table: "withdrawal_requests" */
  update_withdrawal_requests_by_pk?: Maybe<Withdrawal_Requests>;
  /** update multiples rows of table: "withdrawal_requests" */
  update_withdrawal_requests_many?: Maybe<Array<Maybe<Withdrawal_Requests_Mutation_Response>>>;
};


/** mutation root */
export type MutationAddUpiDetailsArgs = {
  upiID: Scalars['String'];
};


/** mutation root */
export type MutationCheckPanValidationArgs = {
  panNumber: Scalars['String'];
};


/** mutation root */
export type MutationChooseRandomSideArgs = {
  roomID: Scalars['uuid'];
};


/** mutation root */
export type MutationCreateNewRoomArgs = {
  isOnboardingUser?: InputMaybe<Scalars['Boolean']>;
  isSinglePlayer?: InputMaybe<Scalars['Boolean']>;
  setOnboardData?: InputMaybe<SetOnboardDataType>;
  tournamentID?: InputMaybe<Scalars['uuid']>;
};


/** mutation root */
export type MutationCreateReplayRoomArgs = {
  roomID: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_Pan_Card_DetailsArgs = {
  where: Pan_Card_Details_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Pan_Card_Details_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_Potential_GamesArgs = {
  where: Potential_Games_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Potential_Games_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_Room_MovesArgs = {
  where: Room_Moves_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Room_Moves_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_Room_ParticipantsArgs = {
  where: Room_Participants_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Room_Participants_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_Room_SidesArgs = {
  where: Room_Sides_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Room_Sides_By_PkArgs = {
  side: Scalars['String'];
};


/** mutation root */
export type MutationDelete_Room_Wallet_TransactionsArgs = {
  where: Room_Wallet_Transactions_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Room_Wallet_Transactions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_RoomsArgs = {
  where: Rooms_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Rooms_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_Supertokens_All_Auth_Recipe_UsersArgs = {
  where: Supertokens_All_Auth_Recipe_Users_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Supertokens_All_Auth_Recipe_Users_By_PkArgs = {
  user_id: Scalars['bpchar'];
};


/** mutation root */
export type MutationDelete_Supertokens_Jwt_Signing_KeysArgs = {
  where: Supertokens_Jwt_Signing_Keys_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Supertokens_Jwt_Signing_Keys_By_PkArgs = {
  key_id: Scalars['String'];
};


/** mutation root */
export type MutationDelete_Supertokens_Key_ValueArgs = {
  where: Supertokens_Key_Value_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Supertokens_Key_Value_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type MutationDelete_Supertokens_Passwordless_CodesArgs = {
  where: Supertokens_Passwordless_Codes_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Supertokens_Passwordless_Codes_By_PkArgs = {
  code_id: Scalars['bpchar'];
};


/** mutation root */
export type MutationDelete_Supertokens_Passwordless_UsersArgs = {
  where: Supertokens_Passwordless_Users_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Supertokens_Passwordless_Users_By_PkArgs = {
  user_id: Scalars['bpchar'];
};


/** mutation root */
export type MutationDelete_Supertokens_Session_Access_Token_Signing_KeysArgs = {
  where: Supertokens_Session_Access_Token_Signing_Keys_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Supertokens_Session_Access_Token_Signing_Keys_By_PkArgs = {
  created_at_time: Scalars['bigint'];
};


/** mutation root */
export type MutationDelete_Supertokens_Session_InfoArgs = {
  where: Supertokens_Session_Info_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Supertokens_Session_Info_By_PkArgs = {
  session_handle: Scalars['String'];
};


/** mutation root */
export type MutationDelete_Supertokens_User_Additional_FieldsArgs = {
  where: Supertokens_User_Additional_Fields_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Supertokens_User_Additional_Fields_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_Supertokens_User_Public_FieldsArgs = {
  where: Supertokens_User_Public_Fields_Bool_Exp;
};


/** mutation root */
export type MutationDelete_TournamentArgs = {
  where: Tournament_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Tournament_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_Tournament_LeaderboardArgs = {
  where: Tournament_Leaderboard_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Tournament_Leaderboard_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_Tournament_Levels_MetadataArgs = {
  where: Tournament_Levels_Metadata_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Tournament_Levels_Metadata_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_Tournament_ParticipantsArgs = {
  where: Tournament_Participants_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Tournament_Participants_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_Tournament_Wallet_TransactionsArgs = {
  where: Tournament_Wallet_Transactions_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Tournament_Wallet_Transactions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_Upi_DetailsArgs = {
  where: Upi_Details_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Upi_Details_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_User_Room_PingsArgs = {
  where: User_Room_Pings_Bool_Exp;
};


/** mutation root */
export type MutationDelete_User_Room_Pings_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_Wallet_Transaction_StatusesArgs = {
  where: Wallet_Transaction_Statuses_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Wallet_Transaction_Statuses_By_PkArgs = {
  status: Scalars['String'];
};


/** mutation root */
export type MutationDelete_Wallet_Transaction_TypeArgs = {
  where: Wallet_Transaction_Type_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Wallet_Transaction_Type_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type MutationDelete_Wallet_TransactionsArgs = {
  where: Wallet_Transactions_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Wallet_Transactions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_Withdrawal_RequestsArgs = {
  where: Withdrawal_Requests_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Withdrawal_Requests_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationEndGameRoomArgs = {
  roomID: Scalars['uuid'];
};


/** mutation root */
export type MutationEndSinglePlayerGameRoomWithWinnerArgs = {
  is_checkmate?: InputMaybe<Scalars['Boolean']>;
  is_draw?: InputMaybe<Scalars['Boolean']>;
  roomID: Scalars['uuid'];
  winner: Scalars['String'];
};


/** mutation root */
export type MutationInsert_Pan_Card_DetailsArgs = {
  objects: Array<Pan_Card_Details_Insert_Input>;
  on_conflict?: InputMaybe<Pan_Card_Details_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Pan_Card_Details_OneArgs = {
  object: Pan_Card_Details_Insert_Input;
  on_conflict?: InputMaybe<Pan_Card_Details_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Potential_GamesArgs = {
  objects: Array<Potential_Games_Insert_Input>;
  on_conflict?: InputMaybe<Potential_Games_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Potential_Games_OneArgs = {
  object: Potential_Games_Insert_Input;
  on_conflict?: InputMaybe<Potential_Games_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Room_MovesArgs = {
  objects: Array<Room_Moves_Insert_Input>;
  on_conflict?: InputMaybe<Room_Moves_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Room_Moves_OneArgs = {
  object: Room_Moves_Insert_Input;
  on_conflict?: InputMaybe<Room_Moves_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Room_ParticipantsArgs = {
  objects: Array<Room_Participants_Insert_Input>;
  on_conflict?: InputMaybe<Room_Participants_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Room_Participants_OneArgs = {
  object: Room_Participants_Insert_Input;
  on_conflict?: InputMaybe<Room_Participants_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Room_SidesArgs = {
  objects: Array<Room_Sides_Insert_Input>;
  on_conflict?: InputMaybe<Room_Sides_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Room_Sides_OneArgs = {
  object: Room_Sides_Insert_Input;
  on_conflict?: InputMaybe<Room_Sides_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Room_Wallet_TransactionsArgs = {
  objects: Array<Room_Wallet_Transactions_Insert_Input>;
  on_conflict?: InputMaybe<Room_Wallet_Transactions_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Room_Wallet_Transactions_OneArgs = {
  object: Room_Wallet_Transactions_Insert_Input;
  on_conflict?: InputMaybe<Room_Wallet_Transactions_On_Conflict>;
};


/** mutation root */
export type MutationInsert_RoomsArgs = {
  objects: Array<Rooms_Insert_Input>;
  on_conflict?: InputMaybe<Rooms_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Rooms_OneArgs = {
  object: Rooms_Insert_Input;
  on_conflict?: InputMaybe<Rooms_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Supertokens_All_Auth_Recipe_UsersArgs = {
  objects: Array<Supertokens_All_Auth_Recipe_Users_Insert_Input>;
  on_conflict?: InputMaybe<Supertokens_All_Auth_Recipe_Users_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Supertokens_All_Auth_Recipe_Users_OneArgs = {
  object: Supertokens_All_Auth_Recipe_Users_Insert_Input;
  on_conflict?: InputMaybe<Supertokens_All_Auth_Recipe_Users_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Supertokens_Jwt_Signing_KeysArgs = {
  objects: Array<Supertokens_Jwt_Signing_Keys_Insert_Input>;
  on_conflict?: InputMaybe<Supertokens_Jwt_Signing_Keys_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Supertokens_Jwt_Signing_Keys_OneArgs = {
  object: Supertokens_Jwt_Signing_Keys_Insert_Input;
  on_conflict?: InputMaybe<Supertokens_Jwt_Signing_Keys_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Supertokens_Key_ValueArgs = {
  objects: Array<Supertokens_Key_Value_Insert_Input>;
  on_conflict?: InputMaybe<Supertokens_Key_Value_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Supertokens_Key_Value_OneArgs = {
  object: Supertokens_Key_Value_Insert_Input;
  on_conflict?: InputMaybe<Supertokens_Key_Value_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Supertokens_Passwordless_CodesArgs = {
  objects: Array<Supertokens_Passwordless_Codes_Insert_Input>;
  on_conflict?: InputMaybe<Supertokens_Passwordless_Codes_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Supertokens_Passwordless_Codes_OneArgs = {
  object: Supertokens_Passwordless_Codes_Insert_Input;
  on_conflict?: InputMaybe<Supertokens_Passwordless_Codes_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Supertokens_Passwordless_UsersArgs = {
  objects: Array<Supertokens_Passwordless_Users_Insert_Input>;
  on_conflict?: InputMaybe<Supertokens_Passwordless_Users_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Supertokens_Passwordless_Users_OneArgs = {
  object: Supertokens_Passwordless_Users_Insert_Input;
  on_conflict?: InputMaybe<Supertokens_Passwordless_Users_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Supertokens_Session_Access_Token_Signing_KeysArgs = {
  objects: Array<Supertokens_Session_Access_Token_Signing_Keys_Insert_Input>;
  on_conflict?: InputMaybe<Supertokens_Session_Access_Token_Signing_Keys_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Supertokens_Session_Access_Token_Signing_Keys_OneArgs = {
  object: Supertokens_Session_Access_Token_Signing_Keys_Insert_Input;
  on_conflict?: InputMaybe<Supertokens_Session_Access_Token_Signing_Keys_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Supertokens_Session_InfoArgs = {
  objects: Array<Supertokens_Session_Info_Insert_Input>;
  on_conflict?: InputMaybe<Supertokens_Session_Info_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Supertokens_Session_Info_OneArgs = {
  object: Supertokens_Session_Info_Insert_Input;
  on_conflict?: InputMaybe<Supertokens_Session_Info_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Supertokens_User_Additional_FieldsArgs = {
  objects: Array<Supertokens_User_Additional_Fields_Insert_Input>;
  on_conflict?: InputMaybe<Supertokens_User_Additional_Fields_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Supertokens_User_Additional_Fields_OneArgs = {
  object: Supertokens_User_Additional_Fields_Insert_Input;
  on_conflict?: InputMaybe<Supertokens_User_Additional_Fields_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Supertokens_User_Public_FieldsArgs = {
  objects: Array<Supertokens_User_Public_Fields_Insert_Input>;
};


/** mutation root */
export type MutationInsert_Supertokens_User_Public_Fields_OneArgs = {
  object: Supertokens_User_Public_Fields_Insert_Input;
};


/** mutation root */
export type MutationInsert_TournamentArgs = {
  objects: Array<Tournament_Insert_Input>;
  on_conflict?: InputMaybe<Tournament_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Tournament_LeaderboardArgs = {
  objects: Array<Tournament_Leaderboard_Insert_Input>;
  on_conflict?: InputMaybe<Tournament_Leaderboard_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Tournament_Leaderboard_OneArgs = {
  object: Tournament_Leaderboard_Insert_Input;
  on_conflict?: InputMaybe<Tournament_Leaderboard_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Tournament_Levels_MetadataArgs = {
  objects: Array<Tournament_Levels_Metadata_Insert_Input>;
  on_conflict?: InputMaybe<Tournament_Levels_Metadata_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Tournament_Levels_Metadata_OneArgs = {
  object: Tournament_Levels_Metadata_Insert_Input;
  on_conflict?: InputMaybe<Tournament_Levels_Metadata_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Tournament_OneArgs = {
  object: Tournament_Insert_Input;
  on_conflict?: InputMaybe<Tournament_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Tournament_ParticipantsArgs = {
  objects: Array<Tournament_Participants_Insert_Input>;
  on_conflict?: InputMaybe<Tournament_Participants_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Tournament_Participants_OneArgs = {
  object: Tournament_Participants_Insert_Input;
  on_conflict?: InputMaybe<Tournament_Participants_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Tournament_Wallet_TransactionsArgs = {
  objects: Array<Tournament_Wallet_Transactions_Insert_Input>;
  on_conflict?: InputMaybe<Tournament_Wallet_Transactions_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Tournament_Wallet_Transactions_OneArgs = {
  object: Tournament_Wallet_Transactions_Insert_Input;
  on_conflict?: InputMaybe<Tournament_Wallet_Transactions_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Upi_DetailsArgs = {
  objects: Array<Upi_Details_Insert_Input>;
  on_conflict?: InputMaybe<Upi_Details_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Upi_Details_OneArgs = {
  object: Upi_Details_Insert_Input;
  on_conflict?: InputMaybe<Upi_Details_On_Conflict>;
};


/** mutation root */
export type MutationInsert_User_Room_PingsArgs = {
  objects: Array<User_Room_Pings_Insert_Input>;
  on_conflict?: InputMaybe<User_Room_Pings_On_Conflict>;
};


/** mutation root */
export type MutationInsert_User_Room_Pings_OneArgs = {
  object: User_Room_Pings_Insert_Input;
  on_conflict?: InputMaybe<User_Room_Pings_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Wallet_Transaction_StatusesArgs = {
  objects: Array<Wallet_Transaction_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Wallet_Transaction_Statuses_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Wallet_Transaction_Statuses_OneArgs = {
  object: Wallet_Transaction_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Wallet_Transaction_Statuses_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Wallet_Transaction_TypeArgs = {
  objects: Array<Wallet_Transaction_Type_Insert_Input>;
  on_conflict?: InputMaybe<Wallet_Transaction_Type_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Wallet_Transaction_Type_OneArgs = {
  object: Wallet_Transaction_Type_Insert_Input;
  on_conflict?: InputMaybe<Wallet_Transaction_Type_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Wallet_TransactionsArgs = {
  objects: Array<Wallet_Transactions_Insert_Input>;
  on_conflict?: InputMaybe<Wallet_Transactions_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Wallet_Transactions_OneArgs = {
  object: Wallet_Transactions_Insert_Input;
  on_conflict?: InputMaybe<Wallet_Transactions_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Withdrawal_RequestsArgs = {
  objects: Array<Withdrawal_Requests_Insert_Input>;
  on_conflict?: InputMaybe<Withdrawal_Requests_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Withdrawal_Requests_OneArgs = {
  object: Withdrawal_Requests_Insert_Input;
  on_conflict?: InputMaybe<Withdrawal_Requests_On_Conflict>;
};


/** mutation root */
export type MutationMakeCashWithdrawRequestArgs = {
  amount: Scalars['Int'];
};


/** mutation root */
export type MutationMakeMoveArgs = {
  cpScore?: InputMaybe<Scalars['Int']>;
  moveRank?: InputMaybe<Scalars['Int']>;
  promotion?: InputMaybe<Scalars['String']>;
  roomID: Scalars['uuid'];
  sourceSquare: Scalars['String'];
  targetSquare: Scalars['String'];
};


/** mutation root */
export type MutationMakeMoveWithTimerExpiredArgs = {
  roomID: Scalars['uuid'];
};


/** mutation root */
export type MutationStartOnboardingGameArgs = {
  roomID: Scalars['uuid'];
};


/** mutation root */
export type MutationStartSinglePlayerGameArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type MutationUpdate_Pan_Card_DetailsArgs = {
  _set?: InputMaybe<Pan_Card_Details_Set_Input>;
  where: Pan_Card_Details_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Pan_Card_Details_By_PkArgs = {
  _set?: InputMaybe<Pan_Card_Details_Set_Input>;
  pk_columns: Pan_Card_Details_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Pan_Card_Details_ManyArgs = {
  updates: Array<Pan_Card_Details_Updates>;
};


/** mutation root */
export type MutationUpdate_Potential_GamesArgs = {
  _set?: InputMaybe<Potential_Games_Set_Input>;
  where: Potential_Games_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Potential_Games_By_PkArgs = {
  _set?: InputMaybe<Potential_Games_Set_Input>;
  pk_columns: Potential_Games_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Potential_Games_ManyArgs = {
  updates: Array<Potential_Games_Updates>;
};


/** mutation root */
export type MutationUpdate_Room_MovesArgs = {
  _inc?: InputMaybe<Room_Moves_Inc_Input>;
  _set?: InputMaybe<Room_Moves_Set_Input>;
  where: Room_Moves_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Room_Moves_By_PkArgs = {
  _inc?: InputMaybe<Room_Moves_Inc_Input>;
  _set?: InputMaybe<Room_Moves_Set_Input>;
  pk_columns: Room_Moves_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Room_Moves_ManyArgs = {
  updates: Array<Room_Moves_Updates>;
};


/** mutation root */
export type MutationUpdate_Room_ParticipantsArgs = {
  _inc?: InputMaybe<Room_Participants_Inc_Input>;
  _set?: InputMaybe<Room_Participants_Set_Input>;
  where: Room_Participants_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Room_Participants_By_PkArgs = {
  _inc?: InputMaybe<Room_Participants_Inc_Input>;
  _set?: InputMaybe<Room_Participants_Set_Input>;
  pk_columns: Room_Participants_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Room_Participants_ManyArgs = {
  updates: Array<Room_Participants_Updates>;
};


/** mutation root */
export type MutationUpdate_Room_SidesArgs = {
  _set?: InputMaybe<Room_Sides_Set_Input>;
  where: Room_Sides_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Room_Sides_By_PkArgs = {
  _set?: InputMaybe<Room_Sides_Set_Input>;
  pk_columns: Room_Sides_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Room_Sides_ManyArgs = {
  updates: Array<Room_Sides_Updates>;
};


/** mutation root */
export type MutationUpdate_Room_Wallet_TransactionsArgs = {
  _set?: InputMaybe<Room_Wallet_Transactions_Set_Input>;
  where: Room_Wallet_Transactions_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Room_Wallet_Transactions_By_PkArgs = {
  _set?: InputMaybe<Room_Wallet_Transactions_Set_Input>;
  pk_columns: Room_Wallet_Transactions_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Room_Wallet_Transactions_ManyArgs = {
  updates: Array<Room_Wallet_Transactions_Updates>;
};


/** mutation root */
export type MutationUpdate_RoomsArgs = {
  _inc?: InputMaybe<Rooms_Inc_Input>;
  _set?: InputMaybe<Rooms_Set_Input>;
  where: Rooms_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Rooms_By_PkArgs = {
  _inc?: InputMaybe<Rooms_Inc_Input>;
  _set?: InputMaybe<Rooms_Set_Input>;
  pk_columns: Rooms_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Rooms_ManyArgs = {
  updates: Array<Rooms_Updates>;
};


/** mutation root */
export type MutationUpdate_Supertokens_All_Auth_Recipe_UsersArgs = {
  _inc?: InputMaybe<Supertokens_All_Auth_Recipe_Users_Inc_Input>;
  _set?: InputMaybe<Supertokens_All_Auth_Recipe_Users_Set_Input>;
  where: Supertokens_All_Auth_Recipe_Users_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Supertokens_All_Auth_Recipe_Users_By_PkArgs = {
  _inc?: InputMaybe<Supertokens_All_Auth_Recipe_Users_Inc_Input>;
  _set?: InputMaybe<Supertokens_All_Auth_Recipe_Users_Set_Input>;
  pk_columns: Supertokens_All_Auth_Recipe_Users_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Supertokens_All_Auth_Recipe_Users_ManyArgs = {
  updates: Array<Supertokens_All_Auth_Recipe_Users_Updates>;
};


/** mutation root */
export type MutationUpdate_Supertokens_Jwt_Signing_KeysArgs = {
  _inc?: InputMaybe<Supertokens_Jwt_Signing_Keys_Inc_Input>;
  _set?: InputMaybe<Supertokens_Jwt_Signing_Keys_Set_Input>;
  where: Supertokens_Jwt_Signing_Keys_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Supertokens_Jwt_Signing_Keys_By_PkArgs = {
  _inc?: InputMaybe<Supertokens_Jwt_Signing_Keys_Inc_Input>;
  _set?: InputMaybe<Supertokens_Jwt_Signing_Keys_Set_Input>;
  pk_columns: Supertokens_Jwt_Signing_Keys_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Supertokens_Jwt_Signing_Keys_ManyArgs = {
  updates: Array<Supertokens_Jwt_Signing_Keys_Updates>;
};


/** mutation root */
export type MutationUpdate_Supertokens_Key_ValueArgs = {
  _inc?: InputMaybe<Supertokens_Key_Value_Inc_Input>;
  _set?: InputMaybe<Supertokens_Key_Value_Set_Input>;
  where: Supertokens_Key_Value_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Supertokens_Key_Value_By_PkArgs = {
  _inc?: InputMaybe<Supertokens_Key_Value_Inc_Input>;
  _set?: InputMaybe<Supertokens_Key_Value_Set_Input>;
  pk_columns: Supertokens_Key_Value_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Supertokens_Key_Value_ManyArgs = {
  updates: Array<Supertokens_Key_Value_Updates>;
};


/** mutation root */
export type MutationUpdate_Supertokens_Passwordless_CodesArgs = {
  _inc?: InputMaybe<Supertokens_Passwordless_Codes_Inc_Input>;
  _set?: InputMaybe<Supertokens_Passwordless_Codes_Set_Input>;
  where: Supertokens_Passwordless_Codes_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Supertokens_Passwordless_Codes_By_PkArgs = {
  _inc?: InputMaybe<Supertokens_Passwordless_Codes_Inc_Input>;
  _set?: InputMaybe<Supertokens_Passwordless_Codes_Set_Input>;
  pk_columns: Supertokens_Passwordless_Codes_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Supertokens_Passwordless_Codes_ManyArgs = {
  updates: Array<Supertokens_Passwordless_Codes_Updates>;
};


/** mutation root */
export type MutationUpdate_Supertokens_Passwordless_UsersArgs = {
  _inc?: InputMaybe<Supertokens_Passwordless_Users_Inc_Input>;
  _set?: InputMaybe<Supertokens_Passwordless_Users_Set_Input>;
  where: Supertokens_Passwordless_Users_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Supertokens_Passwordless_Users_By_PkArgs = {
  _inc?: InputMaybe<Supertokens_Passwordless_Users_Inc_Input>;
  _set?: InputMaybe<Supertokens_Passwordless_Users_Set_Input>;
  pk_columns: Supertokens_Passwordless_Users_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Supertokens_Passwordless_Users_ManyArgs = {
  updates: Array<Supertokens_Passwordless_Users_Updates>;
};


/** mutation root */
export type MutationUpdate_Supertokens_Session_Access_Token_Signing_KeysArgs = {
  _inc?: InputMaybe<Supertokens_Session_Access_Token_Signing_Keys_Inc_Input>;
  _set?: InputMaybe<Supertokens_Session_Access_Token_Signing_Keys_Set_Input>;
  where: Supertokens_Session_Access_Token_Signing_Keys_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Supertokens_Session_Access_Token_Signing_Keys_By_PkArgs = {
  _inc?: InputMaybe<Supertokens_Session_Access_Token_Signing_Keys_Inc_Input>;
  _set?: InputMaybe<Supertokens_Session_Access_Token_Signing_Keys_Set_Input>;
  pk_columns: Supertokens_Session_Access_Token_Signing_Keys_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Supertokens_Session_Access_Token_Signing_Keys_ManyArgs = {
  updates: Array<Supertokens_Session_Access_Token_Signing_Keys_Updates>;
};


/** mutation root */
export type MutationUpdate_Supertokens_Session_InfoArgs = {
  _inc?: InputMaybe<Supertokens_Session_Info_Inc_Input>;
  _set?: InputMaybe<Supertokens_Session_Info_Set_Input>;
  where: Supertokens_Session_Info_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Supertokens_Session_Info_By_PkArgs = {
  _inc?: InputMaybe<Supertokens_Session_Info_Inc_Input>;
  _set?: InputMaybe<Supertokens_Session_Info_Set_Input>;
  pk_columns: Supertokens_Session_Info_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Supertokens_Session_Info_ManyArgs = {
  updates: Array<Supertokens_Session_Info_Updates>;
};


/** mutation root */
export type MutationUpdate_Supertokens_User_Additional_FieldsArgs = {
  _inc?: InputMaybe<Supertokens_User_Additional_Fields_Inc_Input>;
  _set?: InputMaybe<Supertokens_User_Additional_Fields_Set_Input>;
  where: Supertokens_User_Additional_Fields_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Supertokens_User_Additional_Fields_By_PkArgs = {
  _inc?: InputMaybe<Supertokens_User_Additional_Fields_Inc_Input>;
  _set?: InputMaybe<Supertokens_User_Additional_Fields_Set_Input>;
  pk_columns: Supertokens_User_Additional_Fields_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Supertokens_User_Additional_Fields_ManyArgs = {
  updates: Array<Supertokens_User_Additional_Fields_Updates>;
};


/** mutation root */
export type MutationUpdate_Supertokens_User_Public_FieldsArgs = {
  _set?: InputMaybe<Supertokens_User_Public_Fields_Set_Input>;
  where: Supertokens_User_Public_Fields_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Supertokens_User_Public_Fields_ManyArgs = {
  updates: Array<Supertokens_User_Public_Fields_Updates>;
};


/** mutation root */
export type MutationUpdate_TournamentArgs = {
  _inc?: InputMaybe<Tournament_Inc_Input>;
  _set?: InputMaybe<Tournament_Set_Input>;
  where: Tournament_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Tournament_By_PkArgs = {
  _inc?: InputMaybe<Tournament_Inc_Input>;
  _set?: InputMaybe<Tournament_Set_Input>;
  pk_columns: Tournament_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Tournament_LeaderboardArgs = {
  _inc?: InputMaybe<Tournament_Leaderboard_Inc_Input>;
  _set?: InputMaybe<Tournament_Leaderboard_Set_Input>;
  where: Tournament_Leaderboard_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Tournament_Leaderboard_By_PkArgs = {
  _inc?: InputMaybe<Tournament_Leaderboard_Inc_Input>;
  _set?: InputMaybe<Tournament_Leaderboard_Set_Input>;
  pk_columns: Tournament_Leaderboard_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Tournament_Leaderboard_ManyArgs = {
  updates: Array<Tournament_Leaderboard_Updates>;
};


/** mutation root */
export type MutationUpdate_Tournament_Levels_MetadataArgs = {
  _inc?: InputMaybe<Tournament_Levels_Metadata_Inc_Input>;
  _set?: InputMaybe<Tournament_Levels_Metadata_Set_Input>;
  where: Tournament_Levels_Metadata_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Tournament_Levels_Metadata_By_PkArgs = {
  _inc?: InputMaybe<Tournament_Levels_Metadata_Inc_Input>;
  _set?: InputMaybe<Tournament_Levels_Metadata_Set_Input>;
  pk_columns: Tournament_Levels_Metadata_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Tournament_Levels_Metadata_ManyArgs = {
  updates: Array<Tournament_Levels_Metadata_Updates>;
};


/** mutation root */
export type MutationUpdate_Tournament_ManyArgs = {
  updates: Array<Tournament_Updates>;
};


/** mutation root */
export type MutationUpdate_Tournament_ParticipantsArgs = {
  _inc?: InputMaybe<Tournament_Participants_Inc_Input>;
  _set?: InputMaybe<Tournament_Participants_Set_Input>;
  where: Tournament_Participants_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Tournament_Participants_By_PkArgs = {
  _inc?: InputMaybe<Tournament_Participants_Inc_Input>;
  _set?: InputMaybe<Tournament_Participants_Set_Input>;
  pk_columns: Tournament_Participants_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Tournament_Participants_ManyArgs = {
  updates: Array<Tournament_Participants_Updates>;
};


/** mutation root */
export type MutationUpdate_Tournament_Wallet_TransactionsArgs = {
  _set?: InputMaybe<Tournament_Wallet_Transactions_Set_Input>;
  where: Tournament_Wallet_Transactions_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Tournament_Wallet_Transactions_By_PkArgs = {
  _set?: InputMaybe<Tournament_Wallet_Transactions_Set_Input>;
  pk_columns: Tournament_Wallet_Transactions_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Tournament_Wallet_Transactions_ManyArgs = {
  updates: Array<Tournament_Wallet_Transactions_Updates>;
};


/** mutation root */
export type MutationUpdate_Upi_DetailsArgs = {
  _set?: InputMaybe<Upi_Details_Set_Input>;
  where: Upi_Details_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Upi_Details_By_PkArgs = {
  _set?: InputMaybe<Upi_Details_Set_Input>;
  pk_columns: Upi_Details_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Upi_Details_ManyArgs = {
  updates: Array<Upi_Details_Updates>;
};


/** mutation root */
export type MutationUpdate_User_Room_PingsArgs = {
  _set?: InputMaybe<User_Room_Pings_Set_Input>;
  where: User_Room_Pings_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_User_Room_Pings_By_PkArgs = {
  _set?: InputMaybe<User_Room_Pings_Set_Input>;
  pk_columns: User_Room_Pings_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_User_Room_Pings_ManyArgs = {
  updates: Array<User_Room_Pings_Updates>;
};


/** mutation root */
export type MutationUpdate_Wallet_Transaction_StatusesArgs = {
  _set?: InputMaybe<Wallet_Transaction_Statuses_Set_Input>;
  where: Wallet_Transaction_Statuses_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Wallet_Transaction_Statuses_By_PkArgs = {
  _set?: InputMaybe<Wallet_Transaction_Statuses_Set_Input>;
  pk_columns: Wallet_Transaction_Statuses_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Wallet_Transaction_Statuses_ManyArgs = {
  updates: Array<Wallet_Transaction_Statuses_Updates>;
};


/** mutation root */
export type MutationUpdate_Wallet_Transaction_TypeArgs = {
  _set?: InputMaybe<Wallet_Transaction_Type_Set_Input>;
  where: Wallet_Transaction_Type_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Wallet_Transaction_Type_By_PkArgs = {
  _set?: InputMaybe<Wallet_Transaction_Type_Set_Input>;
  pk_columns: Wallet_Transaction_Type_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Wallet_Transaction_Type_ManyArgs = {
  updates: Array<Wallet_Transaction_Type_Updates>;
};


/** mutation root */
export type MutationUpdate_Wallet_TransactionsArgs = {
  _inc?: InputMaybe<Wallet_Transactions_Inc_Input>;
  _set?: InputMaybe<Wallet_Transactions_Set_Input>;
  where: Wallet_Transactions_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Wallet_Transactions_By_PkArgs = {
  _inc?: InputMaybe<Wallet_Transactions_Inc_Input>;
  _set?: InputMaybe<Wallet_Transactions_Set_Input>;
  pk_columns: Wallet_Transactions_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Wallet_Transactions_ManyArgs = {
  updates: Array<Wallet_Transactions_Updates>;
};


/** mutation root */
export type MutationUpdate_Withdrawal_RequestsArgs = {
  _inc?: InputMaybe<Withdrawal_Requests_Inc_Input>;
  _set?: InputMaybe<Withdrawal_Requests_Set_Input>;
  where: Withdrawal_Requests_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Withdrawal_Requests_By_PkArgs = {
  _inc?: InputMaybe<Withdrawal_Requests_Inc_Input>;
  _set?: InputMaybe<Withdrawal_Requests_Set_Input>;
  pk_columns: Withdrawal_Requests_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Withdrawal_Requests_ManyArgs = {
  updates: Array<Withdrawal_Requests_Updates>;
};

export type Query = {
  __typename?: 'Query';
  /** execute function "get_random_bot" which returns "supertokens.user_additional_fields" */
  get_random_bot: Array<Supertokens_User_Additional_Fields>;
  /** execute function "get_random_bot" and query aggregates on result of table type "supertokens.user_additional_fields" */
  get_random_bot_aggregate: Supertokens_User_Additional_Fields_Aggregate;
  /** execute function "get_random_fen" which returns "potential_games" */
  get_random_fen: Array<Potential_Games>;
  /** execute function "get_random_fen" and query aggregates on result of table type "potential_games" */
  get_random_fen_aggregate: Potential_Games_Aggregate;
  me: Users;
  /** An array relationship */
  pan_card_details: Array<Pan_Card_Details>;
  /** An aggregate relationship */
  pan_card_details_aggregate: Pan_Card_Details_Aggregate;
  /** fetch data from the table: "pan_card_details" using primary key columns */
  pan_card_details_by_pk?: Maybe<Pan_Card_Details>;
  ping: Scalars['String'];
  /** fetch data from the table: "potential_games" */
  potential_games: Array<Potential_Games>;
  /** fetch aggregated fields from the table: "potential_games" */
  potential_games_aggregate: Potential_Games_Aggregate;
  /** fetch data from the table: "potential_games" using primary key columns */
  potential_games_by_pk?: Maybe<Potential_Games>;
  /** fetch data from the table: "room_moves" */
  room_moves: Array<Room_Moves>;
  /** fetch aggregated fields from the table: "room_moves" */
  room_moves_aggregate: Room_Moves_Aggregate;
  /** fetch data from the table: "room_moves" using primary key columns */
  room_moves_by_pk?: Maybe<Room_Moves>;
  /** An array relationship */
  room_participants: Array<Room_Participants>;
  /** An aggregate relationship */
  room_participants_aggregate: Room_Participants_Aggregate;
  /** fetch data from the table: "room_participants" using primary key columns */
  room_participants_by_pk?: Maybe<Room_Participants>;
  /** fetch data from the table: "room_sides" */
  room_sides: Array<Room_Sides>;
  /** fetch aggregated fields from the table: "room_sides" */
  room_sides_aggregate: Room_Sides_Aggregate;
  /** fetch data from the table: "room_sides" using primary key columns */
  room_sides_by_pk?: Maybe<Room_Sides>;
  /** An array relationship */
  room_wallet_transactions: Array<Room_Wallet_Transactions>;
  /** An aggregate relationship */
  room_wallet_transactions_aggregate: Room_Wallet_Transactions_Aggregate;
  /** fetch data from the table: "room_wallet_transactions" using primary key columns */
  room_wallet_transactions_by_pk?: Maybe<Room_Wallet_Transactions>;
  /** An array relationship */
  rooms: Array<Rooms>;
  /** An aggregate relationship */
  rooms_aggregate: Rooms_Aggregate;
  /** fetch data from the table: "rooms" using primary key columns */
  rooms_by_pk?: Maybe<Rooms>;
  /** fetch data from the table: "supertokens.all_auth_recipe_users" */
  supertokens_all_auth_recipe_users: Array<Supertokens_All_Auth_Recipe_Users>;
  /** fetch aggregated fields from the table: "supertokens.all_auth_recipe_users" */
  supertokens_all_auth_recipe_users_aggregate: Supertokens_All_Auth_Recipe_Users_Aggregate;
  /** fetch data from the table: "supertokens.all_auth_recipe_users" using primary key columns */
  supertokens_all_auth_recipe_users_by_pk?: Maybe<Supertokens_All_Auth_Recipe_Users>;
  /** fetch data from the table: "supertokens.jwt_signing_keys" */
  supertokens_jwt_signing_keys: Array<Supertokens_Jwt_Signing_Keys>;
  /** fetch aggregated fields from the table: "supertokens.jwt_signing_keys" */
  supertokens_jwt_signing_keys_aggregate: Supertokens_Jwt_Signing_Keys_Aggregate;
  /** fetch data from the table: "supertokens.jwt_signing_keys" using primary key columns */
  supertokens_jwt_signing_keys_by_pk?: Maybe<Supertokens_Jwt_Signing_Keys>;
  /** fetch data from the table: "supertokens.key_value" */
  supertokens_key_value: Array<Supertokens_Key_Value>;
  /** fetch aggregated fields from the table: "supertokens.key_value" */
  supertokens_key_value_aggregate: Supertokens_Key_Value_Aggregate;
  /** fetch data from the table: "supertokens.key_value" using primary key columns */
  supertokens_key_value_by_pk?: Maybe<Supertokens_Key_Value>;
  /** fetch data from the table: "supertokens.passwordless_codes" */
  supertokens_passwordless_codes: Array<Supertokens_Passwordless_Codes>;
  /** fetch aggregated fields from the table: "supertokens.passwordless_codes" */
  supertokens_passwordless_codes_aggregate: Supertokens_Passwordless_Codes_Aggregate;
  /** fetch data from the table: "supertokens.passwordless_codes" using primary key columns */
  supertokens_passwordless_codes_by_pk?: Maybe<Supertokens_Passwordless_Codes>;
  /** fetch data from the table: "supertokens.passwordless_users" */
  supertokens_passwordless_users: Array<Supertokens_Passwordless_Users>;
  /** fetch aggregated fields from the table: "supertokens.passwordless_users" */
  supertokens_passwordless_users_aggregate: Supertokens_Passwordless_Users_Aggregate;
  /** fetch data from the table: "supertokens.passwordless_users" using primary key columns */
  supertokens_passwordless_users_by_pk?: Maybe<Supertokens_Passwordless_Users>;
  /** fetch data from the table: "supertokens.session_access_token_signing_keys" */
  supertokens_session_access_token_signing_keys: Array<Supertokens_Session_Access_Token_Signing_Keys>;
  /** fetch aggregated fields from the table: "supertokens.session_access_token_signing_keys" */
  supertokens_session_access_token_signing_keys_aggregate: Supertokens_Session_Access_Token_Signing_Keys_Aggregate;
  /** fetch data from the table: "supertokens.session_access_token_signing_keys" using primary key columns */
  supertokens_session_access_token_signing_keys_by_pk?: Maybe<Supertokens_Session_Access_Token_Signing_Keys>;
  /** fetch data from the table: "supertokens.session_info" */
  supertokens_session_info: Array<Supertokens_Session_Info>;
  /** fetch aggregated fields from the table: "supertokens.session_info" */
  supertokens_session_info_aggregate: Supertokens_Session_Info_Aggregate;
  /** fetch data from the table: "supertokens.session_info" using primary key columns */
  supertokens_session_info_by_pk?: Maybe<Supertokens_Session_Info>;
  /** fetch data from the table: "supertokens.user_additional_fields" */
  supertokens_user_additional_fields: Array<Supertokens_User_Additional_Fields>;
  /** fetch aggregated fields from the table: "supertokens.user_additional_fields" */
  supertokens_user_additional_fields_aggregate: Supertokens_User_Additional_Fields_Aggregate;
  /** fetch data from the table: "supertokens.user_additional_fields" using primary key columns */
  supertokens_user_additional_fields_by_pk?: Maybe<Supertokens_User_Additional_Fields>;
  /** fetch data from the table: "supertokens.user_public_fields" */
  supertokens_user_public_fields: Array<Supertokens_User_Public_Fields>;
  /** fetch aggregated fields from the table: "supertokens.user_public_fields" */
  supertokens_user_public_fields_aggregate: Supertokens_User_Public_Fields_Aggregate;
  /** fetch data from the table: "tournament" */
  tournament: Array<Tournament>;
  /** fetch aggregated fields from the table: "tournament" */
  tournament_aggregate: Tournament_Aggregate;
  /** fetch data from the table: "tournament" using primary key columns */
  tournament_by_pk?: Maybe<Tournament>;
  /** fetch data from the table: "tournament_leaderboard" */
  tournament_leaderboard: Array<Tournament_Leaderboard>;
  /** fetch aggregated fields from the table: "tournament_leaderboard" */
  tournament_leaderboard_aggregate: Tournament_Leaderboard_Aggregate;
  /** fetch data from the table: "tournament_leaderboard" using primary key columns */
  tournament_leaderboard_by_pk?: Maybe<Tournament_Leaderboard>;
  /** An array relationship */
  tournament_levels_metadata: Array<Tournament_Levels_Metadata>;
  /** An aggregate relationship */
  tournament_levels_metadata_aggregate: Tournament_Levels_Metadata_Aggregate;
  /** fetch data from the table: "tournament_levels_metadata" using primary key columns */
  tournament_levels_metadata_by_pk?: Maybe<Tournament_Levels_Metadata>;
  /** An array relationship */
  tournament_participants: Array<Tournament_Participants>;
  /** An aggregate relationship */
  tournament_participants_aggregate: Tournament_Participants_Aggregate;
  /** fetch data from the table: "tournament_participants" using primary key columns */
  tournament_participants_by_pk?: Maybe<Tournament_Participants>;
  /** An array relationship */
  tournament_wallet_transactions: Array<Tournament_Wallet_Transactions>;
  /** An aggregate relationship */
  tournament_wallet_transactions_aggregate: Tournament_Wallet_Transactions_Aggregate;
  /** fetch data from the table: "tournament_wallet_transactions" using primary key columns */
  tournament_wallet_transactions_by_pk?: Maybe<Tournament_Wallet_Transactions>;
  /** An array relationship */
  upi_details: Array<Upi_Details>;
  /** An aggregate relationship */
  upi_details_aggregate: Upi_Details_Aggregate;
  /** fetch data from the table: "upi_details" using primary key columns */
  upi_details_by_pk?: Maybe<Upi_Details>;
  /** fetch data from the table: "user_room_pings" */
  user_room_pings: Array<User_Room_Pings>;
  /** fetch aggregated fields from the table: "user_room_pings" */
  user_room_pings_aggregate: User_Room_Pings_Aggregate;
  /** fetch data from the table: "user_room_pings" using primary key columns */
  user_room_pings_by_pk?: Maybe<User_Room_Pings>;
  /** fetch data from the table: "wallet_transaction_statuses" */
  wallet_transaction_statuses: Array<Wallet_Transaction_Statuses>;
  /** fetch aggregated fields from the table: "wallet_transaction_statuses" */
  wallet_transaction_statuses_aggregate: Wallet_Transaction_Statuses_Aggregate;
  /** fetch data from the table: "wallet_transaction_statuses" using primary key columns */
  wallet_transaction_statuses_by_pk?: Maybe<Wallet_Transaction_Statuses>;
  /** fetch data from the table: "wallet_transaction_type" */
  wallet_transaction_type: Array<Wallet_Transaction_Type>;
  /** fetch aggregated fields from the table: "wallet_transaction_type" */
  wallet_transaction_type_aggregate: Wallet_Transaction_Type_Aggregate;
  /** fetch data from the table: "wallet_transaction_type" using primary key columns */
  wallet_transaction_type_by_pk?: Maybe<Wallet_Transaction_Type>;
  /** An array relationship */
  wallet_transactions: Array<Wallet_Transactions>;
  /** An aggregate relationship */
  wallet_transactions_aggregate: Wallet_Transactions_Aggregate;
  /** fetch data from the table: "wallet_transactions" using primary key columns */
  wallet_transactions_by_pk?: Maybe<Wallet_Transactions>;
  /** fetch data from the table: "withdrawal_requests" */
  withdrawal_requests: Array<Withdrawal_Requests>;
  /** fetch aggregated fields from the table: "withdrawal_requests" */
  withdrawal_requests_aggregate: Withdrawal_Requests_Aggregate;
  /** fetch data from the table: "withdrawal_requests" using primary key columns */
  withdrawal_requests_by_pk?: Maybe<Withdrawal_Requests>;
};


export type QueryGet_Random_BotArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_User_Additional_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_User_Additional_Fields_Order_By>>;
  where?: InputMaybe<Supertokens_User_Additional_Fields_Bool_Exp>;
};


export type QueryGet_Random_Bot_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_User_Additional_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_User_Additional_Fields_Order_By>>;
  where?: InputMaybe<Supertokens_User_Additional_Fields_Bool_Exp>;
};


export type QueryGet_Random_FenArgs = {
  args?: InputMaybe<Get_Random_Fen_Args>;
  distinct_on?: InputMaybe<Array<Potential_Games_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Potential_Games_Order_By>>;
  where?: InputMaybe<Potential_Games_Bool_Exp>;
};


export type QueryGet_Random_Fen_AggregateArgs = {
  args?: InputMaybe<Get_Random_Fen_Args>;
  distinct_on?: InputMaybe<Array<Potential_Games_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Potential_Games_Order_By>>;
  where?: InputMaybe<Potential_Games_Bool_Exp>;
};


export type QueryPan_Card_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Pan_Card_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pan_Card_Details_Order_By>>;
  where?: InputMaybe<Pan_Card_Details_Bool_Exp>;
};


export type QueryPan_Card_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pan_Card_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pan_Card_Details_Order_By>>;
  where?: InputMaybe<Pan_Card_Details_Bool_Exp>;
};


export type QueryPan_Card_Details_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryPotential_GamesArgs = {
  distinct_on?: InputMaybe<Array<Potential_Games_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Potential_Games_Order_By>>;
  where?: InputMaybe<Potential_Games_Bool_Exp>;
};


export type QueryPotential_Games_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Potential_Games_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Potential_Games_Order_By>>;
  where?: InputMaybe<Potential_Games_Bool_Exp>;
};


export type QueryPotential_Games_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryRoom_MovesArgs = {
  distinct_on?: InputMaybe<Array<Room_Moves_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Moves_Order_By>>;
  where?: InputMaybe<Room_Moves_Bool_Exp>;
};


export type QueryRoom_Moves_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Moves_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Moves_Order_By>>;
  where?: InputMaybe<Room_Moves_Bool_Exp>;
};


export type QueryRoom_Moves_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryRoom_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Room_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Participants_Order_By>>;
  where?: InputMaybe<Room_Participants_Bool_Exp>;
};


export type QueryRoom_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Participants_Order_By>>;
  where?: InputMaybe<Room_Participants_Bool_Exp>;
};


export type QueryRoom_Participants_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryRoom_SidesArgs = {
  distinct_on?: InputMaybe<Array<Room_Sides_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Sides_Order_By>>;
  where?: InputMaybe<Room_Sides_Bool_Exp>;
};


export type QueryRoom_Sides_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Sides_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Sides_Order_By>>;
  where?: InputMaybe<Room_Sides_Bool_Exp>;
};


export type QueryRoom_Sides_By_PkArgs = {
  side: Scalars['String'];
};


export type QueryRoom_Wallet_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Room_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Room_Wallet_Transactions_Bool_Exp>;
};


export type QueryRoom_Wallet_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Room_Wallet_Transactions_Bool_Exp>;
};


export type QueryRoom_Wallet_Transactions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryRoomsArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


export type QueryRooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


export type QueryRooms_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QuerySupertokens_All_Auth_Recipe_UsersArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_All_Auth_Recipe_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_All_Auth_Recipe_Users_Order_By>>;
  where?: InputMaybe<Supertokens_All_Auth_Recipe_Users_Bool_Exp>;
};


export type QuerySupertokens_All_Auth_Recipe_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_All_Auth_Recipe_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_All_Auth_Recipe_Users_Order_By>>;
  where?: InputMaybe<Supertokens_All_Auth_Recipe_Users_Bool_Exp>;
};


export type QuerySupertokens_All_Auth_Recipe_Users_By_PkArgs = {
  user_id: Scalars['bpchar'];
};


export type QuerySupertokens_Jwt_Signing_KeysArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Jwt_Signing_Keys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Jwt_Signing_Keys_Order_By>>;
  where?: InputMaybe<Supertokens_Jwt_Signing_Keys_Bool_Exp>;
};


export type QuerySupertokens_Jwt_Signing_Keys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Jwt_Signing_Keys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Jwt_Signing_Keys_Order_By>>;
  where?: InputMaybe<Supertokens_Jwt_Signing_Keys_Bool_Exp>;
};


export type QuerySupertokens_Jwt_Signing_Keys_By_PkArgs = {
  key_id: Scalars['String'];
};


export type QuerySupertokens_Key_ValueArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Key_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Key_Value_Order_By>>;
  where?: InputMaybe<Supertokens_Key_Value_Bool_Exp>;
};


export type QuerySupertokens_Key_Value_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Key_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Key_Value_Order_By>>;
  where?: InputMaybe<Supertokens_Key_Value_Bool_Exp>;
};


export type QuerySupertokens_Key_Value_By_PkArgs = {
  name: Scalars['String'];
};


export type QuerySupertokens_Passwordless_CodesArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Passwordless_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Passwordless_Codes_Order_By>>;
  where?: InputMaybe<Supertokens_Passwordless_Codes_Bool_Exp>;
};


export type QuerySupertokens_Passwordless_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Passwordless_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Passwordless_Codes_Order_By>>;
  where?: InputMaybe<Supertokens_Passwordless_Codes_Bool_Exp>;
};


export type QuerySupertokens_Passwordless_Codes_By_PkArgs = {
  code_id: Scalars['bpchar'];
};


export type QuerySupertokens_Passwordless_UsersArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Passwordless_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Passwordless_Users_Order_By>>;
  where?: InputMaybe<Supertokens_Passwordless_Users_Bool_Exp>;
};


export type QuerySupertokens_Passwordless_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Passwordless_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Passwordless_Users_Order_By>>;
  where?: InputMaybe<Supertokens_Passwordless_Users_Bool_Exp>;
};


export type QuerySupertokens_Passwordless_Users_By_PkArgs = {
  user_id: Scalars['bpchar'];
};


export type QuerySupertokens_Session_Access_Token_Signing_KeysArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Session_Access_Token_Signing_Keys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Session_Access_Token_Signing_Keys_Order_By>>;
  where?: InputMaybe<Supertokens_Session_Access_Token_Signing_Keys_Bool_Exp>;
};


export type QuerySupertokens_Session_Access_Token_Signing_Keys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Session_Access_Token_Signing_Keys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Session_Access_Token_Signing_Keys_Order_By>>;
  where?: InputMaybe<Supertokens_Session_Access_Token_Signing_Keys_Bool_Exp>;
};


export type QuerySupertokens_Session_Access_Token_Signing_Keys_By_PkArgs = {
  created_at_time: Scalars['bigint'];
};


export type QuerySupertokens_Session_InfoArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Session_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Session_Info_Order_By>>;
  where?: InputMaybe<Supertokens_Session_Info_Bool_Exp>;
};


export type QuerySupertokens_Session_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Session_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Session_Info_Order_By>>;
  where?: InputMaybe<Supertokens_Session_Info_Bool_Exp>;
};


export type QuerySupertokens_Session_Info_By_PkArgs = {
  session_handle: Scalars['String'];
};


export type QuerySupertokens_User_Additional_FieldsArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_User_Additional_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_User_Additional_Fields_Order_By>>;
  where?: InputMaybe<Supertokens_User_Additional_Fields_Bool_Exp>;
};


export type QuerySupertokens_User_Additional_Fields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_User_Additional_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_User_Additional_Fields_Order_By>>;
  where?: InputMaybe<Supertokens_User_Additional_Fields_Bool_Exp>;
};


export type QuerySupertokens_User_Additional_Fields_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QuerySupertokens_User_Public_FieldsArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_User_Public_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_User_Public_Fields_Order_By>>;
  where?: InputMaybe<Supertokens_User_Public_Fields_Bool_Exp>;
};


export type QuerySupertokens_User_Public_Fields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_User_Public_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_User_Public_Fields_Order_By>>;
  where?: InputMaybe<Supertokens_User_Public_Fields_Bool_Exp>;
};


export type QueryTournamentArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Order_By>>;
  where?: InputMaybe<Tournament_Bool_Exp>;
};


export type QueryTournament_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Order_By>>;
  where?: InputMaybe<Tournament_Bool_Exp>;
};


export type QueryTournament_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryTournament_LeaderboardArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Leaderboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Leaderboard_Order_By>>;
  where?: InputMaybe<Tournament_Leaderboard_Bool_Exp>;
};


export type QueryTournament_Leaderboard_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Leaderboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Leaderboard_Order_By>>;
  where?: InputMaybe<Tournament_Leaderboard_Bool_Exp>;
};


export type QueryTournament_Leaderboard_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryTournament_Levels_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Levels_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Levels_Metadata_Order_By>>;
  where?: InputMaybe<Tournament_Levels_Metadata_Bool_Exp>;
};


export type QueryTournament_Levels_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Levels_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Levels_Metadata_Order_By>>;
  where?: InputMaybe<Tournament_Levels_Metadata_Bool_Exp>;
};


export type QueryTournament_Levels_Metadata_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryTournament_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Participants_Order_By>>;
  where?: InputMaybe<Tournament_Participants_Bool_Exp>;
};


export type QueryTournament_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Participants_Order_By>>;
  where?: InputMaybe<Tournament_Participants_Bool_Exp>;
};


export type QueryTournament_Participants_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryTournament_Wallet_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Tournament_Wallet_Transactions_Bool_Exp>;
};


export type QueryTournament_Wallet_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Tournament_Wallet_Transactions_Bool_Exp>;
};


export type QueryTournament_Wallet_Transactions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryUpi_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Upi_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Upi_Details_Order_By>>;
  where?: InputMaybe<Upi_Details_Bool_Exp>;
};


export type QueryUpi_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Upi_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Upi_Details_Order_By>>;
  where?: InputMaybe<Upi_Details_Bool_Exp>;
};


export type QueryUpi_Details_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryUser_Room_PingsArgs = {
  distinct_on?: InputMaybe<Array<User_Room_Pings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Room_Pings_Order_By>>;
  where?: InputMaybe<User_Room_Pings_Bool_Exp>;
};


export type QueryUser_Room_Pings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Room_Pings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Room_Pings_Order_By>>;
  where?: InputMaybe<User_Room_Pings_Bool_Exp>;
};


export type QueryUser_Room_Pings_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryWallet_Transaction_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transaction_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transaction_Statuses_Order_By>>;
  where?: InputMaybe<Wallet_Transaction_Statuses_Bool_Exp>;
};


export type QueryWallet_Transaction_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transaction_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transaction_Statuses_Order_By>>;
  where?: InputMaybe<Wallet_Transaction_Statuses_Bool_Exp>;
};


export type QueryWallet_Transaction_Statuses_By_PkArgs = {
  status: Scalars['String'];
};


export type QueryWallet_Transaction_TypeArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transaction_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transaction_Type_Order_By>>;
  where?: InputMaybe<Wallet_Transaction_Type_Bool_Exp>;
};


export type QueryWallet_Transaction_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transaction_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transaction_Type_Order_By>>;
  where?: InputMaybe<Wallet_Transaction_Type_Bool_Exp>;
};


export type QueryWallet_Transaction_Type_By_PkArgs = {
  type: Scalars['String'];
};


export type QueryWallet_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Wallet_Transactions_Bool_Exp>;
};


export type QueryWallet_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Wallet_Transactions_Bool_Exp>;
};


export type QueryWallet_Transactions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryWithdrawal_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Withdrawal_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Withdrawal_Requests_Order_By>>;
  where?: InputMaybe<Withdrawal_Requests_Bool_Exp>;
};


export type QueryWithdrawal_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Withdrawal_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Withdrawal_Requests_Order_By>>;
  where?: InputMaybe<Withdrawal_Requests_Bool_Exp>;
};


export type QueryWithdrawal_Requests_By_PkArgs = {
  id: Scalars['uuid'];
};

export enum Role {
  Admin = 'ADMIN',
  Anonymous = 'ANONYMOUS',
  User = 'USER'
}

export type Sdl = {
  __typename?: 'SDL';
  sdl: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** execute function "get_random_bot" which returns "supertokens.user_additional_fields" */
  get_random_bot: Array<Supertokens_User_Additional_Fields>;
  /** execute function "get_random_bot" and query aggregates on result of table type "supertokens.user_additional_fields" */
  get_random_bot_aggregate: Supertokens_User_Additional_Fields_Aggregate;
  /** execute function "get_random_fen" which returns "potential_games" */
  get_random_fen: Array<Potential_Games>;
  /** execute function "get_random_fen" and query aggregates on result of table type "potential_games" */
  get_random_fen_aggregate: Potential_Games_Aggregate;
  /** An array relationship */
  pan_card_details: Array<Pan_Card_Details>;
  /** An aggregate relationship */
  pan_card_details_aggregate: Pan_Card_Details_Aggregate;
  /** fetch data from the table: "pan_card_details" using primary key columns */
  pan_card_details_by_pk?: Maybe<Pan_Card_Details>;
  /** fetch data from the table in a streaming manner: "pan_card_details" */
  pan_card_details_stream: Array<Pan_Card_Details>;
  ping: Scalars['String'];
  /** fetch data from the table: "potential_games" */
  potential_games: Array<Potential_Games>;
  /** fetch aggregated fields from the table: "potential_games" */
  potential_games_aggregate: Potential_Games_Aggregate;
  /** fetch data from the table: "potential_games" using primary key columns */
  potential_games_by_pk?: Maybe<Potential_Games>;
  /** fetch data from the table in a streaming manner: "potential_games" */
  potential_games_stream: Array<Potential_Games>;
  /** fetch data from the table: "room_moves" */
  room_moves: Array<Room_Moves>;
  /** fetch aggregated fields from the table: "room_moves" */
  room_moves_aggregate: Room_Moves_Aggregate;
  /** fetch data from the table: "room_moves" using primary key columns */
  room_moves_by_pk?: Maybe<Room_Moves>;
  /** fetch data from the table in a streaming manner: "room_moves" */
  room_moves_stream: Array<Room_Moves>;
  /** An array relationship */
  room_participants: Array<Room_Participants>;
  /** An aggregate relationship */
  room_participants_aggregate: Room_Participants_Aggregate;
  /** fetch data from the table: "room_participants" using primary key columns */
  room_participants_by_pk?: Maybe<Room_Participants>;
  /** fetch data from the table in a streaming manner: "room_participants" */
  room_participants_stream: Array<Room_Participants>;
  /** fetch data from the table: "room_sides" */
  room_sides: Array<Room_Sides>;
  /** fetch aggregated fields from the table: "room_sides" */
  room_sides_aggregate: Room_Sides_Aggregate;
  /** fetch data from the table: "room_sides" using primary key columns */
  room_sides_by_pk?: Maybe<Room_Sides>;
  /** fetch data from the table in a streaming manner: "room_sides" */
  room_sides_stream: Array<Room_Sides>;
  /** An array relationship */
  room_wallet_transactions: Array<Room_Wallet_Transactions>;
  /** An aggregate relationship */
  room_wallet_transactions_aggregate: Room_Wallet_Transactions_Aggregate;
  /** fetch data from the table: "room_wallet_transactions" using primary key columns */
  room_wallet_transactions_by_pk?: Maybe<Room_Wallet_Transactions>;
  /** fetch data from the table in a streaming manner: "room_wallet_transactions" */
  room_wallet_transactions_stream: Array<Room_Wallet_Transactions>;
  /** An array relationship */
  rooms: Array<Rooms>;
  /** An aggregate relationship */
  rooms_aggregate: Rooms_Aggregate;
  /** fetch data from the table: "rooms" using primary key columns */
  rooms_by_pk?: Maybe<Rooms>;
  /** fetch data from the table in a streaming manner: "rooms" */
  rooms_stream: Array<Rooms>;
  /** fetch data from the table: "supertokens.all_auth_recipe_users" */
  supertokens_all_auth_recipe_users: Array<Supertokens_All_Auth_Recipe_Users>;
  /** fetch aggregated fields from the table: "supertokens.all_auth_recipe_users" */
  supertokens_all_auth_recipe_users_aggregate: Supertokens_All_Auth_Recipe_Users_Aggregate;
  /** fetch data from the table: "supertokens.all_auth_recipe_users" using primary key columns */
  supertokens_all_auth_recipe_users_by_pk?: Maybe<Supertokens_All_Auth_Recipe_Users>;
  /** fetch data from the table in a streaming manner: "supertokens.all_auth_recipe_users" */
  supertokens_all_auth_recipe_users_stream: Array<Supertokens_All_Auth_Recipe_Users>;
  /** fetch data from the table: "supertokens.jwt_signing_keys" */
  supertokens_jwt_signing_keys: Array<Supertokens_Jwt_Signing_Keys>;
  /** fetch aggregated fields from the table: "supertokens.jwt_signing_keys" */
  supertokens_jwt_signing_keys_aggregate: Supertokens_Jwt_Signing_Keys_Aggregate;
  /** fetch data from the table: "supertokens.jwt_signing_keys" using primary key columns */
  supertokens_jwt_signing_keys_by_pk?: Maybe<Supertokens_Jwt_Signing_Keys>;
  /** fetch data from the table in a streaming manner: "supertokens.jwt_signing_keys" */
  supertokens_jwt_signing_keys_stream: Array<Supertokens_Jwt_Signing_Keys>;
  /** fetch data from the table: "supertokens.key_value" */
  supertokens_key_value: Array<Supertokens_Key_Value>;
  /** fetch aggregated fields from the table: "supertokens.key_value" */
  supertokens_key_value_aggregate: Supertokens_Key_Value_Aggregate;
  /** fetch data from the table: "supertokens.key_value" using primary key columns */
  supertokens_key_value_by_pk?: Maybe<Supertokens_Key_Value>;
  /** fetch data from the table in a streaming manner: "supertokens.key_value" */
  supertokens_key_value_stream: Array<Supertokens_Key_Value>;
  /** fetch data from the table: "supertokens.passwordless_codes" */
  supertokens_passwordless_codes: Array<Supertokens_Passwordless_Codes>;
  /** fetch aggregated fields from the table: "supertokens.passwordless_codes" */
  supertokens_passwordless_codes_aggregate: Supertokens_Passwordless_Codes_Aggregate;
  /** fetch data from the table: "supertokens.passwordless_codes" using primary key columns */
  supertokens_passwordless_codes_by_pk?: Maybe<Supertokens_Passwordless_Codes>;
  /** fetch data from the table in a streaming manner: "supertokens.passwordless_codes" */
  supertokens_passwordless_codes_stream: Array<Supertokens_Passwordless_Codes>;
  /** fetch data from the table: "supertokens.passwordless_users" */
  supertokens_passwordless_users: Array<Supertokens_Passwordless_Users>;
  /** fetch aggregated fields from the table: "supertokens.passwordless_users" */
  supertokens_passwordless_users_aggregate: Supertokens_Passwordless_Users_Aggregate;
  /** fetch data from the table: "supertokens.passwordless_users" using primary key columns */
  supertokens_passwordless_users_by_pk?: Maybe<Supertokens_Passwordless_Users>;
  /** fetch data from the table in a streaming manner: "supertokens.passwordless_users" */
  supertokens_passwordless_users_stream: Array<Supertokens_Passwordless_Users>;
  /** fetch data from the table: "supertokens.session_access_token_signing_keys" */
  supertokens_session_access_token_signing_keys: Array<Supertokens_Session_Access_Token_Signing_Keys>;
  /** fetch aggregated fields from the table: "supertokens.session_access_token_signing_keys" */
  supertokens_session_access_token_signing_keys_aggregate: Supertokens_Session_Access_Token_Signing_Keys_Aggregate;
  /** fetch data from the table: "supertokens.session_access_token_signing_keys" using primary key columns */
  supertokens_session_access_token_signing_keys_by_pk?: Maybe<Supertokens_Session_Access_Token_Signing_Keys>;
  /** fetch data from the table in a streaming manner: "supertokens.session_access_token_signing_keys" */
  supertokens_session_access_token_signing_keys_stream: Array<Supertokens_Session_Access_Token_Signing_Keys>;
  /** fetch data from the table: "supertokens.session_info" */
  supertokens_session_info: Array<Supertokens_Session_Info>;
  /** fetch aggregated fields from the table: "supertokens.session_info" */
  supertokens_session_info_aggregate: Supertokens_Session_Info_Aggregate;
  /** fetch data from the table: "supertokens.session_info" using primary key columns */
  supertokens_session_info_by_pk?: Maybe<Supertokens_Session_Info>;
  /** fetch data from the table in a streaming manner: "supertokens.session_info" */
  supertokens_session_info_stream: Array<Supertokens_Session_Info>;
  /** fetch data from the table: "supertokens.user_additional_fields" */
  supertokens_user_additional_fields: Array<Supertokens_User_Additional_Fields>;
  /** fetch aggregated fields from the table: "supertokens.user_additional_fields" */
  supertokens_user_additional_fields_aggregate: Supertokens_User_Additional_Fields_Aggregate;
  /** fetch data from the table: "supertokens.user_additional_fields" using primary key columns */
  supertokens_user_additional_fields_by_pk?: Maybe<Supertokens_User_Additional_Fields>;
  /** fetch data from the table in a streaming manner: "supertokens.user_additional_fields" */
  supertokens_user_additional_fields_stream: Array<Supertokens_User_Additional_Fields>;
  /** fetch data from the table: "supertokens.user_public_fields" */
  supertokens_user_public_fields: Array<Supertokens_User_Public_Fields>;
  /** fetch aggregated fields from the table: "supertokens.user_public_fields" */
  supertokens_user_public_fields_aggregate: Supertokens_User_Public_Fields_Aggregate;
  /** fetch data from the table in a streaming manner: "supertokens.user_public_fields" */
  supertokens_user_public_fields_stream: Array<Supertokens_User_Public_Fields>;
  /** fetch data from the table: "tournament" */
  tournament: Array<Tournament>;
  /** fetch aggregated fields from the table: "tournament" */
  tournament_aggregate: Tournament_Aggregate;
  /** fetch data from the table: "tournament" using primary key columns */
  tournament_by_pk?: Maybe<Tournament>;
  /** fetch data from the table: "tournament_leaderboard" */
  tournament_leaderboard: Array<Tournament_Leaderboard>;
  /** fetch aggregated fields from the table: "tournament_leaderboard" */
  tournament_leaderboard_aggregate: Tournament_Leaderboard_Aggregate;
  /** fetch data from the table: "tournament_leaderboard" using primary key columns */
  tournament_leaderboard_by_pk?: Maybe<Tournament_Leaderboard>;
  /** fetch data from the table in a streaming manner: "tournament_leaderboard" */
  tournament_leaderboard_stream: Array<Tournament_Leaderboard>;
  /** An array relationship */
  tournament_levels_metadata: Array<Tournament_Levels_Metadata>;
  /** An aggregate relationship */
  tournament_levels_metadata_aggregate: Tournament_Levels_Metadata_Aggregate;
  /** fetch data from the table: "tournament_levels_metadata" using primary key columns */
  tournament_levels_metadata_by_pk?: Maybe<Tournament_Levels_Metadata>;
  /** fetch data from the table in a streaming manner: "tournament_levels_metadata" */
  tournament_levels_metadata_stream: Array<Tournament_Levels_Metadata>;
  /** An array relationship */
  tournament_participants: Array<Tournament_Participants>;
  /** An aggregate relationship */
  tournament_participants_aggregate: Tournament_Participants_Aggregate;
  /** fetch data from the table: "tournament_participants" using primary key columns */
  tournament_participants_by_pk?: Maybe<Tournament_Participants>;
  /** fetch data from the table in a streaming manner: "tournament_participants" */
  tournament_participants_stream: Array<Tournament_Participants>;
  /** fetch data from the table in a streaming manner: "tournament" */
  tournament_stream: Array<Tournament>;
  /** An array relationship */
  tournament_wallet_transactions: Array<Tournament_Wallet_Transactions>;
  /** An aggregate relationship */
  tournament_wallet_transactions_aggregate: Tournament_Wallet_Transactions_Aggregate;
  /** fetch data from the table: "tournament_wallet_transactions" using primary key columns */
  tournament_wallet_transactions_by_pk?: Maybe<Tournament_Wallet_Transactions>;
  /** fetch data from the table in a streaming manner: "tournament_wallet_transactions" */
  tournament_wallet_transactions_stream: Array<Tournament_Wallet_Transactions>;
  /** An array relationship */
  upi_details: Array<Upi_Details>;
  /** An aggregate relationship */
  upi_details_aggregate: Upi_Details_Aggregate;
  /** fetch data from the table: "upi_details" using primary key columns */
  upi_details_by_pk?: Maybe<Upi_Details>;
  /** fetch data from the table in a streaming manner: "upi_details" */
  upi_details_stream: Array<Upi_Details>;
  /** fetch data from the table: "user_room_pings" */
  user_room_pings: Array<User_Room_Pings>;
  /** fetch aggregated fields from the table: "user_room_pings" */
  user_room_pings_aggregate: User_Room_Pings_Aggregate;
  /** fetch data from the table: "user_room_pings" using primary key columns */
  user_room_pings_by_pk?: Maybe<User_Room_Pings>;
  /** fetch data from the table in a streaming manner: "user_room_pings" */
  user_room_pings_stream: Array<User_Room_Pings>;
  /** fetch data from the table: "wallet_transaction_statuses" */
  wallet_transaction_statuses: Array<Wallet_Transaction_Statuses>;
  /** fetch aggregated fields from the table: "wallet_transaction_statuses" */
  wallet_transaction_statuses_aggregate: Wallet_Transaction_Statuses_Aggregate;
  /** fetch data from the table: "wallet_transaction_statuses" using primary key columns */
  wallet_transaction_statuses_by_pk?: Maybe<Wallet_Transaction_Statuses>;
  /** fetch data from the table in a streaming manner: "wallet_transaction_statuses" */
  wallet_transaction_statuses_stream: Array<Wallet_Transaction_Statuses>;
  /** fetch data from the table: "wallet_transaction_type" */
  wallet_transaction_type: Array<Wallet_Transaction_Type>;
  /** fetch aggregated fields from the table: "wallet_transaction_type" */
  wallet_transaction_type_aggregate: Wallet_Transaction_Type_Aggregate;
  /** fetch data from the table: "wallet_transaction_type" using primary key columns */
  wallet_transaction_type_by_pk?: Maybe<Wallet_Transaction_Type>;
  /** fetch data from the table in a streaming manner: "wallet_transaction_type" */
  wallet_transaction_type_stream: Array<Wallet_Transaction_Type>;
  /** An array relationship */
  wallet_transactions: Array<Wallet_Transactions>;
  /** An aggregate relationship */
  wallet_transactions_aggregate: Wallet_Transactions_Aggregate;
  /** fetch data from the table: "wallet_transactions" using primary key columns */
  wallet_transactions_by_pk?: Maybe<Wallet_Transactions>;
  /** fetch data from the table in a streaming manner: "wallet_transactions" */
  wallet_transactions_stream: Array<Wallet_Transactions>;
  /** fetch data from the table: "withdrawal_requests" */
  withdrawal_requests: Array<Withdrawal_Requests>;
  /** fetch aggregated fields from the table: "withdrawal_requests" */
  withdrawal_requests_aggregate: Withdrawal_Requests_Aggregate;
  /** fetch data from the table: "withdrawal_requests" using primary key columns */
  withdrawal_requests_by_pk?: Maybe<Withdrawal_Requests>;
  /** fetch data from the table in a streaming manner: "withdrawal_requests" */
  withdrawal_requests_stream: Array<Withdrawal_Requests>;
};


export type SubscriptionGet_Random_BotArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_User_Additional_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_User_Additional_Fields_Order_By>>;
  where?: InputMaybe<Supertokens_User_Additional_Fields_Bool_Exp>;
};


export type SubscriptionGet_Random_Bot_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_User_Additional_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_User_Additional_Fields_Order_By>>;
  where?: InputMaybe<Supertokens_User_Additional_Fields_Bool_Exp>;
};


export type SubscriptionGet_Random_FenArgs = {
  args?: InputMaybe<Get_Random_Fen_Args>;
  distinct_on?: InputMaybe<Array<Potential_Games_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Potential_Games_Order_By>>;
  where?: InputMaybe<Potential_Games_Bool_Exp>;
};


export type SubscriptionGet_Random_Fen_AggregateArgs = {
  args?: InputMaybe<Get_Random_Fen_Args>;
  distinct_on?: InputMaybe<Array<Potential_Games_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Potential_Games_Order_By>>;
  where?: InputMaybe<Potential_Games_Bool_Exp>;
};


export type SubscriptionPan_Card_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Pan_Card_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pan_Card_Details_Order_By>>;
  where?: InputMaybe<Pan_Card_Details_Bool_Exp>;
};


export type SubscriptionPan_Card_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pan_Card_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pan_Card_Details_Order_By>>;
  where?: InputMaybe<Pan_Card_Details_Bool_Exp>;
};


export type SubscriptionPan_Card_Details_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionPan_Card_Details_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Pan_Card_Details_Stream_Cursor_Input>>;
  where?: InputMaybe<Pan_Card_Details_Bool_Exp>;
};


export type SubscriptionPotential_GamesArgs = {
  distinct_on?: InputMaybe<Array<Potential_Games_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Potential_Games_Order_By>>;
  where?: InputMaybe<Potential_Games_Bool_Exp>;
};


export type SubscriptionPotential_Games_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Potential_Games_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Potential_Games_Order_By>>;
  where?: InputMaybe<Potential_Games_Bool_Exp>;
};


export type SubscriptionPotential_Games_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionPotential_Games_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Potential_Games_Stream_Cursor_Input>>;
  where?: InputMaybe<Potential_Games_Bool_Exp>;
};


export type SubscriptionRoom_MovesArgs = {
  distinct_on?: InputMaybe<Array<Room_Moves_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Moves_Order_By>>;
  where?: InputMaybe<Room_Moves_Bool_Exp>;
};


export type SubscriptionRoom_Moves_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Moves_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Moves_Order_By>>;
  where?: InputMaybe<Room_Moves_Bool_Exp>;
};


export type SubscriptionRoom_Moves_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRoom_Moves_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Room_Moves_Stream_Cursor_Input>>;
  where?: InputMaybe<Room_Moves_Bool_Exp>;
};


export type SubscriptionRoom_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Room_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Participants_Order_By>>;
  where?: InputMaybe<Room_Participants_Bool_Exp>;
};


export type SubscriptionRoom_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Participants_Order_By>>;
  where?: InputMaybe<Room_Participants_Bool_Exp>;
};


export type SubscriptionRoom_Participants_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRoom_Participants_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Room_Participants_Stream_Cursor_Input>>;
  where?: InputMaybe<Room_Participants_Bool_Exp>;
};


export type SubscriptionRoom_SidesArgs = {
  distinct_on?: InputMaybe<Array<Room_Sides_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Sides_Order_By>>;
  where?: InputMaybe<Room_Sides_Bool_Exp>;
};


export type SubscriptionRoom_Sides_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Sides_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Sides_Order_By>>;
  where?: InputMaybe<Room_Sides_Bool_Exp>;
};


export type SubscriptionRoom_Sides_By_PkArgs = {
  side: Scalars['String'];
};


export type SubscriptionRoom_Sides_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Room_Sides_Stream_Cursor_Input>>;
  where?: InputMaybe<Room_Sides_Bool_Exp>;
};


export type SubscriptionRoom_Wallet_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Room_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Room_Wallet_Transactions_Bool_Exp>;
};


export type SubscriptionRoom_Wallet_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Room_Wallet_Transactions_Bool_Exp>;
};


export type SubscriptionRoom_Wallet_Transactions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRoom_Wallet_Transactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Room_Wallet_Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Room_Wallet_Transactions_Bool_Exp>;
};


export type SubscriptionRoomsArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


export type SubscriptionRooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


export type SubscriptionRooms_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRooms_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Rooms_Stream_Cursor_Input>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


export type SubscriptionSupertokens_All_Auth_Recipe_UsersArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_All_Auth_Recipe_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_All_Auth_Recipe_Users_Order_By>>;
  where?: InputMaybe<Supertokens_All_Auth_Recipe_Users_Bool_Exp>;
};


export type SubscriptionSupertokens_All_Auth_Recipe_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_All_Auth_Recipe_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_All_Auth_Recipe_Users_Order_By>>;
  where?: InputMaybe<Supertokens_All_Auth_Recipe_Users_Bool_Exp>;
};


export type SubscriptionSupertokens_All_Auth_Recipe_Users_By_PkArgs = {
  user_id: Scalars['bpchar'];
};


export type SubscriptionSupertokens_All_Auth_Recipe_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supertokens_All_Auth_Recipe_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Supertokens_All_Auth_Recipe_Users_Bool_Exp>;
};


export type SubscriptionSupertokens_Jwt_Signing_KeysArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Jwt_Signing_Keys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Jwt_Signing_Keys_Order_By>>;
  where?: InputMaybe<Supertokens_Jwt_Signing_Keys_Bool_Exp>;
};


export type SubscriptionSupertokens_Jwt_Signing_Keys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Jwt_Signing_Keys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Jwt_Signing_Keys_Order_By>>;
  where?: InputMaybe<Supertokens_Jwt_Signing_Keys_Bool_Exp>;
};


export type SubscriptionSupertokens_Jwt_Signing_Keys_By_PkArgs = {
  key_id: Scalars['String'];
};


export type SubscriptionSupertokens_Jwt_Signing_Keys_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supertokens_Jwt_Signing_Keys_Stream_Cursor_Input>>;
  where?: InputMaybe<Supertokens_Jwt_Signing_Keys_Bool_Exp>;
};


export type SubscriptionSupertokens_Key_ValueArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Key_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Key_Value_Order_By>>;
  where?: InputMaybe<Supertokens_Key_Value_Bool_Exp>;
};


export type SubscriptionSupertokens_Key_Value_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Key_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Key_Value_Order_By>>;
  where?: InputMaybe<Supertokens_Key_Value_Bool_Exp>;
};


export type SubscriptionSupertokens_Key_Value_By_PkArgs = {
  name: Scalars['String'];
};


export type SubscriptionSupertokens_Key_Value_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supertokens_Key_Value_Stream_Cursor_Input>>;
  where?: InputMaybe<Supertokens_Key_Value_Bool_Exp>;
};


export type SubscriptionSupertokens_Passwordless_CodesArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Passwordless_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Passwordless_Codes_Order_By>>;
  where?: InputMaybe<Supertokens_Passwordless_Codes_Bool_Exp>;
};


export type SubscriptionSupertokens_Passwordless_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Passwordless_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Passwordless_Codes_Order_By>>;
  where?: InputMaybe<Supertokens_Passwordless_Codes_Bool_Exp>;
};


export type SubscriptionSupertokens_Passwordless_Codes_By_PkArgs = {
  code_id: Scalars['bpchar'];
};


export type SubscriptionSupertokens_Passwordless_Codes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supertokens_Passwordless_Codes_Stream_Cursor_Input>>;
  where?: InputMaybe<Supertokens_Passwordless_Codes_Bool_Exp>;
};


export type SubscriptionSupertokens_Passwordless_UsersArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Passwordless_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Passwordless_Users_Order_By>>;
  where?: InputMaybe<Supertokens_Passwordless_Users_Bool_Exp>;
};


export type SubscriptionSupertokens_Passwordless_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Passwordless_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Passwordless_Users_Order_By>>;
  where?: InputMaybe<Supertokens_Passwordless_Users_Bool_Exp>;
};


export type SubscriptionSupertokens_Passwordless_Users_By_PkArgs = {
  user_id: Scalars['bpchar'];
};


export type SubscriptionSupertokens_Passwordless_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supertokens_Passwordless_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Supertokens_Passwordless_Users_Bool_Exp>;
};


export type SubscriptionSupertokens_Session_Access_Token_Signing_KeysArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Session_Access_Token_Signing_Keys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Session_Access_Token_Signing_Keys_Order_By>>;
  where?: InputMaybe<Supertokens_Session_Access_Token_Signing_Keys_Bool_Exp>;
};


export type SubscriptionSupertokens_Session_Access_Token_Signing_Keys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Session_Access_Token_Signing_Keys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Session_Access_Token_Signing_Keys_Order_By>>;
  where?: InputMaybe<Supertokens_Session_Access_Token_Signing_Keys_Bool_Exp>;
};


export type SubscriptionSupertokens_Session_Access_Token_Signing_Keys_By_PkArgs = {
  created_at_time: Scalars['bigint'];
};


export type SubscriptionSupertokens_Session_Access_Token_Signing_Keys_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supertokens_Session_Access_Token_Signing_Keys_Stream_Cursor_Input>>;
  where?: InputMaybe<Supertokens_Session_Access_Token_Signing_Keys_Bool_Exp>;
};


export type SubscriptionSupertokens_Session_InfoArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Session_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Session_Info_Order_By>>;
  where?: InputMaybe<Supertokens_Session_Info_Bool_Exp>;
};


export type SubscriptionSupertokens_Session_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_Session_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_Session_Info_Order_By>>;
  where?: InputMaybe<Supertokens_Session_Info_Bool_Exp>;
};


export type SubscriptionSupertokens_Session_Info_By_PkArgs = {
  session_handle: Scalars['String'];
};


export type SubscriptionSupertokens_Session_Info_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supertokens_Session_Info_Stream_Cursor_Input>>;
  where?: InputMaybe<Supertokens_Session_Info_Bool_Exp>;
};


export type SubscriptionSupertokens_User_Additional_FieldsArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_User_Additional_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_User_Additional_Fields_Order_By>>;
  where?: InputMaybe<Supertokens_User_Additional_Fields_Bool_Exp>;
};


export type SubscriptionSupertokens_User_Additional_Fields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_User_Additional_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_User_Additional_Fields_Order_By>>;
  where?: InputMaybe<Supertokens_User_Additional_Fields_Bool_Exp>;
};


export type SubscriptionSupertokens_User_Additional_Fields_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionSupertokens_User_Additional_Fields_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supertokens_User_Additional_Fields_Stream_Cursor_Input>>;
  where?: InputMaybe<Supertokens_User_Additional_Fields_Bool_Exp>;
};


export type SubscriptionSupertokens_User_Public_FieldsArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_User_Public_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_User_Public_Fields_Order_By>>;
  where?: InputMaybe<Supertokens_User_Public_Fields_Bool_Exp>;
};


export type SubscriptionSupertokens_User_Public_Fields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Supertokens_User_Public_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Supertokens_User_Public_Fields_Order_By>>;
  where?: InputMaybe<Supertokens_User_Public_Fields_Bool_Exp>;
};


export type SubscriptionSupertokens_User_Public_Fields_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Supertokens_User_Public_Fields_Stream_Cursor_Input>>;
  where?: InputMaybe<Supertokens_User_Public_Fields_Bool_Exp>;
};


export type SubscriptionTournamentArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Order_By>>;
  where?: InputMaybe<Tournament_Bool_Exp>;
};


export type SubscriptionTournament_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Order_By>>;
  where?: InputMaybe<Tournament_Bool_Exp>;
};


export type SubscriptionTournament_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionTournament_LeaderboardArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Leaderboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Leaderboard_Order_By>>;
  where?: InputMaybe<Tournament_Leaderboard_Bool_Exp>;
};


export type SubscriptionTournament_Leaderboard_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Leaderboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Leaderboard_Order_By>>;
  where?: InputMaybe<Tournament_Leaderboard_Bool_Exp>;
};


export type SubscriptionTournament_Leaderboard_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionTournament_Leaderboard_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tournament_Leaderboard_Stream_Cursor_Input>>;
  where?: InputMaybe<Tournament_Leaderboard_Bool_Exp>;
};


export type SubscriptionTournament_Levels_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Levels_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Levels_Metadata_Order_By>>;
  where?: InputMaybe<Tournament_Levels_Metadata_Bool_Exp>;
};


export type SubscriptionTournament_Levels_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Levels_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Levels_Metadata_Order_By>>;
  where?: InputMaybe<Tournament_Levels_Metadata_Bool_Exp>;
};


export type SubscriptionTournament_Levels_Metadata_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionTournament_Levels_Metadata_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tournament_Levels_Metadata_Stream_Cursor_Input>>;
  where?: InputMaybe<Tournament_Levels_Metadata_Bool_Exp>;
};


export type SubscriptionTournament_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Participants_Order_By>>;
  where?: InputMaybe<Tournament_Participants_Bool_Exp>;
};


export type SubscriptionTournament_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Participants_Order_By>>;
  where?: InputMaybe<Tournament_Participants_Bool_Exp>;
};


export type SubscriptionTournament_Participants_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionTournament_Participants_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tournament_Participants_Stream_Cursor_Input>>;
  where?: InputMaybe<Tournament_Participants_Bool_Exp>;
};


export type SubscriptionTournament_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tournament_Stream_Cursor_Input>>;
  where?: InputMaybe<Tournament_Bool_Exp>;
};


export type SubscriptionTournament_Wallet_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Tournament_Wallet_Transactions_Bool_Exp>;
};


export type SubscriptionTournament_Wallet_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Tournament_Wallet_Transactions_Bool_Exp>;
};


export type SubscriptionTournament_Wallet_Transactions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionTournament_Wallet_Transactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tournament_Wallet_Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Tournament_Wallet_Transactions_Bool_Exp>;
};


export type SubscriptionUpi_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Upi_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Upi_Details_Order_By>>;
  where?: InputMaybe<Upi_Details_Bool_Exp>;
};


export type SubscriptionUpi_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Upi_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Upi_Details_Order_By>>;
  where?: InputMaybe<Upi_Details_Bool_Exp>;
};


export type SubscriptionUpi_Details_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionUpi_Details_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Upi_Details_Stream_Cursor_Input>>;
  where?: InputMaybe<Upi_Details_Bool_Exp>;
};


export type SubscriptionUser_Room_PingsArgs = {
  distinct_on?: InputMaybe<Array<User_Room_Pings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Room_Pings_Order_By>>;
  where?: InputMaybe<User_Room_Pings_Bool_Exp>;
};


export type SubscriptionUser_Room_Pings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Room_Pings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Room_Pings_Order_By>>;
  where?: InputMaybe<User_Room_Pings_Bool_Exp>;
};


export type SubscriptionUser_Room_Pings_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionUser_Room_Pings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Room_Pings_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Room_Pings_Bool_Exp>;
};


export type SubscriptionWallet_Transaction_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transaction_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transaction_Statuses_Order_By>>;
  where?: InputMaybe<Wallet_Transaction_Statuses_Bool_Exp>;
};


export type SubscriptionWallet_Transaction_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transaction_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transaction_Statuses_Order_By>>;
  where?: InputMaybe<Wallet_Transaction_Statuses_Bool_Exp>;
};


export type SubscriptionWallet_Transaction_Statuses_By_PkArgs = {
  status: Scalars['String'];
};


export type SubscriptionWallet_Transaction_Statuses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wallet_Transaction_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Wallet_Transaction_Statuses_Bool_Exp>;
};


export type SubscriptionWallet_Transaction_TypeArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transaction_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transaction_Type_Order_By>>;
  where?: InputMaybe<Wallet_Transaction_Type_Bool_Exp>;
};


export type SubscriptionWallet_Transaction_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transaction_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transaction_Type_Order_By>>;
  where?: InputMaybe<Wallet_Transaction_Type_Bool_Exp>;
};


export type SubscriptionWallet_Transaction_Type_By_PkArgs = {
  type: Scalars['String'];
};


export type SubscriptionWallet_Transaction_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wallet_Transaction_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Wallet_Transaction_Type_Bool_Exp>;
};


export type SubscriptionWallet_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Wallet_Transactions_Bool_Exp>;
};


export type SubscriptionWallet_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Wallet_Transactions_Bool_Exp>;
};


export type SubscriptionWallet_Transactions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionWallet_Transactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wallet_Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Wallet_Transactions_Bool_Exp>;
};


export type SubscriptionWithdrawal_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Withdrawal_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Withdrawal_Requests_Order_By>>;
  where?: InputMaybe<Withdrawal_Requests_Bool_Exp>;
};


export type SubscriptionWithdrawal_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Withdrawal_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Withdrawal_Requests_Order_By>>;
  where?: InputMaybe<Withdrawal_Requests_Bool_Exp>;
};


export type SubscriptionWithdrawal_Requests_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionWithdrawal_Requests_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Withdrawal_Requests_Stream_Cursor_Input>>;
  where?: InputMaybe<Withdrawal_Requests_Bool_Exp>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** Boolean expression to compare columns of type "bpchar". All fields are combined with logical 'AND'. */
export type Bpchar_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bpchar']>;
  _gt?: InputMaybe<Scalars['bpchar']>;
  _gte?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['bpchar']>;
  _in?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['bpchar']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['bpchar']>;
  _lt?: InputMaybe<Scalars['bpchar']>;
  _lte?: InputMaybe<Scalars['bpchar']>;
  _neq?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['bpchar']>;
  _nin?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['bpchar']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

export type Get_Random_Fen_Args = {
  type?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "pan_card_details" */
export type Pan_Card_Details = {
  __typename?: 'pan_card_details';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  pan_name: Scalars['String'];
  pan_number: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  upi_details: Array<Upi_Details>;
  /** An aggregate relationship */
  upi_details_aggregate: Upi_Details_Aggregate;
  /** An object relationship */
  user: Supertokens_User_Additional_Fields;
  user_id: Scalars['String'];
};


/** columns and relationships of "pan_card_details" */
export type Pan_Card_DetailsUpi_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Upi_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Upi_Details_Order_By>>;
  where?: InputMaybe<Upi_Details_Bool_Exp>;
};


/** columns and relationships of "pan_card_details" */
export type Pan_Card_DetailsUpi_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Upi_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Upi_Details_Order_By>>;
  where?: InputMaybe<Upi_Details_Bool_Exp>;
};

/** aggregated selection of "pan_card_details" */
export type Pan_Card_Details_Aggregate = {
  __typename?: 'pan_card_details_aggregate';
  aggregate?: Maybe<Pan_Card_Details_Aggregate_Fields>;
  nodes: Array<Pan_Card_Details>;
};

export type Pan_Card_Details_Aggregate_Bool_Exp = {
  count?: InputMaybe<Pan_Card_Details_Aggregate_Bool_Exp_Count>;
};

export type Pan_Card_Details_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Pan_Card_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Pan_Card_Details_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "pan_card_details" */
export type Pan_Card_Details_Aggregate_Fields = {
  __typename?: 'pan_card_details_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Pan_Card_Details_Max_Fields>;
  min?: Maybe<Pan_Card_Details_Min_Fields>;
};


/** aggregate fields of "pan_card_details" */
export type Pan_Card_Details_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Pan_Card_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pan_card_details" */
export type Pan_Card_Details_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Pan_Card_Details_Max_Order_By>;
  min?: InputMaybe<Pan_Card_Details_Min_Order_By>;
};

/** input type for inserting array relation for remote table "pan_card_details" */
export type Pan_Card_Details_Arr_Rel_Insert_Input = {
  data: Array<Pan_Card_Details_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Pan_Card_Details_On_Conflict>;
};

/** Boolean expression to filter rows from the table "pan_card_details". All fields are combined with a logical 'AND'. */
export type Pan_Card_Details_Bool_Exp = {
  _and?: InputMaybe<Array<Pan_Card_Details_Bool_Exp>>;
  _not?: InputMaybe<Pan_Card_Details_Bool_Exp>;
  _or?: InputMaybe<Array<Pan_Card_Details_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  pan_name?: InputMaybe<String_Comparison_Exp>;
  pan_number?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  upi_details?: InputMaybe<Upi_Details_Bool_Exp>;
  upi_details_aggregate?: InputMaybe<Upi_Details_Aggregate_Bool_Exp>;
  user?: InputMaybe<Supertokens_User_Additional_Fields_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "pan_card_details" */
export enum Pan_Card_Details_Constraint {
  /** unique or primary key constraint on columns "id" */
  PanCardDetailsPkey = 'pan_card_details_pkey'
}

/** input type for inserting data into table "pan_card_details" */
export type Pan_Card_Details_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  pan_name?: InputMaybe<Scalars['String']>;
  pan_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  upi_details?: InputMaybe<Upi_Details_Arr_Rel_Insert_Input>;
  user?: InputMaybe<Supertokens_User_Additional_Fields_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Pan_Card_Details_Max_Fields = {
  __typename?: 'pan_card_details_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  pan_name?: Maybe<Scalars['String']>;
  pan_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "pan_card_details" */
export type Pan_Card_Details_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pan_name?: InputMaybe<Order_By>;
  pan_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Pan_Card_Details_Min_Fields = {
  __typename?: 'pan_card_details_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  pan_name?: Maybe<Scalars['String']>;
  pan_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "pan_card_details" */
export type Pan_Card_Details_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pan_name?: InputMaybe<Order_By>;
  pan_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "pan_card_details" */
export type Pan_Card_Details_Mutation_Response = {
  __typename?: 'pan_card_details_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pan_Card_Details>;
};

/** on_conflict condition type for table "pan_card_details" */
export type Pan_Card_Details_On_Conflict = {
  constraint: Pan_Card_Details_Constraint;
  update_columns?: Array<Pan_Card_Details_Update_Column>;
  where?: InputMaybe<Pan_Card_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "pan_card_details". */
export type Pan_Card_Details_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pan_name?: InputMaybe<Order_By>;
  pan_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upi_details_aggregate?: InputMaybe<Upi_Details_Aggregate_Order_By>;
  user?: InputMaybe<Supertokens_User_Additional_Fields_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pan_card_details */
export type Pan_Card_Details_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "pan_card_details" */
export enum Pan_Card_Details_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PanName = 'pan_name',
  /** column name */
  PanNumber = 'pan_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "pan_card_details" */
export type Pan_Card_Details_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  pan_name?: InputMaybe<Scalars['String']>;
  pan_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "pan_card_details" */
export type Pan_Card_Details_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pan_Card_Details_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Pan_Card_Details_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  pan_name?: InputMaybe<Scalars['String']>;
  pan_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "pan_card_details" */
export enum Pan_Card_Details_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PanName = 'pan_name',
  /** column name */
  PanNumber = 'pan_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Pan_Card_Details_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Pan_Card_Details_Set_Input>;
  /** filter the rows which have to be updated */
  where: Pan_Card_Details_Bool_Exp;
};

/** columns and relationships of "potential_games" */
export type Potential_Games = {
  __typename?: 'potential_games';
  created_at: Scalars['timestamptz'];
  fen: Scalars['String'];
  id: Scalars['uuid'];
  moves?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

export type Potential_Games_Aggregate = {
  __typename?: 'potential_games_aggregate';
  aggregate?: Maybe<Potential_Games_Aggregate_Fields>;
  nodes: Array<Potential_Games>;
};

/** aggregate fields of "potential_games" */
export type Potential_Games_Aggregate_Fields = {
  __typename?: 'potential_games_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Potential_Games_Max_Fields>;
  min?: Maybe<Potential_Games_Min_Fields>;
};


/** aggregate fields of "potential_games" */
export type Potential_Games_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Potential_Games_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "potential_games". All fields are combined with a logical 'AND'. */
export type Potential_Games_Bool_Exp = {
  _and?: InputMaybe<Array<Potential_Games_Bool_Exp>>;
  _not?: InputMaybe<Potential_Games_Bool_Exp>;
  _or?: InputMaybe<Array<Potential_Games_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fen?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  moves?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "potential_games" */
export enum Potential_Games_Constraint {
  /** unique or primary key constraint on columns "fen" */
  PotentialGamesFenKey = 'potential_games_fen_key',
  /** unique or primary key constraint on columns "id" */
  PotentialGamesPkey = 'potential_games_pkey'
}

/** input type for inserting data into table "potential_games" */
export type Potential_Games_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  fen?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  moves?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Potential_Games_Max_Fields = {
  __typename?: 'potential_games_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  fen?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  moves?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Potential_Games_Min_Fields = {
  __typename?: 'potential_games_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  fen?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  moves?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "potential_games" */
export type Potential_Games_Mutation_Response = {
  __typename?: 'potential_games_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Potential_Games>;
};

/** on_conflict condition type for table "potential_games" */
export type Potential_Games_On_Conflict = {
  constraint: Potential_Games_Constraint;
  update_columns?: Array<Potential_Games_Update_Column>;
  where?: InputMaybe<Potential_Games_Bool_Exp>;
};

/** Ordering options when selecting data from "potential_games". */
export type Potential_Games_Order_By = {
  created_at?: InputMaybe<Order_By>;
  fen?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  moves?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: potential_games */
export type Potential_Games_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "potential_games" */
export enum Potential_Games_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Fen = 'fen',
  /** column name */
  Id = 'id',
  /** column name */
  Moves = 'moves',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "potential_games" */
export type Potential_Games_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  fen?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  moves?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "potential_games" */
export type Potential_Games_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Potential_Games_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Potential_Games_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  fen?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  moves?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "potential_games" */
export enum Potential_Games_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Fen = 'fen',
  /** column name */
  Id = 'id',
  /** column name */
  Moves = 'moves',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Potential_Games_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Potential_Games_Set_Input>;
  /** filter the rows which have to be updated */
  where: Potential_Games_Bool_Exp;
};

/** columns and relationships of "room_moves" */
export type Room_Moves = {
  __typename?: 'room_moves';
  cp_score?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  is_timer_expired: Scalars['Boolean'];
  move_rank?: Maybe<Scalars['Int']>;
  promotion: Scalars['String'];
  /** An object relationship */
  room_moves_room_participant: Room_Participants;
  room_participant_id: Scalars['uuid'];
  source_square?: Maybe<Scalars['String']>;
  target_square?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "room_moves" */
export type Room_Moves_Aggregate = {
  __typename?: 'room_moves_aggregate';
  aggregate?: Maybe<Room_Moves_Aggregate_Fields>;
  nodes: Array<Room_Moves>;
};

export type Room_Moves_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Room_Moves_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Room_Moves_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Room_Moves_Aggregate_Bool_Exp_Count>;
};

export type Room_Moves_Aggregate_Bool_Exp_Bool_And = {
  arguments: Room_Moves_Select_Column_Room_Moves_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Room_Moves_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Room_Moves_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Room_Moves_Select_Column_Room_Moves_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Room_Moves_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Room_Moves_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Room_Moves_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Room_Moves_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "room_moves" */
export type Room_Moves_Aggregate_Fields = {
  __typename?: 'room_moves_aggregate_fields';
  avg?: Maybe<Room_Moves_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Room_Moves_Max_Fields>;
  min?: Maybe<Room_Moves_Min_Fields>;
  stddev?: Maybe<Room_Moves_Stddev_Fields>;
  stddev_pop?: Maybe<Room_Moves_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Room_Moves_Stddev_Samp_Fields>;
  sum?: Maybe<Room_Moves_Sum_Fields>;
  var_pop?: Maybe<Room_Moves_Var_Pop_Fields>;
  var_samp?: Maybe<Room_Moves_Var_Samp_Fields>;
  variance?: Maybe<Room_Moves_Variance_Fields>;
};


/** aggregate fields of "room_moves" */
export type Room_Moves_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Room_Moves_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "room_moves" */
export type Room_Moves_Aggregate_Order_By = {
  avg?: InputMaybe<Room_Moves_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Room_Moves_Max_Order_By>;
  min?: InputMaybe<Room_Moves_Min_Order_By>;
  stddev?: InputMaybe<Room_Moves_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Room_Moves_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Room_Moves_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Room_Moves_Sum_Order_By>;
  var_pop?: InputMaybe<Room_Moves_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Room_Moves_Var_Samp_Order_By>;
  variance?: InputMaybe<Room_Moves_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "room_moves" */
export type Room_Moves_Arr_Rel_Insert_Input = {
  data: Array<Room_Moves_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Room_Moves_On_Conflict>;
};

/** aggregate avg on columns */
export type Room_Moves_Avg_Fields = {
  __typename?: 'room_moves_avg_fields';
  cp_score?: Maybe<Scalars['Float']>;
  move_rank?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "room_moves" */
export type Room_Moves_Avg_Order_By = {
  cp_score?: InputMaybe<Order_By>;
  move_rank?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "room_moves". All fields are combined with a logical 'AND'. */
export type Room_Moves_Bool_Exp = {
  _and?: InputMaybe<Array<Room_Moves_Bool_Exp>>;
  _not?: InputMaybe<Room_Moves_Bool_Exp>;
  _or?: InputMaybe<Array<Room_Moves_Bool_Exp>>;
  cp_score?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_timer_expired?: InputMaybe<Boolean_Comparison_Exp>;
  move_rank?: InputMaybe<Int_Comparison_Exp>;
  promotion?: InputMaybe<String_Comparison_Exp>;
  room_moves_room_participant?: InputMaybe<Room_Participants_Bool_Exp>;
  room_participant_id?: InputMaybe<Uuid_Comparison_Exp>;
  source_square?: InputMaybe<String_Comparison_Exp>;
  target_square?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "room_moves" */
export enum Room_Moves_Constraint {
  /** unique or primary key constraint on columns "id" */
  RoomMovesPkey = 'room_moves_pkey'
}

/** input type for incrementing numeric columns in table "room_moves" */
export type Room_Moves_Inc_Input = {
  cp_score?: InputMaybe<Scalars['Int']>;
  move_rank?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "room_moves" */
export type Room_Moves_Insert_Input = {
  cp_score?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_timer_expired?: InputMaybe<Scalars['Boolean']>;
  move_rank?: InputMaybe<Scalars['Int']>;
  promotion?: InputMaybe<Scalars['String']>;
  room_moves_room_participant?: InputMaybe<Room_Participants_Obj_Rel_Insert_Input>;
  room_participant_id?: InputMaybe<Scalars['uuid']>;
  source_square?: InputMaybe<Scalars['String']>;
  target_square?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Room_Moves_Max_Fields = {
  __typename?: 'room_moves_max_fields';
  cp_score?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  move_rank?: Maybe<Scalars['Int']>;
  promotion?: Maybe<Scalars['String']>;
  room_participant_id?: Maybe<Scalars['uuid']>;
  source_square?: Maybe<Scalars['String']>;
  target_square?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "room_moves" */
export type Room_Moves_Max_Order_By = {
  cp_score?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  move_rank?: InputMaybe<Order_By>;
  promotion?: InputMaybe<Order_By>;
  room_participant_id?: InputMaybe<Order_By>;
  source_square?: InputMaybe<Order_By>;
  target_square?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Room_Moves_Min_Fields = {
  __typename?: 'room_moves_min_fields';
  cp_score?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  move_rank?: Maybe<Scalars['Int']>;
  promotion?: Maybe<Scalars['String']>;
  room_participant_id?: Maybe<Scalars['uuid']>;
  source_square?: Maybe<Scalars['String']>;
  target_square?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "room_moves" */
export type Room_Moves_Min_Order_By = {
  cp_score?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  move_rank?: InputMaybe<Order_By>;
  promotion?: InputMaybe<Order_By>;
  room_participant_id?: InputMaybe<Order_By>;
  source_square?: InputMaybe<Order_By>;
  target_square?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "room_moves" */
export type Room_Moves_Mutation_Response = {
  __typename?: 'room_moves_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Room_Moves>;
};

/** on_conflict condition type for table "room_moves" */
export type Room_Moves_On_Conflict = {
  constraint: Room_Moves_Constraint;
  update_columns?: Array<Room_Moves_Update_Column>;
  where?: InputMaybe<Room_Moves_Bool_Exp>;
};

/** Ordering options when selecting data from "room_moves". */
export type Room_Moves_Order_By = {
  cp_score?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_timer_expired?: InputMaybe<Order_By>;
  move_rank?: InputMaybe<Order_By>;
  promotion?: InputMaybe<Order_By>;
  room_moves_room_participant?: InputMaybe<Room_Participants_Order_By>;
  room_participant_id?: InputMaybe<Order_By>;
  source_square?: InputMaybe<Order_By>;
  target_square?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: room_moves */
export type Room_Moves_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "room_moves" */
export enum Room_Moves_Select_Column {
  /** column name */
  CpScore = 'cp_score',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsTimerExpired = 'is_timer_expired',
  /** column name */
  MoveRank = 'move_rank',
  /** column name */
  Promotion = 'promotion',
  /** column name */
  RoomParticipantId = 'room_participant_id',
  /** column name */
  SourceSquare = 'source_square',
  /** column name */
  TargetSquare = 'target_square',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "room_moves_aggregate_bool_exp_bool_and_arguments_columns" columns of table "room_moves" */
export enum Room_Moves_Select_Column_Room_Moves_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsTimerExpired = 'is_timer_expired'
}

/** select "room_moves_aggregate_bool_exp_bool_or_arguments_columns" columns of table "room_moves" */
export enum Room_Moves_Select_Column_Room_Moves_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsTimerExpired = 'is_timer_expired'
}

/** input type for updating data in table "room_moves" */
export type Room_Moves_Set_Input = {
  cp_score?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_timer_expired?: InputMaybe<Scalars['Boolean']>;
  move_rank?: InputMaybe<Scalars['Int']>;
  promotion?: InputMaybe<Scalars['String']>;
  room_participant_id?: InputMaybe<Scalars['uuid']>;
  source_square?: InputMaybe<Scalars['String']>;
  target_square?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Room_Moves_Stddev_Fields = {
  __typename?: 'room_moves_stddev_fields';
  cp_score?: Maybe<Scalars['Float']>;
  move_rank?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "room_moves" */
export type Room_Moves_Stddev_Order_By = {
  cp_score?: InputMaybe<Order_By>;
  move_rank?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Room_Moves_Stddev_Pop_Fields = {
  __typename?: 'room_moves_stddev_pop_fields';
  cp_score?: Maybe<Scalars['Float']>;
  move_rank?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "room_moves" */
export type Room_Moves_Stddev_Pop_Order_By = {
  cp_score?: InputMaybe<Order_By>;
  move_rank?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Room_Moves_Stddev_Samp_Fields = {
  __typename?: 'room_moves_stddev_samp_fields';
  cp_score?: Maybe<Scalars['Float']>;
  move_rank?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "room_moves" */
export type Room_Moves_Stddev_Samp_Order_By = {
  cp_score?: InputMaybe<Order_By>;
  move_rank?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "room_moves" */
export type Room_Moves_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Room_Moves_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Room_Moves_Stream_Cursor_Value_Input = {
  cp_score?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_timer_expired?: InputMaybe<Scalars['Boolean']>;
  move_rank?: InputMaybe<Scalars['Int']>;
  promotion?: InputMaybe<Scalars['String']>;
  room_participant_id?: InputMaybe<Scalars['uuid']>;
  source_square?: InputMaybe<Scalars['String']>;
  target_square?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Room_Moves_Sum_Fields = {
  __typename?: 'room_moves_sum_fields';
  cp_score?: Maybe<Scalars['Int']>;
  move_rank?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "room_moves" */
export type Room_Moves_Sum_Order_By = {
  cp_score?: InputMaybe<Order_By>;
  move_rank?: InputMaybe<Order_By>;
};

/** update columns of table "room_moves" */
export enum Room_Moves_Update_Column {
  /** column name */
  CpScore = 'cp_score',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsTimerExpired = 'is_timer_expired',
  /** column name */
  MoveRank = 'move_rank',
  /** column name */
  Promotion = 'promotion',
  /** column name */
  RoomParticipantId = 'room_participant_id',
  /** column name */
  SourceSquare = 'source_square',
  /** column name */
  TargetSquare = 'target_square',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Room_Moves_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Room_Moves_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Room_Moves_Set_Input>;
  /** filter the rows which have to be updated */
  where: Room_Moves_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Room_Moves_Var_Pop_Fields = {
  __typename?: 'room_moves_var_pop_fields';
  cp_score?: Maybe<Scalars['Float']>;
  move_rank?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "room_moves" */
export type Room_Moves_Var_Pop_Order_By = {
  cp_score?: InputMaybe<Order_By>;
  move_rank?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Room_Moves_Var_Samp_Fields = {
  __typename?: 'room_moves_var_samp_fields';
  cp_score?: Maybe<Scalars['Float']>;
  move_rank?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "room_moves" */
export type Room_Moves_Var_Samp_Order_By = {
  cp_score?: InputMaybe<Order_By>;
  move_rank?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Room_Moves_Variance_Fields = {
  __typename?: 'room_moves_variance_fields';
  cp_score?: Maybe<Scalars['Float']>;
  move_rank?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "room_moves" */
export type Room_Moves_Variance_Order_By = {
  cp_score?: InputMaybe<Order_By>;
  move_rank?: InputMaybe<Order_By>;
};

/** columns and relationships of "room_participants" */
export type Room_Participants = {
  __typename?: 'room_participants';
  created_at: Scalars['timestamptz'];
  did_choose_side?: Maybe<Scalars['Boolean']>;
  did_choose_whether_to_start_first: Scalars['Boolean'];
  did_reach_daily_limit_for_rewards?: Maybe<Scalars['Boolean']>;
  did_start_first: Scalars['Boolean'];
  elo_rating: Scalars['Int'];
  elo_rating_change?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  is_bot: Scalars['Boolean'];
  /** A computed field, executes function "room_participants__is_current_user" */
  is_current_user?: Maybe<Scalars['Boolean']>;
  is_toss_winner?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  moves: Array<Room_Moves>;
  /** An aggregate relationship */
  moves_aggregate: Room_Moves_Aggregate;
  name: Scalars['String'];
  /** An object relationship */
  participant_additional_fields?: Maybe<Supertokens_User_Additional_Fields>;
  participant_id: Scalars['String'];
  /** An object relationship */
  room: Rooms;
  room_id: Scalars['uuid'];
  /** An object relationship */
  room_side?: Maybe<Room_Sides>;
  side?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user_room_ping?: Maybe<User_Room_Pings>;
};


/** columns and relationships of "room_participants" */
export type Room_ParticipantsMovesArgs = {
  distinct_on?: InputMaybe<Array<Room_Moves_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Moves_Order_By>>;
  where?: InputMaybe<Room_Moves_Bool_Exp>;
};


/** columns and relationships of "room_participants" */
export type Room_ParticipantsMoves_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Moves_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Moves_Order_By>>;
  where?: InputMaybe<Room_Moves_Bool_Exp>;
};

/** aggregated selection of "room_participants" */
export type Room_Participants_Aggregate = {
  __typename?: 'room_participants_aggregate';
  aggregate?: Maybe<Room_Participants_Aggregate_Fields>;
  nodes: Array<Room_Participants>;
};

export type Room_Participants_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Room_Participants_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Room_Participants_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Room_Participants_Aggregate_Bool_Exp_Count>;
};

export type Room_Participants_Aggregate_Bool_Exp_Bool_And = {
  arguments: Room_Participants_Select_Column_Room_Participants_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Room_Participants_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Room_Participants_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Room_Participants_Select_Column_Room_Participants_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Room_Participants_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Room_Participants_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Room_Participants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Room_Participants_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "room_participants" */
export type Room_Participants_Aggregate_Fields = {
  __typename?: 'room_participants_aggregate_fields';
  avg?: Maybe<Room_Participants_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Room_Participants_Max_Fields>;
  min?: Maybe<Room_Participants_Min_Fields>;
  stddev?: Maybe<Room_Participants_Stddev_Fields>;
  stddev_pop?: Maybe<Room_Participants_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Room_Participants_Stddev_Samp_Fields>;
  sum?: Maybe<Room_Participants_Sum_Fields>;
  var_pop?: Maybe<Room_Participants_Var_Pop_Fields>;
  var_samp?: Maybe<Room_Participants_Var_Samp_Fields>;
  variance?: Maybe<Room_Participants_Variance_Fields>;
};


/** aggregate fields of "room_participants" */
export type Room_Participants_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Room_Participants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "room_participants" */
export type Room_Participants_Aggregate_Order_By = {
  avg?: InputMaybe<Room_Participants_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Room_Participants_Max_Order_By>;
  min?: InputMaybe<Room_Participants_Min_Order_By>;
  stddev?: InputMaybe<Room_Participants_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Room_Participants_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Room_Participants_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Room_Participants_Sum_Order_By>;
  var_pop?: InputMaybe<Room_Participants_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Room_Participants_Var_Samp_Order_By>;
  variance?: InputMaybe<Room_Participants_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "room_participants" */
export type Room_Participants_Arr_Rel_Insert_Input = {
  data: Array<Room_Participants_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Room_Participants_On_Conflict>;
};

/** aggregate avg on columns */
export type Room_Participants_Avg_Fields = {
  __typename?: 'room_participants_avg_fields';
  elo_rating?: Maybe<Scalars['Float']>;
  elo_rating_change?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "room_participants" */
export type Room_Participants_Avg_Order_By = {
  elo_rating?: InputMaybe<Order_By>;
  elo_rating_change?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "room_participants". All fields are combined with a logical 'AND'. */
export type Room_Participants_Bool_Exp = {
  _and?: InputMaybe<Array<Room_Participants_Bool_Exp>>;
  _not?: InputMaybe<Room_Participants_Bool_Exp>;
  _or?: InputMaybe<Array<Room_Participants_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  did_choose_side?: InputMaybe<Boolean_Comparison_Exp>;
  did_choose_whether_to_start_first?: InputMaybe<Boolean_Comparison_Exp>;
  did_reach_daily_limit_for_rewards?: InputMaybe<Boolean_Comparison_Exp>;
  did_start_first?: InputMaybe<Boolean_Comparison_Exp>;
  elo_rating?: InputMaybe<Int_Comparison_Exp>;
  elo_rating_change?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_bot?: InputMaybe<Boolean_Comparison_Exp>;
  is_current_user?: InputMaybe<Boolean_Comparison_Exp>;
  is_toss_winner?: InputMaybe<Boolean_Comparison_Exp>;
  moves?: InputMaybe<Room_Moves_Bool_Exp>;
  moves_aggregate?: InputMaybe<Room_Moves_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  participant_additional_fields?: InputMaybe<Supertokens_User_Additional_Fields_Bool_Exp>;
  participant_id?: InputMaybe<String_Comparison_Exp>;
  room?: InputMaybe<Rooms_Bool_Exp>;
  room_id?: InputMaybe<Uuid_Comparison_Exp>;
  room_side?: InputMaybe<Room_Sides_Bool_Exp>;
  side?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_room_ping?: InputMaybe<User_Room_Pings_Bool_Exp>;
};

/** unique or primary key constraints on table "room_participants" */
export enum Room_Participants_Constraint {
  /** unique or primary key constraint on columns "id" */
  RoomParticipantsPkey = 'room_participants_pkey',
  /** unique or primary key constraint on columns "participant_id", "room_id" */
  RoomParticipantsRoomIdPlayerIdKey = 'room_participants_room_id_player_id_key'
}

/** input type for incrementing numeric columns in table "room_participants" */
export type Room_Participants_Inc_Input = {
  elo_rating?: InputMaybe<Scalars['Int']>;
  elo_rating_change?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "room_participants" */
export type Room_Participants_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  did_choose_side?: InputMaybe<Scalars['Boolean']>;
  did_choose_whether_to_start_first?: InputMaybe<Scalars['Boolean']>;
  did_reach_daily_limit_for_rewards?: InputMaybe<Scalars['Boolean']>;
  did_start_first?: InputMaybe<Scalars['Boolean']>;
  elo_rating?: InputMaybe<Scalars['Int']>;
  elo_rating_change?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_bot?: InputMaybe<Scalars['Boolean']>;
  is_toss_winner?: InputMaybe<Scalars['Boolean']>;
  moves?: InputMaybe<Room_Moves_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  participant_additional_fields?: InputMaybe<Supertokens_User_Additional_Fields_Obj_Rel_Insert_Input>;
  participant_id?: InputMaybe<Scalars['String']>;
  room?: InputMaybe<Rooms_Obj_Rel_Insert_Input>;
  room_id?: InputMaybe<Scalars['uuid']>;
  room_side?: InputMaybe<Room_Sides_Obj_Rel_Insert_Input>;
  side?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_room_ping?: InputMaybe<User_Room_Pings_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Room_Participants_Max_Fields = {
  __typename?: 'room_participants_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  elo_rating?: Maybe<Scalars['Int']>;
  elo_rating_change?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  participant_id?: Maybe<Scalars['String']>;
  room_id?: Maybe<Scalars['uuid']>;
  side?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "room_participants" */
export type Room_Participants_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  elo_rating?: InputMaybe<Order_By>;
  elo_rating_change?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  participant_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  side?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Room_Participants_Min_Fields = {
  __typename?: 'room_participants_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  elo_rating?: Maybe<Scalars['Int']>;
  elo_rating_change?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  participant_id?: Maybe<Scalars['String']>;
  room_id?: Maybe<Scalars['uuid']>;
  side?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "room_participants" */
export type Room_Participants_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  elo_rating?: InputMaybe<Order_By>;
  elo_rating_change?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  participant_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  side?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "room_participants" */
export type Room_Participants_Mutation_Response = {
  __typename?: 'room_participants_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Room_Participants>;
};

/** input type for inserting object relation for remote table "room_participants" */
export type Room_Participants_Obj_Rel_Insert_Input = {
  data: Room_Participants_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Room_Participants_On_Conflict>;
};

/** on_conflict condition type for table "room_participants" */
export type Room_Participants_On_Conflict = {
  constraint: Room_Participants_Constraint;
  update_columns?: Array<Room_Participants_Update_Column>;
  where?: InputMaybe<Room_Participants_Bool_Exp>;
};

/** Ordering options when selecting data from "room_participants". */
export type Room_Participants_Order_By = {
  created_at?: InputMaybe<Order_By>;
  did_choose_side?: InputMaybe<Order_By>;
  did_choose_whether_to_start_first?: InputMaybe<Order_By>;
  did_reach_daily_limit_for_rewards?: InputMaybe<Order_By>;
  did_start_first?: InputMaybe<Order_By>;
  elo_rating?: InputMaybe<Order_By>;
  elo_rating_change?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_bot?: InputMaybe<Order_By>;
  is_current_user?: InputMaybe<Order_By>;
  is_toss_winner?: InputMaybe<Order_By>;
  moves_aggregate?: InputMaybe<Room_Moves_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  participant_additional_fields?: InputMaybe<Supertokens_User_Additional_Fields_Order_By>;
  participant_id?: InputMaybe<Order_By>;
  room?: InputMaybe<Rooms_Order_By>;
  room_id?: InputMaybe<Order_By>;
  room_side?: InputMaybe<Room_Sides_Order_By>;
  side?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_room_ping?: InputMaybe<User_Room_Pings_Order_By>;
};

/** primary key columns input for table: room_participants */
export type Room_Participants_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "room_participants" */
export enum Room_Participants_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DidChooseSide = 'did_choose_side',
  /** column name */
  DidChooseWhetherToStartFirst = 'did_choose_whether_to_start_first',
  /** column name */
  DidReachDailyLimitForRewards = 'did_reach_daily_limit_for_rewards',
  /** column name */
  DidStartFirst = 'did_start_first',
  /** column name */
  EloRating = 'elo_rating',
  /** column name */
  EloRatingChange = 'elo_rating_change',
  /** column name */
  Id = 'id',
  /** column name */
  IsBot = 'is_bot',
  /** column name */
  IsTossWinner = 'is_toss_winner',
  /** column name */
  Name = 'name',
  /** column name */
  ParticipantId = 'participant_id',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  Side = 'side',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "room_participants_aggregate_bool_exp_bool_and_arguments_columns" columns of table "room_participants" */
export enum Room_Participants_Select_Column_Room_Participants_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  DidChooseSide = 'did_choose_side',
  /** column name */
  DidChooseWhetherToStartFirst = 'did_choose_whether_to_start_first',
  /** column name */
  DidReachDailyLimitForRewards = 'did_reach_daily_limit_for_rewards',
  /** column name */
  DidStartFirst = 'did_start_first',
  /** column name */
  IsBot = 'is_bot',
  /** column name */
  IsTossWinner = 'is_toss_winner'
}

/** select "room_participants_aggregate_bool_exp_bool_or_arguments_columns" columns of table "room_participants" */
export enum Room_Participants_Select_Column_Room_Participants_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  DidChooseSide = 'did_choose_side',
  /** column name */
  DidChooseWhetherToStartFirst = 'did_choose_whether_to_start_first',
  /** column name */
  DidReachDailyLimitForRewards = 'did_reach_daily_limit_for_rewards',
  /** column name */
  DidStartFirst = 'did_start_first',
  /** column name */
  IsBot = 'is_bot',
  /** column name */
  IsTossWinner = 'is_toss_winner'
}

/** input type for updating data in table "room_participants" */
export type Room_Participants_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  did_choose_side?: InputMaybe<Scalars['Boolean']>;
  did_choose_whether_to_start_first?: InputMaybe<Scalars['Boolean']>;
  did_reach_daily_limit_for_rewards?: InputMaybe<Scalars['Boolean']>;
  did_start_first?: InputMaybe<Scalars['Boolean']>;
  elo_rating?: InputMaybe<Scalars['Int']>;
  elo_rating_change?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_bot?: InputMaybe<Scalars['Boolean']>;
  is_toss_winner?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  participant_id?: InputMaybe<Scalars['String']>;
  room_id?: InputMaybe<Scalars['uuid']>;
  side?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Room_Participants_Stddev_Fields = {
  __typename?: 'room_participants_stddev_fields';
  elo_rating?: Maybe<Scalars['Float']>;
  elo_rating_change?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "room_participants" */
export type Room_Participants_Stddev_Order_By = {
  elo_rating?: InputMaybe<Order_By>;
  elo_rating_change?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Room_Participants_Stddev_Pop_Fields = {
  __typename?: 'room_participants_stddev_pop_fields';
  elo_rating?: Maybe<Scalars['Float']>;
  elo_rating_change?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "room_participants" */
export type Room_Participants_Stddev_Pop_Order_By = {
  elo_rating?: InputMaybe<Order_By>;
  elo_rating_change?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Room_Participants_Stddev_Samp_Fields = {
  __typename?: 'room_participants_stddev_samp_fields';
  elo_rating?: Maybe<Scalars['Float']>;
  elo_rating_change?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "room_participants" */
export type Room_Participants_Stddev_Samp_Order_By = {
  elo_rating?: InputMaybe<Order_By>;
  elo_rating_change?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "room_participants" */
export type Room_Participants_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Room_Participants_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Room_Participants_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  did_choose_side?: InputMaybe<Scalars['Boolean']>;
  did_choose_whether_to_start_first?: InputMaybe<Scalars['Boolean']>;
  did_reach_daily_limit_for_rewards?: InputMaybe<Scalars['Boolean']>;
  did_start_first?: InputMaybe<Scalars['Boolean']>;
  elo_rating?: InputMaybe<Scalars['Int']>;
  elo_rating_change?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_bot?: InputMaybe<Scalars['Boolean']>;
  is_toss_winner?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  participant_id?: InputMaybe<Scalars['String']>;
  room_id?: InputMaybe<Scalars['uuid']>;
  side?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Room_Participants_Sum_Fields = {
  __typename?: 'room_participants_sum_fields';
  elo_rating?: Maybe<Scalars['Int']>;
  elo_rating_change?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "room_participants" */
export type Room_Participants_Sum_Order_By = {
  elo_rating?: InputMaybe<Order_By>;
  elo_rating_change?: InputMaybe<Order_By>;
};

/** update columns of table "room_participants" */
export enum Room_Participants_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DidChooseSide = 'did_choose_side',
  /** column name */
  DidChooseWhetherToStartFirst = 'did_choose_whether_to_start_first',
  /** column name */
  DidReachDailyLimitForRewards = 'did_reach_daily_limit_for_rewards',
  /** column name */
  DidStartFirst = 'did_start_first',
  /** column name */
  EloRating = 'elo_rating',
  /** column name */
  EloRatingChange = 'elo_rating_change',
  /** column name */
  Id = 'id',
  /** column name */
  IsBot = 'is_bot',
  /** column name */
  IsTossWinner = 'is_toss_winner',
  /** column name */
  Name = 'name',
  /** column name */
  ParticipantId = 'participant_id',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  Side = 'side',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Room_Participants_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Room_Participants_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Room_Participants_Set_Input>;
  /** filter the rows which have to be updated */
  where: Room_Participants_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Room_Participants_Var_Pop_Fields = {
  __typename?: 'room_participants_var_pop_fields';
  elo_rating?: Maybe<Scalars['Float']>;
  elo_rating_change?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "room_participants" */
export type Room_Participants_Var_Pop_Order_By = {
  elo_rating?: InputMaybe<Order_By>;
  elo_rating_change?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Room_Participants_Var_Samp_Fields = {
  __typename?: 'room_participants_var_samp_fields';
  elo_rating?: Maybe<Scalars['Float']>;
  elo_rating_change?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "room_participants" */
export type Room_Participants_Var_Samp_Order_By = {
  elo_rating?: InputMaybe<Order_By>;
  elo_rating_change?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Room_Participants_Variance_Fields = {
  __typename?: 'room_participants_variance_fields';
  elo_rating?: Maybe<Scalars['Float']>;
  elo_rating_change?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "room_participants" */
export type Room_Participants_Variance_Order_By = {
  elo_rating?: InputMaybe<Order_By>;
  elo_rating_change?: InputMaybe<Order_By>;
};

/** columns and relationships of "room_sides" */
export type Room_Sides = {
  __typename?: 'room_sides';
  /** An array relationship */
  room_participants: Array<Room_Participants>;
  /** An aggregate relationship */
  room_participants_aggregate: Room_Participants_Aggregate;
  side: Scalars['String'];
};


/** columns and relationships of "room_sides" */
export type Room_SidesRoom_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Room_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Participants_Order_By>>;
  where?: InputMaybe<Room_Participants_Bool_Exp>;
};


/** columns and relationships of "room_sides" */
export type Room_SidesRoom_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Participants_Order_By>>;
  where?: InputMaybe<Room_Participants_Bool_Exp>;
};

/** aggregated selection of "room_sides" */
export type Room_Sides_Aggregate = {
  __typename?: 'room_sides_aggregate';
  aggregate?: Maybe<Room_Sides_Aggregate_Fields>;
  nodes: Array<Room_Sides>;
};

/** aggregate fields of "room_sides" */
export type Room_Sides_Aggregate_Fields = {
  __typename?: 'room_sides_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Room_Sides_Max_Fields>;
  min?: Maybe<Room_Sides_Min_Fields>;
};


/** aggregate fields of "room_sides" */
export type Room_Sides_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Room_Sides_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "room_sides". All fields are combined with a logical 'AND'. */
export type Room_Sides_Bool_Exp = {
  _and?: InputMaybe<Array<Room_Sides_Bool_Exp>>;
  _not?: InputMaybe<Room_Sides_Bool_Exp>;
  _or?: InputMaybe<Array<Room_Sides_Bool_Exp>>;
  room_participants?: InputMaybe<Room_Participants_Bool_Exp>;
  room_participants_aggregate?: InputMaybe<Room_Participants_Aggregate_Bool_Exp>;
  side?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "room_sides" */
export enum Room_Sides_Constraint {
  /** unique or primary key constraint on columns "side" */
  RoomSidesPkey = 'room_sides_pkey'
}

/** input type for inserting data into table "room_sides" */
export type Room_Sides_Insert_Input = {
  room_participants?: InputMaybe<Room_Participants_Arr_Rel_Insert_Input>;
  side?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Room_Sides_Max_Fields = {
  __typename?: 'room_sides_max_fields';
  side?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Room_Sides_Min_Fields = {
  __typename?: 'room_sides_min_fields';
  side?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "room_sides" */
export type Room_Sides_Mutation_Response = {
  __typename?: 'room_sides_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Room_Sides>;
};

/** input type for inserting object relation for remote table "room_sides" */
export type Room_Sides_Obj_Rel_Insert_Input = {
  data: Room_Sides_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Room_Sides_On_Conflict>;
};

/** on_conflict condition type for table "room_sides" */
export type Room_Sides_On_Conflict = {
  constraint: Room_Sides_Constraint;
  update_columns?: Array<Room_Sides_Update_Column>;
  where?: InputMaybe<Room_Sides_Bool_Exp>;
};

/** Ordering options when selecting data from "room_sides". */
export type Room_Sides_Order_By = {
  room_participants_aggregate?: InputMaybe<Room_Participants_Aggregate_Order_By>;
  side?: InputMaybe<Order_By>;
};

/** primary key columns input for table: room_sides */
export type Room_Sides_Pk_Columns_Input = {
  side: Scalars['String'];
};

/** select columns of table "room_sides" */
export enum Room_Sides_Select_Column {
  /** column name */
  Side = 'side'
}

/** input type for updating data in table "room_sides" */
export type Room_Sides_Set_Input = {
  side?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "room_sides" */
export type Room_Sides_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Room_Sides_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Room_Sides_Stream_Cursor_Value_Input = {
  side?: InputMaybe<Scalars['String']>;
};

/** update columns of table "room_sides" */
export enum Room_Sides_Update_Column {
  /** column name */
  Side = 'side'
}

export type Room_Sides_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Room_Sides_Set_Input>;
  /** filter the rows which have to be updated */
  where: Room_Sides_Bool_Exp;
};

/** columns and relationships of "room_wallet_transactions" */
export type Room_Wallet_Transactions = {
  __typename?: 'room_wallet_transactions';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  room_id: Scalars['uuid'];
  /** An object relationship */
  rooms: Rooms;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  wallet_transaction: Wallet_Transactions;
  wallet_transaction_id: Scalars['uuid'];
};

/** aggregated selection of "room_wallet_transactions" */
export type Room_Wallet_Transactions_Aggregate = {
  __typename?: 'room_wallet_transactions_aggregate';
  aggregate?: Maybe<Room_Wallet_Transactions_Aggregate_Fields>;
  nodes: Array<Room_Wallet_Transactions>;
};

export type Room_Wallet_Transactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Room_Wallet_Transactions_Aggregate_Bool_Exp_Count>;
};

export type Room_Wallet_Transactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Room_Wallet_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Room_Wallet_Transactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "room_wallet_transactions" */
export type Room_Wallet_Transactions_Aggregate_Fields = {
  __typename?: 'room_wallet_transactions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Room_Wallet_Transactions_Max_Fields>;
  min?: Maybe<Room_Wallet_Transactions_Min_Fields>;
};


/** aggregate fields of "room_wallet_transactions" */
export type Room_Wallet_Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Room_Wallet_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "room_wallet_transactions" */
export type Room_Wallet_Transactions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Room_Wallet_Transactions_Max_Order_By>;
  min?: InputMaybe<Room_Wallet_Transactions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "room_wallet_transactions" */
export type Room_Wallet_Transactions_Arr_Rel_Insert_Input = {
  data: Array<Room_Wallet_Transactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Room_Wallet_Transactions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "room_wallet_transactions". All fields are combined with a logical 'AND'. */
export type Room_Wallet_Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<Room_Wallet_Transactions_Bool_Exp>>;
  _not?: InputMaybe<Room_Wallet_Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<Room_Wallet_Transactions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  room_id?: InputMaybe<Uuid_Comparison_Exp>;
  rooms?: InputMaybe<Rooms_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  wallet_transaction?: InputMaybe<Wallet_Transactions_Bool_Exp>;
  wallet_transaction_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "room_wallet_transactions" */
export enum Room_Wallet_Transactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  RoomWalletTransactionsPkey = 'room_wallet_transactions_pkey',
  /** unique or primary key constraint on columns "room_id" */
  RoomWalletTransactionsRoomIdKey = 'room_wallet_transactions_room_id_key'
}

/** input type for inserting data into table "room_wallet_transactions" */
export type Room_Wallet_Transactions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  room_id?: InputMaybe<Scalars['uuid']>;
  rooms?: InputMaybe<Rooms_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wallet_transaction?: InputMaybe<Wallet_Transactions_Obj_Rel_Insert_Input>;
  wallet_transaction_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Room_Wallet_Transactions_Max_Fields = {
  __typename?: 'room_wallet_transactions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  room_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wallet_transaction_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "room_wallet_transactions" */
export type Room_Wallet_Transactions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wallet_transaction_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Room_Wallet_Transactions_Min_Fields = {
  __typename?: 'room_wallet_transactions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  room_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wallet_transaction_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "room_wallet_transactions" */
export type Room_Wallet_Transactions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wallet_transaction_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "room_wallet_transactions" */
export type Room_Wallet_Transactions_Mutation_Response = {
  __typename?: 'room_wallet_transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Room_Wallet_Transactions>;
};

/** on_conflict condition type for table "room_wallet_transactions" */
export type Room_Wallet_Transactions_On_Conflict = {
  constraint: Room_Wallet_Transactions_Constraint;
  update_columns?: Array<Room_Wallet_Transactions_Update_Column>;
  where?: InputMaybe<Room_Wallet_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "room_wallet_transactions". */
export type Room_Wallet_Transactions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  rooms?: InputMaybe<Rooms_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wallet_transaction?: InputMaybe<Wallet_Transactions_Order_By>;
  wallet_transaction_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: room_wallet_transactions */
export type Room_Wallet_Transactions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "room_wallet_transactions" */
export enum Room_Wallet_Transactions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WalletTransactionId = 'wallet_transaction_id'
}

/** input type for updating data in table "room_wallet_transactions" */
export type Room_Wallet_Transactions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  room_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wallet_transaction_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "room_wallet_transactions" */
export type Room_Wallet_Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Room_Wallet_Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Room_Wallet_Transactions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  room_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wallet_transaction_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "room_wallet_transactions" */
export enum Room_Wallet_Transactions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WalletTransactionId = 'wallet_transaction_id'
}

export type Room_Wallet_Transactions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Room_Wallet_Transactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Room_Wallet_Transactions_Bool_Exp;
};

/** columns and relationships of "rooms" */
export type Rooms = {
  __typename?: 'rooms';
  black_user_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  creator_id: Scalars['String'];
  /** Field to tell backend that we're running a test and it should not let the bot make the moves */
  disable_bot_moves_for_testing: Scalars['Boolean'];
  game_start_time?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "has_participated" */
  has_participated?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  initial_fen: Scalars['String'];
  is_checkmate?: Maybe<Scalars['Boolean']>;
  is_draw?: Maybe<Scalars['Boolean']>;
  is_game_over: Scalars['Boolean'];
  is_onboarding_game?: Maybe<Scalars['Boolean']>;
  is_play_with_friends_game_start_button_clicked: Scalars['Boolean'];
  is_rewards_distributed: Scalars['Boolean'];
  is_single_player_game: Scalars['Boolean'];
  is_toss_completed: Scalars['Boolean'];
  /** An object relationship */
  parent_room?: Maybe<Rooms>;
  /** An array relationship */
  participants: Array<Room_Participants>;
  /** An aggregate relationship */
  participants_aggregate: Room_Participants_Aggregate;
  /** An object relationship */
  replay_room?: Maybe<Rooms>;
  replay_room_id?: Maybe<Scalars['uuid']>;
  retry_level: Scalars['Int'];
  /** An object relationship */
  retry_level_metadata?: Maybe<Tournament_Levels_Metadata>;
  /** An array relationship */
  room_wallet_transactions: Array<Room_Wallet_Transactions>;
  /** An aggregate relationship */
  room_wallet_transactions_aggregate: Room_Wallet_Transactions_Aggregate;
  /** An object relationship */
  tournament?: Maybe<Tournament>;
  tournament_id?: Maybe<Scalars['uuid']>;
  tournament_level?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  white_user_id?: Maybe<Scalars['uuid']>;
  win_margin?: Maybe<Scalars['Int']>;
  winner_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "rooms" */
export type RoomsParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Room_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Participants_Order_By>>;
  where?: InputMaybe<Room_Participants_Bool_Exp>;
};


/** columns and relationships of "rooms" */
export type RoomsParticipants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Participants_Order_By>>;
  where?: InputMaybe<Room_Participants_Bool_Exp>;
};


/** columns and relationships of "rooms" */
export type RoomsRoom_Wallet_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Room_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Room_Wallet_Transactions_Bool_Exp>;
};


/** columns and relationships of "rooms" */
export type RoomsRoom_Wallet_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Room_Wallet_Transactions_Bool_Exp>;
};

/** aggregated selection of "rooms" */
export type Rooms_Aggregate = {
  __typename?: 'rooms_aggregate';
  aggregate?: Maybe<Rooms_Aggregate_Fields>;
  nodes: Array<Rooms>;
};

export type Rooms_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Rooms_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Rooms_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Rooms_Aggregate_Bool_Exp_Count>;
};

export type Rooms_Aggregate_Bool_Exp_Bool_And = {
  arguments: Rooms_Select_Column_Rooms_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rooms_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Rooms_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Rooms_Select_Column_Rooms_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rooms_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Rooms_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Rooms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rooms_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rooms" */
export type Rooms_Aggregate_Fields = {
  __typename?: 'rooms_aggregate_fields';
  avg?: Maybe<Rooms_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Rooms_Max_Fields>;
  min?: Maybe<Rooms_Min_Fields>;
  stddev?: Maybe<Rooms_Stddev_Fields>;
  stddev_pop?: Maybe<Rooms_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rooms_Stddev_Samp_Fields>;
  sum?: Maybe<Rooms_Sum_Fields>;
  var_pop?: Maybe<Rooms_Var_Pop_Fields>;
  var_samp?: Maybe<Rooms_Var_Samp_Fields>;
  variance?: Maybe<Rooms_Variance_Fields>;
};


/** aggregate fields of "rooms" */
export type Rooms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rooms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "rooms" */
export type Rooms_Aggregate_Order_By = {
  avg?: InputMaybe<Rooms_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rooms_Max_Order_By>;
  min?: InputMaybe<Rooms_Min_Order_By>;
  stddev?: InputMaybe<Rooms_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rooms_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rooms_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rooms_Sum_Order_By>;
  var_pop?: InputMaybe<Rooms_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rooms_Var_Samp_Order_By>;
  variance?: InputMaybe<Rooms_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rooms" */
export type Rooms_Arr_Rel_Insert_Input = {
  data: Array<Rooms_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Rooms_On_Conflict>;
};

/** aggregate avg on columns */
export type Rooms_Avg_Fields = {
  __typename?: 'rooms_avg_fields';
  retry_level?: Maybe<Scalars['Float']>;
  tournament_level?: Maybe<Scalars['Float']>;
  win_margin?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "rooms" */
export type Rooms_Avg_Order_By = {
  retry_level?: InputMaybe<Order_By>;
  tournament_level?: InputMaybe<Order_By>;
  win_margin?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rooms". All fields are combined with a logical 'AND'. */
export type Rooms_Bool_Exp = {
  _and?: InputMaybe<Array<Rooms_Bool_Exp>>;
  _not?: InputMaybe<Rooms_Bool_Exp>;
  _or?: InputMaybe<Array<Rooms_Bool_Exp>>;
  black_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  creator_id?: InputMaybe<String_Comparison_Exp>;
  disable_bot_moves_for_testing?: InputMaybe<Boolean_Comparison_Exp>;
  game_start_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  has_participated?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  initial_fen?: InputMaybe<String_Comparison_Exp>;
  is_checkmate?: InputMaybe<Boolean_Comparison_Exp>;
  is_draw?: InputMaybe<Boolean_Comparison_Exp>;
  is_game_over?: InputMaybe<Boolean_Comparison_Exp>;
  is_onboarding_game?: InputMaybe<Boolean_Comparison_Exp>;
  is_play_with_friends_game_start_button_clicked?: InputMaybe<Boolean_Comparison_Exp>;
  is_rewards_distributed?: InputMaybe<Boolean_Comparison_Exp>;
  is_single_player_game?: InputMaybe<Boolean_Comparison_Exp>;
  is_toss_completed?: InputMaybe<Boolean_Comparison_Exp>;
  parent_room?: InputMaybe<Rooms_Bool_Exp>;
  participants?: InputMaybe<Room_Participants_Bool_Exp>;
  participants_aggregate?: InputMaybe<Room_Participants_Aggregate_Bool_Exp>;
  replay_room?: InputMaybe<Rooms_Bool_Exp>;
  replay_room_id?: InputMaybe<Uuid_Comparison_Exp>;
  retry_level?: InputMaybe<Int_Comparison_Exp>;
  retry_level_metadata?: InputMaybe<Tournament_Levels_Metadata_Bool_Exp>;
  room_wallet_transactions?: InputMaybe<Room_Wallet_Transactions_Bool_Exp>;
  room_wallet_transactions_aggregate?: InputMaybe<Room_Wallet_Transactions_Aggregate_Bool_Exp>;
  tournament?: InputMaybe<Tournament_Bool_Exp>;
  tournament_id?: InputMaybe<Uuid_Comparison_Exp>;
  tournament_level?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  white_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  win_margin?: InputMaybe<Int_Comparison_Exp>;
  winner_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "rooms" */
export enum Rooms_Constraint {
  /** unique or primary key constraint on columns "id" */
  RoomsPkey = 'rooms_pkey',
  /** unique or primary key constraint on columns "replay_room_id" */
  RoomsReplayRoomIdKey = 'rooms_replay_room_id_key'
}

/** input type for incrementing numeric columns in table "rooms" */
export type Rooms_Inc_Input = {
  retry_level?: InputMaybe<Scalars['Int']>;
  tournament_level?: InputMaybe<Scalars['Int']>;
  win_margin?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "rooms" */
export type Rooms_Insert_Input = {
  black_user_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['String']>;
  /** Field to tell backend that we're running a test and it should not let the bot make the moves */
  disable_bot_moves_for_testing?: InputMaybe<Scalars['Boolean']>;
  game_start_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  initial_fen?: InputMaybe<Scalars['String']>;
  is_checkmate?: InputMaybe<Scalars['Boolean']>;
  is_draw?: InputMaybe<Scalars['Boolean']>;
  is_game_over?: InputMaybe<Scalars['Boolean']>;
  is_onboarding_game?: InputMaybe<Scalars['Boolean']>;
  is_play_with_friends_game_start_button_clicked?: InputMaybe<Scalars['Boolean']>;
  is_rewards_distributed?: InputMaybe<Scalars['Boolean']>;
  is_single_player_game?: InputMaybe<Scalars['Boolean']>;
  is_toss_completed?: InputMaybe<Scalars['Boolean']>;
  parent_room?: InputMaybe<Rooms_Obj_Rel_Insert_Input>;
  participants?: InputMaybe<Room_Participants_Arr_Rel_Insert_Input>;
  replay_room?: InputMaybe<Rooms_Obj_Rel_Insert_Input>;
  replay_room_id?: InputMaybe<Scalars['uuid']>;
  retry_level?: InputMaybe<Scalars['Int']>;
  retry_level_metadata?: InputMaybe<Tournament_Levels_Metadata_Obj_Rel_Insert_Input>;
  room_wallet_transactions?: InputMaybe<Room_Wallet_Transactions_Arr_Rel_Insert_Input>;
  tournament?: InputMaybe<Tournament_Obj_Rel_Insert_Input>;
  tournament_id?: InputMaybe<Scalars['uuid']>;
  tournament_level?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  white_user_id?: InputMaybe<Scalars['uuid']>;
  win_margin?: InputMaybe<Scalars['Int']>;
  winner_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Rooms_Max_Fields = {
  __typename?: 'rooms_max_fields';
  black_user_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['String']>;
  game_start_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  initial_fen?: Maybe<Scalars['String']>;
  replay_room_id?: Maybe<Scalars['uuid']>;
  retry_level?: Maybe<Scalars['Int']>;
  tournament_id?: Maybe<Scalars['uuid']>;
  tournament_level?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  white_user_id?: Maybe<Scalars['uuid']>;
  win_margin?: Maybe<Scalars['Int']>;
  winner_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "rooms" */
export type Rooms_Max_Order_By = {
  black_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  game_start_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_fen?: InputMaybe<Order_By>;
  replay_room_id?: InputMaybe<Order_By>;
  retry_level?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
  tournament_level?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  white_user_id?: InputMaybe<Order_By>;
  win_margin?: InputMaybe<Order_By>;
  winner_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rooms_Min_Fields = {
  __typename?: 'rooms_min_fields';
  black_user_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['String']>;
  game_start_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  initial_fen?: Maybe<Scalars['String']>;
  replay_room_id?: Maybe<Scalars['uuid']>;
  retry_level?: Maybe<Scalars['Int']>;
  tournament_id?: Maybe<Scalars['uuid']>;
  tournament_level?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  white_user_id?: Maybe<Scalars['uuid']>;
  win_margin?: Maybe<Scalars['Int']>;
  winner_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "rooms" */
export type Rooms_Min_Order_By = {
  black_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  game_start_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_fen?: InputMaybe<Order_By>;
  replay_room_id?: InputMaybe<Order_By>;
  retry_level?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
  tournament_level?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  white_user_id?: InputMaybe<Order_By>;
  win_margin?: InputMaybe<Order_By>;
  winner_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rooms" */
export type Rooms_Mutation_Response = {
  __typename?: 'rooms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rooms>;
};

/** input type for inserting object relation for remote table "rooms" */
export type Rooms_Obj_Rel_Insert_Input = {
  data: Rooms_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Rooms_On_Conflict>;
};

/** on_conflict condition type for table "rooms" */
export type Rooms_On_Conflict = {
  constraint: Rooms_Constraint;
  update_columns?: Array<Rooms_Update_Column>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};

/** Ordering options when selecting data from "rooms". */
export type Rooms_Order_By = {
  black_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  disable_bot_moves_for_testing?: InputMaybe<Order_By>;
  game_start_time?: InputMaybe<Order_By>;
  has_participated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_fen?: InputMaybe<Order_By>;
  is_checkmate?: InputMaybe<Order_By>;
  is_draw?: InputMaybe<Order_By>;
  is_game_over?: InputMaybe<Order_By>;
  is_onboarding_game?: InputMaybe<Order_By>;
  is_play_with_friends_game_start_button_clicked?: InputMaybe<Order_By>;
  is_rewards_distributed?: InputMaybe<Order_By>;
  is_single_player_game?: InputMaybe<Order_By>;
  is_toss_completed?: InputMaybe<Order_By>;
  parent_room?: InputMaybe<Rooms_Order_By>;
  participants_aggregate?: InputMaybe<Room_Participants_Aggregate_Order_By>;
  replay_room?: InputMaybe<Rooms_Order_By>;
  replay_room_id?: InputMaybe<Order_By>;
  retry_level?: InputMaybe<Order_By>;
  retry_level_metadata?: InputMaybe<Tournament_Levels_Metadata_Order_By>;
  room_wallet_transactions_aggregate?: InputMaybe<Room_Wallet_Transactions_Aggregate_Order_By>;
  tournament?: InputMaybe<Tournament_Order_By>;
  tournament_id?: InputMaybe<Order_By>;
  tournament_level?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  white_user_id?: InputMaybe<Order_By>;
  win_margin?: InputMaybe<Order_By>;
  winner_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rooms */
export type Rooms_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "rooms" */
export enum Rooms_Select_Column {
  /** column name */
  BlackUserId = 'black_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  DisableBotMovesForTesting = 'disable_bot_moves_for_testing',
  /** column name */
  GameStartTime = 'game_start_time',
  /** column name */
  Id = 'id',
  /** column name */
  InitialFen = 'initial_fen',
  /** column name */
  IsCheckmate = 'is_checkmate',
  /** column name */
  IsDraw = 'is_draw',
  /** column name */
  IsGameOver = 'is_game_over',
  /** column name */
  IsOnboardingGame = 'is_onboarding_game',
  /** column name */
  IsPlayWithFriendsGameStartButtonClicked = 'is_play_with_friends_game_start_button_clicked',
  /** column name */
  IsRewardsDistributed = 'is_rewards_distributed',
  /** column name */
  IsSinglePlayerGame = 'is_single_player_game',
  /** column name */
  IsTossCompleted = 'is_toss_completed',
  /** column name */
  ReplayRoomId = 'replay_room_id',
  /** column name */
  RetryLevel = 'retry_level',
  /** column name */
  TournamentId = 'tournament_id',
  /** column name */
  TournamentLevel = 'tournament_level',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WhiteUserId = 'white_user_id',
  /** column name */
  WinMargin = 'win_margin',
  /** column name */
  WinnerId = 'winner_id'
}

/** select "rooms_aggregate_bool_exp_bool_and_arguments_columns" columns of table "rooms" */
export enum Rooms_Select_Column_Rooms_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  DisableBotMovesForTesting = 'disable_bot_moves_for_testing',
  /** column name */
  IsCheckmate = 'is_checkmate',
  /** column name */
  IsDraw = 'is_draw',
  /** column name */
  IsGameOver = 'is_game_over',
  /** column name */
  IsOnboardingGame = 'is_onboarding_game',
  /** column name */
  IsPlayWithFriendsGameStartButtonClicked = 'is_play_with_friends_game_start_button_clicked',
  /** column name */
  IsRewardsDistributed = 'is_rewards_distributed',
  /** column name */
  IsSinglePlayerGame = 'is_single_player_game',
  /** column name */
  IsTossCompleted = 'is_toss_completed'
}

/** select "rooms_aggregate_bool_exp_bool_or_arguments_columns" columns of table "rooms" */
export enum Rooms_Select_Column_Rooms_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  DisableBotMovesForTesting = 'disable_bot_moves_for_testing',
  /** column name */
  IsCheckmate = 'is_checkmate',
  /** column name */
  IsDraw = 'is_draw',
  /** column name */
  IsGameOver = 'is_game_over',
  /** column name */
  IsOnboardingGame = 'is_onboarding_game',
  /** column name */
  IsPlayWithFriendsGameStartButtonClicked = 'is_play_with_friends_game_start_button_clicked',
  /** column name */
  IsRewardsDistributed = 'is_rewards_distributed',
  /** column name */
  IsSinglePlayerGame = 'is_single_player_game',
  /** column name */
  IsTossCompleted = 'is_toss_completed'
}

/** input type for updating data in table "rooms" */
export type Rooms_Set_Input = {
  black_user_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['String']>;
  /** Field to tell backend that we're running a test and it should not let the bot make the moves */
  disable_bot_moves_for_testing?: InputMaybe<Scalars['Boolean']>;
  game_start_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  initial_fen?: InputMaybe<Scalars['String']>;
  is_checkmate?: InputMaybe<Scalars['Boolean']>;
  is_draw?: InputMaybe<Scalars['Boolean']>;
  is_game_over?: InputMaybe<Scalars['Boolean']>;
  is_onboarding_game?: InputMaybe<Scalars['Boolean']>;
  is_play_with_friends_game_start_button_clicked?: InputMaybe<Scalars['Boolean']>;
  is_rewards_distributed?: InputMaybe<Scalars['Boolean']>;
  is_single_player_game?: InputMaybe<Scalars['Boolean']>;
  is_toss_completed?: InputMaybe<Scalars['Boolean']>;
  replay_room_id?: InputMaybe<Scalars['uuid']>;
  retry_level?: InputMaybe<Scalars['Int']>;
  tournament_id?: InputMaybe<Scalars['uuid']>;
  tournament_level?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  white_user_id?: InputMaybe<Scalars['uuid']>;
  win_margin?: InputMaybe<Scalars['Int']>;
  winner_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Rooms_Stddev_Fields = {
  __typename?: 'rooms_stddev_fields';
  retry_level?: Maybe<Scalars['Float']>;
  tournament_level?: Maybe<Scalars['Float']>;
  win_margin?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "rooms" */
export type Rooms_Stddev_Order_By = {
  retry_level?: InputMaybe<Order_By>;
  tournament_level?: InputMaybe<Order_By>;
  win_margin?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rooms_Stddev_Pop_Fields = {
  __typename?: 'rooms_stddev_pop_fields';
  retry_level?: Maybe<Scalars['Float']>;
  tournament_level?: Maybe<Scalars['Float']>;
  win_margin?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "rooms" */
export type Rooms_Stddev_Pop_Order_By = {
  retry_level?: InputMaybe<Order_By>;
  tournament_level?: InputMaybe<Order_By>;
  win_margin?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rooms_Stddev_Samp_Fields = {
  __typename?: 'rooms_stddev_samp_fields';
  retry_level?: Maybe<Scalars['Float']>;
  tournament_level?: Maybe<Scalars['Float']>;
  win_margin?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "rooms" */
export type Rooms_Stddev_Samp_Order_By = {
  retry_level?: InputMaybe<Order_By>;
  tournament_level?: InputMaybe<Order_By>;
  win_margin?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "rooms" */
export type Rooms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rooms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rooms_Stream_Cursor_Value_Input = {
  black_user_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['String']>;
  /** Field to tell backend that we're running a test and it should not let the bot make the moves */
  disable_bot_moves_for_testing?: InputMaybe<Scalars['Boolean']>;
  game_start_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  initial_fen?: InputMaybe<Scalars['String']>;
  is_checkmate?: InputMaybe<Scalars['Boolean']>;
  is_draw?: InputMaybe<Scalars['Boolean']>;
  is_game_over?: InputMaybe<Scalars['Boolean']>;
  is_onboarding_game?: InputMaybe<Scalars['Boolean']>;
  is_play_with_friends_game_start_button_clicked?: InputMaybe<Scalars['Boolean']>;
  is_rewards_distributed?: InputMaybe<Scalars['Boolean']>;
  is_single_player_game?: InputMaybe<Scalars['Boolean']>;
  is_toss_completed?: InputMaybe<Scalars['Boolean']>;
  replay_room_id?: InputMaybe<Scalars['uuid']>;
  retry_level?: InputMaybe<Scalars['Int']>;
  tournament_id?: InputMaybe<Scalars['uuid']>;
  tournament_level?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  white_user_id?: InputMaybe<Scalars['uuid']>;
  win_margin?: InputMaybe<Scalars['Int']>;
  winner_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Rooms_Sum_Fields = {
  __typename?: 'rooms_sum_fields';
  retry_level?: Maybe<Scalars['Int']>;
  tournament_level?: Maybe<Scalars['Int']>;
  win_margin?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "rooms" */
export type Rooms_Sum_Order_By = {
  retry_level?: InputMaybe<Order_By>;
  tournament_level?: InputMaybe<Order_By>;
  win_margin?: InputMaybe<Order_By>;
};

/** update columns of table "rooms" */
export enum Rooms_Update_Column {
  /** column name */
  BlackUserId = 'black_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  DisableBotMovesForTesting = 'disable_bot_moves_for_testing',
  /** column name */
  GameStartTime = 'game_start_time',
  /** column name */
  Id = 'id',
  /** column name */
  InitialFen = 'initial_fen',
  /** column name */
  IsCheckmate = 'is_checkmate',
  /** column name */
  IsDraw = 'is_draw',
  /** column name */
  IsGameOver = 'is_game_over',
  /** column name */
  IsOnboardingGame = 'is_onboarding_game',
  /** column name */
  IsPlayWithFriendsGameStartButtonClicked = 'is_play_with_friends_game_start_button_clicked',
  /** column name */
  IsRewardsDistributed = 'is_rewards_distributed',
  /** column name */
  IsSinglePlayerGame = 'is_single_player_game',
  /** column name */
  IsTossCompleted = 'is_toss_completed',
  /** column name */
  ReplayRoomId = 'replay_room_id',
  /** column name */
  RetryLevel = 'retry_level',
  /** column name */
  TournamentId = 'tournament_id',
  /** column name */
  TournamentLevel = 'tournament_level',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WhiteUserId = 'white_user_id',
  /** column name */
  WinMargin = 'win_margin',
  /** column name */
  WinnerId = 'winner_id'
}

export type Rooms_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Rooms_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rooms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rooms_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Rooms_Var_Pop_Fields = {
  __typename?: 'rooms_var_pop_fields';
  retry_level?: Maybe<Scalars['Float']>;
  tournament_level?: Maybe<Scalars['Float']>;
  win_margin?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "rooms" */
export type Rooms_Var_Pop_Order_By = {
  retry_level?: InputMaybe<Order_By>;
  tournament_level?: InputMaybe<Order_By>;
  win_margin?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rooms_Var_Samp_Fields = {
  __typename?: 'rooms_var_samp_fields';
  retry_level?: Maybe<Scalars['Float']>;
  tournament_level?: Maybe<Scalars['Float']>;
  win_margin?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "rooms" */
export type Rooms_Var_Samp_Order_By = {
  retry_level?: InputMaybe<Order_By>;
  tournament_level?: InputMaybe<Order_By>;
  win_margin?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rooms_Variance_Fields = {
  __typename?: 'rooms_variance_fields';
  retry_level?: Maybe<Scalars['Float']>;
  tournament_level?: Maybe<Scalars['Float']>;
  win_margin?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "rooms" */
export type Rooms_Variance_Order_By = {
  retry_level?: InputMaybe<Order_By>;
  tournament_level?: InputMaybe<Order_By>;
  win_margin?: InputMaybe<Order_By>;
};

export type SetOnboardDataType = {
  onboard_current_game?: InputMaybe<Scalars['String']>;
  onboard_did_checkmate_in_first_attempt?: InputMaybe<Scalars['Boolean']>;
  onboard_did_tried_again_any_moves?: InputMaybe<Scalars['Boolean']>;
  onboard_is_first_game_completed?: InputMaybe<Scalars['Boolean']>;
};

/** columns and relationships of "supertokens.all_auth_recipe_users" */
export type Supertokens_All_Auth_Recipe_Users = {
  __typename?: 'supertokens_all_auth_recipe_users';
  recipe_id: Scalars['String'];
  time_joined: Scalars['bigint'];
  user_id: Scalars['bpchar'];
};

/** aggregated selection of "supertokens.all_auth_recipe_users" */
export type Supertokens_All_Auth_Recipe_Users_Aggregate = {
  __typename?: 'supertokens_all_auth_recipe_users_aggregate';
  aggregate?: Maybe<Supertokens_All_Auth_Recipe_Users_Aggregate_Fields>;
  nodes: Array<Supertokens_All_Auth_Recipe_Users>;
};

/** aggregate fields of "supertokens.all_auth_recipe_users" */
export type Supertokens_All_Auth_Recipe_Users_Aggregate_Fields = {
  __typename?: 'supertokens_all_auth_recipe_users_aggregate_fields';
  avg?: Maybe<Supertokens_All_Auth_Recipe_Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Supertokens_All_Auth_Recipe_Users_Max_Fields>;
  min?: Maybe<Supertokens_All_Auth_Recipe_Users_Min_Fields>;
  stddev?: Maybe<Supertokens_All_Auth_Recipe_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Supertokens_All_Auth_Recipe_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Supertokens_All_Auth_Recipe_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Supertokens_All_Auth_Recipe_Users_Sum_Fields>;
  var_pop?: Maybe<Supertokens_All_Auth_Recipe_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Supertokens_All_Auth_Recipe_Users_Var_Samp_Fields>;
  variance?: Maybe<Supertokens_All_Auth_Recipe_Users_Variance_Fields>;
};


/** aggregate fields of "supertokens.all_auth_recipe_users" */
export type Supertokens_All_Auth_Recipe_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supertokens_All_Auth_Recipe_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Supertokens_All_Auth_Recipe_Users_Avg_Fields = {
  __typename?: 'supertokens_all_auth_recipe_users_avg_fields';
  time_joined?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "supertokens.all_auth_recipe_users". All fields are combined with a logical 'AND'. */
export type Supertokens_All_Auth_Recipe_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Supertokens_All_Auth_Recipe_Users_Bool_Exp>>;
  _not?: InputMaybe<Supertokens_All_Auth_Recipe_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Supertokens_All_Auth_Recipe_Users_Bool_Exp>>;
  recipe_id?: InputMaybe<String_Comparison_Exp>;
  time_joined?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Bpchar_Comparison_Exp>;
};

/** unique or primary key constraints on table "supertokens.all_auth_recipe_users" */
export enum Supertokens_All_Auth_Recipe_Users_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  AllAuthRecipeUsersPkey = 'all_auth_recipe_users_pkey'
}

/** input type for incrementing numeric columns in table "supertokens.all_auth_recipe_users" */
export type Supertokens_All_Auth_Recipe_Users_Inc_Input = {
  time_joined?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "supertokens.all_auth_recipe_users" */
export type Supertokens_All_Auth_Recipe_Users_Insert_Input = {
  recipe_id?: InputMaybe<Scalars['String']>;
  time_joined?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bpchar']>;
};

/** aggregate max on columns */
export type Supertokens_All_Auth_Recipe_Users_Max_Fields = {
  __typename?: 'supertokens_all_auth_recipe_users_max_fields';
  recipe_id?: Maybe<Scalars['String']>;
  time_joined?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bpchar']>;
};

/** aggregate min on columns */
export type Supertokens_All_Auth_Recipe_Users_Min_Fields = {
  __typename?: 'supertokens_all_auth_recipe_users_min_fields';
  recipe_id?: Maybe<Scalars['String']>;
  time_joined?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bpchar']>;
};

/** response of any mutation on the table "supertokens.all_auth_recipe_users" */
export type Supertokens_All_Auth_Recipe_Users_Mutation_Response = {
  __typename?: 'supertokens_all_auth_recipe_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Supertokens_All_Auth_Recipe_Users>;
};

/** input type for inserting object relation for remote table "supertokens.all_auth_recipe_users" */
export type Supertokens_All_Auth_Recipe_Users_Obj_Rel_Insert_Input = {
  data: Supertokens_All_Auth_Recipe_Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Supertokens_All_Auth_Recipe_Users_On_Conflict>;
};

/** on_conflict condition type for table "supertokens.all_auth_recipe_users" */
export type Supertokens_All_Auth_Recipe_Users_On_Conflict = {
  constraint: Supertokens_All_Auth_Recipe_Users_Constraint;
  update_columns?: Array<Supertokens_All_Auth_Recipe_Users_Update_Column>;
  where?: InputMaybe<Supertokens_All_Auth_Recipe_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "supertokens.all_auth_recipe_users". */
export type Supertokens_All_Auth_Recipe_Users_Order_By = {
  recipe_id?: InputMaybe<Order_By>;
  time_joined?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: supertokens.all_auth_recipe_users */
export type Supertokens_All_Auth_Recipe_Users_Pk_Columns_Input = {
  user_id: Scalars['bpchar'];
};

/** select columns of table "supertokens.all_auth_recipe_users" */
export enum Supertokens_All_Auth_Recipe_Users_Select_Column {
  /** column name */
  RecipeId = 'recipe_id',
  /** column name */
  TimeJoined = 'time_joined',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "supertokens.all_auth_recipe_users" */
export type Supertokens_All_Auth_Recipe_Users_Set_Input = {
  recipe_id?: InputMaybe<Scalars['String']>;
  time_joined?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bpchar']>;
};

/** aggregate stddev on columns */
export type Supertokens_All_Auth_Recipe_Users_Stddev_Fields = {
  __typename?: 'supertokens_all_auth_recipe_users_stddev_fields';
  time_joined?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Supertokens_All_Auth_Recipe_Users_Stddev_Pop_Fields = {
  __typename?: 'supertokens_all_auth_recipe_users_stddev_pop_fields';
  time_joined?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Supertokens_All_Auth_Recipe_Users_Stddev_Samp_Fields = {
  __typename?: 'supertokens_all_auth_recipe_users_stddev_samp_fields';
  time_joined?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "supertokens_all_auth_recipe_users" */
export type Supertokens_All_Auth_Recipe_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supertokens_All_Auth_Recipe_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supertokens_All_Auth_Recipe_Users_Stream_Cursor_Value_Input = {
  recipe_id?: InputMaybe<Scalars['String']>;
  time_joined?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bpchar']>;
};

/** aggregate sum on columns */
export type Supertokens_All_Auth_Recipe_Users_Sum_Fields = {
  __typename?: 'supertokens_all_auth_recipe_users_sum_fields';
  time_joined?: Maybe<Scalars['bigint']>;
};

/** update columns of table "supertokens.all_auth_recipe_users" */
export enum Supertokens_All_Auth_Recipe_Users_Update_Column {
  /** column name */
  RecipeId = 'recipe_id',
  /** column name */
  TimeJoined = 'time_joined',
  /** column name */
  UserId = 'user_id'
}

export type Supertokens_All_Auth_Recipe_Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Supertokens_All_Auth_Recipe_Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Supertokens_All_Auth_Recipe_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Supertokens_All_Auth_Recipe_Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Supertokens_All_Auth_Recipe_Users_Var_Pop_Fields = {
  __typename?: 'supertokens_all_auth_recipe_users_var_pop_fields';
  time_joined?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Supertokens_All_Auth_Recipe_Users_Var_Samp_Fields = {
  __typename?: 'supertokens_all_auth_recipe_users_var_samp_fields';
  time_joined?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Supertokens_All_Auth_Recipe_Users_Variance_Fields = {
  __typename?: 'supertokens_all_auth_recipe_users_variance_fields';
  time_joined?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "supertokens.jwt_signing_keys" */
export type Supertokens_Jwt_Signing_Keys = {
  __typename?: 'supertokens_jwt_signing_keys';
  algorithm: Scalars['String'];
  created_at?: Maybe<Scalars['bigint']>;
  key_id: Scalars['String'];
  key_string: Scalars['String'];
};

/** aggregated selection of "supertokens.jwt_signing_keys" */
export type Supertokens_Jwt_Signing_Keys_Aggregate = {
  __typename?: 'supertokens_jwt_signing_keys_aggregate';
  aggregate?: Maybe<Supertokens_Jwt_Signing_Keys_Aggregate_Fields>;
  nodes: Array<Supertokens_Jwt_Signing_Keys>;
};

/** aggregate fields of "supertokens.jwt_signing_keys" */
export type Supertokens_Jwt_Signing_Keys_Aggregate_Fields = {
  __typename?: 'supertokens_jwt_signing_keys_aggregate_fields';
  avg?: Maybe<Supertokens_Jwt_Signing_Keys_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Supertokens_Jwt_Signing_Keys_Max_Fields>;
  min?: Maybe<Supertokens_Jwt_Signing_Keys_Min_Fields>;
  stddev?: Maybe<Supertokens_Jwt_Signing_Keys_Stddev_Fields>;
  stddev_pop?: Maybe<Supertokens_Jwt_Signing_Keys_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Supertokens_Jwt_Signing_Keys_Stddev_Samp_Fields>;
  sum?: Maybe<Supertokens_Jwt_Signing_Keys_Sum_Fields>;
  var_pop?: Maybe<Supertokens_Jwt_Signing_Keys_Var_Pop_Fields>;
  var_samp?: Maybe<Supertokens_Jwt_Signing_Keys_Var_Samp_Fields>;
  variance?: Maybe<Supertokens_Jwt_Signing_Keys_Variance_Fields>;
};


/** aggregate fields of "supertokens.jwt_signing_keys" */
export type Supertokens_Jwt_Signing_Keys_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supertokens_Jwt_Signing_Keys_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Supertokens_Jwt_Signing_Keys_Avg_Fields = {
  __typename?: 'supertokens_jwt_signing_keys_avg_fields';
  created_at?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "supertokens.jwt_signing_keys". All fields are combined with a logical 'AND'. */
export type Supertokens_Jwt_Signing_Keys_Bool_Exp = {
  _and?: InputMaybe<Array<Supertokens_Jwt_Signing_Keys_Bool_Exp>>;
  _not?: InputMaybe<Supertokens_Jwt_Signing_Keys_Bool_Exp>;
  _or?: InputMaybe<Array<Supertokens_Jwt_Signing_Keys_Bool_Exp>>;
  algorithm?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Bigint_Comparison_Exp>;
  key_id?: InputMaybe<String_Comparison_Exp>;
  key_string?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "supertokens.jwt_signing_keys" */
export enum Supertokens_Jwt_Signing_Keys_Constraint {
  /** unique or primary key constraint on columns "key_id" */
  JwtSigningKeysPkey = 'jwt_signing_keys_pkey'
}

/** input type for incrementing numeric columns in table "supertokens.jwt_signing_keys" */
export type Supertokens_Jwt_Signing_Keys_Inc_Input = {
  created_at?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "supertokens.jwt_signing_keys" */
export type Supertokens_Jwt_Signing_Keys_Insert_Input = {
  algorithm?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['bigint']>;
  key_id?: InputMaybe<Scalars['String']>;
  key_string?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Supertokens_Jwt_Signing_Keys_Max_Fields = {
  __typename?: 'supertokens_jwt_signing_keys_max_fields';
  algorithm?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['bigint']>;
  key_id?: Maybe<Scalars['String']>;
  key_string?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Supertokens_Jwt_Signing_Keys_Min_Fields = {
  __typename?: 'supertokens_jwt_signing_keys_min_fields';
  algorithm?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['bigint']>;
  key_id?: Maybe<Scalars['String']>;
  key_string?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "supertokens.jwt_signing_keys" */
export type Supertokens_Jwt_Signing_Keys_Mutation_Response = {
  __typename?: 'supertokens_jwt_signing_keys_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Supertokens_Jwt_Signing_Keys>;
};

/** on_conflict condition type for table "supertokens.jwt_signing_keys" */
export type Supertokens_Jwt_Signing_Keys_On_Conflict = {
  constraint: Supertokens_Jwt_Signing_Keys_Constraint;
  update_columns?: Array<Supertokens_Jwt_Signing_Keys_Update_Column>;
  where?: InputMaybe<Supertokens_Jwt_Signing_Keys_Bool_Exp>;
};

/** Ordering options when selecting data from "supertokens.jwt_signing_keys". */
export type Supertokens_Jwt_Signing_Keys_Order_By = {
  algorithm?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  key_string?: InputMaybe<Order_By>;
};

/** primary key columns input for table: supertokens.jwt_signing_keys */
export type Supertokens_Jwt_Signing_Keys_Pk_Columns_Input = {
  key_id: Scalars['String'];
};

/** select columns of table "supertokens.jwt_signing_keys" */
export enum Supertokens_Jwt_Signing_Keys_Select_Column {
  /** column name */
  Algorithm = 'algorithm',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  KeyString = 'key_string'
}

/** input type for updating data in table "supertokens.jwt_signing_keys" */
export type Supertokens_Jwt_Signing_Keys_Set_Input = {
  algorithm?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['bigint']>;
  key_id?: InputMaybe<Scalars['String']>;
  key_string?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Supertokens_Jwt_Signing_Keys_Stddev_Fields = {
  __typename?: 'supertokens_jwt_signing_keys_stddev_fields';
  created_at?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Supertokens_Jwt_Signing_Keys_Stddev_Pop_Fields = {
  __typename?: 'supertokens_jwt_signing_keys_stddev_pop_fields';
  created_at?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Supertokens_Jwt_Signing_Keys_Stddev_Samp_Fields = {
  __typename?: 'supertokens_jwt_signing_keys_stddev_samp_fields';
  created_at?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "supertokens_jwt_signing_keys" */
export type Supertokens_Jwt_Signing_Keys_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supertokens_Jwt_Signing_Keys_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supertokens_Jwt_Signing_Keys_Stream_Cursor_Value_Input = {
  algorithm?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['bigint']>;
  key_id?: InputMaybe<Scalars['String']>;
  key_string?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Supertokens_Jwt_Signing_Keys_Sum_Fields = {
  __typename?: 'supertokens_jwt_signing_keys_sum_fields';
  created_at?: Maybe<Scalars['bigint']>;
};

/** update columns of table "supertokens.jwt_signing_keys" */
export enum Supertokens_Jwt_Signing_Keys_Update_Column {
  /** column name */
  Algorithm = 'algorithm',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  KeyString = 'key_string'
}

export type Supertokens_Jwt_Signing_Keys_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Supertokens_Jwt_Signing_Keys_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Supertokens_Jwt_Signing_Keys_Set_Input>;
  /** filter the rows which have to be updated */
  where: Supertokens_Jwt_Signing_Keys_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Supertokens_Jwt_Signing_Keys_Var_Pop_Fields = {
  __typename?: 'supertokens_jwt_signing_keys_var_pop_fields';
  created_at?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Supertokens_Jwt_Signing_Keys_Var_Samp_Fields = {
  __typename?: 'supertokens_jwt_signing_keys_var_samp_fields';
  created_at?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Supertokens_Jwt_Signing_Keys_Variance_Fields = {
  __typename?: 'supertokens_jwt_signing_keys_variance_fields';
  created_at?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "supertokens.key_value" */
export type Supertokens_Key_Value = {
  __typename?: 'supertokens_key_value';
  created_at_time?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

/** aggregated selection of "supertokens.key_value" */
export type Supertokens_Key_Value_Aggregate = {
  __typename?: 'supertokens_key_value_aggregate';
  aggregate?: Maybe<Supertokens_Key_Value_Aggregate_Fields>;
  nodes: Array<Supertokens_Key_Value>;
};

/** aggregate fields of "supertokens.key_value" */
export type Supertokens_Key_Value_Aggregate_Fields = {
  __typename?: 'supertokens_key_value_aggregate_fields';
  avg?: Maybe<Supertokens_Key_Value_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Supertokens_Key_Value_Max_Fields>;
  min?: Maybe<Supertokens_Key_Value_Min_Fields>;
  stddev?: Maybe<Supertokens_Key_Value_Stddev_Fields>;
  stddev_pop?: Maybe<Supertokens_Key_Value_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Supertokens_Key_Value_Stddev_Samp_Fields>;
  sum?: Maybe<Supertokens_Key_Value_Sum_Fields>;
  var_pop?: Maybe<Supertokens_Key_Value_Var_Pop_Fields>;
  var_samp?: Maybe<Supertokens_Key_Value_Var_Samp_Fields>;
  variance?: Maybe<Supertokens_Key_Value_Variance_Fields>;
};


/** aggregate fields of "supertokens.key_value" */
export type Supertokens_Key_Value_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supertokens_Key_Value_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Supertokens_Key_Value_Avg_Fields = {
  __typename?: 'supertokens_key_value_avg_fields';
  created_at_time?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "supertokens.key_value". All fields are combined with a logical 'AND'. */
export type Supertokens_Key_Value_Bool_Exp = {
  _and?: InputMaybe<Array<Supertokens_Key_Value_Bool_Exp>>;
  _not?: InputMaybe<Supertokens_Key_Value_Bool_Exp>;
  _or?: InputMaybe<Array<Supertokens_Key_Value_Bool_Exp>>;
  created_at_time?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "supertokens.key_value" */
export enum Supertokens_Key_Value_Constraint {
  /** unique or primary key constraint on columns "name" */
  KeyValuePkey = 'key_value_pkey'
}

/** input type for incrementing numeric columns in table "supertokens.key_value" */
export type Supertokens_Key_Value_Inc_Input = {
  created_at_time?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "supertokens.key_value" */
export type Supertokens_Key_Value_Insert_Input = {
  created_at_time?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Supertokens_Key_Value_Max_Fields = {
  __typename?: 'supertokens_key_value_max_fields';
  created_at_time?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Supertokens_Key_Value_Min_Fields = {
  __typename?: 'supertokens_key_value_min_fields';
  created_at_time?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "supertokens.key_value" */
export type Supertokens_Key_Value_Mutation_Response = {
  __typename?: 'supertokens_key_value_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Supertokens_Key_Value>;
};

/** on_conflict condition type for table "supertokens.key_value" */
export type Supertokens_Key_Value_On_Conflict = {
  constraint: Supertokens_Key_Value_Constraint;
  update_columns?: Array<Supertokens_Key_Value_Update_Column>;
  where?: InputMaybe<Supertokens_Key_Value_Bool_Exp>;
};

/** Ordering options when selecting data from "supertokens.key_value". */
export type Supertokens_Key_Value_Order_By = {
  created_at_time?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: supertokens.key_value */
export type Supertokens_Key_Value_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "supertokens.key_value" */
export enum Supertokens_Key_Value_Select_Column {
  /** column name */
  CreatedAtTime = 'created_at_time',
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "supertokens.key_value" */
export type Supertokens_Key_Value_Set_Input = {
  created_at_time?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Supertokens_Key_Value_Stddev_Fields = {
  __typename?: 'supertokens_key_value_stddev_fields';
  created_at_time?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Supertokens_Key_Value_Stddev_Pop_Fields = {
  __typename?: 'supertokens_key_value_stddev_pop_fields';
  created_at_time?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Supertokens_Key_Value_Stddev_Samp_Fields = {
  __typename?: 'supertokens_key_value_stddev_samp_fields';
  created_at_time?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "supertokens_key_value" */
export type Supertokens_Key_Value_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supertokens_Key_Value_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supertokens_Key_Value_Stream_Cursor_Value_Input = {
  created_at_time?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Supertokens_Key_Value_Sum_Fields = {
  __typename?: 'supertokens_key_value_sum_fields';
  created_at_time?: Maybe<Scalars['bigint']>;
};

/** update columns of table "supertokens.key_value" */
export enum Supertokens_Key_Value_Update_Column {
  /** column name */
  CreatedAtTime = 'created_at_time',
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value'
}

export type Supertokens_Key_Value_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Supertokens_Key_Value_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Supertokens_Key_Value_Set_Input>;
  /** filter the rows which have to be updated */
  where: Supertokens_Key_Value_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Supertokens_Key_Value_Var_Pop_Fields = {
  __typename?: 'supertokens_key_value_var_pop_fields';
  created_at_time?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Supertokens_Key_Value_Var_Samp_Fields = {
  __typename?: 'supertokens_key_value_var_samp_fields';
  created_at_time?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Supertokens_Key_Value_Variance_Fields = {
  __typename?: 'supertokens_key_value_variance_fields';
  created_at_time?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "supertokens.passwordless_codes" */
export type Supertokens_Passwordless_Codes = {
  __typename?: 'supertokens_passwordless_codes';
  code_id: Scalars['bpchar'];
  created_at: Scalars['bigint'];
  device_id_hash: Scalars['bpchar'];
  link_code_hash: Scalars['bpchar'];
};

/** aggregated selection of "supertokens.passwordless_codes" */
export type Supertokens_Passwordless_Codes_Aggregate = {
  __typename?: 'supertokens_passwordless_codes_aggregate';
  aggregate?: Maybe<Supertokens_Passwordless_Codes_Aggregate_Fields>;
  nodes: Array<Supertokens_Passwordless_Codes>;
};

/** aggregate fields of "supertokens.passwordless_codes" */
export type Supertokens_Passwordless_Codes_Aggregate_Fields = {
  __typename?: 'supertokens_passwordless_codes_aggregate_fields';
  avg?: Maybe<Supertokens_Passwordless_Codes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Supertokens_Passwordless_Codes_Max_Fields>;
  min?: Maybe<Supertokens_Passwordless_Codes_Min_Fields>;
  stddev?: Maybe<Supertokens_Passwordless_Codes_Stddev_Fields>;
  stddev_pop?: Maybe<Supertokens_Passwordless_Codes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Supertokens_Passwordless_Codes_Stddev_Samp_Fields>;
  sum?: Maybe<Supertokens_Passwordless_Codes_Sum_Fields>;
  var_pop?: Maybe<Supertokens_Passwordless_Codes_Var_Pop_Fields>;
  var_samp?: Maybe<Supertokens_Passwordless_Codes_Var_Samp_Fields>;
  variance?: Maybe<Supertokens_Passwordless_Codes_Variance_Fields>;
};


/** aggregate fields of "supertokens.passwordless_codes" */
export type Supertokens_Passwordless_Codes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supertokens_Passwordless_Codes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Supertokens_Passwordless_Codes_Avg_Fields = {
  __typename?: 'supertokens_passwordless_codes_avg_fields';
  created_at?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "supertokens.passwordless_codes". All fields are combined with a logical 'AND'. */
export type Supertokens_Passwordless_Codes_Bool_Exp = {
  _and?: InputMaybe<Array<Supertokens_Passwordless_Codes_Bool_Exp>>;
  _not?: InputMaybe<Supertokens_Passwordless_Codes_Bool_Exp>;
  _or?: InputMaybe<Array<Supertokens_Passwordless_Codes_Bool_Exp>>;
  code_id?: InputMaybe<Bpchar_Comparison_Exp>;
  created_at?: InputMaybe<Bigint_Comparison_Exp>;
  device_id_hash?: InputMaybe<Bpchar_Comparison_Exp>;
  link_code_hash?: InputMaybe<Bpchar_Comparison_Exp>;
};

/** unique or primary key constraints on table "supertokens.passwordless_codes" */
export enum Supertokens_Passwordless_Codes_Constraint {
  /** unique or primary key constraint on columns "link_code_hash" */
  PasswordlessCodesLinkCodeHashKey = 'passwordless_codes_link_code_hash_key',
  /** unique or primary key constraint on columns "code_id" */
  PasswordlessCodesPkey = 'passwordless_codes_pkey'
}

/** input type for incrementing numeric columns in table "supertokens.passwordless_codes" */
export type Supertokens_Passwordless_Codes_Inc_Input = {
  created_at?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "supertokens.passwordless_codes" */
export type Supertokens_Passwordless_Codes_Insert_Input = {
  code_id?: InputMaybe<Scalars['bpchar']>;
  created_at?: InputMaybe<Scalars['bigint']>;
  device_id_hash?: InputMaybe<Scalars['bpchar']>;
  link_code_hash?: InputMaybe<Scalars['bpchar']>;
};

/** aggregate max on columns */
export type Supertokens_Passwordless_Codes_Max_Fields = {
  __typename?: 'supertokens_passwordless_codes_max_fields';
  code_id?: Maybe<Scalars['bpchar']>;
  created_at?: Maybe<Scalars['bigint']>;
  device_id_hash?: Maybe<Scalars['bpchar']>;
  link_code_hash?: Maybe<Scalars['bpchar']>;
};

/** aggregate min on columns */
export type Supertokens_Passwordless_Codes_Min_Fields = {
  __typename?: 'supertokens_passwordless_codes_min_fields';
  code_id?: Maybe<Scalars['bpchar']>;
  created_at?: Maybe<Scalars['bigint']>;
  device_id_hash?: Maybe<Scalars['bpchar']>;
  link_code_hash?: Maybe<Scalars['bpchar']>;
};

/** response of any mutation on the table "supertokens.passwordless_codes" */
export type Supertokens_Passwordless_Codes_Mutation_Response = {
  __typename?: 'supertokens_passwordless_codes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Supertokens_Passwordless_Codes>;
};

/** on_conflict condition type for table "supertokens.passwordless_codes" */
export type Supertokens_Passwordless_Codes_On_Conflict = {
  constraint: Supertokens_Passwordless_Codes_Constraint;
  update_columns?: Array<Supertokens_Passwordless_Codes_Update_Column>;
  where?: InputMaybe<Supertokens_Passwordless_Codes_Bool_Exp>;
};

/** Ordering options when selecting data from "supertokens.passwordless_codes". */
export type Supertokens_Passwordless_Codes_Order_By = {
  code_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  device_id_hash?: InputMaybe<Order_By>;
  link_code_hash?: InputMaybe<Order_By>;
};

/** primary key columns input for table: supertokens.passwordless_codes */
export type Supertokens_Passwordless_Codes_Pk_Columns_Input = {
  code_id: Scalars['bpchar'];
};

/** select columns of table "supertokens.passwordless_codes" */
export enum Supertokens_Passwordless_Codes_Select_Column {
  /** column name */
  CodeId = 'code_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeviceIdHash = 'device_id_hash',
  /** column name */
  LinkCodeHash = 'link_code_hash'
}

/** input type for updating data in table "supertokens.passwordless_codes" */
export type Supertokens_Passwordless_Codes_Set_Input = {
  code_id?: InputMaybe<Scalars['bpchar']>;
  created_at?: InputMaybe<Scalars['bigint']>;
  device_id_hash?: InputMaybe<Scalars['bpchar']>;
  link_code_hash?: InputMaybe<Scalars['bpchar']>;
};

/** aggregate stddev on columns */
export type Supertokens_Passwordless_Codes_Stddev_Fields = {
  __typename?: 'supertokens_passwordless_codes_stddev_fields';
  created_at?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Supertokens_Passwordless_Codes_Stddev_Pop_Fields = {
  __typename?: 'supertokens_passwordless_codes_stddev_pop_fields';
  created_at?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Supertokens_Passwordless_Codes_Stddev_Samp_Fields = {
  __typename?: 'supertokens_passwordless_codes_stddev_samp_fields';
  created_at?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "supertokens_passwordless_codes" */
export type Supertokens_Passwordless_Codes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supertokens_Passwordless_Codes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supertokens_Passwordless_Codes_Stream_Cursor_Value_Input = {
  code_id?: InputMaybe<Scalars['bpchar']>;
  created_at?: InputMaybe<Scalars['bigint']>;
  device_id_hash?: InputMaybe<Scalars['bpchar']>;
  link_code_hash?: InputMaybe<Scalars['bpchar']>;
};

/** aggregate sum on columns */
export type Supertokens_Passwordless_Codes_Sum_Fields = {
  __typename?: 'supertokens_passwordless_codes_sum_fields';
  created_at?: Maybe<Scalars['bigint']>;
};

/** update columns of table "supertokens.passwordless_codes" */
export enum Supertokens_Passwordless_Codes_Update_Column {
  /** column name */
  CodeId = 'code_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeviceIdHash = 'device_id_hash',
  /** column name */
  LinkCodeHash = 'link_code_hash'
}

export type Supertokens_Passwordless_Codes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Supertokens_Passwordless_Codes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Supertokens_Passwordless_Codes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Supertokens_Passwordless_Codes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Supertokens_Passwordless_Codes_Var_Pop_Fields = {
  __typename?: 'supertokens_passwordless_codes_var_pop_fields';
  created_at?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Supertokens_Passwordless_Codes_Var_Samp_Fields = {
  __typename?: 'supertokens_passwordless_codes_var_samp_fields';
  created_at?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Supertokens_Passwordless_Codes_Variance_Fields = {
  __typename?: 'supertokens_passwordless_codes_variance_fields';
  created_at?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "supertokens.passwordless_users" */
export type Supertokens_Passwordless_Users = {
  __typename?: 'supertokens_passwordless_users';
  email?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  time_joined: Scalars['bigint'];
  user_id: Scalars['bpchar'];
};

/** aggregated selection of "supertokens.passwordless_users" */
export type Supertokens_Passwordless_Users_Aggregate = {
  __typename?: 'supertokens_passwordless_users_aggregate';
  aggregate?: Maybe<Supertokens_Passwordless_Users_Aggregate_Fields>;
  nodes: Array<Supertokens_Passwordless_Users>;
};

/** aggregate fields of "supertokens.passwordless_users" */
export type Supertokens_Passwordless_Users_Aggregate_Fields = {
  __typename?: 'supertokens_passwordless_users_aggregate_fields';
  avg?: Maybe<Supertokens_Passwordless_Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Supertokens_Passwordless_Users_Max_Fields>;
  min?: Maybe<Supertokens_Passwordless_Users_Min_Fields>;
  stddev?: Maybe<Supertokens_Passwordless_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Supertokens_Passwordless_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Supertokens_Passwordless_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Supertokens_Passwordless_Users_Sum_Fields>;
  var_pop?: Maybe<Supertokens_Passwordless_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Supertokens_Passwordless_Users_Var_Samp_Fields>;
  variance?: Maybe<Supertokens_Passwordless_Users_Variance_Fields>;
};


/** aggregate fields of "supertokens.passwordless_users" */
export type Supertokens_Passwordless_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supertokens_Passwordless_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Supertokens_Passwordless_Users_Avg_Fields = {
  __typename?: 'supertokens_passwordless_users_avg_fields';
  time_joined?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "supertokens.passwordless_users". All fields are combined with a logical 'AND'. */
export type Supertokens_Passwordless_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Supertokens_Passwordless_Users_Bool_Exp>>;
  _not?: InputMaybe<Supertokens_Passwordless_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Supertokens_Passwordless_Users_Bool_Exp>>;
  email?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  time_joined?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Bpchar_Comparison_Exp>;
};

/** unique or primary key constraints on table "supertokens.passwordless_users" */
export enum Supertokens_Passwordless_Users_Constraint {
  /** unique or primary key constraint on columns "email" */
  PasswordlessUsersEmailKey = 'passwordless_users_email_key',
  /** unique or primary key constraint on columns "phone_number" */
  PasswordlessUsersPhoneNumberKey = 'passwordless_users_phone_number_key',
  /** unique or primary key constraint on columns "user_id" */
  PasswordlessUsersPkey = 'passwordless_users_pkey'
}

/** input type for incrementing numeric columns in table "supertokens.passwordless_users" */
export type Supertokens_Passwordless_Users_Inc_Input = {
  time_joined?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "supertokens.passwordless_users" */
export type Supertokens_Passwordless_Users_Insert_Input = {
  email?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  time_joined?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bpchar']>;
};

/** aggregate max on columns */
export type Supertokens_Passwordless_Users_Max_Fields = {
  __typename?: 'supertokens_passwordless_users_max_fields';
  email?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  time_joined?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bpchar']>;
};

/** aggregate min on columns */
export type Supertokens_Passwordless_Users_Min_Fields = {
  __typename?: 'supertokens_passwordless_users_min_fields';
  email?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  time_joined?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bpchar']>;
};

/** response of any mutation on the table "supertokens.passwordless_users" */
export type Supertokens_Passwordless_Users_Mutation_Response = {
  __typename?: 'supertokens_passwordless_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Supertokens_Passwordless_Users>;
};

/** input type for inserting object relation for remote table "supertokens.passwordless_users" */
export type Supertokens_Passwordless_Users_Obj_Rel_Insert_Input = {
  data: Supertokens_Passwordless_Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Supertokens_Passwordless_Users_On_Conflict>;
};

/** on_conflict condition type for table "supertokens.passwordless_users" */
export type Supertokens_Passwordless_Users_On_Conflict = {
  constraint: Supertokens_Passwordless_Users_Constraint;
  update_columns?: Array<Supertokens_Passwordless_Users_Update_Column>;
  where?: InputMaybe<Supertokens_Passwordless_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "supertokens.passwordless_users". */
export type Supertokens_Passwordless_Users_Order_By = {
  email?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  time_joined?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: supertokens.passwordless_users */
export type Supertokens_Passwordless_Users_Pk_Columns_Input = {
  user_id: Scalars['bpchar'];
};

/** select columns of table "supertokens.passwordless_users" */
export enum Supertokens_Passwordless_Users_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  TimeJoined = 'time_joined',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "supertokens.passwordless_users" */
export type Supertokens_Passwordless_Users_Set_Input = {
  email?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  time_joined?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bpchar']>;
};

/** aggregate stddev on columns */
export type Supertokens_Passwordless_Users_Stddev_Fields = {
  __typename?: 'supertokens_passwordless_users_stddev_fields';
  time_joined?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Supertokens_Passwordless_Users_Stddev_Pop_Fields = {
  __typename?: 'supertokens_passwordless_users_stddev_pop_fields';
  time_joined?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Supertokens_Passwordless_Users_Stddev_Samp_Fields = {
  __typename?: 'supertokens_passwordless_users_stddev_samp_fields';
  time_joined?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "supertokens_passwordless_users" */
export type Supertokens_Passwordless_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supertokens_Passwordless_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supertokens_Passwordless_Users_Stream_Cursor_Value_Input = {
  email?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  time_joined?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bpchar']>;
};

/** aggregate sum on columns */
export type Supertokens_Passwordless_Users_Sum_Fields = {
  __typename?: 'supertokens_passwordless_users_sum_fields';
  time_joined?: Maybe<Scalars['bigint']>;
};

/** update columns of table "supertokens.passwordless_users" */
export enum Supertokens_Passwordless_Users_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  TimeJoined = 'time_joined',
  /** column name */
  UserId = 'user_id'
}

export type Supertokens_Passwordless_Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Supertokens_Passwordless_Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Supertokens_Passwordless_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Supertokens_Passwordless_Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Supertokens_Passwordless_Users_Var_Pop_Fields = {
  __typename?: 'supertokens_passwordless_users_var_pop_fields';
  time_joined?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Supertokens_Passwordless_Users_Var_Samp_Fields = {
  __typename?: 'supertokens_passwordless_users_var_samp_fields';
  time_joined?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Supertokens_Passwordless_Users_Variance_Fields = {
  __typename?: 'supertokens_passwordless_users_variance_fields';
  time_joined?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "supertokens.session_access_token_signing_keys" */
export type Supertokens_Session_Access_Token_Signing_Keys = {
  __typename?: 'supertokens_session_access_token_signing_keys';
  created_at_time: Scalars['bigint'];
  value?: Maybe<Scalars['String']>;
};

/** aggregated selection of "supertokens.session_access_token_signing_keys" */
export type Supertokens_Session_Access_Token_Signing_Keys_Aggregate = {
  __typename?: 'supertokens_session_access_token_signing_keys_aggregate';
  aggregate?: Maybe<Supertokens_Session_Access_Token_Signing_Keys_Aggregate_Fields>;
  nodes: Array<Supertokens_Session_Access_Token_Signing_Keys>;
};

/** aggregate fields of "supertokens.session_access_token_signing_keys" */
export type Supertokens_Session_Access_Token_Signing_Keys_Aggregate_Fields = {
  __typename?: 'supertokens_session_access_token_signing_keys_aggregate_fields';
  avg?: Maybe<Supertokens_Session_Access_Token_Signing_Keys_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Supertokens_Session_Access_Token_Signing_Keys_Max_Fields>;
  min?: Maybe<Supertokens_Session_Access_Token_Signing_Keys_Min_Fields>;
  stddev?: Maybe<Supertokens_Session_Access_Token_Signing_Keys_Stddev_Fields>;
  stddev_pop?: Maybe<Supertokens_Session_Access_Token_Signing_Keys_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Supertokens_Session_Access_Token_Signing_Keys_Stddev_Samp_Fields>;
  sum?: Maybe<Supertokens_Session_Access_Token_Signing_Keys_Sum_Fields>;
  var_pop?: Maybe<Supertokens_Session_Access_Token_Signing_Keys_Var_Pop_Fields>;
  var_samp?: Maybe<Supertokens_Session_Access_Token_Signing_Keys_Var_Samp_Fields>;
  variance?: Maybe<Supertokens_Session_Access_Token_Signing_Keys_Variance_Fields>;
};


/** aggregate fields of "supertokens.session_access_token_signing_keys" */
export type Supertokens_Session_Access_Token_Signing_Keys_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supertokens_Session_Access_Token_Signing_Keys_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Supertokens_Session_Access_Token_Signing_Keys_Avg_Fields = {
  __typename?: 'supertokens_session_access_token_signing_keys_avg_fields';
  created_at_time?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "supertokens.session_access_token_signing_keys". All fields are combined with a logical 'AND'. */
export type Supertokens_Session_Access_Token_Signing_Keys_Bool_Exp = {
  _and?: InputMaybe<Array<Supertokens_Session_Access_Token_Signing_Keys_Bool_Exp>>;
  _not?: InputMaybe<Supertokens_Session_Access_Token_Signing_Keys_Bool_Exp>;
  _or?: InputMaybe<Array<Supertokens_Session_Access_Token_Signing_Keys_Bool_Exp>>;
  created_at_time?: InputMaybe<Bigint_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "supertokens.session_access_token_signing_keys" */
export enum Supertokens_Session_Access_Token_Signing_Keys_Constraint {
  /** unique or primary key constraint on columns "created_at_time" */
  SessionAccessTokenSigningKeysPkey = 'session_access_token_signing_keys_pkey'
}

/** input type for incrementing numeric columns in table "supertokens.session_access_token_signing_keys" */
export type Supertokens_Session_Access_Token_Signing_Keys_Inc_Input = {
  created_at_time?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "supertokens.session_access_token_signing_keys" */
export type Supertokens_Session_Access_Token_Signing_Keys_Insert_Input = {
  created_at_time?: InputMaybe<Scalars['bigint']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Supertokens_Session_Access_Token_Signing_Keys_Max_Fields = {
  __typename?: 'supertokens_session_access_token_signing_keys_max_fields';
  created_at_time?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Supertokens_Session_Access_Token_Signing_Keys_Min_Fields = {
  __typename?: 'supertokens_session_access_token_signing_keys_min_fields';
  created_at_time?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "supertokens.session_access_token_signing_keys" */
export type Supertokens_Session_Access_Token_Signing_Keys_Mutation_Response = {
  __typename?: 'supertokens_session_access_token_signing_keys_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Supertokens_Session_Access_Token_Signing_Keys>;
};

/** on_conflict condition type for table "supertokens.session_access_token_signing_keys" */
export type Supertokens_Session_Access_Token_Signing_Keys_On_Conflict = {
  constraint: Supertokens_Session_Access_Token_Signing_Keys_Constraint;
  update_columns?: Array<Supertokens_Session_Access_Token_Signing_Keys_Update_Column>;
  where?: InputMaybe<Supertokens_Session_Access_Token_Signing_Keys_Bool_Exp>;
};

/** Ordering options when selecting data from "supertokens.session_access_token_signing_keys". */
export type Supertokens_Session_Access_Token_Signing_Keys_Order_By = {
  created_at_time?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: supertokens.session_access_token_signing_keys */
export type Supertokens_Session_Access_Token_Signing_Keys_Pk_Columns_Input = {
  created_at_time: Scalars['bigint'];
};

/** select columns of table "supertokens.session_access_token_signing_keys" */
export enum Supertokens_Session_Access_Token_Signing_Keys_Select_Column {
  /** column name */
  CreatedAtTime = 'created_at_time',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "supertokens.session_access_token_signing_keys" */
export type Supertokens_Session_Access_Token_Signing_Keys_Set_Input = {
  created_at_time?: InputMaybe<Scalars['bigint']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Supertokens_Session_Access_Token_Signing_Keys_Stddev_Fields = {
  __typename?: 'supertokens_session_access_token_signing_keys_stddev_fields';
  created_at_time?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Supertokens_Session_Access_Token_Signing_Keys_Stddev_Pop_Fields = {
  __typename?: 'supertokens_session_access_token_signing_keys_stddev_pop_fields';
  created_at_time?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Supertokens_Session_Access_Token_Signing_Keys_Stddev_Samp_Fields = {
  __typename?: 'supertokens_session_access_token_signing_keys_stddev_samp_fields';
  created_at_time?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "supertokens_session_access_token_signing_keys" */
export type Supertokens_Session_Access_Token_Signing_Keys_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supertokens_Session_Access_Token_Signing_Keys_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supertokens_Session_Access_Token_Signing_Keys_Stream_Cursor_Value_Input = {
  created_at_time?: InputMaybe<Scalars['bigint']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Supertokens_Session_Access_Token_Signing_Keys_Sum_Fields = {
  __typename?: 'supertokens_session_access_token_signing_keys_sum_fields';
  created_at_time?: Maybe<Scalars['bigint']>;
};

/** update columns of table "supertokens.session_access_token_signing_keys" */
export enum Supertokens_Session_Access_Token_Signing_Keys_Update_Column {
  /** column name */
  CreatedAtTime = 'created_at_time',
  /** column name */
  Value = 'value'
}

export type Supertokens_Session_Access_Token_Signing_Keys_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Supertokens_Session_Access_Token_Signing_Keys_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Supertokens_Session_Access_Token_Signing_Keys_Set_Input>;
  /** filter the rows which have to be updated */
  where: Supertokens_Session_Access_Token_Signing_Keys_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Supertokens_Session_Access_Token_Signing_Keys_Var_Pop_Fields = {
  __typename?: 'supertokens_session_access_token_signing_keys_var_pop_fields';
  created_at_time?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Supertokens_Session_Access_Token_Signing_Keys_Var_Samp_Fields = {
  __typename?: 'supertokens_session_access_token_signing_keys_var_samp_fields';
  created_at_time?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Supertokens_Session_Access_Token_Signing_Keys_Variance_Fields = {
  __typename?: 'supertokens_session_access_token_signing_keys_variance_fields';
  created_at_time?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "supertokens.session_info" */
export type Supertokens_Session_Info = {
  __typename?: 'supertokens_session_info';
  created_at_time: Scalars['bigint'];
  expires_at: Scalars['bigint'];
  jwt_user_payload?: Maybe<Scalars['String']>;
  refresh_token_hash_2: Scalars['String'];
  session_data?: Maybe<Scalars['String']>;
  session_handle: Scalars['String'];
  use_static_key: Scalars['Boolean'];
  user_id: Scalars['String'];
};

/** aggregated selection of "supertokens.session_info" */
export type Supertokens_Session_Info_Aggregate = {
  __typename?: 'supertokens_session_info_aggregate';
  aggregate?: Maybe<Supertokens_Session_Info_Aggregate_Fields>;
  nodes: Array<Supertokens_Session_Info>;
};

/** aggregate fields of "supertokens.session_info" */
export type Supertokens_Session_Info_Aggregate_Fields = {
  __typename?: 'supertokens_session_info_aggregate_fields';
  avg?: Maybe<Supertokens_Session_Info_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Supertokens_Session_Info_Max_Fields>;
  min?: Maybe<Supertokens_Session_Info_Min_Fields>;
  stddev?: Maybe<Supertokens_Session_Info_Stddev_Fields>;
  stddev_pop?: Maybe<Supertokens_Session_Info_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Supertokens_Session_Info_Stddev_Samp_Fields>;
  sum?: Maybe<Supertokens_Session_Info_Sum_Fields>;
  var_pop?: Maybe<Supertokens_Session_Info_Var_Pop_Fields>;
  var_samp?: Maybe<Supertokens_Session_Info_Var_Samp_Fields>;
  variance?: Maybe<Supertokens_Session_Info_Variance_Fields>;
};


/** aggregate fields of "supertokens.session_info" */
export type Supertokens_Session_Info_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supertokens_Session_Info_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Supertokens_Session_Info_Avg_Fields = {
  __typename?: 'supertokens_session_info_avg_fields';
  created_at_time?: Maybe<Scalars['Float']>;
  expires_at?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "supertokens.session_info". All fields are combined with a logical 'AND'. */
export type Supertokens_Session_Info_Bool_Exp = {
  _and?: InputMaybe<Array<Supertokens_Session_Info_Bool_Exp>>;
  _not?: InputMaybe<Supertokens_Session_Info_Bool_Exp>;
  _or?: InputMaybe<Array<Supertokens_Session_Info_Bool_Exp>>;
  created_at_time?: InputMaybe<Bigint_Comparison_Exp>;
  expires_at?: InputMaybe<Bigint_Comparison_Exp>;
  jwt_user_payload?: InputMaybe<String_Comparison_Exp>;
  refresh_token_hash_2?: InputMaybe<String_Comparison_Exp>;
  session_data?: InputMaybe<String_Comparison_Exp>;
  session_handle?: InputMaybe<String_Comparison_Exp>;
  use_static_key?: InputMaybe<Boolean_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "supertokens.session_info" */
export enum Supertokens_Session_Info_Constraint {
  /** unique or primary key constraint on columns "session_handle" */
  SessionInfoPkey = 'session_info_pkey'
}

/** input type for incrementing numeric columns in table "supertokens.session_info" */
export type Supertokens_Session_Info_Inc_Input = {
  created_at_time?: InputMaybe<Scalars['bigint']>;
  expires_at?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "supertokens.session_info" */
export type Supertokens_Session_Info_Insert_Input = {
  created_at_time?: InputMaybe<Scalars['bigint']>;
  expires_at?: InputMaybe<Scalars['bigint']>;
  jwt_user_payload?: InputMaybe<Scalars['String']>;
  refresh_token_hash_2?: InputMaybe<Scalars['String']>;
  session_data?: InputMaybe<Scalars['String']>;
  session_handle?: InputMaybe<Scalars['String']>;
  use_static_key?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Supertokens_Session_Info_Max_Fields = {
  __typename?: 'supertokens_session_info_max_fields';
  created_at_time?: Maybe<Scalars['bigint']>;
  expires_at?: Maybe<Scalars['bigint']>;
  jwt_user_payload?: Maybe<Scalars['String']>;
  refresh_token_hash_2?: Maybe<Scalars['String']>;
  session_data?: Maybe<Scalars['String']>;
  session_handle?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Supertokens_Session_Info_Min_Fields = {
  __typename?: 'supertokens_session_info_min_fields';
  created_at_time?: Maybe<Scalars['bigint']>;
  expires_at?: Maybe<Scalars['bigint']>;
  jwt_user_payload?: Maybe<Scalars['String']>;
  refresh_token_hash_2?: Maybe<Scalars['String']>;
  session_data?: Maybe<Scalars['String']>;
  session_handle?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "supertokens.session_info" */
export type Supertokens_Session_Info_Mutation_Response = {
  __typename?: 'supertokens_session_info_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Supertokens_Session_Info>;
};

/** on_conflict condition type for table "supertokens.session_info" */
export type Supertokens_Session_Info_On_Conflict = {
  constraint: Supertokens_Session_Info_Constraint;
  update_columns?: Array<Supertokens_Session_Info_Update_Column>;
  where?: InputMaybe<Supertokens_Session_Info_Bool_Exp>;
};

/** Ordering options when selecting data from "supertokens.session_info". */
export type Supertokens_Session_Info_Order_By = {
  created_at_time?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  jwt_user_payload?: InputMaybe<Order_By>;
  refresh_token_hash_2?: InputMaybe<Order_By>;
  session_data?: InputMaybe<Order_By>;
  session_handle?: InputMaybe<Order_By>;
  use_static_key?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: supertokens.session_info */
export type Supertokens_Session_Info_Pk_Columns_Input = {
  session_handle: Scalars['String'];
};

/** select columns of table "supertokens.session_info" */
export enum Supertokens_Session_Info_Select_Column {
  /** column name */
  CreatedAtTime = 'created_at_time',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  JwtUserPayload = 'jwt_user_payload',
  /** column name */
  RefreshTokenHash_2 = 'refresh_token_hash_2',
  /** column name */
  SessionData = 'session_data',
  /** column name */
  SessionHandle = 'session_handle',
  /** column name */
  UseStaticKey = 'use_static_key',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "supertokens.session_info" */
export type Supertokens_Session_Info_Set_Input = {
  created_at_time?: InputMaybe<Scalars['bigint']>;
  expires_at?: InputMaybe<Scalars['bigint']>;
  jwt_user_payload?: InputMaybe<Scalars['String']>;
  refresh_token_hash_2?: InputMaybe<Scalars['String']>;
  session_data?: InputMaybe<Scalars['String']>;
  session_handle?: InputMaybe<Scalars['String']>;
  use_static_key?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Supertokens_Session_Info_Stddev_Fields = {
  __typename?: 'supertokens_session_info_stddev_fields';
  created_at_time?: Maybe<Scalars['Float']>;
  expires_at?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Supertokens_Session_Info_Stddev_Pop_Fields = {
  __typename?: 'supertokens_session_info_stddev_pop_fields';
  created_at_time?: Maybe<Scalars['Float']>;
  expires_at?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Supertokens_Session_Info_Stddev_Samp_Fields = {
  __typename?: 'supertokens_session_info_stddev_samp_fields';
  created_at_time?: Maybe<Scalars['Float']>;
  expires_at?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "supertokens_session_info" */
export type Supertokens_Session_Info_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supertokens_Session_Info_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supertokens_Session_Info_Stream_Cursor_Value_Input = {
  created_at_time?: InputMaybe<Scalars['bigint']>;
  expires_at?: InputMaybe<Scalars['bigint']>;
  jwt_user_payload?: InputMaybe<Scalars['String']>;
  refresh_token_hash_2?: InputMaybe<Scalars['String']>;
  session_data?: InputMaybe<Scalars['String']>;
  session_handle?: InputMaybe<Scalars['String']>;
  use_static_key?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Supertokens_Session_Info_Sum_Fields = {
  __typename?: 'supertokens_session_info_sum_fields';
  created_at_time?: Maybe<Scalars['bigint']>;
  expires_at?: Maybe<Scalars['bigint']>;
};

/** update columns of table "supertokens.session_info" */
export enum Supertokens_Session_Info_Update_Column {
  /** column name */
  CreatedAtTime = 'created_at_time',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  JwtUserPayload = 'jwt_user_payload',
  /** column name */
  RefreshTokenHash_2 = 'refresh_token_hash_2',
  /** column name */
  SessionData = 'session_data',
  /** column name */
  SessionHandle = 'session_handle',
  /** column name */
  UseStaticKey = 'use_static_key',
  /** column name */
  UserId = 'user_id'
}

export type Supertokens_Session_Info_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Supertokens_Session_Info_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Supertokens_Session_Info_Set_Input>;
  /** filter the rows which have to be updated */
  where: Supertokens_Session_Info_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Supertokens_Session_Info_Var_Pop_Fields = {
  __typename?: 'supertokens_session_info_var_pop_fields';
  created_at_time?: Maybe<Scalars['Float']>;
  expires_at?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Supertokens_Session_Info_Var_Samp_Fields = {
  __typename?: 'supertokens_session_info_var_samp_fields';
  created_at_time?: Maybe<Scalars['Float']>;
  expires_at?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Supertokens_Session_Info_Variance_Fields = {
  __typename?: 'supertokens_session_info_variance_fields';
  created_at_time?: Maybe<Scalars['Float']>;
  expires_at?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "supertokens.user_additional_fields" */
export type Supertokens_User_Additional_Fields = {
  __typename?: 'supertokens_user_additional_fields';
  anonymous_user_id?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  custom_user_name?: Maybe<Scalars['String']>;
  elo_rating: Scalars['Int'];
  id: Scalars['uuid'];
  is_admin?: Maybe<Scalars['Boolean']>;
  is_bot: Scalars['Boolean'];
  is_tournament_enabled?: Maybe<Scalars['Boolean']>;
  is_user_activated: Scalars['Boolean'];
  name: Scalars['String'];
  onboard_current_game?: Maybe<Scalars['String']>;
  onboard_did_checkmate_in_first_attempt?: Maybe<Scalars['Boolean']>;
  onboard_did_tried_again_any_moves?: Maybe<Scalars['Boolean']>;
  onboard_is_first_game_completed?: Maybe<Scalars['Boolean']>;
  onboard_is_second_game_completed?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  pan_card_details: Array<Pan_Card_Details>;
  /** An aggregate relationship */
  pan_card_details_aggregate: Pan_Card_Details_Aggregate;
  /** An object relationship */
  passwordless_users?: Maybe<Supertokens_Passwordless_Users>;
  profile_picture?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  upi_details: Array<Upi_Details>;
  /** An aggregate relationship */
  upi_details_aggregate: Upi_Details_Aggregate;
  user_id: Scalars['String'];
  user_type?: Maybe<Scalars['String']>;
  /** A computed field, executes function "supertokens.wallet_balance" */
  wallet_balance?: Maybe<Scalars['float8']>;
};


/** columns and relationships of "supertokens.user_additional_fields" */
export type Supertokens_User_Additional_FieldsPan_Card_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Pan_Card_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pan_Card_Details_Order_By>>;
  where?: InputMaybe<Pan_Card_Details_Bool_Exp>;
};


/** columns and relationships of "supertokens.user_additional_fields" */
export type Supertokens_User_Additional_FieldsPan_Card_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pan_Card_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pan_Card_Details_Order_By>>;
  where?: InputMaybe<Pan_Card_Details_Bool_Exp>;
};


/** columns and relationships of "supertokens.user_additional_fields" */
export type Supertokens_User_Additional_FieldsUpi_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Upi_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Upi_Details_Order_By>>;
  where?: InputMaybe<Upi_Details_Bool_Exp>;
};


/** columns and relationships of "supertokens.user_additional_fields" */
export type Supertokens_User_Additional_FieldsUpi_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Upi_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Upi_Details_Order_By>>;
  where?: InputMaybe<Upi_Details_Bool_Exp>;
};

export type Supertokens_User_Additional_Fields_Aggregate = {
  __typename?: 'supertokens_user_additional_fields_aggregate';
  aggregate?: Maybe<Supertokens_User_Additional_Fields_Aggregate_Fields>;
  nodes: Array<Supertokens_User_Additional_Fields>;
};

/** aggregate fields of "supertokens.user_additional_fields" */
export type Supertokens_User_Additional_Fields_Aggregate_Fields = {
  __typename?: 'supertokens_user_additional_fields_aggregate_fields';
  avg?: Maybe<Supertokens_User_Additional_Fields_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Supertokens_User_Additional_Fields_Max_Fields>;
  min?: Maybe<Supertokens_User_Additional_Fields_Min_Fields>;
  stddev?: Maybe<Supertokens_User_Additional_Fields_Stddev_Fields>;
  stddev_pop?: Maybe<Supertokens_User_Additional_Fields_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Supertokens_User_Additional_Fields_Stddev_Samp_Fields>;
  sum?: Maybe<Supertokens_User_Additional_Fields_Sum_Fields>;
  var_pop?: Maybe<Supertokens_User_Additional_Fields_Var_Pop_Fields>;
  var_samp?: Maybe<Supertokens_User_Additional_Fields_Var_Samp_Fields>;
  variance?: Maybe<Supertokens_User_Additional_Fields_Variance_Fields>;
};


/** aggregate fields of "supertokens.user_additional_fields" */
export type Supertokens_User_Additional_Fields_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supertokens_User_Additional_Fields_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Supertokens_User_Additional_Fields_Avg_Fields = {
  __typename?: 'supertokens_user_additional_fields_avg_fields';
  elo_rating?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "supertokens.wallet_balance" */
  wallet_balance?: Maybe<Scalars['float8']>;
};

/** Boolean expression to filter rows from the table "supertokens.user_additional_fields". All fields are combined with a logical 'AND'. */
export type Supertokens_User_Additional_Fields_Bool_Exp = {
  _and?: InputMaybe<Array<Supertokens_User_Additional_Fields_Bool_Exp>>;
  _not?: InputMaybe<Supertokens_User_Additional_Fields_Bool_Exp>;
  _or?: InputMaybe<Array<Supertokens_User_Additional_Fields_Bool_Exp>>;
  anonymous_user_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_user_name?: InputMaybe<String_Comparison_Exp>;
  elo_rating?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_admin?: InputMaybe<Boolean_Comparison_Exp>;
  is_bot?: InputMaybe<Boolean_Comparison_Exp>;
  is_tournament_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  is_user_activated?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  onboard_current_game?: InputMaybe<String_Comparison_Exp>;
  onboard_did_checkmate_in_first_attempt?: InputMaybe<Boolean_Comparison_Exp>;
  onboard_did_tried_again_any_moves?: InputMaybe<Boolean_Comparison_Exp>;
  onboard_is_first_game_completed?: InputMaybe<Boolean_Comparison_Exp>;
  onboard_is_second_game_completed?: InputMaybe<Boolean_Comparison_Exp>;
  pan_card_details?: InputMaybe<Pan_Card_Details_Bool_Exp>;
  pan_card_details_aggregate?: InputMaybe<Pan_Card_Details_Aggregate_Bool_Exp>;
  passwordless_users?: InputMaybe<Supertokens_Passwordless_Users_Bool_Exp>;
  profile_picture?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  upi_details?: InputMaybe<Upi_Details_Bool_Exp>;
  upi_details_aggregate?: InputMaybe<Upi_Details_Aggregate_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  user_type?: InputMaybe<String_Comparison_Exp>;
  wallet_balance?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "supertokens.user_additional_fields" */
export enum Supertokens_User_Additional_Fields_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserAdditionalFieldsPkey = 'user_additional_fields_pkey',
  /** unique or primary key constraint on columns "user_id" */
  UserAdditionalFieldsUserIdKey = 'user_additional_fields_user_id_key'
}

/** input type for incrementing numeric columns in table "supertokens.user_additional_fields" */
export type Supertokens_User_Additional_Fields_Inc_Input = {
  elo_rating?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "supertokens.user_additional_fields" */
export type Supertokens_User_Additional_Fields_Insert_Input = {
  anonymous_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_user_name?: InputMaybe<Scalars['String']>;
  elo_rating?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_admin?: InputMaybe<Scalars['Boolean']>;
  is_bot?: InputMaybe<Scalars['Boolean']>;
  is_tournament_enabled?: InputMaybe<Scalars['Boolean']>;
  is_user_activated?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  onboard_current_game?: InputMaybe<Scalars['String']>;
  onboard_did_checkmate_in_first_attempt?: InputMaybe<Scalars['Boolean']>;
  onboard_did_tried_again_any_moves?: InputMaybe<Scalars['Boolean']>;
  onboard_is_first_game_completed?: InputMaybe<Scalars['Boolean']>;
  onboard_is_second_game_completed?: InputMaybe<Scalars['Boolean']>;
  pan_card_details?: InputMaybe<Pan_Card_Details_Arr_Rel_Insert_Input>;
  passwordless_users?: InputMaybe<Supertokens_Passwordless_Users_Obj_Rel_Insert_Input>;
  profile_picture?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  upi_details?: InputMaybe<Upi_Details_Arr_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
  user_type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Supertokens_User_Additional_Fields_Max_Fields = {
  __typename?: 'supertokens_user_additional_fields_max_fields';
  anonymous_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_user_name?: Maybe<Scalars['String']>;
  elo_rating?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  onboard_current_game?: Maybe<Scalars['String']>;
  profile_picture?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  user_type?: Maybe<Scalars['String']>;
  /** A computed field, executes function "supertokens.wallet_balance" */
  wallet_balance?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Supertokens_User_Additional_Fields_Min_Fields = {
  __typename?: 'supertokens_user_additional_fields_min_fields';
  anonymous_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_user_name?: Maybe<Scalars['String']>;
  elo_rating?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  onboard_current_game?: Maybe<Scalars['String']>;
  profile_picture?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  user_type?: Maybe<Scalars['String']>;
  /** A computed field, executes function "supertokens.wallet_balance" */
  wallet_balance?: Maybe<Scalars['float8']>;
};

/** response of any mutation on the table "supertokens.user_additional_fields" */
export type Supertokens_User_Additional_Fields_Mutation_Response = {
  __typename?: 'supertokens_user_additional_fields_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Supertokens_User_Additional_Fields>;
};

/** input type for inserting object relation for remote table "supertokens.user_additional_fields" */
export type Supertokens_User_Additional_Fields_Obj_Rel_Insert_Input = {
  data: Supertokens_User_Additional_Fields_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Supertokens_User_Additional_Fields_On_Conflict>;
};

/** on_conflict condition type for table "supertokens.user_additional_fields" */
export type Supertokens_User_Additional_Fields_On_Conflict = {
  constraint: Supertokens_User_Additional_Fields_Constraint;
  update_columns?: Array<Supertokens_User_Additional_Fields_Update_Column>;
  where?: InputMaybe<Supertokens_User_Additional_Fields_Bool_Exp>;
};

/** Ordering options when selecting data from "supertokens.user_additional_fields". */
export type Supertokens_User_Additional_Fields_Order_By = {
  anonymous_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_user_name?: InputMaybe<Order_By>;
  elo_rating?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_admin?: InputMaybe<Order_By>;
  is_bot?: InputMaybe<Order_By>;
  is_tournament_enabled?: InputMaybe<Order_By>;
  is_user_activated?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  onboard_current_game?: InputMaybe<Order_By>;
  onboard_did_checkmate_in_first_attempt?: InputMaybe<Order_By>;
  onboard_did_tried_again_any_moves?: InputMaybe<Order_By>;
  onboard_is_first_game_completed?: InputMaybe<Order_By>;
  onboard_is_second_game_completed?: InputMaybe<Order_By>;
  pan_card_details_aggregate?: InputMaybe<Pan_Card_Details_Aggregate_Order_By>;
  passwordless_users?: InputMaybe<Supertokens_Passwordless_Users_Order_By>;
  profile_picture?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upi_details_aggregate?: InputMaybe<Upi_Details_Aggregate_Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_type?: InputMaybe<Order_By>;
  wallet_balance?: InputMaybe<Order_By>;
};

/** primary key columns input for table: supertokens.user_additional_fields */
export type Supertokens_User_Additional_Fields_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "supertokens.user_additional_fields" */
export enum Supertokens_User_Additional_Fields_Select_Column {
  /** column name */
  AnonymousUserId = 'anonymous_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomUserName = 'custom_user_name',
  /** column name */
  EloRating = 'elo_rating',
  /** column name */
  Id = 'id',
  /** column name */
  IsAdmin = 'is_admin',
  /** column name */
  IsBot = 'is_bot',
  /** column name */
  IsTournamentEnabled = 'is_tournament_enabled',
  /** column name */
  IsUserActivated = 'is_user_activated',
  /** column name */
  Name = 'name',
  /** column name */
  OnboardCurrentGame = 'onboard_current_game',
  /** column name */
  OnboardDidCheckmateInFirstAttempt = 'onboard_did_checkmate_in_first_attempt',
  /** column name */
  OnboardDidTriedAgainAnyMoves = 'onboard_did_tried_again_any_moves',
  /** column name */
  OnboardIsFirstGameCompleted = 'onboard_is_first_game_completed',
  /** column name */
  OnboardIsSecondGameCompleted = 'onboard_is_second_game_completed',
  /** column name */
  ProfilePicture = 'profile_picture',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserType = 'user_type'
}

/** input type for updating data in table "supertokens.user_additional_fields" */
export type Supertokens_User_Additional_Fields_Set_Input = {
  anonymous_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_user_name?: InputMaybe<Scalars['String']>;
  elo_rating?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_admin?: InputMaybe<Scalars['Boolean']>;
  is_bot?: InputMaybe<Scalars['Boolean']>;
  is_tournament_enabled?: InputMaybe<Scalars['Boolean']>;
  is_user_activated?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  onboard_current_game?: InputMaybe<Scalars['String']>;
  onboard_did_checkmate_in_first_attempt?: InputMaybe<Scalars['Boolean']>;
  onboard_did_tried_again_any_moves?: InputMaybe<Scalars['Boolean']>;
  onboard_is_first_game_completed?: InputMaybe<Scalars['Boolean']>;
  onboard_is_second_game_completed?: InputMaybe<Scalars['Boolean']>;
  profile_picture?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
  user_type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Supertokens_User_Additional_Fields_Stddev_Fields = {
  __typename?: 'supertokens_user_additional_fields_stddev_fields';
  elo_rating?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "supertokens.wallet_balance" */
  wallet_balance?: Maybe<Scalars['float8']>;
};

/** aggregate stddev_pop on columns */
export type Supertokens_User_Additional_Fields_Stddev_Pop_Fields = {
  __typename?: 'supertokens_user_additional_fields_stddev_pop_fields';
  elo_rating?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "supertokens.wallet_balance" */
  wallet_balance?: Maybe<Scalars['float8']>;
};

/** aggregate stddev_samp on columns */
export type Supertokens_User_Additional_Fields_Stddev_Samp_Fields = {
  __typename?: 'supertokens_user_additional_fields_stddev_samp_fields';
  elo_rating?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "supertokens.wallet_balance" */
  wallet_balance?: Maybe<Scalars['float8']>;
};

/** Streaming cursor of the table "supertokens_user_additional_fields" */
export type Supertokens_User_Additional_Fields_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supertokens_User_Additional_Fields_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supertokens_User_Additional_Fields_Stream_Cursor_Value_Input = {
  anonymous_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_user_name?: InputMaybe<Scalars['String']>;
  elo_rating?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_admin?: InputMaybe<Scalars['Boolean']>;
  is_bot?: InputMaybe<Scalars['Boolean']>;
  is_tournament_enabled?: InputMaybe<Scalars['Boolean']>;
  is_user_activated?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  onboard_current_game?: InputMaybe<Scalars['String']>;
  onboard_did_checkmate_in_first_attempt?: InputMaybe<Scalars['Boolean']>;
  onboard_did_tried_again_any_moves?: InputMaybe<Scalars['Boolean']>;
  onboard_is_first_game_completed?: InputMaybe<Scalars['Boolean']>;
  onboard_is_second_game_completed?: InputMaybe<Scalars['Boolean']>;
  profile_picture?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
  user_type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Supertokens_User_Additional_Fields_Sum_Fields = {
  __typename?: 'supertokens_user_additional_fields_sum_fields';
  elo_rating?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "supertokens.wallet_balance" */
  wallet_balance?: Maybe<Scalars['float8']>;
};

/** update columns of table "supertokens.user_additional_fields" */
export enum Supertokens_User_Additional_Fields_Update_Column {
  /** column name */
  AnonymousUserId = 'anonymous_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomUserName = 'custom_user_name',
  /** column name */
  EloRating = 'elo_rating',
  /** column name */
  Id = 'id',
  /** column name */
  IsAdmin = 'is_admin',
  /** column name */
  IsBot = 'is_bot',
  /** column name */
  IsTournamentEnabled = 'is_tournament_enabled',
  /** column name */
  IsUserActivated = 'is_user_activated',
  /** column name */
  Name = 'name',
  /** column name */
  OnboardCurrentGame = 'onboard_current_game',
  /** column name */
  OnboardDidCheckmateInFirstAttempt = 'onboard_did_checkmate_in_first_attempt',
  /** column name */
  OnboardDidTriedAgainAnyMoves = 'onboard_did_tried_again_any_moves',
  /** column name */
  OnboardIsFirstGameCompleted = 'onboard_is_first_game_completed',
  /** column name */
  OnboardIsSecondGameCompleted = 'onboard_is_second_game_completed',
  /** column name */
  ProfilePicture = 'profile_picture',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserType = 'user_type'
}

export type Supertokens_User_Additional_Fields_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Supertokens_User_Additional_Fields_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Supertokens_User_Additional_Fields_Set_Input>;
  /** filter the rows which have to be updated */
  where: Supertokens_User_Additional_Fields_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Supertokens_User_Additional_Fields_Var_Pop_Fields = {
  __typename?: 'supertokens_user_additional_fields_var_pop_fields';
  elo_rating?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "supertokens.wallet_balance" */
  wallet_balance?: Maybe<Scalars['float8']>;
};

/** aggregate var_samp on columns */
export type Supertokens_User_Additional_Fields_Var_Samp_Fields = {
  __typename?: 'supertokens_user_additional_fields_var_samp_fields';
  elo_rating?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "supertokens.wallet_balance" */
  wallet_balance?: Maybe<Scalars['float8']>;
};

/** aggregate variance on columns */
export type Supertokens_User_Additional_Fields_Variance_Fields = {
  __typename?: 'supertokens_user_additional_fields_variance_fields';
  elo_rating?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "supertokens.wallet_balance" */
  wallet_balance?: Maybe<Scalars['float8']>;
};

/** columns and relationships of "supertokens.user_public_fields" */
export type Supertokens_User_Public_Fields = {
  __typename?: 'supertokens_user_public_fields';
  custom_username?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  profile_picture?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "supertokens.user_public_fields" */
export type Supertokens_User_Public_Fields_Aggregate = {
  __typename?: 'supertokens_user_public_fields_aggregate';
  aggregate?: Maybe<Supertokens_User_Public_Fields_Aggregate_Fields>;
  nodes: Array<Supertokens_User_Public_Fields>;
};

/** aggregate fields of "supertokens.user_public_fields" */
export type Supertokens_User_Public_Fields_Aggregate_Fields = {
  __typename?: 'supertokens_user_public_fields_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Supertokens_User_Public_Fields_Max_Fields>;
  min?: Maybe<Supertokens_User_Public_Fields_Min_Fields>;
};


/** aggregate fields of "supertokens.user_public_fields" */
export type Supertokens_User_Public_Fields_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Supertokens_User_Public_Fields_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "supertokens.user_public_fields". All fields are combined with a logical 'AND'. */
export type Supertokens_User_Public_Fields_Bool_Exp = {
  _and?: InputMaybe<Array<Supertokens_User_Public_Fields_Bool_Exp>>;
  _not?: InputMaybe<Supertokens_User_Public_Fields_Bool_Exp>;
  _or?: InputMaybe<Array<Supertokens_User_Public_Fields_Bool_Exp>>;
  custom_username?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  profile_picture?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "supertokens.user_public_fields" */
export type Supertokens_User_Public_Fields_Insert_Input = {
  custom_username?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  profile_picture?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Supertokens_User_Public_Fields_Max_Fields = {
  __typename?: 'supertokens_user_public_fields_max_fields';
  custom_username?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  profile_picture?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Supertokens_User_Public_Fields_Min_Fields = {
  __typename?: 'supertokens_user_public_fields_min_fields';
  custom_username?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  profile_picture?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "supertokens.user_public_fields" */
export type Supertokens_User_Public_Fields_Mutation_Response = {
  __typename?: 'supertokens_user_public_fields_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Supertokens_User_Public_Fields>;
};

/** input type for inserting object relation for remote table "supertokens.user_public_fields" */
export type Supertokens_User_Public_Fields_Obj_Rel_Insert_Input = {
  data: Supertokens_User_Public_Fields_Insert_Input;
};

/** Ordering options when selecting data from "supertokens.user_public_fields". */
export type Supertokens_User_Public_Fields_Order_By = {
  custom_username?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  profile_picture?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "supertokens.user_public_fields" */
export enum Supertokens_User_Public_Fields_Select_Column {
  /** column name */
  CustomUsername = 'custom_username',
  /** column name */
  Name = 'name',
  /** column name */
  ProfilePicture = 'profile_picture',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "supertokens.user_public_fields" */
export type Supertokens_User_Public_Fields_Set_Input = {
  custom_username?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  profile_picture?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "supertokens_user_public_fields" */
export type Supertokens_User_Public_Fields_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Supertokens_User_Public_Fields_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Supertokens_User_Public_Fields_Stream_Cursor_Value_Input = {
  custom_username?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  profile_picture?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Supertokens_User_Public_Fields_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Supertokens_User_Public_Fields_Set_Input>;
  /** filter the rows which have to be updated */
  where: Supertokens_User_Public_Fields_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "tournament" */
export type Tournament = {
  __typename?: 'tournament';
  created_at: Scalars['timestamptz'];
  end_time: Scalars['timestamptz'];
  /** A computed field, executes function "has_user_made_an_entry" */
  has_user_made_an_entry?: Maybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  is_active: Scalars['Boolean'];
  is_rewards_distributed: Scalars['Boolean'];
  number_of_levels: Scalars['Int'];
  /** An array relationship */
  rooms: Array<Rooms>;
  /** An aggregate relationship */
  rooms_aggregate: Rooms_Aggregate;
  start_time: Scalars['timestamptz'];
  /** An array relationship */
  tournament_leaderboards: Array<Tournament_Leaderboard>;
  /** An aggregate relationship */
  tournament_leaderboards_aggregate: Tournament_Leaderboard_Aggregate;
  /** An array relationship */
  tournament_levels_metadata: Array<Tournament_Levels_Metadata>;
  /** An aggregate relationship */
  tournament_levels_metadata_aggregate: Tournament_Levels_Metadata_Aggregate;
  /** An array relationship */
  tournament_participants: Array<Tournament_Participants>;
  /** An aggregate relationship */
  tournament_participants_aggregate: Tournament_Participants_Aggregate;
  /** An array relationship */
  tournament_wallet_transactions: Array<Tournament_Wallet_Transactions>;
  /** An aggregate relationship */
  tournament_wallet_transactions_aggregate: Tournament_Wallet_Transactions_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "tournament" */
export type TournamentRoomsArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


/** columns and relationships of "tournament" */
export type TournamentRooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


/** columns and relationships of "tournament" */
export type TournamentTournament_LeaderboardsArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Leaderboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Leaderboard_Order_By>>;
  where?: InputMaybe<Tournament_Leaderboard_Bool_Exp>;
};


/** columns and relationships of "tournament" */
export type TournamentTournament_Leaderboards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Leaderboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Leaderboard_Order_By>>;
  where?: InputMaybe<Tournament_Leaderboard_Bool_Exp>;
};


/** columns and relationships of "tournament" */
export type TournamentTournament_Levels_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Levels_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Levels_Metadata_Order_By>>;
  where?: InputMaybe<Tournament_Levels_Metadata_Bool_Exp>;
};


/** columns and relationships of "tournament" */
export type TournamentTournament_Levels_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Levels_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Levels_Metadata_Order_By>>;
  where?: InputMaybe<Tournament_Levels_Metadata_Bool_Exp>;
};


/** columns and relationships of "tournament" */
export type TournamentTournament_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Participants_Order_By>>;
  where?: InputMaybe<Tournament_Participants_Bool_Exp>;
};


/** columns and relationships of "tournament" */
export type TournamentTournament_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Participants_Order_By>>;
  where?: InputMaybe<Tournament_Participants_Bool_Exp>;
};


/** columns and relationships of "tournament" */
export type TournamentTournament_Wallet_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Tournament_Wallet_Transactions_Bool_Exp>;
};


/** columns and relationships of "tournament" */
export type TournamentTournament_Wallet_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Tournament_Wallet_Transactions_Bool_Exp>;
};

/** aggregated selection of "tournament" */
export type Tournament_Aggregate = {
  __typename?: 'tournament_aggregate';
  aggregate?: Maybe<Tournament_Aggregate_Fields>;
  nodes: Array<Tournament>;
};

/** aggregate fields of "tournament" */
export type Tournament_Aggregate_Fields = {
  __typename?: 'tournament_aggregate_fields';
  avg?: Maybe<Tournament_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tournament_Max_Fields>;
  min?: Maybe<Tournament_Min_Fields>;
  stddev?: Maybe<Tournament_Stddev_Fields>;
  stddev_pop?: Maybe<Tournament_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tournament_Stddev_Samp_Fields>;
  sum?: Maybe<Tournament_Sum_Fields>;
  var_pop?: Maybe<Tournament_Var_Pop_Fields>;
  var_samp?: Maybe<Tournament_Var_Samp_Fields>;
  variance?: Maybe<Tournament_Variance_Fields>;
};


/** aggregate fields of "tournament" */
export type Tournament_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tournament_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Tournament_Avg_Fields = {
  __typename?: 'tournament_avg_fields';
  number_of_levels?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "tournament". All fields are combined with a logical 'AND'. */
export type Tournament_Bool_Exp = {
  _and?: InputMaybe<Array<Tournament_Bool_Exp>>;
  _not?: InputMaybe<Tournament_Bool_Exp>;
  _or?: InputMaybe<Array<Tournament_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  end_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  has_user_made_an_entry?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_rewards_distributed?: InputMaybe<Boolean_Comparison_Exp>;
  number_of_levels?: InputMaybe<Int_Comparison_Exp>;
  rooms?: InputMaybe<Rooms_Bool_Exp>;
  rooms_aggregate?: InputMaybe<Rooms_Aggregate_Bool_Exp>;
  start_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  tournament_leaderboards?: InputMaybe<Tournament_Leaderboard_Bool_Exp>;
  tournament_leaderboards_aggregate?: InputMaybe<Tournament_Leaderboard_Aggregate_Bool_Exp>;
  tournament_levels_metadata?: InputMaybe<Tournament_Levels_Metadata_Bool_Exp>;
  tournament_levels_metadata_aggregate?: InputMaybe<Tournament_Levels_Metadata_Aggregate_Bool_Exp>;
  tournament_participants?: InputMaybe<Tournament_Participants_Bool_Exp>;
  tournament_participants_aggregate?: InputMaybe<Tournament_Participants_Aggregate_Bool_Exp>;
  tournament_wallet_transactions?: InputMaybe<Tournament_Wallet_Transactions_Bool_Exp>;
  tournament_wallet_transactions_aggregate?: InputMaybe<Tournament_Wallet_Transactions_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tournament" */
export enum Tournament_Constraint {
  /** unique or primary key constraint on columns "id" */
  TournamentPkey = 'tournament_pkey'
}

/** input type for incrementing numeric columns in table "tournament" */
export type Tournament_Inc_Input = {
  number_of_levels?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "tournament" */
export type Tournament_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_rewards_distributed?: InputMaybe<Scalars['Boolean']>;
  number_of_levels?: InputMaybe<Scalars['Int']>;
  rooms?: InputMaybe<Rooms_Arr_Rel_Insert_Input>;
  start_time?: InputMaybe<Scalars['timestamptz']>;
  tournament_leaderboards?: InputMaybe<Tournament_Leaderboard_Arr_Rel_Insert_Input>;
  tournament_levels_metadata?: InputMaybe<Tournament_Levels_Metadata_Arr_Rel_Insert_Input>;
  tournament_participants?: InputMaybe<Tournament_Participants_Arr_Rel_Insert_Input>;
  tournament_wallet_transactions?: InputMaybe<Tournament_Wallet_Transactions_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "tournament_leaderboard" */
export type Tournament_Leaderboard = {
  __typename?: 'tournament_leaderboard';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  level?: Maybe<Scalars['Int']>;
  number_of_checkmates?: Maybe<Scalars['Int']>;
  rank: Scalars['Int'];
  /** An object relationship */
  tournament: Tournament;
  /** An object relationship */
  tournamentParticipantByTournamentIdUserId?: Maybe<Tournament_Participants>;
  tournament_id: Scalars['uuid'];
  /** An object relationship */
  tournament_participant: Tournament_Participants;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Supertokens_User_Public_Fields>;
  user_id: Scalars['String'];
  winnings?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "tournament_leaderboard" */
export type Tournament_Leaderboard_Aggregate = {
  __typename?: 'tournament_leaderboard_aggregate';
  aggregate?: Maybe<Tournament_Leaderboard_Aggregate_Fields>;
  nodes: Array<Tournament_Leaderboard>;
};

export type Tournament_Leaderboard_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Tournament_Leaderboard_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Tournament_Leaderboard_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Tournament_Leaderboard_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Tournament_Leaderboard_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Tournament_Leaderboard_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Tournament_Leaderboard_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Tournament_Leaderboard_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Tournament_Leaderboard_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Tournament_Leaderboard_Aggregate_Bool_Exp_Var_Samp>;
};

export type Tournament_Leaderboard_Aggregate_Bool_Exp_Avg = {
  arguments: Tournament_Leaderboard_Select_Column_Tournament_Leaderboard_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tournament_Leaderboard_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tournament_Leaderboard_Aggregate_Bool_Exp_Corr = {
  arguments: Tournament_Leaderboard_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tournament_Leaderboard_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tournament_Leaderboard_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Tournament_Leaderboard_Select_Column_Tournament_Leaderboard_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Tournament_Leaderboard_Select_Column_Tournament_Leaderboard_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Tournament_Leaderboard_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tournament_Leaderboard_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tournament_Leaderboard_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Tournament_Leaderboard_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Tournament_Leaderboard_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tournament_Leaderboard_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tournament_Leaderboard_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Tournament_Leaderboard_Select_Column_Tournament_Leaderboard_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Tournament_Leaderboard_Select_Column_Tournament_Leaderboard_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Tournament_Leaderboard_Aggregate_Bool_Exp_Max = {
  arguments: Tournament_Leaderboard_Select_Column_Tournament_Leaderboard_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tournament_Leaderboard_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tournament_Leaderboard_Aggregate_Bool_Exp_Min = {
  arguments: Tournament_Leaderboard_Select_Column_Tournament_Leaderboard_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tournament_Leaderboard_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tournament_Leaderboard_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Tournament_Leaderboard_Select_Column_Tournament_Leaderboard_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tournament_Leaderboard_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tournament_Leaderboard_Aggregate_Bool_Exp_Sum = {
  arguments: Tournament_Leaderboard_Select_Column_Tournament_Leaderboard_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tournament_Leaderboard_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tournament_Leaderboard_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Tournament_Leaderboard_Select_Column_Tournament_Leaderboard_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tournament_Leaderboard_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "tournament_leaderboard" */
export type Tournament_Leaderboard_Aggregate_Fields = {
  __typename?: 'tournament_leaderboard_aggregate_fields';
  avg?: Maybe<Tournament_Leaderboard_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tournament_Leaderboard_Max_Fields>;
  min?: Maybe<Tournament_Leaderboard_Min_Fields>;
  stddev?: Maybe<Tournament_Leaderboard_Stddev_Fields>;
  stddev_pop?: Maybe<Tournament_Leaderboard_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tournament_Leaderboard_Stddev_Samp_Fields>;
  sum?: Maybe<Tournament_Leaderboard_Sum_Fields>;
  var_pop?: Maybe<Tournament_Leaderboard_Var_Pop_Fields>;
  var_samp?: Maybe<Tournament_Leaderboard_Var_Samp_Fields>;
  variance?: Maybe<Tournament_Leaderboard_Variance_Fields>;
};


/** aggregate fields of "tournament_leaderboard" */
export type Tournament_Leaderboard_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tournament_Leaderboard_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tournament_leaderboard" */
export type Tournament_Leaderboard_Aggregate_Order_By = {
  avg?: InputMaybe<Tournament_Leaderboard_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tournament_Leaderboard_Max_Order_By>;
  min?: InputMaybe<Tournament_Leaderboard_Min_Order_By>;
  stddev?: InputMaybe<Tournament_Leaderboard_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tournament_Leaderboard_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tournament_Leaderboard_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tournament_Leaderboard_Sum_Order_By>;
  var_pop?: InputMaybe<Tournament_Leaderboard_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tournament_Leaderboard_Var_Samp_Order_By>;
  variance?: InputMaybe<Tournament_Leaderboard_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tournament_leaderboard" */
export type Tournament_Leaderboard_Arr_Rel_Insert_Input = {
  data: Array<Tournament_Leaderboard_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tournament_Leaderboard_On_Conflict>;
};

/** aggregate avg on columns */
export type Tournament_Leaderboard_Avg_Fields = {
  __typename?: 'tournament_leaderboard_avg_fields';
  level?: Maybe<Scalars['Float']>;
  number_of_checkmates?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  winnings?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tournament_leaderboard" */
export type Tournament_Leaderboard_Avg_Order_By = {
  level?: InputMaybe<Order_By>;
  number_of_checkmates?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  winnings?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tournament_leaderboard". All fields are combined with a logical 'AND'. */
export type Tournament_Leaderboard_Bool_Exp = {
  _and?: InputMaybe<Array<Tournament_Leaderboard_Bool_Exp>>;
  _not?: InputMaybe<Tournament_Leaderboard_Bool_Exp>;
  _or?: InputMaybe<Array<Tournament_Leaderboard_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  level?: InputMaybe<Int_Comparison_Exp>;
  number_of_checkmates?: InputMaybe<Int_Comparison_Exp>;
  rank?: InputMaybe<Int_Comparison_Exp>;
  tournament?: InputMaybe<Tournament_Bool_Exp>;
  tournamentParticipantByTournamentIdUserId?: InputMaybe<Tournament_Participants_Bool_Exp>;
  tournament_id?: InputMaybe<Uuid_Comparison_Exp>;
  tournament_participant?: InputMaybe<Tournament_Participants_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Supertokens_User_Public_Fields_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  winnings?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "tournament_leaderboard" */
export enum Tournament_Leaderboard_Constraint {
  /** unique or primary key constraint on columns "id" */
  TournamentLeaderboardPkey = 'tournament_leaderboard_pkey',
  /** unique or primary key constraint on columns "user_id", "tournament_id" */
  TournamentLeaderboardTournamentIdUserIdKey = 'tournament_leaderboard_tournament_id_user_id_key'
}

/** input type for incrementing numeric columns in table "tournament_leaderboard" */
export type Tournament_Leaderboard_Inc_Input = {
  level?: InputMaybe<Scalars['Int']>;
  number_of_checkmates?: InputMaybe<Scalars['Int']>;
  rank?: InputMaybe<Scalars['Int']>;
  winnings?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "tournament_leaderboard" */
export type Tournament_Leaderboard_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  level?: InputMaybe<Scalars['Int']>;
  number_of_checkmates?: InputMaybe<Scalars['Int']>;
  rank?: InputMaybe<Scalars['Int']>;
  tournament?: InputMaybe<Tournament_Obj_Rel_Insert_Input>;
  tournamentParticipantByTournamentIdUserId?: InputMaybe<Tournament_Participants_Obj_Rel_Insert_Input>;
  tournament_id?: InputMaybe<Scalars['uuid']>;
  tournament_participant?: InputMaybe<Tournament_Participants_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Supertokens_User_Public_Fields_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
  winnings?: InputMaybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Tournament_Leaderboard_Max_Fields = {
  __typename?: 'tournament_leaderboard_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['Int']>;
  number_of_checkmates?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['Int']>;
  tournament_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  winnings?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "tournament_leaderboard" */
export type Tournament_Leaderboard_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  number_of_checkmates?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  winnings?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tournament_Leaderboard_Min_Fields = {
  __typename?: 'tournament_leaderboard_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['Int']>;
  number_of_checkmates?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['Int']>;
  tournament_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  winnings?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "tournament_leaderboard" */
export type Tournament_Leaderboard_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  number_of_checkmates?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  winnings?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tournament_leaderboard" */
export type Tournament_Leaderboard_Mutation_Response = {
  __typename?: 'tournament_leaderboard_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tournament_Leaderboard>;
};

/** input type for inserting object relation for remote table "tournament_leaderboard" */
export type Tournament_Leaderboard_Obj_Rel_Insert_Input = {
  data: Tournament_Leaderboard_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tournament_Leaderboard_On_Conflict>;
};

/** on_conflict condition type for table "tournament_leaderboard" */
export type Tournament_Leaderboard_On_Conflict = {
  constraint: Tournament_Leaderboard_Constraint;
  update_columns?: Array<Tournament_Leaderboard_Update_Column>;
  where?: InputMaybe<Tournament_Leaderboard_Bool_Exp>;
};

/** Ordering options when selecting data from "tournament_leaderboard". */
export type Tournament_Leaderboard_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  number_of_checkmates?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  tournament?: InputMaybe<Tournament_Order_By>;
  tournamentParticipantByTournamentIdUserId?: InputMaybe<Tournament_Participants_Order_By>;
  tournament_id?: InputMaybe<Order_By>;
  tournament_participant?: InputMaybe<Tournament_Participants_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Supertokens_User_Public_Fields_Order_By>;
  user_id?: InputMaybe<Order_By>;
  winnings?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tournament_leaderboard */
export type Tournament_Leaderboard_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tournament_leaderboard" */
export enum Tournament_Leaderboard_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  NumberOfCheckmates = 'number_of_checkmates',
  /** column name */
  Rank = 'rank',
  /** column name */
  TournamentId = 'tournament_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Winnings = 'winnings'
}

/** select "tournament_leaderboard_aggregate_bool_exp_avg_arguments_columns" columns of table "tournament_leaderboard" */
export enum Tournament_Leaderboard_Select_Column_Tournament_Leaderboard_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Winnings = 'winnings'
}

/** select "tournament_leaderboard_aggregate_bool_exp_corr_arguments_columns" columns of table "tournament_leaderboard" */
export enum Tournament_Leaderboard_Select_Column_Tournament_Leaderboard_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Winnings = 'winnings'
}

/** select "tournament_leaderboard_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "tournament_leaderboard" */
export enum Tournament_Leaderboard_Select_Column_Tournament_Leaderboard_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Winnings = 'winnings'
}

/** select "tournament_leaderboard_aggregate_bool_exp_max_arguments_columns" columns of table "tournament_leaderboard" */
export enum Tournament_Leaderboard_Select_Column_Tournament_Leaderboard_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Winnings = 'winnings'
}

/** select "tournament_leaderboard_aggregate_bool_exp_min_arguments_columns" columns of table "tournament_leaderboard" */
export enum Tournament_Leaderboard_Select_Column_Tournament_Leaderboard_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Winnings = 'winnings'
}

/** select "tournament_leaderboard_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "tournament_leaderboard" */
export enum Tournament_Leaderboard_Select_Column_Tournament_Leaderboard_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Winnings = 'winnings'
}

/** select "tournament_leaderboard_aggregate_bool_exp_sum_arguments_columns" columns of table "tournament_leaderboard" */
export enum Tournament_Leaderboard_Select_Column_Tournament_Leaderboard_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Winnings = 'winnings'
}

/** select "tournament_leaderboard_aggregate_bool_exp_var_samp_arguments_columns" columns of table "tournament_leaderboard" */
export enum Tournament_Leaderboard_Select_Column_Tournament_Leaderboard_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Winnings = 'winnings'
}

/** input type for updating data in table "tournament_leaderboard" */
export type Tournament_Leaderboard_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  level?: InputMaybe<Scalars['Int']>;
  number_of_checkmates?: InputMaybe<Scalars['Int']>;
  rank?: InputMaybe<Scalars['Int']>;
  tournament_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
  winnings?: InputMaybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Tournament_Leaderboard_Stddev_Fields = {
  __typename?: 'tournament_leaderboard_stddev_fields';
  level?: Maybe<Scalars['Float']>;
  number_of_checkmates?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  winnings?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tournament_leaderboard" */
export type Tournament_Leaderboard_Stddev_Order_By = {
  level?: InputMaybe<Order_By>;
  number_of_checkmates?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  winnings?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tournament_Leaderboard_Stddev_Pop_Fields = {
  __typename?: 'tournament_leaderboard_stddev_pop_fields';
  level?: Maybe<Scalars['Float']>;
  number_of_checkmates?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  winnings?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tournament_leaderboard" */
export type Tournament_Leaderboard_Stddev_Pop_Order_By = {
  level?: InputMaybe<Order_By>;
  number_of_checkmates?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  winnings?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tournament_Leaderboard_Stddev_Samp_Fields = {
  __typename?: 'tournament_leaderboard_stddev_samp_fields';
  level?: Maybe<Scalars['Float']>;
  number_of_checkmates?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  winnings?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tournament_leaderboard" */
export type Tournament_Leaderboard_Stddev_Samp_Order_By = {
  level?: InputMaybe<Order_By>;
  number_of_checkmates?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  winnings?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tournament_leaderboard" */
export type Tournament_Leaderboard_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tournament_Leaderboard_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tournament_Leaderboard_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  level?: InputMaybe<Scalars['Int']>;
  number_of_checkmates?: InputMaybe<Scalars['Int']>;
  rank?: InputMaybe<Scalars['Int']>;
  tournament_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
  winnings?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Tournament_Leaderboard_Sum_Fields = {
  __typename?: 'tournament_leaderboard_sum_fields';
  level?: Maybe<Scalars['Int']>;
  number_of_checkmates?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['Int']>;
  winnings?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "tournament_leaderboard" */
export type Tournament_Leaderboard_Sum_Order_By = {
  level?: InputMaybe<Order_By>;
  number_of_checkmates?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  winnings?: InputMaybe<Order_By>;
};

/** update columns of table "tournament_leaderboard" */
export enum Tournament_Leaderboard_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  NumberOfCheckmates = 'number_of_checkmates',
  /** column name */
  Rank = 'rank',
  /** column name */
  TournamentId = 'tournament_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Winnings = 'winnings'
}

export type Tournament_Leaderboard_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tournament_Leaderboard_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tournament_Leaderboard_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tournament_Leaderboard_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tournament_Leaderboard_Var_Pop_Fields = {
  __typename?: 'tournament_leaderboard_var_pop_fields';
  level?: Maybe<Scalars['Float']>;
  number_of_checkmates?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  winnings?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tournament_leaderboard" */
export type Tournament_Leaderboard_Var_Pop_Order_By = {
  level?: InputMaybe<Order_By>;
  number_of_checkmates?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  winnings?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tournament_Leaderboard_Var_Samp_Fields = {
  __typename?: 'tournament_leaderboard_var_samp_fields';
  level?: Maybe<Scalars['Float']>;
  number_of_checkmates?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  winnings?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tournament_leaderboard" */
export type Tournament_Leaderboard_Var_Samp_Order_By = {
  level?: InputMaybe<Order_By>;
  number_of_checkmates?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  winnings?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tournament_Leaderboard_Variance_Fields = {
  __typename?: 'tournament_leaderboard_variance_fields';
  level?: Maybe<Scalars['Float']>;
  number_of_checkmates?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  winnings?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tournament_leaderboard" */
export type Tournament_Leaderboard_Variance_Order_By = {
  level?: InputMaybe<Order_By>;
  number_of_checkmates?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  winnings?: InputMaybe<Order_By>;
};

/** columns and relationships of "tournament_levels_metadata" */
export type Tournament_Levels_Metadata = {
  __typename?: 'tournament_levels_metadata';
  created_at: Scalars['timestamptz'];
  /** A computed field, executes function "has_user_completed_with_checkmate" */
  has_user_completed_with_checkmate?: Maybe<Scalars['Boolean']>;
  highest_rank_in_level?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  is_checkpoint: Scalars['Boolean'];
  level: Scalars['Int'];
  lowest_rank_in_level?: Maybe<Scalars['Int']>;
  /** An array relationship */
  rooms: Array<Rooms>;
  /** An aggregate relationship */
  rooms_aggregate: Rooms_Aggregate;
  tournament_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "tournament_levels_metadata" */
export type Tournament_Levels_MetadataRoomsArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


/** columns and relationships of "tournament_levels_metadata" */
export type Tournament_Levels_MetadataRooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};

/** aggregated selection of "tournament_levels_metadata" */
export type Tournament_Levels_Metadata_Aggregate = {
  __typename?: 'tournament_levels_metadata_aggregate';
  aggregate?: Maybe<Tournament_Levels_Metadata_Aggregate_Fields>;
  nodes: Array<Tournament_Levels_Metadata>;
};

export type Tournament_Levels_Metadata_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tournament_Levels_Metadata_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tournament_Levels_Metadata_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tournament_Levels_Metadata_Aggregate_Bool_Exp_Count>;
};

export type Tournament_Levels_Metadata_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tournament_Levels_Metadata_Select_Column_Tournament_Levels_Metadata_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tournament_Levels_Metadata_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tournament_Levels_Metadata_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tournament_Levels_Metadata_Select_Column_Tournament_Levels_Metadata_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tournament_Levels_Metadata_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tournament_Levels_Metadata_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tournament_Levels_Metadata_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tournament_Levels_Metadata_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tournament_levels_metadata" */
export type Tournament_Levels_Metadata_Aggregate_Fields = {
  __typename?: 'tournament_levels_metadata_aggregate_fields';
  avg?: Maybe<Tournament_Levels_Metadata_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tournament_Levels_Metadata_Max_Fields>;
  min?: Maybe<Tournament_Levels_Metadata_Min_Fields>;
  stddev?: Maybe<Tournament_Levels_Metadata_Stddev_Fields>;
  stddev_pop?: Maybe<Tournament_Levels_Metadata_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tournament_Levels_Metadata_Stddev_Samp_Fields>;
  sum?: Maybe<Tournament_Levels_Metadata_Sum_Fields>;
  var_pop?: Maybe<Tournament_Levels_Metadata_Var_Pop_Fields>;
  var_samp?: Maybe<Tournament_Levels_Metadata_Var_Samp_Fields>;
  variance?: Maybe<Tournament_Levels_Metadata_Variance_Fields>;
};


/** aggregate fields of "tournament_levels_metadata" */
export type Tournament_Levels_Metadata_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tournament_Levels_Metadata_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tournament_levels_metadata" */
export type Tournament_Levels_Metadata_Aggregate_Order_By = {
  avg?: InputMaybe<Tournament_Levels_Metadata_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tournament_Levels_Metadata_Max_Order_By>;
  min?: InputMaybe<Tournament_Levels_Metadata_Min_Order_By>;
  stddev?: InputMaybe<Tournament_Levels_Metadata_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tournament_Levels_Metadata_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tournament_Levels_Metadata_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tournament_Levels_Metadata_Sum_Order_By>;
  var_pop?: InputMaybe<Tournament_Levels_Metadata_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tournament_Levels_Metadata_Var_Samp_Order_By>;
  variance?: InputMaybe<Tournament_Levels_Metadata_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tournament_levels_metadata" */
export type Tournament_Levels_Metadata_Arr_Rel_Insert_Input = {
  data: Array<Tournament_Levels_Metadata_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tournament_Levels_Metadata_On_Conflict>;
};

/** aggregate avg on columns */
export type Tournament_Levels_Metadata_Avg_Fields = {
  __typename?: 'tournament_levels_metadata_avg_fields';
  highest_rank_in_level?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  lowest_rank_in_level?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tournament_levels_metadata" */
export type Tournament_Levels_Metadata_Avg_Order_By = {
  highest_rank_in_level?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  lowest_rank_in_level?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tournament_levels_metadata". All fields are combined with a logical 'AND'. */
export type Tournament_Levels_Metadata_Bool_Exp = {
  _and?: InputMaybe<Array<Tournament_Levels_Metadata_Bool_Exp>>;
  _not?: InputMaybe<Tournament_Levels_Metadata_Bool_Exp>;
  _or?: InputMaybe<Array<Tournament_Levels_Metadata_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  has_user_completed_with_checkmate?: InputMaybe<Boolean_Comparison_Exp>;
  highest_rank_in_level?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_checkpoint?: InputMaybe<Boolean_Comparison_Exp>;
  level?: InputMaybe<Int_Comparison_Exp>;
  lowest_rank_in_level?: InputMaybe<Int_Comparison_Exp>;
  rooms?: InputMaybe<Rooms_Bool_Exp>;
  rooms_aggregate?: InputMaybe<Rooms_Aggregate_Bool_Exp>;
  tournament_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tournament_levels_metadata" */
export enum Tournament_Levels_Metadata_Constraint {
  /** unique or primary key constraint on columns "id" */
  LevelMetadataPkey = 'level_metadata_pkey',
  /** unique or primary key constraint on columns "level", "tournament_id" */
  TournamentLevelsMetadataLevelTournamentIdKey = 'tournament_levels_metadata_level_tournament_id_key'
}

/** input type for incrementing numeric columns in table "tournament_levels_metadata" */
export type Tournament_Levels_Metadata_Inc_Input = {
  highest_rank_in_level?: InputMaybe<Scalars['Int']>;
  level?: InputMaybe<Scalars['Int']>;
  lowest_rank_in_level?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "tournament_levels_metadata" */
export type Tournament_Levels_Metadata_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  highest_rank_in_level?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_checkpoint?: InputMaybe<Scalars['Boolean']>;
  level?: InputMaybe<Scalars['Int']>;
  lowest_rank_in_level?: InputMaybe<Scalars['Int']>;
  rooms?: InputMaybe<Rooms_Arr_Rel_Insert_Input>;
  tournament_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tournament_Levels_Metadata_Max_Fields = {
  __typename?: 'tournament_levels_metadata_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  highest_rank_in_level?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['Int']>;
  lowest_rank_in_level?: Maybe<Scalars['Int']>;
  tournament_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tournament_levels_metadata" */
export type Tournament_Levels_Metadata_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  highest_rank_in_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  lowest_rank_in_level?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tournament_Levels_Metadata_Min_Fields = {
  __typename?: 'tournament_levels_metadata_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  highest_rank_in_level?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['Int']>;
  lowest_rank_in_level?: Maybe<Scalars['Int']>;
  tournament_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tournament_levels_metadata" */
export type Tournament_Levels_Metadata_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  highest_rank_in_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  lowest_rank_in_level?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tournament_levels_metadata" */
export type Tournament_Levels_Metadata_Mutation_Response = {
  __typename?: 'tournament_levels_metadata_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tournament_Levels_Metadata>;
};

/** input type for inserting object relation for remote table "tournament_levels_metadata" */
export type Tournament_Levels_Metadata_Obj_Rel_Insert_Input = {
  data: Tournament_Levels_Metadata_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tournament_Levels_Metadata_On_Conflict>;
};

/** on_conflict condition type for table "tournament_levels_metadata" */
export type Tournament_Levels_Metadata_On_Conflict = {
  constraint: Tournament_Levels_Metadata_Constraint;
  update_columns?: Array<Tournament_Levels_Metadata_Update_Column>;
  where?: InputMaybe<Tournament_Levels_Metadata_Bool_Exp>;
};

/** Ordering options when selecting data from "tournament_levels_metadata". */
export type Tournament_Levels_Metadata_Order_By = {
  created_at?: InputMaybe<Order_By>;
  has_user_completed_with_checkmate?: InputMaybe<Order_By>;
  highest_rank_in_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_checkpoint?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  lowest_rank_in_level?: InputMaybe<Order_By>;
  rooms_aggregate?: InputMaybe<Rooms_Aggregate_Order_By>;
  tournament_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tournament_levels_metadata */
export type Tournament_Levels_Metadata_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tournament_levels_metadata" */
export enum Tournament_Levels_Metadata_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HighestRankInLevel = 'highest_rank_in_level',
  /** column name */
  Id = 'id',
  /** column name */
  IsCheckpoint = 'is_checkpoint',
  /** column name */
  Level = 'level',
  /** column name */
  LowestRankInLevel = 'lowest_rank_in_level',
  /** column name */
  TournamentId = 'tournament_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "tournament_levels_metadata_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tournament_levels_metadata" */
export enum Tournament_Levels_Metadata_Select_Column_Tournament_Levels_Metadata_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsCheckpoint = 'is_checkpoint'
}

/** select "tournament_levels_metadata_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tournament_levels_metadata" */
export enum Tournament_Levels_Metadata_Select_Column_Tournament_Levels_Metadata_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsCheckpoint = 'is_checkpoint'
}

/** input type for updating data in table "tournament_levels_metadata" */
export type Tournament_Levels_Metadata_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  highest_rank_in_level?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_checkpoint?: InputMaybe<Scalars['Boolean']>;
  level?: InputMaybe<Scalars['Int']>;
  lowest_rank_in_level?: InputMaybe<Scalars['Int']>;
  tournament_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tournament_Levels_Metadata_Stddev_Fields = {
  __typename?: 'tournament_levels_metadata_stddev_fields';
  highest_rank_in_level?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  lowest_rank_in_level?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tournament_levels_metadata" */
export type Tournament_Levels_Metadata_Stddev_Order_By = {
  highest_rank_in_level?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  lowest_rank_in_level?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tournament_Levels_Metadata_Stddev_Pop_Fields = {
  __typename?: 'tournament_levels_metadata_stddev_pop_fields';
  highest_rank_in_level?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  lowest_rank_in_level?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tournament_levels_metadata" */
export type Tournament_Levels_Metadata_Stddev_Pop_Order_By = {
  highest_rank_in_level?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  lowest_rank_in_level?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tournament_Levels_Metadata_Stddev_Samp_Fields = {
  __typename?: 'tournament_levels_metadata_stddev_samp_fields';
  highest_rank_in_level?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  lowest_rank_in_level?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tournament_levels_metadata" */
export type Tournament_Levels_Metadata_Stddev_Samp_Order_By = {
  highest_rank_in_level?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  lowest_rank_in_level?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tournament_levels_metadata" */
export type Tournament_Levels_Metadata_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tournament_Levels_Metadata_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tournament_Levels_Metadata_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  highest_rank_in_level?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_checkpoint?: InputMaybe<Scalars['Boolean']>;
  level?: InputMaybe<Scalars['Int']>;
  lowest_rank_in_level?: InputMaybe<Scalars['Int']>;
  tournament_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Tournament_Levels_Metadata_Sum_Fields = {
  __typename?: 'tournament_levels_metadata_sum_fields';
  highest_rank_in_level?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  lowest_rank_in_level?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "tournament_levels_metadata" */
export type Tournament_Levels_Metadata_Sum_Order_By = {
  highest_rank_in_level?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  lowest_rank_in_level?: InputMaybe<Order_By>;
};

/** update columns of table "tournament_levels_metadata" */
export enum Tournament_Levels_Metadata_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HighestRankInLevel = 'highest_rank_in_level',
  /** column name */
  Id = 'id',
  /** column name */
  IsCheckpoint = 'is_checkpoint',
  /** column name */
  Level = 'level',
  /** column name */
  LowestRankInLevel = 'lowest_rank_in_level',
  /** column name */
  TournamentId = 'tournament_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Tournament_Levels_Metadata_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tournament_Levels_Metadata_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tournament_Levels_Metadata_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tournament_Levels_Metadata_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tournament_Levels_Metadata_Var_Pop_Fields = {
  __typename?: 'tournament_levels_metadata_var_pop_fields';
  highest_rank_in_level?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  lowest_rank_in_level?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tournament_levels_metadata" */
export type Tournament_Levels_Metadata_Var_Pop_Order_By = {
  highest_rank_in_level?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  lowest_rank_in_level?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tournament_Levels_Metadata_Var_Samp_Fields = {
  __typename?: 'tournament_levels_metadata_var_samp_fields';
  highest_rank_in_level?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  lowest_rank_in_level?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tournament_levels_metadata" */
export type Tournament_Levels_Metadata_Var_Samp_Order_By = {
  highest_rank_in_level?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  lowest_rank_in_level?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tournament_Levels_Metadata_Variance_Fields = {
  __typename?: 'tournament_levels_metadata_variance_fields';
  highest_rank_in_level?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  lowest_rank_in_level?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tournament_levels_metadata" */
export type Tournament_Levels_Metadata_Variance_Order_By = {
  highest_rank_in_level?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  lowest_rank_in_level?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Tournament_Max_Fields = {
  __typename?: 'tournament_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  number_of_levels?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Tournament_Min_Fields = {
  __typename?: 'tournament_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  number_of_levels?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "tournament" */
export type Tournament_Mutation_Response = {
  __typename?: 'tournament_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tournament>;
};

/** input type for inserting object relation for remote table "tournament" */
export type Tournament_Obj_Rel_Insert_Input = {
  data: Tournament_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tournament_On_Conflict>;
};

/** on_conflict condition type for table "tournament" */
export type Tournament_On_Conflict = {
  constraint: Tournament_Constraint;
  update_columns?: Array<Tournament_Update_Column>;
  where?: InputMaybe<Tournament_Bool_Exp>;
};

/** Ordering options when selecting data from "tournament". */
export type Tournament_Order_By = {
  created_at?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  has_user_made_an_entry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_rewards_distributed?: InputMaybe<Order_By>;
  number_of_levels?: InputMaybe<Order_By>;
  rooms_aggregate?: InputMaybe<Rooms_Aggregate_Order_By>;
  start_time?: InputMaybe<Order_By>;
  tournament_leaderboards_aggregate?: InputMaybe<Tournament_Leaderboard_Aggregate_Order_By>;
  tournament_levels_metadata_aggregate?: InputMaybe<Tournament_Levels_Metadata_Aggregate_Order_By>;
  tournament_participants_aggregate?: InputMaybe<Tournament_Participants_Aggregate_Order_By>;
  tournament_wallet_transactions_aggregate?: InputMaybe<Tournament_Wallet_Transactions_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** columns and relationships of "tournament_participants" */
export type Tournament_Participants = {
  __typename?: 'tournament_participants';
  /** An object relationship */
  all_auth_recipe_user: Supertokens_All_Auth_Recipe_Users;
  created_at: Scalars['timestamptz'];
  current_level: Scalars['Int'];
  highest_level: Scalars['Int'];
  id: Scalars['uuid'];
  is_completed: Scalars['Boolean'];
  number_of_checkmates: Scalars['Int'];
  participant_id: Scalars['String'];
  previous_level: Scalars['Int'];
  /** A computed field, executes function "get_participant_tournament_rank" */
  rank?: Maybe<Scalars['Int']>;
  remaining_retries: Scalars['Int'];
  show_next_level: Scalars['Boolean'];
  /** An object relationship */
  tournament: Tournament;
  /** An object relationship */
  tournamentLeaderboardByParticipantIdTournamentId?: Maybe<Tournament_Leaderboard>;
  tournament_id: Scalars['uuid'];
  /** An object relationship */
  tournament_leaderboard?: Maybe<Tournament_Leaderboard>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Supertokens_User_Additional_Fields>;
};

/** aggregated selection of "tournament_participants" */
export type Tournament_Participants_Aggregate = {
  __typename?: 'tournament_participants_aggregate';
  aggregate?: Maybe<Tournament_Participants_Aggregate_Fields>;
  nodes: Array<Tournament_Participants>;
};

export type Tournament_Participants_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tournament_Participants_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tournament_Participants_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tournament_Participants_Aggregate_Bool_Exp_Count>;
};

export type Tournament_Participants_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tournament_Participants_Select_Column_Tournament_Participants_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tournament_Participants_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tournament_Participants_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tournament_Participants_Select_Column_Tournament_Participants_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tournament_Participants_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tournament_Participants_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tournament_Participants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tournament_Participants_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tournament_participants" */
export type Tournament_Participants_Aggregate_Fields = {
  __typename?: 'tournament_participants_aggregate_fields';
  avg?: Maybe<Tournament_Participants_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tournament_Participants_Max_Fields>;
  min?: Maybe<Tournament_Participants_Min_Fields>;
  stddev?: Maybe<Tournament_Participants_Stddev_Fields>;
  stddev_pop?: Maybe<Tournament_Participants_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tournament_Participants_Stddev_Samp_Fields>;
  sum?: Maybe<Tournament_Participants_Sum_Fields>;
  var_pop?: Maybe<Tournament_Participants_Var_Pop_Fields>;
  var_samp?: Maybe<Tournament_Participants_Var_Samp_Fields>;
  variance?: Maybe<Tournament_Participants_Variance_Fields>;
};


/** aggregate fields of "tournament_participants" */
export type Tournament_Participants_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tournament_Participants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tournament_participants" */
export type Tournament_Participants_Aggregate_Order_By = {
  avg?: InputMaybe<Tournament_Participants_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tournament_Participants_Max_Order_By>;
  min?: InputMaybe<Tournament_Participants_Min_Order_By>;
  stddev?: InputMaybe<Tournament_Participants_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tournament_Participants_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tournament_Participants_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tournament_Participants_Sum_Order_By>;
  var_pop?: InputMaybe<Tournament_Participants_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tournament_Participants_Var_Samp_Order_By>;
  variance?: InputMaybe<Tournament_Participants_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tournament_participants" */
export type Tournament_Participants_Arr_Rel_Insert_Input = {
  data: Array<Tournament_Participants_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tournament_Participants_On_Conflict>;
};

/** aggregate avg on columns */
export type Tournament_Participants_Avg_Fields = {
  __typename?: 'tournament_participants_avg_fields';
  current_level?: Maybe<Scalars['Float']>;
  highest_level?: Maybe<Scalars['Float']>;
  number_of_checkmates?: Maybe<Scalars['Float']>;
  previous_level?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "get_participant_tournament_rank" */
  rank?: Maybe<Scalars['Int']>;
  remaining_retries?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tournament_participants" */
export type Tournament_Participants_Avg_Order_By = {
  current_level?: InputMaybe<Order_By>;
  highest_level?: InputMaybe<Order_By>;
  number_of_checkmates?: InputMaybe<Order_By>;
  previous_level?: InputMaybe<Order_By>;
  remaining_retries?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tournament_participants". All fields are combined with a logical 'AND'. */
export type Tournament_Participants_Bool_Exp = {
  _and?: InputMaybe<Array<Tournament_Participants_Bool_Exp>>;
  _not?: InputMaybe<Tournament_Participants_Bool_Exp>;
  _or?: InputMaybe<Array<Tournament_Participants_Bool_Exp>>;
  all_auth_recipe_user?: InputMaybe<Supertokens_All_Auth_Recipe_Users_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  current_level?: InputMaybe<Int_Comparison_Exp>;
  highest_level?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_completed?: InputMaybe<Boolean_Comparison_Exp>;
  number_of_checkmates?: InputMaybe<Int_Comparison_Exp>;
  participant_id?: InputMaybe<String_Comparison_Exp>;
  previous_level?: InputMaybe<Int_Comparison_Exp>;
  rank?: InputMaybe<Int_Comparison_Exp>;
  remaining_retries?: InputMaybe<Int_Comparison_Exp>;
  show_next_level?: InputMaybe<Boolean_Comparison_Exp>;
  tournament?: InputMaybe<Tournament_Bool_Exp>;
  tournamentLeaderboardByParticipantIdTournamentId?: InputMaybe<Tournament_Leaderboard_Bool_Exp>;
  tournament_id?: InputMaybe<Uuid_Comparison_Exp>;
  tournament_leaderboard?: InputMaybe<Tournament_Leaderboard_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Supertokens_User_Additional_Fields_Bool_Exp>;
};

/** unique or primary key constraints on table "tournament_participants" */
export enum Tournament_Participants_Constraint {
  /** unique or primary key constraint on columns "participant_id", "tournament_id" */
  TournamentParticipantsParticipantIdTournamentIdKey = 'tournament_participants_participant_id_tournament_id_key',
  /** unique or primary key constraint on columns "id" */
  TournamentParticipantsPkey = 'tournament_participants_pkey'
}

/** input type for incrementing numeric columns in table "tournament_participants" */
export type Tournament_Participants_Inc_Input = {
  current_level?: InputMaybe<Scalars['Int']>;
  highest_level?: InputMaybe<Scalars['Int']>;
  number_of_checkmates?: InputMaybe<Scalars['Int']>;
  previous_level?: InputMaybe<Scalars['Int']>;
  remaining_retries?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "tournament_participants" */
export type Tournament_Participants_Insert_Input = {
  all_auth_recipe_user?: InputMaybe<Supertokens_All_Auth_Recipe_Users_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_level?: InputMaybe<Scalars['Int']>;
  highest_level?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_completed?: InputMaybe<Scalars['Boolean']>;
  number_of_checkmates?: InputMaybe<Scalars['Int']>;
  participant_id?: InputMaybe<Scalars['String']>;
  previous_level?: InputMaybe<Scalars['Int']>;
  remaining_retries?: InputMaybe<Scalars['Int']>;
  show_next_level?: InputMaybe<Scalars['Boolean']>;
  tournament?: InputMaybe<Tournament_Obj_Rel_Insert_Input>;
  tournamentLeaderboardByParticipantIdTournamentId?: InputMaybe<Tournament_Leaderboard_Obj_Rel_Insert_Input>;
  tournament_id?: InputMaybe<Scalars['uuid']>;
  tournament_leaderboard?: InputMaybe<Tournament_Leaderboard_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Supertokens_User_Additional_Fields_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tournament_Participants_Max_Fields = {
  __typename?: 'tournament_participants_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  current_level?: Maybe<Scalars['Int']>;
  highest_level?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  number_of_checkmates?: Maybe<Scalars['Int']>;
  participant_id?: Maybe<Scalars['String']>;
  previous_level?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_participant_tournament_rank" */
  rank?: Maybe<Scalars['Int']>;
  remaining_retries?: Maybe<Scalars['Int']>;
  tournament_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tournament_participants" */
export type Tournament_Participants_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  current_level?: InputMaybe<Order_By>;
  highest_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_checkmates?: InputMaybe<Order_By>;
  participant_id?: InputMaybe<Order_By>;
  previous_level?: InputMaybe<Order_By>;
  remaining_retries?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tournament_Participants_Min_Fields = {
  __typename?: 'tournament_participants_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  current_level?: Maybe<Scalars['Int']>;
  highest_level?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  number_of_checkmates?: Maybe<Scalars['Int']>;
  participant_id?: Maybe<Scalars['String']>;
  previous_level?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_participant_tournament_rank" */
  rank?: Maybe<Scalars['Int']>;
  remaining_retries?: Maybe<Scalars['Int']>;
  tournament_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tournament_participants" */
export type Tournament_Participants_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  current_level?: InputMaybe<Order_By>;
  highest_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_checkmates?: InputMaybe<Order_By>;
  participant_id?: InputMaybe<Order_By>;
  previous_level?: InputMaybe<Order_By>;
  remaining_retries?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tournament_participants" */
export type Tournament_Participants_Mutation_Response = {
  __typename?: 'tournament_participants_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tournament_Participants>;
};

/** input type for inserting object relation for remote table "tournament_participants" */
export type Tournament_Participants_Obj_Rel_Insert_Input = {
  data: Tournament_Participants_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tournament_Participants_On_Conflict>;
};

/** on_conflict condition type for table "tournament_participants" */
export type Tournament_Participants_On_Conflict = {
  constraint: Tournament_Participants_Constraint;
  update_columns?: Array<Tournament_Participants_Update_Column>;
  where?: InputMaybe<Tournament_Participants_Bool_Exp>;
};

/** Ordering options when selecting data from "tournament_participants". */
export type Tournament_Participants_Order_By = {
  all_auth_recipe_user?: InputMaybe<Supertokens_All_Auth_Recipe_Users_Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_level?: InputMaybe<Order_By>;
  highest_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_completed?: InputMaybe<Order_By>;
  number_of_checkmates?: InputMaybe<Order_By>;
  participant_id?: InputMaybe<Order_By>;
  previous_level?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  remaining_retries?: InputMaybe<Order_By>;
  show_next_level?: InputMaybe<Order_By>;
  tournament?: InputMaybe<Tournament_Order_By>;
  tournamentLeaderboardByParticipantIdTournamentId?: InputMaybe<Tournament_Leaderboard_Order_By>;
  tournament_id?: InputMaybe<Order_By>;
  tournament_leaderboard?: InputMaybe<Tournament_Leaderboard_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Supertokens_User_Additional_Fields_Order_By>;
};

/** primary key columns input for table: tournament_participants */
export type Tournament_Participants_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tournament_participants" */
export enum Tournament_Participants_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentLevel = 'current_level',
  /** column name */
  HighestLevel = 'highest_level',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  NumberOfCheckmates = 'number_of_checkmates',
  /** column name */
  ParticipantId = 'participant_id',
  /** column name */
  PreviousLevel = 'previous_level',
  /** column name */
  RemainingRetries = 'remaining_retries',
  /** column name */
  ShowNextLevel = 'show_next_level',
  /** column name */
  TournamentId = 'tournament_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "tournament_participants_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tournament_participants" */
export enum Tournament_Participants_Select_Column_Tournament_Participants_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  ShowNextLevel = 'show_next_level'
}

/** select "tournament_participants_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tournament_participants" */
export enum Tournament_Participants_Select_Column_Tournament_Participants_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  ShowNextLevel = 'show_next_level'
}

/** input type for updating data in table "tournament_participants" */
export type Tournament_Participants_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_level?: InputMaybe<Scalars['Int']>;
  highest_level?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_completed?: InputMaybe<Scalars['Boolean']>;
  number_of_checkmates?: InputMaybe<Scalars['Int']>;
  participant_id?: InputMaybe<Scalars['String']>;
  previous_level?: InputMaybe<Scalars['Int']>;
  remaining_retries?: InputMaybe<Scalars['Int']>;
  show_next_level?: InputMaybe<Scalars['Boolean']>;
  tournament_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tournament_Participants_Stddev_Fields = {
  __typename?: 'tournament_participants_stddev_fields';
  current_level?: Maybe<Scalars['Float']>;
  highest_level?: Maybe<Scalars['Float']>;
  number_of_checkmates?: Maybe<Scalars['Float']>;
  previous_level?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "get_participant_tournament_rank" */
  rank?: Maybe<Scalars['Int']>;
  remaining_retries?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tournament_participants" */
export type Tournament_Participants_Stddev_Order_By = {
  current_level?: InputMaybe<Order_By>;
  highest_level?: InputMaybe<Order_By>;
  number_of_checkmates?: InputMaybe<Order_By>;
  previous_level?: InputMaybe<Order_By>;
  remaining_retries?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tournament_Participants_Stddev_Pop_Fields = {
  __typename?: 'tournament_participants_stddev_pop_fields';
  current_level?: Maybe<Scalars['Float']>;
  highest_level?: Maybe<Scalars['Float']>;
  number_of_checkmates?: Maybe<Scalars['Float']>;
  previous_level?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "get_participant_tournament_rank" */
  rank?: Maybe<Scalars['Int']>;
  remaining_retries?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tournament_participants" */
export type Tournament_Participants_Stddev_Pop_Order_By = {
  current_level?: InputMaybe<Order_By>;
  highest_level?: InputMaybe<Order_By>;
  number_of_checkmates?: InputMaybe<Order_By>;
  previous_level?: InputMaybe<Order_By>;
  remaining_retries?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tournament_Participants_Stddev_Samp_Fields = {
  __typename?: 'tournament_participants_stddev_samp_fields';
  current_level?: Maybe<Scalars['Float']>;
  highest_level?: Maybe<Scalars['Float']>;
  number_of_checkmates?: Maybe<Scalars['Float']>;
  previous_level?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "get_participant_tournament_rank" */
  rank?: Maybe<Scalars['Int']>;
  remaining_retries?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tournament_participants" */
export type Tournament_Participants_Stddev_Samp_Order_By = {
  current_level?: InputMaybe<Order_By>;
  highest_level?: InputMaybe<Order_By>;
  number_of_checkmates?: InputMaybe<Order_By>;
  previous_level?: InputMaybe<Order_By>;
  remaining_retries?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tournament_participants" */
export type Tournament_Participants_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tournament_Participants_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tournament_Participants_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_level?: InputMaybe<Scalars['Int']>;
  highest_level?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_completed?: InputMaybe<Scalars['Boolean']>;
  number_of_checkmates?: InputMaybe<Scalars['Int']>;
  participant_id?: InputMaybe<Scalars['String']>;
  previous_level?: InputMaybe<Scalars['Int']>;
  remaining_retries?: InputMaybe<Scalars['Int']>;
  show_next_level?: InputMaybe<Scalars['Boolean']>;
  tournament_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Tournament_Participants_Sum_Fields = {
  __typename?: 'tournament_participants_sum_fields';
  current_level?: Maybe<Scalars['Int']>;
  highest_level?: Maybe<Scalars['Int']>;
  number_of_checkmates?: Maybe<Scalars['Int']>;
  previous_level?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_participant_tournament_rank" */
  rank?: Maybe<Scalars['Int']>;
  remaining_retries?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "tournament_participants" */
export type Tournament_Participants_Sum_Order_By = {
  current_level?: InputMaybe<Order_By>;
  highest_level?: InputMaybe<Order_By>;
  number_of_checkmates?: InputMaybe<Order_By>;
  previous_level?: InputMaybe<Order_By>;
  remaining_retries?: InputMaybe<Order_By>;
};

/** update columns of table "tournament_participants" */
export enum Tournament_Participants_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentLevel = 'current_level',
  /** column name */
  HighestLevel = 'highest_level',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  NumberOfCheckmates = 'number_of_checkmates',
  /** column name */
  ParticipantId = 'participant_id',
  /** column name */
  PreviousLevel = 'previous_level',
  /** column name */
  RemainingRetries = 'remaining_retries',
  /** column name */
  ShowNextLevel = 'show_next_level',
  /** column name */
  TournamentId = 'tournament_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Tournament_Participants_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tournament_Participants_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tournament_Participants_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tournament_Participants_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tournament_Participants_Var_Pop_Fields = {
  __typename?: 'tournament_participants_var_pop_fields';
  current_level?: Maybe<Scalars['Float']>;
  highest_level?: Maybe<Scalars['Float']>;
  number_of_checkmates?: Maybe<Scalars['Float']>;
  previous_level?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "get_participant_tournament_rank" */
  rank?: Maybe<Scalars['Int']>;
  remaining_retries?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tournament_participants" */
export type Tournament_Participants_Var_Pop_Order_By = {
  current_level?: InputMaybe<Order_By>;
  highest_level?: InputMaybe<Order_By>;
  number_of_checkmates?: InputMaybe<Order_By>;
  previous_level?: InputMaybe<Order_By>;
  remaining_retries?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tournament_Participants_Var_Samp_Fields = {
  __typename?: 'tournament_participants_var_samp_fields';
  current_level?: Maybe<Scalars['Float']>;
  highest_level?: Maybe<Scalars['Float']>;
  number_of_checkmates?: Maybe<Scalars['Float']>;
  previous_level?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "get_participant_tournament_rank" */
  rank?: Maybe<Scalars['Int']>;
  remaining_retries?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tournament_participants" */
export type Tournament_Participants_Var_Samp_Order_By = {
  current_level?: InputMaybe<Order_By>;
  highest_level?: InputMaybe<Order_By>;
  number_of_checkmates?: InputMaybe<Order_By>;
  previous_level?: InputMaybe<Order_By>;
  remaining_retries?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tournament_Participants_Variance_Fields = {
  __typename?: 'tournament_participants_variance_fields';
  current_level?: Maybe<Scalars['Float']>;
  highest_level?: Maybe<Scalars['Float']>;
  number_of_checkmates?: Maybe<Scalars['Float']>;
  previous_level?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "get_participant_tournament_rank" */
  rank?: Maybe<Scalars['Int']>;
  remaining_retries?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tournament_participants" */
export type Tournament_Participants_Variance_Order_By = {
  current_level?: InputMaybe<Order_By>;
  highest_level?: InputMaybe<Order_By>;
  number_of_checkmates?: InputMaybe<Order_By>;
  previous_level?: InputMaybe<Order_By>;
  remaining_retries?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tournament */
export type Tournament_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tournament" */
export enum Tournament_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsRewardsDistributed = 'is_rewards_distributed',
  /** column name */
  NumberOfLevels = 'number_of_levels',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tournament" */
export type Tournament_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_rewards_distributed?: InputMaybe<Scalars['Boolean']>;
  number_of_levels?: InputMaybe<Scalars['Int']>;
  start_time?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tournament_Stddev_Fields = {
  __typename?: 'tournament_stddev_fields';
  number_of_levels?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tournament_Stddev_Pop_Fields = {
  __typename?: 'tournament_stddev_pop_fields';
  number_of_levels?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tournament_Stddev_Samp_Fields = {
  __typename?: 'tournament_stddev_samp_fields';
  number_of_levels?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "tournament" */
export type Tournament_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tournament_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tournament_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_rewards_distributed?: InputMaybe<Scalars['Boolean']>;
  number_of_levels?: InputMaybe<Scalars['Int']>;
  start_time?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Tournament_Sum_Fields = {
  __typename?: 'tournament_sum_fields';
  number_of_levels?: Maybe<Scalars['Int']>;
};

/** update columns of table "tournament" */
export enum Tournament_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsRewardsDistributed = 'is_rewards_distributed',
  /** column name */
  NumberOfLevels = 'number_of_levels',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Tournament_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tournament_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tournament_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tournament_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tournament_Var_Pop_Fields = {
  __typename?: 'tournament_var_pop_fields';
  number_of_levels?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tournament_Var_Samp_Fields = {
  __typename?: 'tournament_var_samp_fields';
  number_of_levels?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tournament_Variance_Fields = {
  __typename?: 'tournament_variance_fields';
  number_of_levels?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "tournament_wallet_transactions" */
export type Tournament_Wallet_Transactions = {
  __typename?: 'tournament_wallet_transactions';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  tournament: Tournament;
  tournament_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  wallet_transaction: Wallet_Transactions;
  wallet_transaction_id: Scalars['uuid'];
};

/** aggregated selection of "tournament_wallet_transactions" */
export type Tournament_Wallet_Transactions_Aggregate = {
  __typename?: 'tournament_wallet_transactions_aggregate';
  aggregate?: Maybe<Tournament_Wallet_Transactions_Aggregate_Fields>;
  nodes: Array<Tournament_Wallet_Transactions>;
};

export type Tournament_Wallet_Transactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tournament_Wallet_Transactions_Aggregate_Bool_Exp_Count>;
};

export type Tournament_Wallet_Transactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tournament_Wallet_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tournament_Wallet_Transactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tournament_wallet_transactions" */
export type Tournament_Wallet_Transactions_Aggregate_Fields = {
  __typename?: 'tournament_wallet_transactions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tournament_Wallet_Transactions_Max_Fields>;
  min?: Maybe<Tournament_Wallet_Transactions_Min_Fields>;
};


/** aggregate fields of "tournament_wallet_transactions" */
export type Tournament_Wallet_Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tournament_Wallet_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tournament_wallet_transactions" */
export type Tournament_Wallet_Transactions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tournament_Wallet_Transactions_Max_Order_By>;
  min?: InputMaybe<Tournament_Wallet_Transactions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tournament_wallet_transactions" */
export type Tournament_Wallet_Transactions_Arr_Rel_Insert_Input = {
  data: Array<Tournament_Wallet_Transactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tournament_Wallet_Transactions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tournament_wallet_transactions". All fields are combined with a logical 'AND'. */
export type Tournament_Wallet_Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<Tournament_Wallet_Transactions_Bool_Exp>>;
  _not?: InputMaybe<Tournament_Wallet_Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<Tournament_Wallet_Transactions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  tournament?: InputMaybe<Tournament_Bool_Exp>;
  tournament_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  wallet_transaction?: InputMaybe<Wallet_Transactions_Bool_Exp>;
  wallet_transaction_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "tournament_wallet_transactions" */
export enum Tournament_Wallet_Transactions_Constraint {
  /** unique or primary key constraint on columns "wallet_transaction_id", "tournament_id" */
  TournamentWalletTransactionTournamentIdWalletTransactKey = 'tournament_wallet_transaction_tournament_id_wallet_transact_key',
  /** unique or primary key constraint on columns "id" */
  TournamentWalletTransactionsPkey = 'tournament_wallet_transactions_pkey'
}

/** input type for inserting data into table "tournament_wallet_transactions" */
export type Tournament_Wallet_Transactions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  tournament?: InputMaybe<Tournament_Obj_Rel_Insert_Input>;
  tournament_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wallet_transaction?: InputMaybe<Wallet_Transactions_Obj_Rel_Insert_Input>;
  wallet_transaction_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Tournament_Wallet_Transactions_Max_Fields = {
  __typename?: 'tournament_wallet_transactions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  tournament_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wallet_transaction_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "tournament_wallet_transactions" */
export type Tournament_Wallet_Transactions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wallet_transaction_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tournament_Wallet_Transactions_Min_Fields = {
  __typename?: 'tournament_wallet_transactions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  tournament_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wallet_transaction_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "tournament_wallet_transactions" */
export type Tournament_Wallet_Transactions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wallet_transaction_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tournament_wallet_transactions" */
export type Tournament_Wallet_Transactions_Mutation_Response = {
  __typename?: 'tournament_wallet_transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tournament_Wallet_Transactions>;
};

/** on_conflict condition type for table "tournament_wallet_transactions" */
export type Tournament_Wallet_Transactions_On_Conflict = {
  constraint: Tournament_Wallet_Transactions_Constraint;
  update_columns?: Array<Tournament_Wallet_Transactions_Update_Column>;
  where?: InputMaybe<Tournament_Wallet_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "tournament_wallet_transactions". */
export type Tournament_Wallet_Transactions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tournament?: InputMaybe<Tournament_Order_By>;
  tournament_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wallet_transaction?: InputMaybe<Wallet_Transactions_Order_By>;
  wallet_transaction_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tournament_wallet_transactions */
export type Tournament_Wallet_Transactions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tournament_wallet_transactions" */
export enum Tournament_Wallet_Transactions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TournamentId = 'tournament_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WalletTransactionId = 'wallet_transaction_id'
}

/** input type for updating data in table "tournament_wallet_transactions" */
export type Tournament_Wallet_Transactions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  tournament_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wallet_transaction_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "tournament_wallet_transactions" */
export type Tournament_Wallet_Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tournament_Wallet_Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tournament_Wallet_Transactions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  tournament_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wallet_transaction_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "tournament_wallet_transactions" */
export enum Tournament_Wallet_Transactions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TournamentId = 'tournament_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WalletTransactionId = 'wallet_transaction_id'
}

export type Tournament_Wallet_Transactions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tournament_Wallet_Transactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tournament_Wallet_Transactions_Bool_Exp;
};

/** columns and relationships of "upi_details" */
export type Upi_Details = {
  __typename?: 'upi_details';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  upi_id: Scalars['String'];
  upi_username: Scalars['String'];
  /** An object relationship */
  user: Supertokens_User_Additional_Fields;
  user_id: Scalars['String'];
};

/** aggregated selection of "upi_details" */
export type Upi_Details_Aggregate = {
  __typename?: 'upi_details_aggregate';
  aggregate?: Maybe<Upi_Details_Aggregate_Fields>;
  nodes: Array<Upi_Details>;
};

export type Upi_Details_Aggregate_Bool_Exp = {
  count?: InputMaybe<Upi_Details_Aggregate_Bool_Exp_Count>;
};

export type Upi_Details_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Upi_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Upi_Details_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "upi_details" */
export type Upi_Details_Aggregate_Fields = {
  __typename?: 'upi_details_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Upi_Details_Max_Fields>;
  min?: Maybe<Upi_Details_Min_Fields>;
};


/** aggregate fields of "upi_details" */
export type Upi_Details_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Upi_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "upi_details" */
export type Upi_Details_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Upi_Details_Max_Order_By>;
  min?: InputMaybe<Upi_Details_Min_Order_By>;
};

/** input type for inserting array relation for remote table "upi_details" */
export type Upi_Details_Arr_Rel_Insert_Input = {
  data: Array<Upi_Details_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Upi_Details_On_Conflict>;
};

/** Boolean expression to filter rows from the table "upi_details". All fields are combined with a logical 'AND'. */
export type Upi_Details_Bool_Exp = {
  _and?: InputMaybe<Array<Upi_Details_Bool_Exp>>;
  _not?: InputMaybe<Upi_Details_Bool_Exp>;
  _or?: InputMaybe<Array<Upi_Details_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  upi_id?: InputMaybe<String_Comparison_Exp>;
  upi_username?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Supertokens_User_Additional_Fields_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "upi_details" */
export enum Upi_Details_Constraint {
  /** unique or primary key constraint on columns "id" */
  UpiDetailsPkey = 'upi_details_pkey'
}

/** input type for inserting data into table "upi_details" */
export type Upi_Details_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  upi_id?: InputMaybe<Scalars['String']>;
  upi_username?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Supertokens_User_Additional_Fields_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Upi_Details_Max_Fields = {
  __typename?: 'upi_details_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  upi_id?: Maybe<Scalars['String']>;
  upi_username?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "upi_details" */
export type Upi_Details_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upi_id?: InputMaybe<Order_By>;
  upi_username?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Upi_Details_Min_Fields = {
  __typename?: 'upi_details_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  upi_id?: Maybe<Scalars['String']>;
  upi_username?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "upi_details" */
export type Upi_Details_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upi_id?: InputMaybe<Order_By>;
  upi_username?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "upi_details" */
export type Upi_Details_Mutation_Response = {
  __typename?: 'upi_details_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Upi_Details>;
};

/** on_conflict condition type for table "upi_details" */
export type Upi_Details_On_Conflict = {
  constraint: Upi_Details_Constraint;
  update_columns?: Array<Upi_Details_Update_Column>;
  where?: InputMaybe<Upi_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "upi_details". */
export type Upi_Details_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upi_id?: InputMaybe<Order_By>;
  upi_username?: InputMaybe<Order_By>;
  user?: InputMaybe<Supertokens_User_Additional_Fields_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: upi_details */
export type Upi_Details_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "upi_details" */
export enum Upi_Details_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpiId = 'upi_id',
  /** column name */
  UpiUsername = 'upi_username',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "upi_details" */
export type Upi_Details_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  upi_id?: InputMaybe<Scalars['String']>;
  upi_username?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "upi_details" */
export type Upi_Details_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Upi_Details_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Upi_Details_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  upi_id?: InputMaybe<Scalars['String']>;
  upi_username?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "upi_details" */
export enum Upi_Details_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpiId = 'upi_id',
  /** column name */
  UpiUsername = 'upi_username',
  /** column name */
  UserId = 'user_id'
}

export type Upi_Details_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Upi_Details_Set_Input>;
  /** filter the rows which have to be updated */
  where: Upi_Details_Bool_Exp;
};

/** columns and relationships of "user_room_pings" */
export type User_Room_Pings = {
  __typename?: 'user_room_pings';
  created_at: Scalars['timestamp'];
  id: Scalars['uuid'];
  last_ping_timestamp: Scalars['timestamptz'];
  /** An object relationship */
  room_participant: Room_Participants;
  room_participant_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "user_room_pings" */
export type User_Room_Pings_Aggregate = {
  __typename?: 'user_room_pings_aggregate';
  aggregate?: Maybe<User_Room_Pings_Aggregate_Fields>;
  nodes: Array<User_Room_Pings>;
};

/** aggregate fields of "user_room_pings" */
export type User_Room_Pings_Aggregate_Fields = {
  __typename?: 'user_room_pings_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Room_Pings_Max_Fields>;
  min?: Maybe<User_Room_Pings_Min_Fields>;
};


/** aggregate fields of "user_room_pings" */
export type User_Room_Pings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Room_Pings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_room_pings". All fields are combined with a logical 'AND'. */
export type User_Room_Pings_Bool_Exp = {
  _and?: InputMaybe<Array<User_Room_Pings_Bool_Exp>>;
  _not?: InputMaybe<User_Room_Pings_Bool_Exp>;
  _or?: InputMaybe<Array<User_Room_Pings_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_ping_timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  room_participant?: InputMaybe<Room_Participants_Bool_Exp>;
  room_participant_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_room_pings" */
export enum User_Room_Pings_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserRoomPingsPkey = 'user_room_pings_pkey',
  /** unique or primary key constraint on columns "room_participant_id" */
  UserRoomPingsRoomParticipantIdKey = 'user_room_pings_room_participant_id_key'
}

/** input type for inserting data into table "user_room_pings" */
export type User_Room_Pings_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_ping_timestamp?: InputMaybe<Scalars['timestamptz']>;
  room_participant?: InputMaybe<Room_Participants_Obj_Rel_Insert_Input>;
  room_participant_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type User_Room_Pings_Max_Fields = {
  __typename?: 'user_room_pings_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  last_ping_timestamp?: Maybe<Scalars['timestamptz']>;
  room_participant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type User_Room_Pings_Min_Fields = {
  __typename?: 'user_room_pings_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  last_ping_timestamp?: Maybe<Scalars['timestamptz']>;
  room_participant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "user_room_pings" */
export type User_Room_Pings_Mutation_Response = {
  __typename?: 'user_room_pings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Room_Pings>;
};

/** input type for inserting object relation for remote table "user_room_pings" */
export type User_Room_Pings_Obj_Rel_Insert_Input = {
  data: User_Room_Pings_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Room_Pings_On_Conflict>;
};

/** on_conflict condition type for table "user_room_pings" */
export type User_Room_Pings_On_Conflict = {
  constraint: User_Room_Pings_Constraint;
  update_columns?: Array<User_Room_Pings_Update_Column>;
  where?: InputMaybe<User_Room_Pings_Bool_Exp>;
};

/** Ordering options when selecting data from "user_room_pings". */
export type User_Room_Pings_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_ping_timestamp?: InputMaybe<Order_By>;
  room_participant?: InputMaybe<Room_Participants_Order_By>;
  room_participant_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_room_pings */
export type User_Room_Pings_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_room_pings" */
export enum User_Room_Pings_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastPingTimestamp = 'last_ping_timestamp',
  /** column name */
  RoomParticipantId = 'room_participant_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "user_room_pings" */
export type User_Room_Pings_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_ping_timestamp?: InputMaybe<Scalars['timestamptz']>;
  room_participant_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "user_room_pings" */
export type User_Room_Pings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Room_Pings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Room_Pings_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_ping_timestamp?: InputMaybe<Scalars['timestamptz']>;
  room_participant_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "user_room_pings" */
export enum User_Room_Pings_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastPingTimestamp = 'last_ping_timestamp',
  /** column name */
  RoomParticipantId = 'room_participant_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type User_Room_Pings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Room_Pings_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Room_Pings_Bool_Exp;
};

export type Users = {
  __typename?: 'users';
  id: Scalars['uuid'];
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "wallet_transaction_statuses" */
export type Wallet_Transaction_Statuses = {
  __typename?: 'wallet_transaction_statuses';
  status: Scalars['String'];
  /** An array relationship */
  wallet_transactions: Array<Wallet_Transactions>;
  /** An aggregate relationship */
  wallet_transactions_aggregate: Wallet_Transactions_Aggregate;
};


/** columns and relationships of "wallet_transaction_statuses" */
export type Wallet_Transaction_StatusesWallet_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Wallet_Transactions_Bool_Exp>;
};


/** columns and relationships of "wallet_transaction_statuses" */
export type Wallet_Transaction_StatusesWallet_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Wallet_Transactions_Bool_Exp>;
};

/** aggregated selection of "wallet_transaction_statuses" */
export type Wallet_Transaction_Statuses_Aggregate = {
  __typename?: 'wallet_transaction_statuses_aggregate';
  aggregate?: Maybe<Wallet_Transaction_Statuses_Aggregate_Fields>;
  nodes: Array<Wallet_Transaction_Statuses>;
};

/** aggregate fields of "wallet_transaction_statuses" */
export type Wallet_Transaction_Statuses_Aggregate_Fields = {
  __typename?: 'wallet_transaction_statuses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wallet_Transaction_Statuses_Max_Fields>;
  min?: Maybe<Wallet_Transaction_Statuses_Min_Fields>;
};


/** aggregate fields of "wallet_transaction_statuses" */
export type Wallet_Transaction_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wallet_Transaction_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "wallet_transaction_statuses". All fields are combined with a logical 'AND'. */
export type Wallet_Transaction_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Wallet_Transaction_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Wallet_Transaction_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Wallet_Transaction_Statuses_Bool_Exp>>;
  status?: InputMaybe<String_Comparison_Exp>;
  wallet_transactions?: InputMaybe<Wallet_Transactions_Bool_Exp>;
  wallet_transactions_aggregate?: InputMaybe<Wallet_Transactions_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "wallet_transaction_statuses" */
export enum Wallet_Transaction_Statuses_Constraint {
  /** unique or primary key constraint on columns "status" */
  WalletTransactionStatusesPkey = 'wallet_transaction_statuses_pkey'
}

export enum Wallet_Transaction_Statuses_Enum {
  Processing = 'processing',
  Success = 'success'
}

/** Boolean expression to compare columns of type "wallet_transaction_statuses_enum". All fields are combined with logical 'AND'. */
export type Wallet_Transaction_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Wallet_Transaction_Statuses_Enum>;
  _in?: InputMaybe<Array<Wallet_Transaction_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Wallet_Transaction_Statuses_Enum>;
  _nin?: InputMaybe<Array<Wallet_Transaction_Statuses_Enum>>;
};

/** input type for inserting data into table "wallet_transaction_statuses" */
export type Wallet_Transaction_Statuses_Insert_Input = {
  status?: InputMaybe<Scalars['String']>;
  wallet_transactions?: InputMaybe<Wallet_Transactions_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Wallet_Transaction_Statuses_Max_Fields = {
  __typename?: 'wallet_transaction_statuses_max_fields';
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wallet_Transaction_Statuses_Min_Fields = {
  __typename?: 'wallet_transaction_statuses_min_fields';
  status?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "wallet_transaction_statuses" */
export type Wallet_Transaction_Statuses_Mutation_Response = {
  __typename?: 'wallet_transaction_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wallet_Transaction_Statuses>;
};

/** input type for inserting object relation for remote table "wallet_transaction_statuses" */
export type Wallet_Transaction_Statuses_Obj_Rel_Insert_Input = {
  data: Wallet_Transaction_Statuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wallet_Transaction_Statuses_On_Conflict>;
};

/** on_conflict condition type for table "wallet_transaction_statuses" */
export type Wallet_Transaction_Statuses_On_Conflict = {
  constraint: Wallet_Transaction_Statuses_Constraint;
  update_columns?: Array<Wallet_Transaction_Statuses_Update_Column>;
  where?: InputMaybe<Wallet_Transaction_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "wallet_transaction_statuses". */
export type Wallet_Transaction_Statuses_Order_By = {
  status?: InputMaybe<Order_By>;
  wallet_transactions_aggregate?: InputMaybe<Wallet_Transactions_Aggregate_Order_By>;
};

/** primary key columns input for table: wallet_transaction_statuses */
export type Wallet_Transaction_Statuses_Pk_Columns_Input = {
  status: Scalars['String'];
};

/** select columns of table "wallet_transaction_statuses" */
export enum Wallet_Transaction_Statuses_Select_Column {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "wallet_transaction_statuses" */
export type Wallet_Transaction_Statuses_Set_Input = {
  status?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "wallet_transaction_statuses" */
export type Wallet_Transaction_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wallet_Transaction_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wallet_Transaction_Statuses_Stream_Cursor_Value_Input = {
  status?: InputMaybe<Scalars['String']>;
};

/** update columns of table "wallet_transaction_statuses" */
export enum Wallet_Transaction_Statuses_Update_Column {
  /** column name */
  Status = 'status'
}

export type Wallet_Transaction_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wallet_Transaction_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wallet_Transaction_Statuses_Bool_Exp;
};

/** columns and relationships of "wallet_transaction_type" */
export type Wallet_Transaction_Type = {
  __typename?: 'wallet_transaction_type';
  type: Scalars['String'];
  /** An array relationship */
  wallet_transactions: Array<Wallet_Transactions>;
  /** An aggregate relationship */
  wallet_transactions_aggregate: Wallet_Transactions_Aggregate;
};


/** columns and relationships of "wallet_transaction_type" */
export type Wallet_Transaction_TypeWallet_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Wallet_Transactions_Bool_Exp>;
};


/** columns and relationships of "wallet_transaction_type" */
export type Wallet_Transaction_TypeWallet_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Wallet_Transactions_Bool_Exp>;
};

/** aggregated selection of "wallet_transaction_type" */
export type Wallet_Transaction_Type_Aggregate = {
  __typename?: 'wallet_transaction_type_aggregate';
  aggregate?: Maybe<Wallet_Transaction_Type_Aggregate_Fields>;
  nodes: Array<Wallet_Transaction_Type>;
};

/** aggregate fields of "wallet_transaction_type" */
export type Wallet_Transaction_Type_Aggregate_Fields = {
  __typename?: 'wallet_transaction_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wallet_Transaction_Type_Max_Fields>;
  min?: Maybe<Wallet_Transaction_Type_Min_Fields>;
};


/** aggregate fields of "wallet_transaction_type" */
export type Wallet_Transaction_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wallet_Transaction_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "wallet_transaction_type". All fields are combined with a logical 'AND'. */
export type Wallet_Transaction_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Wallet_Transaction_Type_Bool_Exp>>;
  _not?: InputMaybe<Wallet_Transaction_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Wallet_Transaction_Type_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
  wallet_transactions?: InputMaybe<Wallet_Transactions_Bool_Exp>;
  wallet_transactions_aggregate?: InputMaybe<Wallet_Transactions_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "wallet_transaction_type" */
export enum Wallet_Transaction_Type_Constraint {
  /** unique or primary key constraint on columns "type" */
  WalletTransactionTypePkey = 'wallet_transaction_type_pkey'
}

export enum Wallet_Transaction_Type_Enum {
  Bonus = 'bonus',
  Deposit = 'deposit',
  EntryFees = 'entry_fees',
  Gst = 'gst',
  Refund = 'refund',
  Tds = 'tds',
  TournamentWinnings = 'tournament_winnings',
  Winning = 'winning',
  Withdrawal = 'withdrawal'
}

/** Boolean expression to compare columns of type "wallet_transaction_type_enum". All fields are combined with logical 'AND'. */
export type Wallet_Transaction_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Wallet_Transaction_Type_Enum>;
  _in?: InputMaybe<Array<Wallet_Transaction_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Wallet_Transaction_Type_Enum>;
  _nin?: InputMaybe<Array<Wallet_Transaction_Type_Enum>>;
};

/** input type for inserting data into table "wallet_transaction_type" */
export type Wallet_Transaction_Type_Insert_Input = {
  type?: InputMaybe<Scalars['String']>;
  wallet_transactions?: InputMaybe<Wallet_Transactions_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Wallet_Transaction_Type_Max_Fields = {
  __typename?: 'wallet_transaction_type_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wallet_Transaction_Type_Min_Fields = {
  __typename?: 'wallet_transaction_type_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "wallet_transaction_type" */
export type Wallet_Transaction_Type_Mutation_Response = {
  __typename?: 'wallet_transaction_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wallet_Transaction_Type>;
};

/** input type for inserting object relation for remote table "wallet_transaction_type" */
export type Wallet_Transaction_Type_Obj_Rel_Insert_Input = {
  data: Wallet_Transaction_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wallet_Transaction_Type_On_Conflict>;
};

/** on_conflict condition type for table "wallet_transaction_type" */
export type Wallet_Transaction_Type_On_Conflict = {
  constraint: Wallet_Transaction_Type_Constraint;
  update_columns?: Array<Wallet_Transaction_Type_Update_Column>;
  where?: InputMaybe<Wallet_Transaction_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "wallet_transaction_type". */
export type Wallet_Transaction_Type_Order_By = {
  type?: InputMaybe<Order_By>;
  wallet_transactions_aggregate?: InputMaybe<Wallet_Transactions_Aggregate_Order_By>;
};

/** primary key columns input for table: wallet_transaction_type */
export type Wallet_Transaction_Type_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "wallet_transaction_type" */
export enum Wallet_Transaction_Type_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "wallet_transaction_type" */
export type Wallet_Transaction_Type_Set_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "wallet_transaction_type" */
export type Wallet_Transaction_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wallet_Transaction_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wallet_Transaction_Type_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "wallet_transaction_type" */
export enum Wallet_Transaction_Type_Update_Column {
  /** column name */
  Type = 'type'
}

export type Wallet_Transaction_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wallet_Transaction_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wallet_Transaction_Type_Bool_Exp;
};

/** columns and relationships of "wallet_transactions" */
export type Wallet_Transactions = {
  __typename?: 'wallet_transactions';
  amount: Scalars['float8'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  pan_card_details: Array<Pan_Card_Details>;
  /** An aggregate relationship */
  pan_card_details_aggregate: Pan_Card_Details_Aggregate;
  /** An object relationship */
  related_wallet_transaction?: Maybe<Wallet_Transactions>;
  related_wallet_transaction_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  room_wallet_transactions: Array<Room_Wallet_Transactions>;
  /** An aggregate relationship */
  room_wallet_transactions_aggregate: Room_Wallet_Transactions_Aggregate;
  status: Wallet_Transaction_Statuses_Enum;
  /** An array relationship */
  tournament_wallet_transactions: Array<Tournament_Wallet_Transactions>;
  /** An aggregate relationship */
  tournament_wallet_transactions_aggregate: Tournament_Wallet_Transactions_Aggregate;
  type: Wallet_Transaction_Type_Enum;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Supertokens_User_Public_Fields>;
  user_id: Scalars['String'];
  /** An object relationship */
  wallet_transaction_status: Wallet_Transaction_Statuses;
  /** An object relationship */
  wallet_transaction_type: Wallet_Transaction_Type;
  /** An array relationship */
  wallet_transactions: Array<Wallet_Transactions>;
  /** An aggregate relationship */
  wallet_transactions_aggregate: Wallet_Transactions_Aggregate;
};


/** columns and relationships of "wallet_transactions" */
export type Wallet_TransactionsPan_Card_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Pan_Card_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pan_Card_Details_Order_By>>;
  where?: InputMaybe<Pan_Card_Details_Bool_Exp>;
};


/** columns and relationships of "wallet_transactions" */
export type Wallet_TransactionsPan_Card_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pan_Card_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pan_Card_Details_Order_By>>;
  where?: InputMaybe<Pan_Card_Details_Bool_Exp>;
};


/** columns and relationships of "wallet_transactions" */
export type Wallet_TransactionsRoom_Wallet_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Room_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Room_Wallet_Transactions_Bool_Exp>;
};


/** columns and relationships of "wallet_transactions" */
export type Wallet_TransactionsRoom_Wallet_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Room_Wallet_Transactions_Bool_Exp>;
};


/** columns and relationships of "wallet_transactions" */
export type Wallet_TransactionsTournament_Wallet_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Tournament_Wallet_Transactions_Bool_Exp>;
};


/** columns and relationships of "wallet_transactions" */
export type Wallet_TransactionsTournament_Wallet_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournament_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tournament_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Tournament_Wallet_Transactions_Bool_Exp>;
};


/** columns and relationships of "wallet_transactions" */
export type Wallet_TransactionsWallet_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Wallet_Transactions_Bool_Exp>;
};


/** columns and relationships of "wallet_transactions" */
export type Wallet_TransactionsWallet_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Wallet_Transactions_Bool_Exp>;
};

/** aggregated selection of "wallet_transactions" */
export type Wallet_Transactions_Aggregate = {
  __typename?: 'wallet_transactions_aggregate';
  aggregate?: Maybe<Wallet_Transactions_Aggregate_Fields>;
  nodes: Array<Wallet_Transactions>;
};

export type Wallet_Transactions_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Wallet_Transactions_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Wallet_Transactions_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Wallet_Transactions_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Wallet_Transactions_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Wallet_Transactions_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Wallet_Transactions_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Wallet_Transactions_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Wallet_Transactions_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Wallet_Transactions_Aggregate_Bool_Exp_Var_Samp>;
};

export type Wallet_Transactions_Aggregate_Bool_Exp_Avg = {
  arguments: Wallet_Transactions_Select_Column_Wallet_Transactions_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wallet_Transactions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Wallet_Transactions_Aggregate_Bool_Exp_Corr = {
  arguments: Wallet_Transactions_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wallet_Transactions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Wallet_Transactions_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Wallet_Transactions_Select_Column_Wallet_Transactions_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Wallet_Transactions_Select_Column_Wallet_Transactions_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Wallet_Transactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Wallet_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wallet_Transactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Wallet_Transactions_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Wallet_Transactions_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wallet_Transactions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Wallet_Transactions_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Wallet_Transactions_Select_Column_Wallet_Transactions_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Wallet_Transactions_Select_Column_Wallet_Transactions_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Wallet_Transactions_Aggregate_Bool_Exp_Max = {
  arguments: Wallet_Transactions_Select_Column_Wallet_Transactions_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wallet_Transactions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Wallet_Transactions_Aggregate_Bool_Exp_Min = {
  arguments: Wallet_Transactions_Select_Column_Wallet_Transactions_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wallet_Transactions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Wallet_Transactions_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Wallet_Transactions_Select_Column_Wallet_Transactions_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wallet_Transactions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Wallet_Transactions_Aggregate_Bool_Exp_Sum = {
  arguments: Wallet_Transactions_Select_Column_Wallet_Transactions_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wallet_Transactions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Wallet_Transactions_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Wallet_Transactions_Select_Column_Wallet_Transactions_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Wallet_Transactions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "wallet_transactions" */
export type Wallet_Transactions_Aggregate_Fields = {
  __typename?: 'wallet_transactions_aggregate_fields';
  avg?: Maybe<Wallet_Transactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wallet_Transactions_Max_Fields>;
  min?: Maybe<Wallet_Transactions_Min_Fields>;
  stddev?: Maybe<Wallet_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Wallet_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wallet_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Wallet_Transactions_Sum_Fields>;
  var_pop?: Maybe<Wallet_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Wallet_Transactions_Var_Samp_Fields>;
  variance?: Maybe<Wallet_Transactions_Variance_Fields>;
};


/** aggregate fields of "wallet_transactions" */
export type Wallet_Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wallet_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "wallet_transactions" */
export type Wallet_Transactions_Aggregate_Order_By = {
  avg?: InputMaybe<Wallet_Transactions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Wallet_Transactions_Max_Order_By>;
  min?: InputMaybe<Wallet_Transactions_Min_Order_By>;
  stddev?: InputMaybe<Wallet_Transactions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Wallet_Transactions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Wallet_Transactions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Wallet_Transactions_Sum_Order_By>;
  var_pop?: InputMaybe<Wallet_Transactions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Wallet_Transactions_Var_Samp_Order_By>;
  variance?: InputMaybe<Wallet_Transactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "wallet_transactions" */
export type Wallet_Transactions_Arr_Rel_Insert_Input = {
  data: Array<Wallet_Transactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Wallet_Transactions_On_Conflict>;
};

/** aggregate avg on columns */
export type Wallet_Transactions_Avg_Fields = {
  __typename?: 'wallet_transactions_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "wallet_transactions" */
export type Wallet_Transactions_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "wallet_transactions". All fields are combined with a logical 'AND'. */
export type Wallet_Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<Wallet_Transactions_Bool_Exp>>;
  _not?: InputMaybe<Wallet_Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<Wallet_Transactions_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  pan_card_details?: InputMaybe<Pan_Card_Details_Bool_Exp>;
  pan_card_details_aggregate?: InputMaybe<Pan_Card_Details_Aggregate_Bool_Exp>;
  related_wallet_transaction?: InputMaybe<Wallet_Transactions_Bool_Exp>;
  related_wallet_transaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  room_wallet_transactions?: InputMaybe<Room_Wallet_Transactions_Bool_Exp>;
  room_wallet_transactions_aggregate?: InputMaybe<Room_Wallet_Transactions_Aggregate_Bool_Exp>;
  status?: InputMaybe<Wallet_Transaction_Statuses_Enum_Comparison_Exp>;
  tournament_wallet_transactions?: InputMaybe<Tournament_Wallet_Transactions_Bool_Exp>;
  tournament_wallet_transactions_aggregate?: InputMaybe<Tournament_Wallet_Transactions_Aggregate_Bool_Exp>;
  type?: InputMaybe<Wallet_Transaction_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Supertokens_User_Public_Fields_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  wallet_transaction_status?: InputMaybe<Wallet_Transaction_Statuses_Bool_Exp>;
  wallet_transaction_type?: InputMaybe<Wallet_Transaction_Type_Bool_Exp>;
  wallet_transactions?: InputMaybe<Wallet_Transactions_Bool_Exp>;
  wallet_transactions_aggregate?: InputMaybe<Wallet_Transactions_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "wallet_transactions" */
export enum Wallet_Transactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  WalletTransactionsPkey = 'wallet_transactions_pkey'
}

/** input type for incrementing numeric columns in table "wallet_transactions" */
export type Wallet_Transactions_Inc_Input = {
  amount?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "wallet_transactions" */
export type Wallet_Transactions_Insert_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  pan_card_details?: InputMaybe<Pan_Card_Details_Arr_Rel_Insert_Input>;
  related_wallet_transaction?: InputMaybe<Wallet_Transactions_Obj_Rel_Insert_Input>;
  related_wallet_transaction_id?: InputMaybe<Scalars['uuid']>;
  room_wallet_transactions?: InputMaybe<Room_Wallet_Transactions_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Wallet_Transaction_Statuses_Enum>;
  tournament_wallet_transactions?: InputMaybe<Tournament_Wallet_Transactions_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Wallet_Transaction_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Supertokens_User_Public_Fields_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
  wallet_transaction_status?: InputMaybe<Wallet_Transaction_Statuses_Obj_Rel_Insert_Input>;
  wallet_transaction_type?: InputMaybe<Wallet_Transaction_Type_Obj_Rel_Insert_Input>;
  wallet_transactions?: InputMaybe<Wallet_Transactions_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Wallet_Transactions_Max_Fields = {
  __typename?: 'wallet_transactions_max_fields';
  amount?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  related_wallet_transaction_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "wallet_transactions" */
export type Wallet_Transactions_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  related_wallet_transaction_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Wallet_Transactions_Min_Fields = {
  __typename?: 'wallet_transactions_min_fields';
  amount?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  related_wallet_transaction_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "wallet_transactions" */
export type Wallet_Transactions_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  related_wallet_transaction_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "wallet_transactions" */
export type Wallet_Transactions_Mutation_Response = {
  __typename?: 'wallet_transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wallet_Transactions>;
};

/** input type for inserting object relation for remote table "wallet_transactions" */
export type Wallet_Transactions_Obj_Rel_Insert_Input = {
  data: Wallet_Transactions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wallet_Transactions_On_Conflict>;
};

/** on_conflict condition type for table "wallet_transactions" */
export type Wallet_Transactions_On_Conflict = {
  constraint: Wallet_Transactions_Constraint;
  update_columns?: Array<Wallet_Transactions_Update_Column>;
  where?: InputMaybe<Wallet_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "wallet_transactions". */
export type Wallet_Transactions_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pan_card_details_aggregate?: InputMaybe<Pan_Card_Details_Aggregate_Order_By>;
  related_wallet_transaction?: InputMaybe<Wallet_Transactions_Order_By>;
  related_wallet_transaction_id?: InputMaybe<Order_By>;
  room_wallet_transactions_aggregate?: InputMaybe<Room_Wallet_Transactions_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  tournament_wallet_transactions_aggregate?: InputMaybe<Tournament_Wallet_Transactions_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Supertokens_User_Public_Fields_Order_By>;
  user_id?: InputMaybe<Order_By>;
  wallet_transaction_status?: InputMaybe<Wallet_Transaction_Statuses_Order_By>;
  wallet_transaction_type?: InputMaybe<Wallet_Transaction_Type_Order_By>;
  wallet_transactions_aggregate?: InputMaybe<Wallet_Transactions_Aggregate_Order_By>;
};

/** primary key columns input for table: wallet_transactions */
export type Wallet_Transactions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "wallet_transactions" */
export enum Wallet_Transactions_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RelatedWalletTransactionId = 'related_wallet_transaction_id',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "wallet_transactions_aggregate_bool_exp_avg_arguments_columns" columns of table "wallet_transactions" */
export enum Wallet_Transactions_Select_Column_Wallet_Transactions_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "wallet_transactions_aggregate_bool_exp_corr_arguments_columns" columns of table "wallet_transactions" */
export enum Wallet_Transactions_Select_Column_Wallet_Transactions_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "wallet_transactions_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "wallet_transactions" */
export enum Wallet_Transactions_Select_Column_Wallet_Transactions_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "wallet_transactions_aggregate_bool_exp_max_arguments_columns" columns of table "wallet_transactions" */
export enum Wallet_Transactions_Select_Column_Wallet_Transactions_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "wallet_transactions_aggregate_bool_exp_min_arguments_columns" columns of table "wallet_transactions" */
export enum Wallet_Transactions_Select_Column_Wallet_Transactions_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "wallet_transactions_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "wallet_transactions" */
export enum Wallet_Transactions_Select_Column_Wallet_Transactions_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "wallet_transactions_aggregate_bool_exp_sum_arguments_columns" columns of table "wallet_transactions" */
export enum Wallet_Transactions_Select_Column_Wallet_Transactions_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "wallet_transactions_aggregate_bool_exp_var_samp_arguments_columns" columns of table "wallet_transactions" */
export enum Wallet_Transactions_Select_Column_Wallet_Transactions_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** input type for updating data in table "wallet_transactions" */
export type Wallet_Transactions_Set_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  related_wallet_transaction_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Wallet_Transaction_Statuses_Enum>;
  type?: InputMaybe<Wallet_Transaction_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Wallet_Transactions_Stddev_Fields = {
  __typename?: 'wallet_transactions_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "wallet_transactions" */
export type Wallet_Transactions_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wallet_Transactions_Stddev_Pop_Fields = {
  __typename?: 'wallet_transactions_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "wallet_transactions" */
export type Wallet_Transactions_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wallet_Transactions_Stddev_Samp_Fields = {
  __typename?: 'wallet_transactions_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "wallet_transactions" */
export type Wallet_Transactions_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "wallet_transactions" */
export type Wallet_Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wallet_Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wallet_Transactions_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  related_wallet_transaction_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Wallet_Transaction_Statuses_Enum>;
  type?: InputMaybe<Wallet_Transaction_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Wallet_Transactions_Sum_Fields = {
  __typename?: 'wallet_transactions_sum_fields';
  amount?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "wallet_transactions" */
export type Wallet_Transactions_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** update columns of table "wallet_transactions" */
export enum Wallet_Transactions_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RelatedWalletTransactionId = 'related_wallet_transaction_id',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Wallet_Transactions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wallet_Transactions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wallet_Transactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wallet_Transactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wallet_Transactions_Var_Pop_Fields = {
  __typename?: 'wallet_transactions_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "wallet_transactions" */
export type Wallet_Transactions_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wallet_Transactions_Var_Samp_Fields = {
  __typename?: 'wallet_transactions_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "wallet_transactions" */
export type Wallet_Transactions_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Wallet_Transactions_Variance_Fields = {
  __typename?: 'wallet_transactions_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "wallet_transactions" */
export type Wallet_Transactions_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** columns and relationships of "withdrawal_requests" */
export type Withdrawal_Requests = {
  __typename?: 'withdrawal_requests';
  amount: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  pan_upi_details: Array<Pan_Card_Details>;
  /** An aggregate relationship */
  pan_upi_details_aggregate: Pan_Card_Details_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user_additional_field: Supertokens_User_Additional_Fields;
  user_id: Scalars['String'];
};


/** columns and relationships of "withdrawal_requests" */
export type Withdrawal_RequestsPan_Upi_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Pan_Card_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pan_Card_Details_Order_By>>;
  where?: InputMaybe<Pan_Card_Details_Bool_Exp>;
};


/** columns and relationships of "withdrawal_requests" */
export type Withdrawal_RequestsPan_Upi_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pan_Card_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pan_Card_Details_Order_By>>;
  where?: InputMaybe<Pan_Card_Details_Bool_Exp>;
};

/** aggregated selection of "withdrawal_requests" */
export type Withdrawal_Requests_Aggregate = {
  __typename?: 'withdrawal_requests_aggregate';
  aggregate?: Maybe<Withdrawal_Requests_Aggregate_Fields>;
  nodes: Array<Withdrawal_Requests>;
};

/** aggregate fields of "withdrawal_requests" */
export type Withdrawal_Requests_Aggregate_Fields = {
  __typename?: 'withdrawal_requests_aggregate_fields';
  avg?: Maybe<Withdrawal_Requests_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Withdrawal_Requests_Max_Fields>;
  min?: Maybe<Withdrawal_Requests_Min_Fields>;
  stddev?: Maybe<Withdrawal_Requests_Stddev_Fields>;
  stddev_pop?: Maybe<Withdrawal_Requests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Withdrawal_Requests_Stddev_Samp_Fields>;
  sum?: Maybe<Withdrawal_Requests_Sum_Fields>;
  var_pop?: Maybe<Withdrawal_Requests_Var_Pop_Fields>;
  var_samp?: Maybe<Withdrawal_Requests_Var_Samp_Fields>;
  variance?: Maybe<Withdrawal_Requests_Variance_Fields>;
};


/** aggregate fields of "withdrawal_requests" */
export type Withdrawal_Requests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Withdrawal_Requests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Withdrawal_Requests_Avg_Fields = {
  __typename?: 'withdrawal_requests_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "withdrawal_requests". All fields are combined with a logical 'AND'. */
export type Withdrawal_Requests_Bool_Exp = {
  _and?: InputMaybe<Array<Withdrawal_Requests_Bool_Exp>>;
  _not?: InputMaybe<Withdrawal_Requests_Bool_Exp>;
  _or?: InputMaybe<Array<Withdrawal_Requests_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  pan_upi_details?: InputMaybe<Pan_Card_Details_Bool_Exp>;
  pan_upi_details_aggregate?: InputMaybe<Pan_Card_Details_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_additional_field?: InputMaybe<Supertokens_User_Additional_Fields_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "withdrawal_requests" */
export enum Withdrawal_Requests_Constraint {
  /** unique or primary key constraint on columns "id" */
  WithdrawalRequestsPkey = 'withdrawal_requests_pkey'
}

/** input type for incrementing numeric columns in table "withdrawal_requests" */
export type Withdrawal_Requests_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "withdrawal_requests" */
export type Withdrawal_Requests_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  pan_upi_details?: InputMaybe<Pan_Card_Details_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_additional_field?: InputMaybe<Supertokens_User_Additional_Fields_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Withdrawal_Requests_Max_Fields = {
  __typename?: 'withdrawal_requests_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Withdrawal_Requests_Min_Fields = {
  __typename?: 'withdrawal_requests_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "withdrawal_requests" */
export type Withdrawal_Requests_Mutation_Response = {
  __typename?: 'withdrawal_requests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Withdrawal_Requests>;
};

/** on_conflict condition type for table "withdrawal_requests" */
export type Withdrawal_Requests_On_Conflict = {
  constraint: Withdrawal_Requests_Constraint;
  update_columns?: Array<Withdrawal_Requests_Update_Column>;
  where?: InputMaybe<Withdrawal_Requests_Bool_Exp>;
};

/** Ordering options when selecting data from "withdrawal_requests". */
export type Withdrawal_Requests_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pan_upi_details_aggregate?: InputMaybe<Pan_Card_Details_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_additional_field?: InputMaybe<Supertokens_User_Additional_Fields_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: withdrawal_requests */
export type Withdrawal_Requests_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "withdrawal_requests" */
export enum Withdrawal_Requests_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "withdrawal_requests" */
export type Withdrawal_Requests_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Withdrawal_Requests_Stddev_Fields = {
  __typename?: 'withdrawal_requests_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Withdrawal_Requests_Stddev_Pop_Fields = {
  __typename?: 'withdrawal_requests_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Withdrawal_Requests_Stddev_Samp_Fields = {
  __typename?: 'withdrawal_requests_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "withdrawal_requests" */
export type Withdrawal_Requests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Withdrawal_Requests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Withdrawal_Requests_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Withdrawal_Requests_Sum_Fields = {
  __typename?: 'withdrawal_requests_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
};

/** update columns of table "withdrawal_requests" */
export enum Withdrawal_Requests_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Withdrawal_Requests_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Withdrawal_Requests_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Withdrawal_Requests_Set_Input>;
  /** filter the rows which have to be updated */
  where: Withdrawal_Requests_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Withdrawal_Requests_Var_Pop_Fields = {
  __typename?: 'withdrawal_requests_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Withdrawal_Requests_Var_Samp_Fields = {
  __typename?: 'withdrawal_requests_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Withdrawal_Requests_Variance_Fields = {
  __typename?: 'withdrawal_requests_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

export type EndSinglePlayerGameRoomWithWinner__Admin_ActionMutationVariables = Exact<{
  roomID: Scalars['uuid'];
  winner: Scalars['String'];
  isDraw: Scalars['Boolean'];
  isCheckmate: Scalars['Boolean'];
}>;


export type EndSinglePlayerGameRoomWithWinner__Admin_ActionMutation = { __typename?: 'Mutation', endSinglePlayerGameRoomWithWinner: { __typename?: 'GenericResponse', success: boolean, message: string } };

export type GetUserAdditionalInfoSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetUserAdditionalInfoSubscription = { __typename?: 'Subscription', supertokens_user_additional_fields: Array<{ __typename?: 'supertokens_user_additional_fields', wallet_balance?: any | null, name: string, is_user_activated: boolean, is_tournament_enabled?: boolean | null, is_admin?: boolean | null, onboard_current_game?: string | null, user_type?: string | null, user_id: string }> };

export type CreateAnonymousJwtMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateAnonymousJwtMutation = { __typename?: 'Mutation', createAnonymousJWT: string };

export type UpdateNameMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type UpdateNameMutation = { __typename?: 'Mutation', update_supertokens_user_additional_fields?: { __typename?: 'supertokens_user_additional_fields_mutation_response', returning: Array<{ __typename?: 'supertokens_user_additional_fields', name: string }> } | null };

export type CreateTournamentRoomMutationVariables = Exact<{
  tournamentID: Scalars['uuid'];
  isSinglePlayer: Scalars['Boolean'];
}>;


export type CreateTournamentRoomMutation = { __typename?: 'Mutation', createNewRoom: { __typename?: 'rooms', id?: any | null } };

export type CreateSinglePlayerRoomMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateSinglePlayerRoomMutation = { __typename?: 'Mutation', insert_rooms_one?: { __typename?: 'rooms', id?: any | null } | null };

export type CreateNewRoomMutationVariables = Exact<{
  is_single_player_game: Scalars['Boolean'];
}>;


export type CreateNewRoomMutation = { __typename?: 'Mutation', insert_rooms_one?: { __typename?: 'rooms', id?: any | null } | null };

export type CreateBattleModeRoomMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateBattleModeRoomMutation = { __typename?: 'Mutation', createBattleModeRoom: { __typename?: 'rooms', id?: any | null } };

export type AddPlayersToRoomMutationVariables = Exact<{
  participantsData: Array<Room_Participants_Insert_Input> | Room_Participants_Insert_Input;
}>;


export type AddPlayersToRoomMutation = { __typename?: 'Mutation', insert_room_participants?: { __typename?: 'room_participants_mutation_response', affected_rows: number } | null };

export type CreateOnBoardingGame2RoomMutationVariables = Exact<{
  setOnboardData?: InputMaybe<Supertokens_User_Additional_Fields_Set_Input>;
}>;


export type CreateOnBoardingGame2RoomMutation = { __typename?: 'Mutation', update_supertokens_user_additional_fields?: { __typename?: 'supertokens_user_additional_fields_mutation_response', affected_rows: number } | null, createOnBoardingGame2Room: { __typename?: 'rooms', id?: any | null } };

export type MakeAMoveMutationVariables = Exact<{
  sourceSquare: Scalars['String'];
  targetSquare: Scalars['String'];
  roomParticipantID: Scalars['uuid'];
  promotion?: InputMaybe<Scalars['String']>;
}>;


export type MakeAMoveMutation = { __typename?: 'Mutation', insert_room_moves_one?: { __typename?: 'room_moves', id: any, room_moves_room_participant: { __typename?: 'room_participants', id: any, moves: Array<{ __typename?: 'room_moves', source_square?: string | null, target_square?: string | null, updated_at: any, is_timer_expired: boolean }> } } | null };

export type PlayAgain__CreateReplayRoomMutationVariables = Exact<{
  roomID: Scalars['uuid'];
}>;


export type PlayAgain__CreateReplayRoomMutation = { __typename?: 'Mutation', createReplayRoom: { __typename?: 'rooms', id?: any | null } };

export type ChoosingSides__ChooseRandomSideMutationVariables = Exact<{
  roomID: Scalars['uuid'];
}>;


export type ChoosingSides__ChooseRandomSideMutation = { __typename?: 'Mutation', chooseRandomSide: { __typename?: 'rooms', id?: any | null, is_toss_completed: boolean, initial_fen: string, creator_id: string, updated_at: any, replay_room_id?: any | null, is_play_with_friends_game_start_button_clicked: boolean, is_single_player_game: boolean, tournament_level?: number | null, tournament_id?: any | null, winner_id?: any | null, is_game_over: boolean, is_draw?: boolean | null, win_margin?: number | null, is_checkmate?: boolean | null, is_onboarding_game?: boolean | null, game_start_time?: any | null, retry_level_metadata?: { __typename?: 'tournament_levels_metadata', level: number, is_checkpoint: boolean } | null, tournament?: { __typename?: 'tournament', end_time: any, tournament_participants: Array<{ __typename?: 'tournament_participants', remaining_retries: number }> } | null, room_wallet_transactions: Array<{ __typename?: 'room_wallet_transactions', wallet_transaction: { __typename?: 'wallet_transactions', amount: any } }>, parent_room?: { __typename?: 'rooms', id?: any | null } | null, participants: Array<{ __typename?: 'room_participants', id: any, participant_id: string, side?: string | null, is_toss_winner?: boolean | null, did_choose_side?: boolean | null, did_choose_whether_to_start_first: boolean, name: string, is_bot: boolean, is_current_user?: boolean | null, did_reach_daily_limit_for_rewards?: boolean | null, moves: Array<{ __typename?: 'room_moves', source_square?: string | null, target_square?: string | null, created_at: any, is_timer_expired: boolean, promotion: string }>, participant_additional_fields?: { __typename?: 'supertokens_user_additional_fields', is_admin?: boolean | null } | null }> } };

export type GetParticipantsAdditionalFieldsSubscriptionVariables = Exact<{
  roomID: Scalars['uuid'];
}>;


export type GetParticipantsAdditionalFieldsSubscription = { __typename?: 'Subscription', rooms_by_pk?: { __typename?: 'rooms', participants: Array<{ __typename?: 'room_participants', is_current_user?: boolean | null, elo_rating: number, participant_additional_fields?: { __typename?: 'supertokens_user_additional_fields', elo_rating: number } | null, moves: Array<{ __typename?: 'room_moves', room_participant_id: any, cp_score?: number | null, move_rank?: number | null }> }> } | null };

export type StartGame__StartGameButtonClickedMutationVariables = Exact<{
  roomID: Scalars['uuid'];
}>;


export type StartGame__StartGameButtonClickedMutation = { __typename?: 'Mutation', update_rooms_by_pk?: { __typename?: 'rooms', is_play_with_friends_game_start_button_clicked: boolean } | null };

export type MakeAMoveWithTimerExpiredMutationVariables = Exact<{
  roomParticipantID: Scalars['uuid'];
}>;


export type MakeAMoveWithTimerExpiredMutation = { __typename?: 'Mutation', insert_room_moves_one?: { __typename?: 'room_moves', id: any } | null };

export type GetTournamentLeaderboardDetailsSubscriptionVariables = Exact<{
  tournamentID: Scalars['uuid'];
}>;


export type GetTournamentLeaderboardDetailsSubscription = { __typename?: 'Subscription', tournament_leaderboard: Array<{ __typename?: 'tournament_leaderboard', id: any, rank: number, winnings?: any | null, level?: number | null, participantID: string, numberOfCheckmates?: number | null, user?: { __typename?: 'supertokens_user_public_fields', name?: string | null, profile_picture?: string | null } | null }> };

export type GetTournamentProgressInfoSubscriptionVariables = Exact<{
  tournamentID: Scalars['uuid'];
}>;


export type GetTournamentProgressInfoSubscription = { __typename?: 'Subscription', tournament_by_pk?: { __typename?: 'tournament', start_time: any, end_time: any, id: any, is_active: boolean, has_user_made_an_entry?: boolean | null, is_rewards_distributed: boolean, currentUserInfo: Array<{ __typename?: 'tournament_participants', created_at: any, current_level: number, previous_level: number, is_completed: boolean, highest_level: number, id: any, participant_id: string, tournament_id: any, updated_at: any, show_next_level: boolean, remaining_retries: number, rank?: number | null }>, tournamentLevels: Array<{ __typename?: 'tournament_levels_metadata', level: number, is_checkpoint: boolean, lowest_rank_in_level?: number | null, highest_rank_in_level?: number | null, has_user_completed_with_checkmate?: boolean | null }> } | null };

export type CheckCheckPointLevelQueryVariables = Exact<{
  level: Scalars['Int'];
  tournament_id: Scalars['uuid'];
}>;


export type CheckCheckPointLevelQuery = { __typename?: 'Query', tournament_levels_metadata: Array<{ __typename?: 'tournament_levels_metadata', id: any }> };

export type UpdateTournamentParticipantMutationVariables = Exact<{
  tournamentID: Scalars['uuid'];
  previous_level: Scalars['Int'];
}>;


export type UpdateTournamentParticipantMutation = { __typename?: 'Mutation', update_tournament_participants?: { __typename?: 'tournament_participants_mutation_response', returning: Array<{ __typename?: 'tournament_participants', id: any }> } | null };

export type GetWalletTransactionsSubscriptionVariables = Exact<{
  whereCondition?: InputMaybe<Wallet_Transactions_Bool_Exp>;
}>;


export type GetWalletTransactionsSubscription = { __typename?: 'Subscription', wallet_transactions: Array<{ __typename?: 'wallet_transactions', id: any, type: Wallet_Transaction_Type_Enum, amount: any, created_at: any, status: Wallet_Transaction_Statuses_Enum, tournament_wallet_transactions: Array<{ __typename?: 'tournament_wallet_transactions', tournament: { __typename?: 'tournament', end_time: any } }> }> };

export type WalletTransactionsDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type WalletTransactionsDetailsQuery = { __typename?: 'Query', wallet_transactions: Array<{ __typename?: 'wallet_transactions', amount: any, id: any, type: Wallet_Transaction_Type_Enum, user_id: string, updated_at: any, created_at: any }> };

export type CheckPanValidationMutationVariables = Exact<{
  panNumber: Scalars['String'];
}>;


export type CheckPanValidationMutation = { __typename?: 'Mutation', checkPanValidation: { __typename?: 'GenericResponse', message: string, success: boolean } };

export type AddUpiDetailsMutationVariables = Exact<{
  upiID: Scalars['String'];
}>;


export type AddUpiDetailsMutation = { __typename?: 'Mutation', addUpiDetails: { __typename?: 'GenericResponse', message: string, success: boolean } };

export type MakeCashWithdrawRequestMutationVariables = Exact<{
  amount: Scalars['Int'];
}>;


export type MakeCashWithdrawRequestMutation = { __typename?: 'Mutation', makeCashWithdrawRequest: { __typename?: 'GenericResponse', success: boolean, message: string } };

export type GetPanAndUpiDetailsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetPanAndUpiDetailsSubscription = { __typename?: 'Subscription', user: Array<{ __typename?: 'supertokens_user_additional_fields', panCardDetails: Array<{ __typename?: 'pan_card_details', pan_name: string, pan_number: string, created_at: any, upi_details: Array<{ __typename?: 'upi_details', id: any }> }>, upiDetails: Array<{ __typename?: 'upi_details', upi_username: string, upi_id: string, created_at: any }> }> };

export type RoomDetailsFragmentFragment = { __typename?: 'rooms', id?: any | null, is_toss_completed: boolean, initial_fen: string, creator_id: string, updated_at: any, replay_room_id?: any | null, is_play_with_friends_game_start_button_clicked: boolean, is_single_player_game: boolean, tournament_level?: number | null, tournament_id?: any | null, winner_id?: any | null, is_game_over: boolean, is_draw?: boolean | null, win_margin?: number | null, is_checkmate?: boolean | null, is_onboarding_game?: boolean | null, game_start_time?: any | null, retry_level_metadata?: { __typename?: 'tournament_levels_metadata', level: number, is_checkpoint: boolean } | null, tournament?: { __typename?: 'tournament', end_time: any, tournament_participants: Array<{ __typename?: 'tournament_participants', remaining_retries: number }> } | null, room_wallet_transactions: Array<{ __typename?: 'room_wallet_transactions', wallet_transaction: { __typename?: 'wallet_transactions', amount: any } }>, parent_room?: { __typename?: 'rooms', id?: any | null } | null, participants: Array<{ __typename?: 'room_participants', id: any, participant_id: string, side?: string | null, is_toss_winner?: boolean | null, did_choose_side?: boolean | null, did_choose_whether_to_start_first: boolean, name: string, is_bot: boolean, is_current_user?: boolean | null, did_reach_daily_limit_for_rewards?: boolean | null, moves: Array<{ __typename?: 'room_moves', source_square?: string | null, target_square?: string | null, created_at: any, is_timer_expired: boolean, promotion: string }>, participant_additional_fields?: { __typename?: 'supertokens_user_additional_fields', is_admin?: boolean | null } | null }> };

export type GetRoomDetailsSubscriptionVariables = Exact<{
  roomID: Scalars['uuid'];
}>;


export type GetRoomDetailsSubscription = { __typename?: 'Subscription', rooms_by_pk?: { __typename?: 'rooms', id?: any | null, is_toss_completed: boolean, initial_fen: string, creator_id: string, updated_at: any, replay_room_id?: any | null, is_play_with_friends_game_start_button_clicked: boolean, is_single_player_game: boolean, tournament_level?: number | null, tournament_id?: any | null, winner_id?: any | null, is_game_over: boolean, is_draw?: boolean | null, win_margin?: number | null, is_checkmate?: boolean | null, is_onboarding_game?: boolean | null, game_start_time?: any | null, retry_level_metadata?: { __typename?: 'tournament_levels_metadata', level: number, is_checkpoint: boolean } | null, tournament?: { __typename?: 'tournament', end_time: any, tournament_participants: Array<{ __typename?: 'tournament_participants', remaining_retries: number }> } | null, room_wallet_transactions: Array<{ __typename?: 'room_wallet_transactions', wallet_transaction: { __typename?: 'wallet_transactions', amount: any } }>, parent_room?: { __typename?: 'rooms', id?: any | null } | null, participants: Array<{ __typename?: 'room_participants', id: any, participant_id: string, side?: string | null, is_toss_winner?: boolean | null, did_choose_side?: boolean | null, did_choose_whether_to_start_first: boolean, name: string, is_bot: boolean, is_current_user?: boolean | null, did_reach_daily_limit_for_rewards?: boolean | null, moves: Array<{ __typename?: 'room_moves', source_square?: string | null, target_square?: string | null, created_at: any, is_timer_expired: boolean, promotion: string }>, participant_additional_fields?: { __typename?: 'supertokens_user_additional_fields', is_admin?: boolean | null } | null }> } | null };

export type EndGameRoomMutationVariables = Exact<{
  roomID: Scalars['uuid'];
}>;


export type EndGameRoomMutation = { __typename?: 'Mutation', endGameRoom: { __typename?: 'GenericResponse', success: boolean, message: string } };

export type StartOnboardingGameMutationVariables = Exact<{
  roomID: Scalars['uuid'];
}>;


export type StartOnboardingGameMutation = { __typename?: 'Mutation', startOnboardingGame: { __typename?: 'GenericResponse', success: boolean, message: string } };

export type GetLatestTournamentQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLatestTournamentQuery = { __typename?: 'Query', tournament: Array<{ __typename?: 'tournament', id: any, start_time: any, end_time: any, tournament_participants: Array<{ __typename?: 'tournament_participants', current_level: number, rank?: number | null, remaining_retries: number }> }>, next_tournament: Array<{ __typename?: 'tournament', id: any, start_time: any }>, tournamentWithRewardsDistributed: Array<{ __typename?: 'tournament', id: any, start_time: any, end_time: any }> };

export const RoomDetailsFragmentFragmentDoc = gql`
    fragment RoomDetailsFragment on rooms {
  id
  is_toss_completed
  initial_fen
  creator_id
  updated_at
  replay_room_id
  is_play_with_friends_game_start_button_clicked
  is_single_player_game
  tournament_level
  tournament_id
  winner_id
  is_game_over
  is_draw
  win_margin
  is_checkmate
  is_onboarding_game
  game_start_time
  retry_level_metadata {
    level
    is_checkpoint
  }
  tournament {
    end_time
    tournament_participants {
      remaining_retries
    }
  }
  room_wallet_transactions {
    wallet_transaction {
      amount
    }
  }
  parent_room {
    id
  }
  participants {
    id
    participant_id
    side
    is_toss_winner
    did_choose_side
    did_choose_whether_to_start_first
    name
    is_bot
    is_current_user
    did_reach_daily_limit_for_rewards
    moves(order_by: {created_at: asc}) {
      source_square
      target_square
      created_at
      is_timer_expired
      promotion
    }
    participant_additional_fields {
      is_admin
    }
  }
}
    `;
export const EndSinglePlayerGameRoomWithWinner__Admin_ActionDocument = gql`
    mutation endSinglePlayerGameRoomWithWinner__Admin_Action($roomID: uuid!, $winner: String!, $isDraw: Boolean!, $isCheckmate: Boolean!) {
  endSinglePlayerGameRoomWithWinner(
    roomID: $roomID
    winner: $winner
    is_draw: $isDraw
    is_checkmate: $isCheckmate
  ) {
    success
    message
  }
}
    `;
export type EndSinglePlayerGameRoomWithWinner__Admin_ActionMutationFn = Apollo.MutationFunction<EndSinglePlayerGameRoomWithWinner__Admin_ActionMutation, EndSinglePlayerGameRoomWithWinner__Admin_ActionMutationVariables>;

/**
 * __useEndSinglePlayerGameRoomWithWinner__Admin_ActionMutation__
 *
 * To run a mutation, you first call `useEndSinglePlayerGameRoomWithWinner__Admin_ActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndSinglePlayerGameRoomWithWinner__Admin_ActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endSinglePlayerGameRoomWithWinnerAdminActionMutation, { data, loading, error }] = useEndSinglePlayerGameRoomWithWinner__Admin_ActionMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *      winner: // value for 'winner'
 *      isDraw: // value for 'isDraw'
 *      isCheckmate: // value for 'isCheckmate'
 *   },
 * });
 */
export function useEndSinglePlayerGameRoomWithWinner__Admin_ActionMutation(baseOptions?: Apollo.MutationHookOptions<EndSinglePlayerGameRoomWithWinner__Admin_ActionMutation, EndSinglePlayerGameRoomWithWinner__Admin_ActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndSinglePlayerGameRoomWithWinner__Admin_ActionMutation, EndSinglePlayerGameRoomWithWinner__Admin_ActionMutationVariables>(EndSinglePlayerGameRoomWithWinner__Admin_ActionDocument, options);
      }
export type EndSinglePlayerGameRoomWithWinner__Admin_ActionMutationHookResult = ReturnType<typeof useEndSinglePlayerGameRoomWithWinner__Admin_ActionMutation>;
export type EndSinglePlayerGameRoomWithWinner__Admin_ActionMutationResult = Apollo.MutationResult<EndSinglePlayerGameRoomWithWinner__Admin_ActionMutation>;
export type EndSinglePlayerGameRoomWithWinner__Admin_ActionMutationOptions = Apollo.BaseMutationOptions<EndSinglePlayerGameRoomWithWinner__Admin_ActionMutation, EndSinglePlayerGameRoomWithWinner__Admin_ActionMutationVariables>;
export const GetUserAdditionalInfoDocument = gql`
    subscription GetUserAdditionalInfo {
  supertokens_user_additional_fields {
    wallet_balance
    name
    is_user_activated
    is_tournament_enabled
    is_admin
    onboard_current_game
    user_type
    user_id
  }
}
    `;

/**
 * __useGetUserAdditionalInfoSubscription__
 *
 * To run a query within a React component, call `useGetUserAdditionalInfoSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAdditionalInfoSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAdditionalInfoSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetUserAdditionalInfoSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetUserAdditionalInfoSubscription, GetUserAdditionalInfoSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetUserAdditionalInfoSubscription, GetUserAdditionalInfoSubscriptionVariables>(GetUserAdditionalInfoDocument, options);
      }
export type GetUserAdditionalInfoSubscriptionHookResult = ReturnType<typeof useGetUserAdditionalInfoSubscription>;
export type GetUserAdditionalInfoSubscriptionResult = Apollo.SubscriptionResult<GetUserAdditionalInfoSubscription>;
export const CreateAnonymousJwtDocument = gql`
    mutation CreateAnonymousJWT {
  createAnonymousJWT
}
    `;
export type CreateAnonymousJwtMutationFn = Apollo.MutationFunction<CreateAnonymousJwtMutation, CreateAnonymousJwtMutationVariables>;

/**
 * __useCreateAnonymousJwtMutation__
 *
 * To run a mutation, you first call `useCreateAnonymousJwtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnonymousJwtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnonymousJwtMutation, { data, loading, error }] = useCreateAnonymousJwtMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateAnonymousJwtMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnonymousJwtMutation, CreateAnonymousJwtMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAnonymousJwtMutation, CreateAnonymousJwtMutationVariables>(CreateAnonymousJwtDocument, options);
      }
export type CreateAnonymousJwtMutationHookResult = ReturnType<typeof useCreateAnonymousJwtMutation>;
export type CreateAnonymousJwtMutationResult = Apollo.MutationResult<CreateAnonymousJwtMutation>;
export type CreateAnonymousJwtMutationOptions = Apollo.BaseMutationOptions<CreateAnonymousJwtMutation, CreateAnonymousJwtMutationVariables>;
export const UpdateNameDocument = gql`
    mutation updateName($name: String!) {
  update_supertokens_user_additional_fields(_set: {name: $name}, where: {}) {
    returning {
      name
    }
  }
}
    `;
export type UpdateNameMutationFn = Apollo.MutationFunction<UpdateNameMutation, UpdateNameMutationVariables>;

/**
 * __useUpdateNameMutation__
 *
 * To run a mutation, you first call `useUpdateNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNameMutation, { data, loading, error }] = useUpdateNameMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNameMutation, UpdateNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNameMutation, UpdateNameMutationVariables>(UpdateNameDocument, options);
      }
export type UpdateNameMutationHookResult = ReturnType<typeof useUpdateNameMutation>;
export type UpdateNameMutationResult = Apollo.MutationResult<UpdateNameMutation>;
export type UpdateNameMutationOptions = Apollo.BaseMutationOptions<UpdateNameMutation, UpdateNameMutationVariables>;
export const CreateTournamentRoomDocument = gql`
    mutation CreateTournamentRoom($tournamentID: uuid!, $isSinglePlayer: Boolean!) {
  createNewRoom(tournamentID: $tournamentID, isSinglePlayer: $isSinglePlayer) {
    id
  }
}
    `;
export type CreateTournamentRoomMutationFn = Apollo.MutationFunction<CreateTournamentRoomMutation, CreateTournamentRoomMutationVariables>;

/**
 * __useCreateTournamentRoomMutation__
 *
 * To run a mutation, you first call `useCreateTournamentRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentRoomMutation, { data, loading, error }] = useCreateTournamentRoomMutation({
 *   variables: {
 *      tournamentID: // value for 'tournamentID'
 *      isSinglePlayer: // value for 'isSinglePlayer'
 *   },
 * });
 */
export function useCreateTournamentRoomMutation(baseOptions?: Apollo.MutationHookOptions<CreateTournamentRoomMutation, CreateTournamentRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTournamentRoomMutation, CreateTournamentRoomMutationVariables>(CreateTournamentRoomDocument, options);
      }
export type CreateTournamentRoomMutationHookResult = ReturnType<typeof useCreateTournamentRoomMutation>;
export type CreateTournamentRoomMutationResult = Apollo.MutationResult<CreateTournamentRoomMutation>;
export type CreateTournamentRoomMutationOptions = Apollo.BaseMutationOptions<CreateTournamentRoomMutation, CreateTournamentRoomMutationVariables>;
export const CreateSinglePlayerRoomDocument = gql`
    mutation CreateSinglePlayerRoom {
  insert_rooms_one(object: {is_single_player_game: true}) {
    id
  }
}
    `;
export type CreateSinglePlayerRoomMutationFn = Apollo.MutationFunction<CreateSinglePlayerRoomMutation, CreateSinglePlayerRoomMutationVariables>;

/**
 * __useCreateSinglePlayerRoomMutation__
 *
 * To run a mutation, you first call `useCreateSinglePlayerRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSinglePlayerRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSinglePlayerRoomMutation, { data, loading, error }] = useCreateSinglePlayerRoomMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateSinglePlayerRoomMutation(baseOptions?: Apollo.MutationHookOptions<CreateSinglePlayerRoomMutation, CreateSinglePlayerRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSinglePlayerRoomMutation, CreateSinglePlayerRoomMutationVariables>(CreateSinglePlayerRoomDocument, options);
      }
export type CreateSinglePlayerRoomMutationHookResult = ReturnType<typeof useCreateSinglePlayerRoomMutation>;
export type CreateSinglePlayerRoomMutationResult = Apollo.MutationResult<CreateSinglePlayerRoomMutation>;
export type CreateSinglePlayerRoomMutationOptions = Apollo.BaseMutationOptions<CreateSinglePlayerRoomMutation, CreateSinglePlayerRoomMutationVariables>;
export const CreateNewRoomDocument = gql`
    mutation CreateNewRoom($is_single_player_game: Boolean!) {
  insert_rooms_one(object: {is_single_player_game: $is_single_player_game}) {
    id
  }
}
    `;
export type CreateNewRoomMutationFn = Apollo.MutationFunction<CreateNewRoomMutation, CreateNewRoomMutationVariables>;

/**
 * __useCreateNewRoomMutation__
 *
 * To run a mutation, you first call `useCreateNewRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewRoomMutation, { data, loading, error }] = useCreateNewRoomMutation({
 *   variables: {
 *      is_single_player_game: // value for 'is_single_player_game'
 *   },
 * });
 */
export function useCreateNewRoomMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewRoomMutation, CreateNewRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewRoomMutation, CreateNewRoomMutationVariables>(CreateNewRoomDocument, options);
      }
export type CreateNewRoomMutationHookResult = ReturnType<typeof useCreateNewRoomMutation>;
export type CreateNewRoomMutationResult = Apollo.MutationResult<CreateNewRoomMutation>;
export type CreateNewRoomMutationOptions = Apollo.BaseMutationOptions<CreateNewRoomMutation, CreateNewRoomMutationVariables>;
export const CreateBattleModeRoomDocument = gql`
    mutation CreateBattleModeRoom {
  createBattleModeRoom {
    id
  }
}
    `;
export type CreateBattleModeRoomMutationFn = Apollo.MutationFunction<CreateBattleModeRoomMutation, CreateBattleModeRoomMutationVariables>;

/**
 * __useCreateBattleModeRoomMutation__
 *
 * To run a mutation, you first call `useCreateBattleModeRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBattleModeRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBattleModeRoomMutation, { data, loading, error }] = useCreateBattleModeRoomMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateBattleModeRoomMutation(baseOptions?: Apollo.MutationHookOptions<CreateBattleModeRoomMutation, CreateBattleModeRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBattleModeRoomMutation, CreateBattleModeRoomMutationVariables>(CreateBattleModeRoomDocument, options);
      }
export type CreateBattleModeRoomMutationHookResult = ReturnType<typeof useCreateBattleModeRoomMutation>;
export type CreateBattleModeRoomMutationResult = Apollo.MutationResult<CreateBattleModeRoomMutation>;
export type CreateBattleModeRoomMutationOptions = Apollo.BaseMutationOptions<CreateBattleModeRoomMutation, CreateBattleModeRoomMutationVariables>;
export const AddPlayersToRoomDocument = gql`
    mutation AddPlayersToRoom($participantsData: [room_participants_insert_input!]!) {
  insert_room_participants(
    objects: $participantsData
    on_conflict: {constraint: room_participants_room_id_player_id_key, update_columns: []}
  ) {
    affected_rows
  }
}
    `;
export type AddPlayersToRoomMutationFn = Apollo.MutationFunction<AddPlayersToRoomMutation, AddPlayersToRoomMutationVariables>;

/**
 * __useAddPlayersToRoomMutation__
 *
 * To run a mutation, you first call `useAddPlayersToRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlayersToRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlayersToRoomMutation, { data, loading, error }] = useAddPlayersToRoomMutation({
 *   variables: {
 *      participantsData: // value for 'participantsData'
 *   },
 * });
 */
export function useAddPlayersToRoomMutation(baseOptions?: Apollo.MutationHookOptions<AddPlayersToRoomMutation, AddPlayersToRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPlayersToRoomMutation, AddPlayersToRoomMutationVariables>(AddPlayersToRoomDocument, options);
      }
export type AddPlayersToRoomMutationHookResult = ReturnType<typeof useAddPlayersToRoomMutation>;
export type AddPlayersToRoomMutationResult = Apollo.MutationResult<AddPlayersToRoomMutation>;
export type AddPlayersToRoomMutationOptions = Apollo.BaseMutationOptions<AddPlayersToRoomMutation, AddPlayersToRoomMutationVariables>;
export const CreateOnBoardingGame2RoomDocument = gql`
    mutation createOnBoardingGame2Room($setOnboardData: supertokens_user_additional_fields_set_input) {
  update_supertokens_user_additional_fields(_set: $setOnboardData, where: {}) {
    affected_rows
  }
  createOnBoardingGame2Room {
    id
  }
}
    `;
export type CreateOnBoardingGame2RoomMutationFn = Apollo.MutationFunction<CreateOnBoardingGame2RoomMutation, CreateOnBoardingGame2RoomMutationVariables>;

/**
 * __useCreateOnBoardingGame2RoomMutation__
 *
 * To run a mutation, you first call `useCreateOnBoardingGame2RoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOnBoardingGame2RoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOnBoardingGame2RoomMutation, { data, loading, error }] = useCreateOnBoardingGame2RoomMutation({
 *   variables: {
 *      setOnboardData: // value for 'setOnboardData'
 *   },
 * });
 */
export function useCreateOnBoardingGame2RoomMutation(baseOptions?: Apollo.MutationHookOptions<CreateOnBoardingGame2RoomMutation, CreateOnBoardingGame2RoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOnBoardingGame2RoomMutation, CreateOnBoardingGame2RoomMutationVariables>(CreateOnBoardingGame2RoomDocument, options);
      }
export type CreateOnBoardingGame2RoomMutationHookResult = ReturnType<typeof useCreateOnBoardingGame2RoomMutation>;
export type CreateOnBoardingGame2RoomMutationResult = Apollo.MutationResult<CreateOnBoardingGame2RoomMutation>;
export type CreateOnBoardingGame2RoomMutationOptions = Apollo.BaseMutationOptions<CreateOnBoardingGame2RoomMutation, CreateOnBoardingGame2RoomMutationVariables>;
export const MakeAMoveDocument = gql`
    mutation MakeAMove($sourceSquare: String!, $targetSquare: String!, $roomParticipantID: uuid!, $promotion: String) {
  insert_room_moves_one(
    object: {source_square: $sourceSquare, target_square: $targetSquare, room_participant_id: $roomParticipantID, promotion: $promotion}
  ) {
    id
    room_moves_room_participant {
      id
      moves {
        source_square
        target_square
        updated_at
        is_timer_expired
      }
    }
  }
}
    `;
export type MakeAMoveMutationFn = Apollo.MutationFunction<MakeAMoveMutation, MakeAMoveMutationVariables>;

/**
 * __useMakeAMoveMutation__
 *
 * To run a mutation, you first call `useMakeAMoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeAMoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeAMoveMutation, { data, loading, error }] = useMakeAMoveMutation({
 *   variables: {
 *      sourceSquare: // value for 'sourceSquare'
 *      targetSquare: // value for 'targetSquare'
 *      roomParticipantID: // value for 'roomParticipantID'
 *      promotion: // value for 'promotion'
 *   },
 * });
 */
export function useMakeAMoveMutation(baseOptions?: Apollo.MutationHookOptions<MakeAMoveMutation, MakeAMoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeAMoveMutation, MakeAMoveMutationVariables>(MakeAMoveDocument, options);
      }
export type MakeAMoveMutationHookResult = ReturnType<typeof useMakeAMoveMutation>;
export type MakeAMoveMutationResult = Apollo.MutationResult<MakeAMoveMutation>;
export type MakeAMoveMutationOptions = Apollo.BaseMutationOptions<MakeAMoveMutation, MakeAMoveMutationVariables>;
export const PlayAgain__CreateReplayRoomDocument = gql`
    mutation PlayAgain__CreateReplayRoom($roomID: uuid!) {
  createReplayRoom(roomID: $roomID) {
    id
  }
}
    `;
export type PlayAgain__CreateReplayRoomMutationFn = Apollo.MutationFunction<PlayAgain__CreateReplayRoomMutation, PlayAgain__CreateReplayRoomMutationVariables>;

/**
 * __usePlayAgain__CreateReplayRoomMutation__
 *
 * To run a mutation, you first call `usePlayAgain__CreateReplayRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayAgain__CreateReplayRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playAgainCreateReplayRoomMutation, { data, loading, error }] = usePlayAgain__CreateReplayRoomMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function usePlayAgain__CreateReplayRoomMutation(baseOptions?: Apollo.MutationHookOptions<PlayAgain__CreateReplayRoomMutation, PlayAgain__CreateReplayRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlayAgain__CreateReplayRoomMutation, PlayAgain__CreateReplayRoomMutationVariables>(PlayAgain__CreateReplayRoomDocument, options);
      }
export type PlayAgain__CreateReplayRoomMutationHookResult = ReturnType<typeof usePlayAgain__CreateReplayRoomMutation>;
export type PlayAgain__CreateReplayRoomMutationResult = Apollo.MutationResult<PlayAgain__CreateReplayRoomMutation>;
export type PlayAgain__CreateReplayRoomMutationOptions = Apollo.BaseMutationOptions<PlayAgain__CreateReplayRoomMutation, PlayAgain__CreateReplayRoomMutationVariables>;
export const ChoosingSides__ChooseRandomSideDocument = gql`
    mutation ChoosingSides__ChooseRandomSide($roomID: uuid!) {
  chooseRandomSide(roomID: $roomID) {
    ...RoomDetailsFragment
  }
}
    ${RoomDetailsFragmentFragmentDoc}`;
export type ChoosingSides__ChooseRandomSideMutationFn = Apollo.MutationFunction<ChoosingSides__ChooseRandomSideMutation, ChoosingSides__ChooseRandomSideMutationVariables>;

/**
 * __useChoosingSides__ChooseRandomSideMutation__
 *
 * To run a mutation, you first call `useChoosingSides__ChooseRandomSideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChoosingSides__ChooseRandomSideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [choosingSidesChooseRandomSideMutation, { data, loading, error }] = useChoosingSides__ChooseRandomSideMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useChoosingSides__ChooseRandomSideMutation(baseOptions?: Apollo.MutationHookOptions<ChoosingSides__ChooseRandomSideMutation, ChoosingSides__ChooseRandomSideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChoosingSides__ChooseRandomSideMutation, ChoosingSides__ChooseRandomSideMutationVariables>(ChoosingSides__ChooseRandomSideDocument, options);
      }
export type ChoosingSides__ChooseRandomSideMutationHookResult = ReturnType<typeof useChoosingSides__ChooseRandomSideMutation>;
export type ChoosingSides__ChooseRandomSideMutationResult = Apollo.MutationResult<ChoosingSides__ChooseRandomSideMutation>;
export type ChoosingSides__ChooseRandomSideMutationOptions = Apollo.BaseMutationOptions<ChoosingSides__ChooseRandomSideMutation, ChoosingSides__ChooseRandomSideMutationVariables>;
export const GetParticipantsAdditionalFieldsDocument = gql`
    subscription GetParticipantsAdditionalFields($roomID: uuid!) {
  rooms_by_pk(id: $roomID) {
    participants {
      is_current_user
      elo_rating
      participant_additional_fields {
        elo_rating
      }
      moves(order_by: {created_at: asc}) {
        room_participant_id
        cp_score
        move_rank
      }
    }
  }
}
    `;

/**
 * __useGetParticipantsAdditionalFieldsSubscription__
 *
 * To run a query within a React component, call `useGetParticipantsAdditionalFieldsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantsAdditionalFieldsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantsAdditionalFieldsSubscription({
 *   variables: {
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useGetParticipantsAdditionalFieldsSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetParticipantsAdditionalFieldsSubscription, GetParticipantsAdditionalFieldsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetParticipantsAdditionalFieldsSubscription, GetParticipantsAdditionalFieldsSubscriptionVariables>(GetParticipantsAdditionalFieldsDocument, options);
      }
export type GetParticipantsAdditionalFieldsSubscriptionHookResult = ReturnType<typeof useGetParticipantsAdditionalFieldsSubscription>;
export type GetParticipantsAdditionalFieldsSubscriptionResult = Apollo.SubscriptionResult<GetParticipantsAdditionalFieldsSubscription>;
export const StartGame__StartGameButtonClickedDocument = gql`
    mutation StartGame__StartGameButtonClicked($roomID: uuid!) {
  update_rooms_by_pk(
    pk_columns: {id: $roomID}
    _set: {is_play_with_friends_game_start_button_clicked: true}
  ) {
    is_play_with_friends_game_start_button_clicked
  }
}
    `;
export type StartGame__StartGameButtonClickedMutationFn = Apollo.MutationFunction<StartGame__StartGameButtonClickedMutation, StartGame__StartGameButtonClickedMutationVariables>;

/**
 * __useStartGame__StartGameButtonClickedMutation__
 *
 * To run a mutation, you first call `useStartGame__StartGameButtonClickedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartGame__StartGameButtonClickedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startGameStartGameButtonClickedMutation, { data, loading, error }] = useStartGame__StartGameButtonClickedMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useStartGame__StartGameButtonClickedMutation(baseOptions?: Apollo.MutationHookOptions<StartGame__StartGameButtonClickedMutation, StartGame__StartGameButtonClickedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartGame__StartGameButtonClickedMutation, StartGame__StartGameButtonClickedMutationVariables>(StartGame__StartGameButtonClickedDocument, options);
      }
export type StartGame__StartGameButtonClickedMutationHookResult = ReturnType<typeof useStartGame__StartGameButtonClickedMutation>;
export type StartGame__StartGameButtonClickedMutationResult = Apollo.MutationResult<StartGame__StartGameButtonClickedMutation>;
export type StartGame__StartGameButtonClickedMutationOptions = Apollo.BaseMutationOptions<StartGame__StartGameButtonClickedMutation, StartGame__StartGameButtonClickedMutationVariables>;
export const MakeAMoveWithTimerExpiredDocument = gql`
    mutation MakeAMoveWithTimerExpired($roomParticipantID: uuid!) {
  insert_room_moves_one(
    object: {room_participant_id: $roomParticipantID, is_timer_expired: true}
  ) {
    id
  }
}
    `;
export type MakeAMoveWithTimerExpiredMutationFn = Apollo.MutationFunction<MakeAMoveWithTimerExpiredMutation, MakeAMoveWithTimerExpiredMutationVariables>;

/**
 * __useMakeAMoveWithTimerExpiredMutation__
 *
 * To run a mutation, you first call `useMakeAMoveWithTimerExpiredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeAMoveWithTimerExpiredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeAMoveWithTimerExpiredMutation, { data, loading, error }] = useMakeAMoveWithTimerExpiredMutation({
 *   variables: {
 *      roomParticipantID: // value for 'roomParticipantID'
 *   },
 * });
 */
export function useMakeAMoveWithTimerExpiredMutation(baseOptions?: Apollo.MutationHookOptions<MakeAMoveWithTimerExpiredMutation, MakeAMoveWithTimerExpiredMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeAMoveWithTimerExpiredMutation, MakeAMoveWithTimerExpiredMutationVariables>(MakeAMoveWithTimerExpiredDocument, options);
      }
export type MakeAMoveWithTimerExpiredMutationHookResult = ReturnType<typeof useMakeAMoveWithTimerExpiredMutation>;
export type MakeAMoveWithTimerExpiredMutationResult = Apollo.MutationResult<MakeAMoveWithTimerExpiredMutation>;
export type MakeAMoveWithTimerExpiredMutationOptions = Apollo.BaseMutationOptions<MakeAMoveWithTimerExpiredMutation, MakeAMoveWithTimerExpiredMutationVariables>;
export const GetTournamentLeaderboardDetailsDocument = gql`
    subscription GetTournamentLeaderboardDetails($tournamentID: uuid!) {
  tournament_leaderboard(
    where: {tournament_id: {_eq: $tournamentID}}
    order_by: [{rank: asc}]
  ) {
    id
    rank
    winnings
    participantID: user_id
    level
    numberOfCheckmates: number_of_checkmates
    user {
      name
      profile_picture
    }
  }
}
    `;

/**
 * __useGetTournamentLeaderboardDetailsSubscription__
 *
 * To run a query within a React component, call `useGetTournamentLeaderboardDetailsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetTournamentLeaderboardDetailsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTournamentLeaderboardDetailsSubscription({
 *   variables: {
 *      tournamentID: // value for 'tournamentID'
 *   },
 * });
 */
export function useGetTournamentLeaderboardDetailsSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetTournamentLeaderboardDetailsSubscription, GetTournamentLeaderboardDetailsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetTournamentLeaderboardDetailsSubscription, GetTournamentLeaderboardDetailsSubscriptionVariables>(GetTournamentLeaderboardDetailsDocument, options);
      }
export type GetTournamentLeaderboardDetailsSubscriptionHookResult = ReturnType<typeof useGetTournamentLeaderboardDetailsSubscription>;
export type GetTournamentLeaderboardDetailsSubscriptionResult = Apollo.SubscriptionResult<GetTournamentLeaderboardDetailsSubscription>;
export const GetTournamentProgressInfoDocument = gql`
    subscription GetTournamentProgressInfo($tournamentID: uuid!) {
  tournament_by_pk(id: $tournamentID) {
    start_time
    end_time
    id
    is_active
    has_user_made_an_entry
    is_rewards_distributed
    currentUserInfo: tournament_participants {
      created_at
      current_level
      previous_level
      is_completed
      highest_level
      id
      participant_id
      tournament_id
      updated_at
      show_next_level
      remaining_retries
      rank
    }
    tournamentLevels: tournament_levels_metadata {
      level
      is_checkpoint
      lowest_rank_in_level
      highest_rank_in_level
      has_user_completed_with_checkmate
    }
  }
}
    `;

/**
 * __useGetTournamentProgressInfoSubscription__
 *
 * To run a query within a React component, call `useGetTournamentProgressInfoSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetTournamentProgressInfoSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTournamentProgressInfoSubscription({
 *   variables: {
 *      tournamentID: // value for 'tournamentID'
 *   },
 * });
 */
export function useGetTournamentProgressInfoSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetTournamentProgressInfoSubscription, GetTournamentProgressInfoSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetTournamentProgressInfoSubscription, GetTournamentProgressInfoSubscriptionVariables>(GetTournamentProgressInfoDocument, options);
      }
export type GetTournamentProgressInfoSubscriptionHookResult = ReturnType<typeof useGetTournamentProgressInfoSubscription>;
export type GetTournamentProgressInfoSubscriptionResult = Apollo.SubscriptionResult<GetTournamentProgressInfoSubscription>;
export const CheckCheckPointLevelDocument = gql`
    query checkCheckPointLevel($level: Int!, $tournament_id: uuid!) {
  tournament_levels_metadata(
    limit: 1
    where: {level: {_eq: $level}, is_checkpoint: {_eq: true}, tournament_id: {_eq: $tournament_id}}
  ) {
    id
  }
}
    `;

/**
 * __useCheckCheckPointLevelQuery__
 *
 * To run a query within a React component, call `useCheckCheckPointLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckCheckPointLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckCheckPointLevelQuery({
 *   variables: {
 *      level: // value for 'level'
 *      tournament_id: // value for 'tournament_id'
 *   },
 * });
 */
export function useCheckCheckPointLevelQuery(baseOptions: Apollo.QueryHookOptions<CheckCheckPointLevelQuery, CheckCheckPointLevelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckCheckPointLevelQuery, CheckCheckPointLevelQueryVariables>(CheckCheckPointLevelDocument, options);
      }
export function useCheckCheckPointLevelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckCheckPointLevelQuery, CheckCheckPointLevelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckCheckPointLevelQuery, CheckCheckPointLevelQueryVariables>(CheckCheckPointLevelDocument, options);
        }
export type CheckCheckPointLevelQueryHookResult = ReturnType<typeof useCheckCheckPointLevelQuery>;
export type CheckCheckPointLevelLazyQueryHookResult = ReturnType<typeof useCheckCheckPointLevelLazyQuery>;
export type CheckCheckPointLevelQueryResult = Apollo.QueryResult<CheckCheckPointLevelQuery, CheckCheckPointLevelQueryVariables>;
export const UpdateTournamentParticipantDocument = gql`
    mutation updateTournamentParticipant($tournamentID: uuid!, $previous_level: Int!) {
  update_tournament_participants(
    where: {tournament_id: {_eq: $tournamentID}}
    _set: {show_next_level: false, previous_level: $previous_level}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdateTournamentParticipantMutationFn = Apollo.MutationFunction<UpdateTournamentParticipantMutation, UpdateTournamentParticipantMutationVariables>;

/**
 * __useUpdateTournamentParticipantMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentParticipantMutation, { data, loading, error }] = useUpdateTournamentParticipantMutation({
 *   variables: {
 *      tournamentID: // value for 'tournamentID'
 *      previous_level: // value for 'previous_level'
 *   },
 * });
 */
export function useUpdateTournamentParticipantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTournamentParticipantMutation, UpdateTournamentParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTournamentParticipantMutation, UpdateTournamentParticipantMutationVariables>(UpdateTournamentParticipantDocument, options);
      }
export type UpdateTournamentParticipantMutationHookResult = ReturnType<typeof useUpdateTournamentParticipantMutation>;
export type UpdateTournamentParticipantMutationResult = Apollo.MutationResult<UpdateTournamentParticipantMutation>;
export type UpdateTournamentParticipantMutationOptions = Apollo.BaseMutationOptions<UpdateTournamentParticipantMutation, UpdateTournamentParticipantMutationVariables>;
export const GetWalletTransactionsDocument = gql`
    subscription GetWalletTransactions($whereCondition: wallet_transactions_bool_exp) {
  wallet_transactions(where: $whereCondition, order_by: {created_at: desc}) {
    id
    type
    amount
    created_at
    status
    tournament_wallet_transactions {
      tournament {
        end_time
      }
    }
  }
}
    `;

/**
 * __useGetWalletTransactionsSubscription__
 *
 * To run a query within a React component, call `useGetWalletTransactionsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetWalletTransactionsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalletTransactionsSubscription({
 *   variables: {
 *      whereCondition: // value for 'whereCondition'
 *   },
 * });
 */
export function useGetWalletTransactionsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetWalletTransactionsSubscription, GetWalletTransactionsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetWalletTransactionsSubscription, GetWalletTransactionsSubscriptionVariables>(GetWalletTransactionsDocument, options);
      }
export type GetWalletTransactionsSubscriptionHookResult = ReturnType<typeof useGetWalletTransactionsSubscription>;
export type GetWalletTransactionsSubscriptionResult = Apollo.SubscriptionResult<GetWalletTransactionsSubscription>;
export const WalletTransactionsDetailsDocument = gql`
    query WalletTransactionsDetails {
  wallet_transactions {
    amount
    id
    type
    user_id
    updated_at
    created_at
  }
}
    `;

/**
 * __useWalletTransactionsDetailsQuery__
 *
 * To run a query within a React component, call `useWalletTransactionsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalletTransactionsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalletTransactionsDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWalletTransactionsDetailsQuery(baseOptions?: Apollo.QueryHookOptions<WalletTransactionsDetailsQuery, WalletTransactionsDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WalletTransactionsDetailsQuery, WalletTransactionsDetailsQueryVariables>(WalletTransactionsDetailsDocument, options);
      }
export function useWalletTransactionsDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WalletTransactionsDetailsQuery, WalletTransactionsDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WalletTransactionsDetailsQuery, WalletTransactionsDetailsQueryVariables>(WalletTransactionsDetailsDocument, options);
        }
export type WalletTransactionsDetailsQueryHookResult = ReturnType<typeof useWalletTransactionsDetailsQuery>;
export type WalletTransactionsDetailsLazyQueryHookResult = ReturnType<typeof useWalletTransactionsDetailsLazyQuery>;
export type WalletTransactionsDetailsQueryResult = Apollo.QueryResult<WalletTransactionsDetailsQuery, WalletTransactionsDetailsQueryVariables>;
export const CheckPanValidationDocument = gql`
    mutation checkPanValidation($panNumber: String!) {
  checkPanValidation(panNumber: $panNumber) {
    message
    success
  }
}
    `;
export type CheckPanValidationMutationFn = Apollo.MutationFunction<CheckPanValidationMutation, CheckPanValidationMutationVariables>;

/**
 * __useCheckPanValidationMutation__
 *
 * To run a mutation, you first call `useCheckPanValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckPanValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkPanValidationMutation, { data, loading, error }] = useCheckPanValidationMutation({
 *   variables: {
 *      panNumber: // value for 'panNumber'
 *   },
 * });
 */
export function useCheckPanValidationMutation(baseOptions?: Apollo.MutationHookOptions<CheckPanValidationMutation, CheckPanValidationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckPanValidationMutation, CheckPanValidationMutationVariables>(CheckPanValidationDocument, options);
      }
export type CheckPanValidationMutationHookResult = ReturnType<typeof useCheckPanValidationMutation>;
export type CheckPanValidationMutationResult = Apollo.MutationResult<CheckPanValidationMutation>;
export type CheckPanValidationMutationOptions = Apollo.BaseMutationOptions<CheckPanValidationMutation, CheckPanValidationMutationVariables>;
export const AddUpiDetailsDocument = gql`
    mutation addUpiDetails($upiID: String!) {
  addUpiDetails(upiID: $upiID) {
    message
    success
  }
}
    `;
export type AddUpiDetailsMutationFn = Apollo.MutationFunction<AddUpiDetailsMutation, AddUpiDetailsMutationVariables>;

/**
 * __useAddUpiDetailsMutation__
 *
 * To run a mutation, you first call `useAddUpiDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUpiDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUpiDetailsMutation, { data, loading, error }] = useAddUpiDetailsMutation({
 *   variables: {
 *      upiID: // value for 'upiID'
 *   },
 * });
 */
export function useAddUpiDetailsMutation(baseOptions?: Apollo.MutationHookOptions<AddUpiDetailsMutation, AddUpiDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUpiDetailsMutation, AddUpiDetailsMutationVariables>(AddUpiDetailsDocument, options);
      }
export type AddUpiDetailsMutationHookResult = ReturnType<typeof useAddUpiDetailsMutation>;
export type AddUpiDetailsMutationResult = Apollo.MutationResult<AddUpiDetailsMutation>;
export type AddUpiDetailsMutationOptions = Apollo.BaseMutationOptions<AddUpiDetailsMutation, AddUpiDetailsMutationVariables>;
export const MakeCashWithdrawRequestDocument = gql`
    mutation makeCashWithdrawRequest($amount: Int!) {
  makeCashWithdrawRequest(amount: $amount) {
    success
    message
  }
}
    `;
export type MakeCashWithdrawRequestMutationFn = Apollo.MutationFunction<MakeCashWithdrawRequestMutation, MakeCashWithdrawRequestMutationVariables>;

/**
 * __useMakeCashWithdrawRequestMutation__
 *
 * To run a mutation, you first call `useMakeCashWithdrawRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeCashWithdrawRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeCashWithdrawRequestMutation, { data, loading, error }] = useMakeCashWithdrawRequestMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useMakeCashWithdrawRequestMutation(baseOptions?: Apollo.MutationHookOptions<MakeCashWithdrawRequestMutation, MakeCashWithdrawRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeCashWithdrawRequestMutation, MakeCashWithdrawRequestMutationVariables>(MakeCashWithdrawRequestDocument, options);
      }
export type MakeCashWithdrawRequestMutationHookResult = ReturnType<typeof useMakeCashWithdrawRequestMutation>;
export type MakeCashWithdrawRequestMutationResult = Apollo.MutationResult<MakeCashWithdrawRequestMutation>;
export type MakeCashWithdrawRequestMutationOptions = Apollo.BaseMutationOptions<MakeCashWithdrawRequestMutation, MakeCashWithdrawRequestMutationVariables>;
export const GetPanAndUpiDetailsDocument = gql`
    subscription getPanAndUpiDetails {
  user: supertokens_user_additional_fields {
    panCardDetails: pan_card_details {
      pan_name
      pan_number
      created_at
      upi_details {
        id
      }
    }
    upiDetails: upi_details {
      upi_username
      upi_id
      created_at
    }
  }
}
    `;

/**
 * __useGetPanAndUpiDetailsSubscription__
 *
 * To run a query within a React component, call `useGetPanAndUpiDetailsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetPanAndUpiDetailsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPanAndUpiDetailsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetPanAndUpiDetailsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetPanAndUpiDetailsSubscription, GetPanAndUpiDetailsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetPanAndUpiDetailsSubscription, GetPanAndUpiDetailsSubscriptionVariables>(GetPanAndUpiDetailsDocument, options);
      }
export type GetPanAndUpiDetailsSubscriptionHookResult = ReturnType<typeof useGetPanAndUpiDetailsSubscription>;
export type GetPanAndUpiDetailsSubscriptionResult = Apollo.SubscriptionResult<GetPanAndUpiDetailsSubscription>;
export const GetRoomDetailsDocument = gql`
    subscription GetRoomDetails($roomID: uuid!) {
  rooms_by_pk(id: $roomID) {
    ...RoomDetailsFragment
  }
}
    ${RoomDetailsFragmentFragmentDoc}`;

/**
 * __useGetRoomDetailsSubscription__
 *
 * To run a query within a React component, call `useGetRoomDetailsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomDetailsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomDetailsSubscription({
 *   variables: {
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useGetRoomDetailsSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetRoomDetailsSubscription, GetRoomDetailsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetRoomDetailsSubscription, GetRoomDetailsSubscriptionVariables>(GetRoomDetailsDocument, options);
      }
export type GetRoomDetailsSubscriptionHookResult = ReturnType<typeof useGetRoomDetailsSubscription>;
export type GetRoomDetailsSubscriptionResult = Apollo.SubscriptionResult<GetRoomDetailsSubscription>;
export const EndGameRoomDocument = gql`
    mutation endGameRoom($roomID: uuid!) {
  endGameRoom(roomID: $roomID) {
    success
    message
  }
}
    `;
export type EndGameRoomMutationFn = Apollo.MutationFunction<EndGameRoomMutation, EndGameRoomMutationVariables>;

/**
 * __useEndGameRoomMutation__
 *
 * To run a mutation, you first call `useEndGameRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndGameRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endGameRoomMutation, { data, loading, error }] = useEndGameRoomMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useEndGameRoomMutation(baseOptions?: Apollo.MutationHookOptions<EndGameRoomMutation, EndGameRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndGameRoomMutation, EndGameRoomMutationVariables>(EndGameRoomDocument, options);
      }
export type EndGameRoomMutationHookResult = ReturnType<typeof useEndGameRoomMutation>;
export type EndGameRoomMutationResult = Apollo.MutationResult<EndGameRoomMutation>;
export type EndGameRoomMutationOptions = Apollo.BaseMutationOptions<EndGameRoomMutation, EndGameRoomMutationVariables>;
export const StartOnboardingGameDocument = gql`
    mutation startOnboardingGame($roomID: uuid!) {
  startOnboardingGame(roomID: $roomID) {
    success
    message
  }
}
    `;
export type StartOnboardingGameMutationFn = Apollo.MutationFunction<StartOnboardingGameMutation, StartOnboardingGameMutationVariables>;

/**
 * __useStartOnboardingGameMutation__
 *
 * To run a mutation, you first call `useStartOnboardingGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartOnboardingGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startOnboardingGameMutation, { data, loading, error }] = useStartOnboardingGameMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useStartOnboardingGameMutation(baseOptions?: Apollo.MutationHookOptions<StartOnboardingGameMutation, StartOnboardingGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartOnboardingGameMutation, StartOnboardingGameMutationVariables>(StartOnboardingGameDocument, options);
      }
export type StartOnboardingGameMutationHookResult = ReturnType<typeof useStartOnboardingGameMutation>;
export type StartOnboardingGameMutationResult = Apollo.MutationResult<StartOnboardingGameMutation>;
export type StartOnboardingGameMutationOptions = Apollo.BaseMutationOptions<StartOnboardingGameMutation, StartOnboardingGameMutationVariables>;
export const GetLatestTournamentDocument = gql`
    query getLatestTournament {
  tournament(
    order_by: {end_time: desc_nulls_last}
    limit: 1
    where: {is_active: {_eq: true}}
  ) {
    id
    start_time
    end_time
    tournament_participants {
      current_level
      rank
      remaining_retries
    }
  }
  next_tournament: tournament(
    order_by: {end_time: desc_nulls_last}
    limit: 1
    where: {is_active: {_eq: false}}
  ) {
    id
    start_time
  }
  tournamentWithRewardsDistributed: tournament(
    order_by: {end_time: desc_nulls_last}
    limit: 1
    where: {is_rewards_distributed: {_eq: true}, is_active: {_eq: false}}
  ) {
    id
    start_time
    end_time
  }
}
    `;

/**
 * __useGetLatestTournamentQuery__
 *
 * To run a query within a React component, call `useGetLatestTournamentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestTournamentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestTournamentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestTournamentQuery(baseOptions?: Apollo.QueryHookOptions<GetLatestTournamentQuery, GetLatestTournamentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestTournamentQuery, GetLatestTournamentQueryVariables>(GetLatestTournamentDocument, options);
      }
export function useGetLatestTournamentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestTournamentQuery, GetLatestTournamentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestTournamentQuery, GetLatestTournamentQueryVariables>(GetLatestTournamentDocument, options);
        }
export type GetLatestTournamentQueryHookResult = ReturnType<typeof useGetLatestTournamentQuery>;
export type GetLatestTournamentLazyQueryHookResult = ReturnType<typeof useGetLatestTournamentLazyQuery>;
export type GetLatestTournamentQueryResult = Apollo.QueryResult<GetLatestTournamentQuery, GetLatestTournamentQueryVariables>;