/* eslint-disable @typescript-eslint/no-explicit-any */
// add better names to spans
const getUpdatedSpanName = (span: any) => {
    const httpURL = span.attributes["http.url"] as string;

    let updatedSpanName = span.name;

    if (span.name === "gateway.request") {
        const operationName = span.attributes["operationName"] as string;

        if (operationName) {
            updatedSpanName = `${operationName} - gateway.request`;
        }
    }

    if (httpURL) {
        if (httpURL.includes("jwks.json")) {
            updatedSpanName = "get jwt keys";
        } else if (httpURL.includes("recipe/session/verify")) {
            updatedSpanName = "verify session";
        } else if (httpURL.includes("auth/signinup/code/consume")) {
            updatedSpanName = "verify otp";
        }
    }

    return updatedSpanName;
};

export default getUpdatedSpanName;
