import { Moves } from "@/types";
import { Chess } from "chess.js";
import getCurrentFEN from "../getCurrentFEN";

type CaptureMoveProps = {
    moves: Moves;
    initialFEN: string;
};

const getCapture = ({ initialFEN, moves }: CaptureMoveProps) => {
    if (moves?.length === 0) return null;

    let game = new Chess(initialFEN);

    for (const [
        i,
        { sourceSquare, targetSquare, isTimerExpired, promotion },
    ] of moves.entries()) {
        if (isTimerExpired) {
            game = new Chess(
                getCurrentFEN({
                    moves: moves.slice(0, i + 1),
                    initialFEN,
                })
            );

            continue;
        }

        if (!sourceSquare || !targetSquare)
            throw new Error("Source or target square is undefined");

        game.move({
            from: sourceSquare,
            to: targetSquare,
            promotion: promotion || "q",
        });
    }

    const finalFEN = game.fen();

    const finalPosition = finalFEN.split(" ")[0];

    const startingPosition = initialFEN.split(" ")[0];

    if (finalPosition === startingPosition) return null;

    const history = game.history({
        verbose: true,
    });

    const lastMove = history?.[history.length - 1];

    const capturedPiece = lastMove?.captured;

    const capturedPieceColor = lastMove?.color === "w" ? "b" : "w";

    return {
        capturedPiece,
        capturedPieceColor,
        from: lastMove?.from,
        to: lastMove?.to,
    };
};

export default getCapture;
