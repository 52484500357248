import { useEffect } from "react";
import { useCreateAnonymousJwtMutation } from "@/types/generated-types";
import { anonymousUser, toast } from "@/utils";

type Args = {
    isAuthenticated: boolean;
    isAuthLoading: boolean;
};

const useAnonymousUser = ({ isAuthenticated, isAuthLoading }: Args) => {    
    const [createAnonymousJwt, { loading, data, error }] =
        useCreateAnonymousJwtMutation({
            onCompleted: (data) => {
                anonymousUser.setAnonymousJwt(data?.createAnonymousJWT);
            },
        });

    useEffect(() => {
        if (
            !isAuthLoading &&
            !isAuthenticated &&
            !anonymousUser.isCreated &&
            !loading &&
            !data?.createAnonymousJWT &&
            !error
        ) {
            createAnonymousJwt();
        }

        if (error) {
            toast({
                type: "error",
                message: "Unable to create anonymous user.",
            });
        }
    }, [isAuthenticated, isAuthLoading, loading, data, error]);

    return anonymousUser;
};

export default useAnonymousUser;
