import { ReadableSpan } from "@opentelemetry/sdk-trace-base";

// given spans ignore a few which doesn't add much value
const getUpdatedReadableSpans = (spans: ReadableSpan[]) => {
    const updatedReadableSpans = [];

    const ignoredNames = new Set(["documentFetch"]);

    const ignoreURLSubstrings = [
        "_next",
        "fonts.googleapis.com",
        "fonts.gstatic.com",
        "jaegar-reverse-proxy.caprover.articunocoding.com",
    ];

    // @opentelemetry/instrumentation-document-load

    for (const span of spans) {
        if (ignoredNames.has(span.name)) {
            continue;
        }

        const url = span.attributes["http.url"];

        // ignore nextjs get requests
        if (url && typeof url === "string") {
            let ignore = false;

            for (const ignoreURLSubstring of ignoreURLSubstrings) {
                if (url.includes(ignoreURLSubstring)) {
                    ignore = true;
                    break;
                }
            }

            if (ignore) {
                continue;
            }
        }

        // if url is supertokens and in the list of ignored names, then ignore

        updatedReadableSpans.push(span);
    }

    return updatedReadableSpans;
};

export default getUpdatedReadableSpans;
