import { sendOTP, hasInitialOTPBeenSent } from "./auth";
import createRandomFEN from "./createRandomFEN";
import executeFunctionAfterInterval from "./executeFunctionAfterInterval";
import getCapture from "./getCapture";
import getCurrentFEN from "./getCurrentFEN";
import getIsCurrentUserTurn from "./getIsCurrentUserTurn";
import getScore from "./getScore";
import getStartingSideFromFEN from "./getStartingSideFromFEN";
import getTossSideRelatedData from "./getTossSideRelatedData";
import getTransactionTitleFromType from "./getTransactionTitleFromType";
import getWinner from "./getWinner";
import isClient from "./isClient";
import switchNextMoveSideInFEN from "./switchNextMoveSideInFEN";
import switchSide from "./switchSide";
import toast from "./toast";
import replaceHistoryToRouteToHomeOnBackButton from "./replaceHistoryToRouteToHomeOnBackButton";
import anonymousUser from "./anonymousUser";
import LocalStorageService from "./localStorageService";
import isProduction from "./isProduction";
import getCharacterNotationForPiece from "./getCharacterNotationForPiece"

export {
    isClient,
    isProduction,
    createRandomFEN,
    getCurrentFEN,
    getIsCurrentUserTurn,
    getScore,
    getCapture,
    getWinner,
    switchNextMoveSideInFEN,
    getTossSideRelatedData,
    sendOTP,
    hasInitialOTPBeenSent,
    executeFunctionAfterInterval,
    toast,
    getStartingSideFromFEN,
    switchSide,
    getTransactionTitleFromType,
    replaceHistoryToRouteToHomeOnBackButton,
    anonymousUser,
    LocalStorageService,
    getCharacterNotationForPiece
};
