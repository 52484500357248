import { createContext } from "react";
import { OpenShareProps } from "./ShareProvider";


type ContextValue = {
    shareRoomLink: (data: OpenShareProps) => void;
    openShare: (data: OpenShareProps) => void;
};

const ShareContext = createContext<ContextValue>({} as ContextValue);

export default ShareContext;