import {
    createCode,
    getLoginAttemptInfo,
} from "supertokens-auth-react/recipe/passwordless";
import toast from "./toast";

const sendOTP = async (phoneNumber: string) => {
    try {
        const response = await createCode({
            phoneNumber: phoneNumber,
        });

        // OTP sent successfully.
        toast({
            type: "success",
            message:
                "OTP sent successfully from Microtribes United Pvt. Ltd. Please enter to login.",
        });

        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        if (error.isSuperTokensGeneralError === true) {
            // this may be a custom error message sent from the API by you,
            // or if the input email / phone number is not valid.
            toast({
                type: "error",
                message: error.message,
            });
        } else {
            toast({
                type: "error",
                message: "Oops! Something went wrong.",
            });
        }
    }
};

async function hasInitialOTPBeenSent() {
    return (await getLoginAttemptInfo()) !== undefined;
}

export { sendOTP, hasInitialOTPBeenSent };
