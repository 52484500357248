import { PieceSymbol } from "chess.js";

const PIECE_SCORES: {
    [key in PieceSymbol]: number;
} = {
    p: 1,
    n: 3,
    b: 3,
    r: 5,
    q: 9,
    k: 1000,
};

export default PIECE_SCORES;
