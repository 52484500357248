import isMobileSession from './isMobileSession';
import PIECE_SCORES from "./pieceScores";
import { TRANSACTIONS, TRANSACTION_STATUS } from "./transactions";
import SHOULD_TRACE_EVENTS from "./shouldTraceEvents";
import MAX_BODY_WIDTH from "./maxBodyWidth";

const RESEND_OTP_AVAILABLE_TIME = 30;

const OTP_MAX_LENGTH = 4;

const PLAYERS_MATCHING_SCREEN = {
    FINDING_OPPONENTS: "finding-opponents",
    VS_PARTICIPANTS:"vs-participants",
    TOSS_INITIATION: "toss-initiation",
    GAME_TIMER_SCREEN: "game-timer-screen",
    TIMER_INFO_POPUP: "timer-info-popup"
};

export {
    RESEND_OTP_AVAILABLE_TIME,
    PIECE_SCORES,
    OTP_MAX_LENGTH,
    TRANSACTIONS,
    TRANSACTION_STATUS,
    isMobileSession,
    PLAYERS_MATCHING_SCREEN,
    SHOULD_TRACE_EVENTS,
    MAX_BODY_WIDTH
};
