//@ts-nocheck
/* @ts-nocheck eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
import * as Types from '../../../types/generated-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@/utils/apolloReactHooks';
const defaultOptions = {} as const;
export type GetUserAdditionalInfoSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserAdditionalInfoSubscription = { __typename?: 'Subscription', supertokens_user_additional_fields: Array<{ __typename?: 'supertokens_user_additional_fields', wallet_balance?: any | null, name: string, is_user_activated: boolean, is_tournament_enabled?: boolean | null, is_admin?: boolean | null, onboard_current_game?: string | null, user_type?: string | null, user_id: string }> };

export type CreateAnonymousJwtMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateAnonymousJwtMutation = { __typename?: 'Mutation', createAnonymousJWT: string };

export type UpdateNameMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
}>;


export type UpdateNameMutation = { __typename?: 'Mutation', update_supertokens_user_additional_fields?: { __typename?: 'supertokens_user_additional_fields_mutation_response', returning: Array<{ __typename?: 'supertokens_user_additional_fields', name: string }> } | null };


export const GetUserAdditionalInfoDocument = gql`
    subscription GetUserAdditionalInfo {
  supertokens_user_additional_fields {
    wallet_balance
    name
    is_user_activated
    is_tournament_enabled
    is_admin
    onboard_current_game
    user_type
    user_id
  }
}
    `;

/**
 * __useGetUserAdditionalInfoSubscription__
 *
 * To run a query within a React component, call `useGetUserAdditionalInfoSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAdditionalInfoSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAdditionalInfoSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetUserAdditionalInfoSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<GetUserAdditionalInfoSubscription, GetUserAdditionalInfoSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<GetUserAdditionalInfoSubscription, GetUserAdditionalInfoSubscriptionVariables>(GetUserAdditionalInfoDocument, options);
      }
export type GetUserAdditionalInfoSubscriptionHookResult = ReturnType<typeof useGetUserAdditionalInfoSubscription>;
export type GetUserAdditionalInfoSubscriptionResult = Apollo.SubscriptionResult<GetUserAdditionalInfoSubscription>;
export const CreateAnonymousJwtDocument = gql`
    mutation CreateAnonymousJWT {
  createAnonymousJWT
}
    `;
export type CreateAnonymousJwtMutationFn = Apollo.MutationFunction<CreateAnonymousJwtMutation, CreateAnonymousJwtMutationVariables>;

/**
 * __useCreateAnonymousJwtMutation__
 *
 * To run a mutation, you first call `useCreateAnonymousJwtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnonymousJwtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnonymousJwtMutation, { data, loading, error }] = useCreateAnonymousJwtMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateAnonymousJwtMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAnonymousJwtMutation, CreateAnonymousJwtMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateAnonymousJwtMutation, CreateAnonymousJwtMutationVariables>(CreateAnonymousJwtDocument, options);
      }
export type CreateAnonymousJwtMutationHookResult = ReturnType<typeof useCreateAnonymousJwtMutation>;
export type CreateAnonymousJwtMutationResult = Apollo.MutationResult<CreateAnonymousJwtMutation>;
export type CreateAnonymousJwtMutationOptions = Apollo.BaseMutationOptions<CreateAnonymousJwtMutation, CreateAnonymousJwtMutationVariables>;
export const UpdateNameDocument = gql`
    mutation updateName($name: String!) {
  update_supertokens_user_additional_fields(_set: {name: $name}, where: {}) {
    returning {
      name
    }
  }
}
    `;
export type UpdateNameMutationFn = Apollo.MutationFunction<UpdateNameMutation, UpdateNameMutationVariables>;

/**
 * __useUpdateNameMutation__
 *
 * To run a mutation, you first call `useUpdateNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNameMutation, { data, loading, error }] = useUpdateNameMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateNameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNameMutation, UpdateNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateNameMutation, UpdateNameMutationVariables>(UpdateNameDocument, options);
      }
export type UpdateNameMutationHookResult = ReturnType<typeof useUpdateNameMutation>;
export type UpdateNameMutationResult = Apollo.MutationResult<UpdateNameMutation>;
export type UpdateNameMutationOptions = Apollo.BaseMutationOptions<UpdateNameMutation, UpdateNameMutationVariables>;