import { Chess } from "chess.js";

const getTossSideRelatedData = ({
    side,
    fen,
}: {
    side: string;
    fen: string;
}) => {
    const chess = new Chess(fen);

    const firstMoveTurn = chess.turn();

    const isFirstMove =
        side ===
        (firstMoveTurn === "b"
            ? "black"
            : firstMoveTurn === "w"
            ? "white"
            : "");

    const opponentSet: {
        did_choose_whether_to_start_first?: boolean;
        did_choose_side?: boolean;
        side?: string;
    } = {};

    opponentSet.did_choose_whether_to_start_first = !isFirstMove;
    opponentSet.did_choose_side = false;
    opponentSet.side = side === "white" ? "black" : "white";

    const sideToBeStored = side;

    let updatedFEN = fen;

    // if current user chose first move we'll just flip the side of opponent
    // if current user chose side we'll just flip this side and make opponent side as first move

    let firstMoveCode;

    if (isFirstMove) {
        firstMoveCode = sideToBeStored === "white" ? "w" : "b";
    } else {
        firstMoveCode = sideToBeStored === "white" ? "b" : "w";
    }

    const updatedSplits = updatedFEN.split(" ");

    updatedSplits[1] = firstMoveCode;

    updatedFEN = updatedSplits.join(" ");

    return {
        isFirstMove,
        sideToBeStored,
        opponentSet,
        updatedFEN,
    };
};

export default getTossSideRelatedData;
