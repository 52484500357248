import { isClient } from "@/utils";
import {
    ApolloClient,
    InMemoryCache,
    createHttpLink,
    ApolloLink,
    split,
} from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { Span, trace, context } from "@opentelemetry/api";
import { createClient } from "graphql-ws";
import Session from "supertokens-auth-react/recipe/session";

const isLocal = process.env.NEXT_PUBLIC_API_DOMAIN?.includes("localhost");

const uri = `${isLocal ? "http" : "https"}://${
    process.env.NEXT_PUBLIC_API_DOMAIN
}/graphql`;

const SUBSCRIPTION_URL = `wss://${process.env.NEXT_PUBLIC_HASURA_DOMAIN}/v1/graphql`;

const generateID = (placeholder?: any) => {
    // @ts-ignore
    return placeholder ? (placeholder ^ Math.random() * 16 >> placeholder / 4).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, generateID)
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        Sentry.captureException(error);
      });
    }
  
    if (networkError) {
      Sentry.captureException(networkError);
    }
  });

export const getLink = () => {
    // Create an http link:
    const httpLink = createHttpLink({
        uri,
        headers: {
            "keep-alive": "true",
        },
    });

    // Create auth link

    if (isClient()) {
        // const wsLink = new WebSocketLink({
        //     uri: SUBSCRIPTION_URL!,
        //     options: {
        //         reconnect: true,
        //         inactivityTimeout: 10 * 1000,
        //         timeout: 10 * 1000,
        //         lazy: true,
        //         minTimeout: 10 * 1000,
        //     },
        // });

        // setTimeout(async () => {
        //     console.log(await Session.getAccessToken());
        // }, 3 * 1000);

        const parentSpans: {
            [key: string]: Span;
        } = {};  // Map to hold parent spans for each subscription

        const tracer = trace.getTracer("web-client")

        const websocketClient = createClient({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            url: SUBSCRIPTION_URL!,
            lazyCloseTimeout: 10 * 1000,
            lazy: true,
            generateID: (payload) => {
                const id = generateID()

                const operationName = payload?.operationName

                // Create or retrieve the parent span for this subscription
                if (!parentSpans[id]) {
                    if (operationName) {
                        parentSpans[id] = tracer.startSpan(`websocket-session-${operationName}`);

                        parentSpans[id].setAttribute("payload", JSON.stringify(payload))

                        parentSpans[id].end()
                    }
                } else {

            // @ts-ignore
            const parentSpan = parentSpans[id];
  
            const ctx = trace.setSpan(
                context.active(),
                parentSpan
            );
                    
            

            const span = tracer.startSpan("websocket-message", undefined, ctx)

                    // @ts-ignore
            span.setAttribute("message.type", payload.type)

                    span.end()
                }

                return id;
            },
            connectionParams: async () => {
                if (!isClient()) return {};

                let anonymousJwt = localStorage.getItem("anonymousJwt")

                if (anonymousJwt) {
                    try {
                        anonymousJwt = JSON.parse(anonymousJwt)
                    } catch(e) {}
                }

                const sessionToken = await Session.getAccessToken() || anonymousJwt

                return {
                    headers: {
                        Authorization: `Bearer ${sessionToken}`,
                    },
                };
            },
        })

        websocketClient.on("message", (message) => {
            const ignoredTypes = [
                "ping",
                "pong",
                "connection_ack"
            ]

            if (
                ignoredTypes.includes(message.type)
            ) return;

            // @ts-ignore
            const parentSpan = parentSpans[message.id];
  
            const ctx = trace.setSpan(
                context.active(),
                parentSpan
            );

            const span = tracer.startSpan("websocket-message", undefined, ctx)

            span.setAttribute("message.type", message.type)

            // @ts-ignore
            const payload = message.payload
            
            if (payload) {
                span.setAttribute("message.payload", JSON.stringify(payload))
            }

            span.end()
        })

        const wsLink = new GraphQLWsLink(
            websocketClient
        );

        return split(
            ({ query }) => {
                const definition = getMainDefinition(query);

                return (
                    definition.kind === "OperationDefinition" &&
                    definition.operation === "subscription"
                );
            },
            wsLink,
            httpLink
        );
    }

    return httpLink;
};

export const getApolloLink = () => {
    return ApolloLink.from([errorLink,getLink()]);
}

const getApolloClient = () => new ApolloClient({
    link: getApolloLink(),
    cache: new InMemoryCache(),
    defaultOptions: {
        query: {
            fetchPolicy: "network-only",
            errorPolicy: "all",
        },
        mutate: {
            fetchPolicy: "network-only",
            errorPolicy: "all",
        },
        watchQuery: {
            fetchPolicy: "cache-and-network",
            errorPolicy: "all",
        },
    },
});

export default getApolloClient;
