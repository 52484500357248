// const currentUserScore = getScore({
//     side: currentUserSide,
//     initialFEN,
//     moves,
// });
import { Moves } from "@/types";
import getScore from "../getScore";

// const opponentScore = getScore({
//     side: currentUserSide === "white" ? "black" : "white",
//     initialFEN,
//     moves,
// });

// const scoreDifference = Math.abs(currentUserScore - opponentScore);

type GetWinnerProps = {
    initialFEN: string;
    moves: Moves;
};

const getWinner = ({ moves, initialFEN }: GetWinnerProps) => {
    const {
        score: whiteScore,
        isWhiteKingCaptured,
        isBlackKingCaptured,
    } = getScore({
        initialFEN,
        moves,
        side: "white",
    });

    if (isWhiteKingCaptured) return "black";

    if (isBlackKingCaptured) return "white";

    const { score: blackScore } = getScore({
        initialFEN,
        moves,
        side: "black",
    });

    /* const scoreDifference = Math.abs(whiteScore - blackScore);*/

    if (moves.length < 12) return null; 

    if (whiteScore > blackScore) return "white";

    if (blackScore > whiteScore) return "black";

    return "draw";
};

export default getWinner;
