import React, { useEffect, useMemo } from "react";
import Loader from "@/components/Loader";
import { isClient } from "@/utils";
import { useRouter } from "next/router";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { v4 as uuidv4 } from "uuid";
import CurrentUserContext from "./context";
import { useGetUserAdditionalInfoSubscription } from "./operations/operations.generated";
import ROUTES from "@/constants/routes";
import { useAnonymousUser } from "./hooks";
import { useApolloClient } from "@apollo/client";
import { getApolloLink } from "@/apollo";

const ANONYMOUS_USER_ALLOWED_ROUTES = new Set([
    ROUTES.termsAndConditions(),
    ROUTES.rules(),
    "/404",
]);

const useUserID = () => {
    if (!isClient()) return null;

    const localStorageKey = "userID";

    const userID = localStorage.getItem(localStorageKey);

    if (userID) return userID;

    const newUserID = uuidv4();

    localStorage.setItem(localStorageKey, newUserID);

    return newUserID;
};

const CurrentUserProvdiver = ({ children }: { children: React.ReactNode }) => {
    const id = useUserID();

    const { pathname, query, replace } = useRouter();

    const session = useSessionContext();

    const apolloClient = useApolloClient();

    // @ts-ignore
    const userID = session.userId;

    const isAuthenticated = !session.loading && session.doesSessionExist;

    const anonymousUser = useAnonymousUser({
        isAuthenticated,
        isAuthLoading: session.loading,
    });

    const isSessionLoading =
        session &&
        (session?.loading || (!session.loading && !session.doesSessionExist));

    /* const isAnonymousUserGameRoom =
        pathname.includes("/room") &&
        !isAuthenticated &&
        !anonymousUser.isCreated; */

    const { data, refetch } = useGetUserAdditionalInfoSubscription({
        skip: !id || (!isAuthenticated && !anonymousUser.isCreated),
    });

    useEffect(() => {
        if (userID !== undefined) sessionStorage.setItem("userID", userID);

        if (userID) {
            apolloClient.setLink(getApolloLink());

            refetch();
        }
    }, [userID]);

    useEffect(() => {
        if (session && !session.loading) {
            if (ANONYMOUS_USER_ALLOWED_ROUTES.has(pathname)) return;

            const localStorageItem =
                window.localStorage.getItem("REDIRECT_URL");

            if (session?.doesSessionExist) {
                //
            } else {
                if (!localStorageItem && pathname) {
                    window.localStorage.setItem(
                        "REDIRECT_URL",
                        JSON.stringify({
                            pathname: pathname.startsWith("/auth")
                                ? "/"
                                : pathname,
                            query: query,
                        })
                    );
                }

                const isOnboardPage =
                    !pathname.startsWith("/auth") &&
                    !pathname.startsWith("/room");

                if (pathname.startsWith("/auth")) {
                    if (pathname.includes("verify-otp")) {
                        return;
                    } else {
                        replace(ROUTES.login());
                    }
                } else if (pathname.startsWith("/room") && query?.roomID)
                    replace(
                        ROUTES.room({ roomID: query?.roomID?.toString() + "" })
                    );
                else if (isOnboardPage) replace(ROUTES.onBoard());
            }
        }
    }, [session, pathname]);

    const {
        walletBalance,
        userName,
        isUserActivated,
        isAdmin,
        isTournamentEnabled,
        anonymousUserInfo,
    } = useMemo(() => {
        const {
            name,
            wallet_balance: walletBalance,
            is_user_activated: isUserActivated,
            is_tournament_enabled: isTournamentEnabled,
            is_admin: isAdmin,
            user_id: anonymousUserID,
            user_type: userType,
            onboard_current_game: currentOnboardGame,
        } = data?.supertokens_user_additional_fields?.[0] ?? {};

        const anonymousUserInfo = {
            userType,
            anonymousUserID,
            currentOnboardGame,
        };

        return {
            userName: name ?? "",
            walletBalance: (walletBalance ?? 0).toFixed(2),
            isUserActivated: isUserActivated ?? false,
            isTournamentEnabled: isTournamentEnabled ?? false,
            isAdmin: isAdmin ?? false,
            anonymousUserInfo:
                userType === "anonymous" ? anonymousUserInfo : null,
        };
    }, [data]);

    const isOnboardRoom =
        pathname.startsWith("/room") &&
        query?.roomID &&
        anonymousUserInfo?.userType === "anonymous";

    const isAnonymousUser = !isAuthenticated && anonymousUser.isCreated;

    const isLoading = isAnonymousUser
        ? !pathname.startsWith("/auth") &&
          !isOnboardRoom &&
          !pathname.startsWith("/onboarding") &&
          !isAuthenticated &&
          !pathname.startsWith("/404")
        : (isSessionLoading && !ANONYMOUS_USER_ALLOWED_ROUTES.has(pathname)) ||
          (userID && !data?.supertokens_user_additional_fields);

    useEffect(() => {
        if (!isLoading) {
            setTimeout(() => {
                window.ReactNativeWebView?.postMessage(
                    JSON.stringify({
                        event: "page-loaded",
                    })
                );
            });
        }
    }, [isLoading]);

    return (
        <CurrentUserContext.Provider
            value={{
                id,
                userID: userID ?? anonymousUserInfo?.anonymousUserID ?? "",
                userName,
                walletBalance,
                isUserActivated,
                isTournamentEnabled,
                isAdmin,
                anonymousUserInfo,
            }}
        >
            {isLoading ? (
                <div className="h-screen w-full">
                    <Loader />
                </div>
            ) : (
                children
            )}
        </CurrentUserContext.Provider>
    );
};

export default CurrentUserProvdiver;
