/* eslint-disable unicorn/filename-case */
const switchNextMoveSideInFEN = (fen: string) => {
    const splits = fen.split(" ");

    splits[1] = splits[1] === "w" ? "b" : "w";

    return splits.join(" ");
};

export default switchNextMoveSideInFEN;
