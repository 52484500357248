/* eslint-disable unicorn/prefer-module */

/* eslint-disable @typescript-eslint/no-var-requires */
// @ts-nocheck
import {
    diag,
    DiagConsoleLogger,
    DiagLogLevel,
} from "@opentelemetry/api";
import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { Resource } from "@opentelemetry/resources";
import {
    SimpleSpanProcessor,
    TracerConfig,
    WebTracerProvider,
} from "@opentelemetry/sdk-trace-web";
import { SemanticResourceAttributes } from "@opentelemetry/semantic-conventions";
import { WSInstrumentation } from "opentelemetry-instrumentation-ws";


let previousClickName: string;

const initializeTelemetry = () => {
    // @ts-ignore
    const { ZoneContextManager } = require("@opentelemetry/context-zone");

    const { OTLPTraceExporter } = require("./customExporter")

    diag.setLogger(new DiagConsoleLogger(), DiagLogLevel.VERBOSE);

    try {
        const providerConfig: TracerConfig = {
            resource: new Resource({
                [SemanticResourceAttributes.SERVICE_NAME]: "web-client",
            }),
        };

        const provider = new WebTracerProvider(providerConfig);

        // we will use ConsoleSpanExporter to check the generated spans in dev console
        provider.addSpanProcessor(
            new SimpleSpanProcessor(
                new OTLPTraceExporter({
                    url: "https://jaegar-reverse-proxy.caprover.articunocoding.com/v1/traces",
                })
            )
        );

        provider.register({
            contextManager: new ZoneContextManager(),
        });

        registerInstrumentations({
            instrumentations: [
                // getWebAutoInstrumentations initializes all the package.
                // it's possible to configure each instrumentation if needed.
                getWebAutoInstrumentations({
                    "@opentelemetry/instrumentation-document-load": {
                        applyCustomAttributesOnSpan: {
                            documentLoad: (span) => {
                                const url = span.attributes["http.url"];

                                
                                if (url) {
                                    const path = new URL(url).pathname;

                                    span.updateName(`${path} - load`);
                                }
                            }
                        }
                    },
                    '@opentelemetry/instrumentation-user-interaction': {
                        shouldPreventSpanCreation: (eventType, element, span) => {
                            // update name of span based on data-testid or text
                            if (eventType === "click") {
                                const dataTestID = element.getAttribute("data-testid");
                                
                                const text = element.innerText;
                                

                                let updatedName = span.name;


                                if (text) {
                                    updatedName = `${text} - innertext - click`;
                                    span.setAttribute("text", text);
                                }


                                if (dataTestID) {
                                    updatedName = `${dataTestID} - testid - click`;
                                    span.setAttribute("data-testid", dataTestID);
                                }

                                // replace multiple spaces with single space
                                updatedName = updatedName.replace(/\s\s+/g, " ");

                                span.updateName(updatedName);

                                // this is done because react will fire multiple events
                                if (previousClickName === updatedName) {
                                    return true;
                                }

                                previousClickName = updatedName;
                            }

                            return false;
                        }
                    },
                    "@opentelemetry/instrumentation-fetch": {
                        propagateTraceHeaderCorsUrls: [
                            new RegExp("localhost:9000"),
                            new RegExp(
                                "sportsfam-chess-hasura.caprover.articunocoding.com"
                            ),
                            new RegExp(
                                "sportsfam-chess-server.caprover.articunocoding.com"
                            ),
                        ],
                        applyCustomAttributesOnSpan: (span, request, result) => {

                            const body = JSON.parse(request.body || "{}")

                            if (body.operationName) {
                                span.setAttribute(`operationName`, body.operationName)

                                span.updateName("gateway.request")
                            }
                        }
                    },
                }),
                new WSInstrumentation({
                    messageEvents: true,
                  })
            ],
        });
    } catch (error) {
        console.log({
            error,
        });
    }
};

export default initializeTelemetry;
