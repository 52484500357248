/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react";
import {
    DocumentNode,
    OperationVariables,
    QueryHookOptions,
    QueryResult,
    SubscriptionHookOptions,
    SubscriptionResult,
    gql,
    useApolloClient,
    useSubscription as useApolloSubscription,
    useQuery,
} from "@apollo/client";

// export the rest of the hooks from "@apollo/react-hooks"
export * from "@apollo/client";

type FunctionType = <
    TData = unknown,
    TVariables extends OperationVariables = OperationVariables
>(
    subscriptionOperationsDoc: DocumentNode,
    options:
        | QueryHookOptions<TData, TVariables>
        | SubscriptionHookOptions<TData, TVariables>
) =>
    | QueryResult<TData, TVariables>
    | (SubscriptionResult<TData, TVariables> & {
          refetch: QueryResult<TData, TVariables>["refetch"];
      });

const useSubscriptionWithCacheUpdate: FunctionType = <
    TData = unknown,
    TVariables extends OperationVariables = OperationVariables
>(
    subscriptionOperationsDoc: DocumentNode,
    options:
        | QueryHookOptions<TData, TVariables>
        | SubscriptionHookOptions<TData, TVariables>
) => {
    const queryClient = useApolloClient();

    // eslint-disable-next-line unicorn/template-indent
    const query = gql`
        ${subscriptionOperationsDoc.loc?.source.body.replace(
            /subscription/,
            "query"
        )}
    `;

    const subscriptionResult = useApolloSubscription<TData, TVariables>(
        subscriptionOperationsDoc,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        {
            ...options,
            fetchPolicy: "network-only",
        }
    );

    if (process.env.NODE_ENV === "test")
        return {
            ...subscriptionResult,
            refetch: () => {
                //
            },
        } as any;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const queryResult = useQuery<TData, TVariables>(query, options);

    const queryLastFetchedTimeStamp = useMemo(() => Date.now(), [queryResult]);

    const subscriptionLastFetchedTimeStamp = useMemo(
        () => Date.now(),
        [subscriptionResult]
    );

    const result = useMemo(() => {
        const queryResultData = queryResult.data || {};

        const subscriptionResultData = subscriptionResult.data || {};

        if (queryResultData && subscriptionResultData) {
            if (queryLastFetchedTimeStamp > subscriptionLastFetchedTimeStamp) {
                return queryResult;
            } else {
                // check if subscriptionResultData is empty
                if (Object.keys(subscriptionResultData).length === 0) {
                    return queryResult;
                }

                // since we are using useQuery, we need to update the cache with the subscription data
                queryClient.writeQuery({
                    query: subscriptionOperationsDoc,
                    variables: options.variables,
                    data: subscriptionResultData,
                });

                return {
                    ...subscriptionResult,
                    refetch: queryResult.refetch,
                } as SubscriptionResult<TData, TVariables> & {
                    refetch: QueryResult<TData, TVariables>["refetch"];
                };
            }
        } else if (queryResultData) {
            return queryResult;
        } else if (subscriptionResultData) {
            return {
                ...subscriptionResult,
                refetch: queryResult.refetch,
            } as SubscriptionResult<TData, TVariables> & {
                refetch: QueryResult<TData, TVariables>["refetch"];
            };
        }

        return queryResult;
    }, [queryResult, subscriptionResult]);

    return result;
};

export const useSubscription = useSubscriptionWithCacheUpdate;
