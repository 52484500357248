import React, {
    useRef,
    memo,
    useState,
    useEffect,
    useCallback,
    Ref,
} from "react";
import { RWebShare } from "react-web-share";
import ShareContext from "./context";

export type OpenShareProps = {
    url: string;
    title: string;
    text: string | number | boolean;
};

export type ShareProps = {
    data: {
        url: string;
        title: string;
        text?: string;
    };
    innerRef: Ref<HTMLButtonElement>;
};

// This is seperated and memoized to avoid re-rendering the component
const Share = memo(
    ({ innerRef, data }: ShareProps) => (
        <RWebShare
            data={data}
            onClick={() => {
                // if (
                //     type === "copy" &&
                //     typeof window.ReactNativeWebView != "undefined" &&
                //     data?.url
                // )
                // window.ReactNativeWebView.copyToClipboard(data.url);
                /* else
                    navigator.clipboard.writeText(text); */
            }}
        >
            <button ref={innerRef} style={{ display: "none" }}></button>
        </RWebShare>
    ),
    (prevProps, nextProps) =>
        prevProps.innerRef === nextProps.innerRef &&
        prevProps.data.url === nextProps.data.url
);

Share.displayName = "Share";

const ShareProvider = ({ children }: { children: React.ReactNode }) => {
    const [shareContent, setShareContent] = useState({});

    const rwebShareRef = useRef<HTMLButtonElement>();

    const openShare = useCallback((data: OpenShareProps) => {
        setShareContent({
            ...data,
            text: window?.navigator?.canShare?.()
                ? data?.text
                : encodeURIComponent(data?.text),
        });
    }, []);

    const shareRoomLink = useCallback(
        (data: OpenShareProps) => {
            openShare(data);
        },
        [openShare]
    );

    useEffect(() => {
        if (Object.keys(shareContent).length > 0 && rwebShareRef.current) {
            rwebShareRef.current?.click();
        }
    }, [shareContent, rwebShareRef]);

    return (
        <ShareContext.Provider
            value={{
                openShare,
                shareRoomLink,
            }}
        >
            {children}
            {/* @ts-ignore */}
            <Share data={shareContent} innerRef={rwebShareRef} />
        </ShareContext.Provider>
    );
};

export default ShareProvider;
