const TRANSACTION_STATUS = {
    FAILED: "failed",
    PENDING: "pending",
    FREE_ROLL: "free-roll",
    SUCCESS: "success",
    FREE_ROLL_ENTRY: "free-roll-entry",
    SIGNUP: "sign-up",
};

const TRANSACTIONS = {
    GIFT: "gift",
    DOCUMENT: "document",
    TICKET: "ticket",
    WALLET: "wallet",
    TROPHY: "trophy",
};

export { TRANSACTIONS, TRANSACTION_STATUS };
