import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import { CurrentUserProvider } from "@/contexts/currentUser";
import { ShareProvider } from "@/contexts/share";
import useGoBack from "@/hooks/useGoBack";
import initialize from "@/initializers";
import "@/styles/globals.css";
import { ApolloProvider } from "@apollo/client";
import { trace } from "@opentelemetry/api";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { SuperTokensWrapper } from "supertokens-auth-react";
import getApolloClient from "@/apollo";

initialize();

let previousURL = "";

const useWebviewListener = () => {
    const { pathname, events } = useRouter();

    useEffect(() => {
        events.on("routeChangeComplete", (url) => {
            if (previousURL === url) return;

            const tracer = trace.getTracer("web-client");

            tracer.startActiveSpan("RouteChangeStart", (span) => {
                span.setAttribute("http.url", url);

                const userID = sessionStorage.getItem("userID");

                if (userID) span.setAttribute("user.id", userID);

                span.updateName(`RouteChangeStart: ${url}`);
                span.end();

                return `RouteChangeStart: ${url}`;
            });

            previousURL = url;
        });

        return () => {
            events.off("routeChangeComplete", () => {
                console.log("unsubscribed");
            });
        };
    }, []);

    const goBack = useGoBack();

    const [backButtonPressCount, setBackButtonPressCount] = useState<number>(0);

    useEffect(() => {
        if (window.ReactNativeWebView) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const listener = ({ data }: any) => {
                const { event } = JSON.parse(data);

                switch (event) {
                    case "current-version": {
                        // alert(JSON.stringify(payload));
                        break;
                    }
                    case "hardware-back-button-pressed": {
                        // check if we're on home page
                        if (pathname === "/") {
                            setBackButtonPressCount((prevCount) => {
                                if (prevCount === 0) {
                                    window.ReactNativeWebView.postMessage(
                                        JSON.stringify({
                                            event: "toast",
                                            payload: {
                                                message:
                                                    "Press Back button twice to close the app",
                                                duration: 3000,
                                            },
                                        })
                                    );
                                } else {
                                    window.ReactNativeWebView.postMessage(
                                        JSON.stringify({
                                            event: "exit-app",
                                        })
                                    );
                                }

                                return backButtonPressCount + 1;
                            });

                            // reset count in 3 seconds
                            setTimeout(() => setBackButtonPressCount(0), 3000);

                            return;
                        }

                        goBack();
                        break;
                    }
                    default: {
                        break;
                    }
                }

                return;
            };

            document.addEventListener("message", listener);

            return () => {
                document.removeEventListener("message", listener);
            };
        }
    }, [goBack]);
};

const apolloClient = getApolloClient();

export default function App({ Component, pageProps }: AppProps) {
    useWebviewListener();

    return (
        <SuperTokensWrapper>
            <ShareProvider>
                <ApolloProvider client={apolloClient}>
                    <CurrentUserProvider>
                        <Component {...pageProps} />
                        <ToastContainer />
                    </CurrentUserProvider>
                </ApolloProvider>
            </ShareProvider>
        </SuperTokensWrapper>
    );
}
